import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { whiteColor } from 'src/assets/jss/GlobalStyles';
import OkButton from 'src/components/buttons/ok-button.component';
import UnsuccessfulVINsList from 'src/components/form-elements/UnsuccessfulVINsList';
import FadeLoader from "react-spinners/FadeLoader";

const useStyles = makeStyles({
  title: {
    fontSize: (props) => props.dialogue ? '24px' : props.variant ? '24px' : 'medium',
    fontWeight: 'normal',
    fontFamily: (props) => props.dialogue ? "LynkcoTypeMedium" : props.variant ? "LynkcoTypeMedium" : "LynkcoTypeLight",
    color: whiteColor,
    textAlign: (props) => props.dialogue ? 'center' : props.variant ? 'center' : 'center',
    verticalAlign: (props) => props.dialogue ? 'center' : props.variant ? 'center' : 'center',
    marginBottom: (props) => props.dialogue ? '-25px' : props.variant ? '-30px' : '',
    marginTop: (props) => props.dialogue ? '0px' : props.variant ? '5px' : '25px',
    textWrap: 'wrap',
    padding: '10px'
  },
  message: {
    color: whiteColor,
    fontFamily: "LynkcoTypeLight",
    textAlign: 'center',
    verticalAlign: 'center',
    marginTop: (props) => props.variant ? '45px' : props.dialogue ? '20px' : '25px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
  },
  dialogAction: {
    color: whiteColor,
    fontFamily: '"LynkcoTypeLight", "LynkcoMedium", "LynkcoRegular"',
    fontWeight: 'normal',
    margin: 0,
    position: 'absolute',
    top: '90%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
  },
  paperScrollPaper: {
    backgroundColor: ' #1c1c1c',
    border: (props) => props.toggleDialogue ? '1px solid #dc1860' : 'none',
    minHeight: (props) => props.dialogue ? 270 : props.variant ? 300 : 220,
    width: (props) => props.dialogue ? 500 : props.variant ? 550 : 370,
    boxShadow: '0 5px 6px #000000',
    top: (props) => props.dialogHeight ? 350 : props.vspheight ? 400 : 300,
    borderRadius: '30px',
  }
});

const VehiclePageDialog = (props) => {
  const classes = useStyles(props);
  const { open, onClose, message, handleDialogOk, title, dialogue = false, unSuccessfulVINs, variant = false, dialogHeight = false, vspheight = false, toggleDialogue, spinnerConfirmButtonProvision } = props;

  return (
    <div >
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        classes={{
          paperScrollPaper: classes.paperScrollPaper
        }}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          position: 'absolute',
          zIndex: 2000,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }}
        disableScrollLock
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          <Typography className={classes.title}>{title} </Typography>
        </DialogTitle>

        <DialogContent className={classes.message} >
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.message}>{message} </Typography>
          </DialogContentText>
          {props.variant && unSuccessfulVINs?.length > 0 ? <UnsuccessfulVINsList unSuccessfulVINs={unSuccessfulVINs} /> : <></>}
        </DialogContent>

        <DialogActions className={classes.dialogAction} >
          <>
            {
              spinnerConfirmButtonProvision ?
                <div className="loaderstyle">
                  <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                </div>
                :
                <OkButton variant={variant} toggleDialogue={toggleDialogue} handleDialogOk={handleDialogOk} dialogue={dialogue} />
            }
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VehiclePageDialog;