import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: (props) => props.standardvalueform ? "#FF131E" : "white",
      backgroundColor: "#000",
      borderRadius: '300px',
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      '@media (min-width: 1501px) and (max-width: 1600px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(8.5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1401px) and (max-width: 1500px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1111px) and (max-width: 1400px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 600px) and (max-width: 850px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1025px) and (max-width: 1075px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(7px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1000px) and (max-width: 1024px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(6px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    },
    "& .MuiInputLabel-root": {
      color: "white",
      backgroundColor: "#000)",
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      '@media (min-width: 600px) and (max-width: 850px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(15px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1000px) and (max-width: 1024px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(6px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1025px) and (max-width: 1075px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(7px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(255, 255, 255, 0.01)',
      borderRadius: '300px',
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiInputLabel-root": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '300px',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1EF1C6"
    },
    "& .MuiOutlinedInput-root.Mui-selected .MuiOutlinedInput-input": {
      color: "#1EF1C6"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#1EF1C6"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    },
  },
  dropdownStyle: {
    border: "1px solid black",
    color: '#fff',
    width: '20px',
    backgroundColor: '#000',
    height: (props) => props.obcdropdownheight ? '165px' : props.towbardropdownheight ? '185px' : '300px',
    borderRadius: '30px',
    fontFamily: "LynkcoTypeLight",
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c",
      borderRadius: '30px',
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121",
      borderRadius: '30px',
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000',
      borderRadius: '30px'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010",
      borderRadius: '10px'
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    },
    "& .MuiMenuItem-root": {
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      fontFamily: "LynkcoTypeLight",
      '@media (min-width: 600px) and (max-width: 850px)': {
        fontSize: (props) => props.dropdownfont ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    }
  }
}));

const FormSelect = (props) => {
  const { open, onClose, onOpen, value, onChange, menuObjectArray = [{ key: '', value: '' }], label, id, name, variant, disabled = false, dropdownfont = false, obcdropdownheight = 'false', towbardropdownheight = "false", standardvalueform = "false" } = props
  const classes = useStyles(props);

  return (
    <div>
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          IconComponent={ChevronDownIcon}
          id={id}
          name={name}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          value={value}
          onChange={onChange}
          disabled={disabled}
          dropdownfont={dropdownfont}
          obcdropdownheight={obcdropdownheight}
          towbardropdownheight={towbardropdownheight}
          standardvalueform={standardvalueform}
          MenuProps={{
            classes: { paper: classes.dropdownStyle }, disableScrollLock: true
          }}
        >
          <MenuItem disabled value="">
            <em style={{ fontStyle: 'normal' }}>{label} </em>
          </MenuItem>
          {
            menuObjectArray.map((menu, key) => {
              return (<MenuItem key={key} value={menu.key}>{menu.value}</MenuItem>);
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default FormSelect;