import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from "src/redux/user/user.selectors";
import BlackCar from "src/assets/img/BackgroundImages/BlackCar.png";
import BlueCar from "src/assets/img/BackgroundImages/BlueCar.png";
import actionButton_dark_lock from "src/assets/img/vehicles/actionButton_dark_lock.png";
import actionButton_dark_unlock from "src/assets/img/vehicles/actionButton_dark_unlock.png";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BelgiumFlag from "src/assets/img/flags/BelgiumFlag.png";
import FranceFlag from "src/assets/img/flags/FranceFlag.png";
import SwedenFlag from "src/assets/img/flags/SwedenFlag.png";
import ItalyFlag from "src/assets/img/flags/ItalyFlag.png";
import GermanyFlag from "src/assets/img/flags/GermanyFlag.png";
import SpainFlag from "src/assets/img/flags/SpainFlag.png";
import NetherlandsFlag from "src/assets/img/flags/Netherlands.png";
import MarketSelect from 'src/components/form-elements/market-select.component';
import FormSelect from 'src/components/form-elements/custom-form-select.component';
import { GetPNOCodesByMarket } from "src/services/pnoCode-http.service";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { permission } from 'src/auth/permissions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Icon } from '@iconify/react';
import { GetB2BUsedCarStockRuleByMarketAndPnoCode, CreateB2BUsedCarStockRule } from "src/services/B2BUsedCarStockRule-http.service";
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        height: '2px',
        marginBottom: '16px'
    },
    titleStyle: {
        marginLeft: '5px',
        fontSize: 'calc(10px + 0.390625vw)',
        marginTop: '20px',
        fontFamily: "LynkcoTypeMedium",
        '@media (min-width: 600px) and (max-width: 1000px)': {
            fontSize: 'calc(17px + 0.390625vw)'
        },

    },
    imageStyleFlag: {
        width: '90%',
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            width: '65%',
        },
    },
    imageStyleCar: {
        marginTop: '10px',
        width: '65%',
        '@media (min-width: 600px) and (max-width: 850px)': {
            width: '50%',
        },
    },
    imageStyleButton: {
        marginTop: '-80px',
        width: '20%',
        cursor: 'pointer',
        boxShadow: '3px 5px 6px #000000',
        borderRadius: '50%'
    },
    backgroundStyle: {
        backgroundColor: 'transparent',
        marginTop: '80px',
        borderRadius: '1px',
        position: 'relative',
        overflow: 'auto',
        overflowX: 'hidden',
        height: '80vh',
        "&::-webkit-scrollbar": {
            height: "0.1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
        marginRight: '0px',
        marginLeft: '0px',
        '@media (min-width: 600px) and (max-width: 1000px)': {
            marginRight: '8px',
            marginLeft: '0px',
            marginTop: '80px',
            overflowY: 'auto'
        },
        '@media (min-width: 1001px) and (max-width: 1075px)': {
            marginRight: '10px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
        },
        '@media (min-width: 1441px) and (max-width: 1600px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '70px',
        },
        '@media (min-width: 1401px) and (max-width: 1440px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '50px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginRight: '0px',
            marginLeft: '0px',
            marginTop: '80px',
        },
    },
    flagStyle: {
        position: 'absolute',
        width: '100%',
        height: '400px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all 0.50s ease',
        "&::-webkit-transition": 'all 0.50s ease',
        "&::-moz-transition:": 'all 0.50s ease'
    },
}));

let queryParams = {}

const UCAssignment = (props) => {
    const { userOrg, userMarketIds, userPermissions } = props
    const classes = UseStyles(props);

    const [selectPNO, setSelectPNO] = useState(false);
    const [selectMarket, setSelectMarket] = useState(false);
    const [isMarketSelected, setIsMarkedSelected] = useState(false)
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [pnoCode, setPNOCode] = useState([]);
    const [pnoCodeDetails, setPNOCodeDetails] = useState([]);
    const [pnoCodeDetail, setPNOCodeDetail] = useState({});
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [placeholder, setPlaceholder] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const [displayPNO, setDisplayPNO] = useState(false);
    const [displaySelect, setDisplaySelect] = useState(false);

    const [displayAge, setDisplayAge] = useState('');
    const [displayMileage, setDisplayMileage] = useState('');

    const [pnoAge, setPNOAge] = useState(null);
    const [pnoMileage, setPNOMileage] = useState(null);
    const [pnoCriteria, setCriteria] = useState('');

    const [value, setValue] = useState('');
    const [hideLabel, setHideLabel] = useState('');

    useEffect(() => {
        selectPNOCode();
        getPNOCode();
    }, [queryParamsMarket]);

    let marketId = 'NONE';

    const handleChange = (event, markets) => {
        const { name, value } = event.target;
        let orgsMarkets = [];
        marketId = value.split('#')[0];
        let marketName = value.split('#')[1];
        setHideLabel(event.target.value);

        if (marketId == 'NONE') {
            setIsMarkedSelected(false);
            setSelectMarket(false);
            setDisplayPNO(false);
            setDisplaySelect(false);
        }
        else {
            setIsMarkedSelected(true);
            setSelectMarket(true);
            setDisplayPNO(true);
            setDisplaySelect(false);
        }

        setSelectedMarket({ key: name, value: value });
        setQueryParamsMarket(marketId);
        handleReset();
    };

    const handleReset = () => {
        setSelectPNO(false);
        setQueryParamsPNOCode('');
        setDisplayAge('');
        setDisplayMileage('');
    };

    const handleChangeRadioButtons = (event) => {
        setValue(event.target.value);
        if (event.target.value == 'age') {
            setCriteria('Age');
            setDisplayMileage('');
        };

        if (event.target.value == 'mileage') {
            setCriteria('Mileage');
            setDisplayAge('');
        };

        if (event.target.value == 'age & mileage') {
            setDisplayAge('');
            setDisplayMileage('');
            setCriteria('AgeAndMileage');
        };
    };

    const selectPNOCode = () => {
        let pnoCodeArray = [];
        let pnoCodeDetails = [];

        GetPNOCodesByMarket(queryParamsMarket).then(data => {
            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeArray.push({
                        key: pnoCode.pnoCode,
                        value: pnoCode.pnoCode
                    });
                })
                : console.log("Error Occured");
            setPNOCode(pnoCodeArray);

            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeDetails.push({
                        pnoCode: pnoCode.pnoCode,
                        lcColor: pnoCode.lcColor,
                        lcEngineType: pnoCode.lcEngineType,
                        lcModelYear: pnoCode.lcModelYear,
                        obcVersion: pnoCode.obcVersion
                    });
                    setPNOCodeDetails(pnoCodeDetails);
                })
                : console.log("Error Occured");
        });
    };

    queryParams = { ...queryParams, marketId: queryParamsMarket };
    queryParams = { ...queryParams, pnoCode: queryParamsPNOCode };

    const getPNOCode = (pnoCodeValue) => {
        let filteredValue = pnoCodeDetails.filter(item => item.pnoCode === pnoCodeValue)[0];
        setPNOCodeDetail(filteredValue);
    };

    const handleChangeExtended = (name) => (event) => {
        setPNOAge(null);
        setPNOMileage(null);
        setCriteria('');
        setValue('');
        let value = event.target.value;
        console.log(event.target);

        if (name == 'pnoCode') {
            setQueryParamsPNOCode(value);
            getPNOCode(value);
            setSelectPNO(true);
            setDisplaySelect(true);
            handleFetchSettings(queryParamsMarket, value);
            if (value == '') {
                setDisplaySelect(false);
            };
        };
    };

    const handleSubmit = () => {
        let market = queryParamsMarket;
        let pnoCode = queryParamsPNOCode;
        let age = parseInt(displayAge.split(" ")[0]) > 0 ? parseInt(displayAge.split(" ")[0]) : 0;
        let mileage = parseInt(displayMileage.replace(/-|\s/g, "").slice(0, -2)) > 0 ? parseInt(displayMileage.replace(/-|\s/g, "").slice(0, -2)) : 0

        if (placeholder === true) {
            setPlaceholder(false);
        } else {
            CreateB2BUsedCarStockRule(market, pnoCode, age, mileage, pnoCriteria).then(data => {
                if (data.ok === true) {
                    setPlaceholder(true);
                    setOpenDialog(true);
                    setDialogMessage(`Your new configuration is now applied`);
                } else {
                    setOpenDialog(true);
                    setDialogMessage('Please make sure that Market, PNO, Age and Mileage value is selected ');
                }
            })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const handleFetchSettings = (market, pnoCode) => {
        setDisplayAge('');
        setDisplayMileage('');
        GetB2BUsedCarStockRuleByMarketAndPnoCode(market, pnoCode).then(data => {
            if (data.ok == true) {
                let filteredPNO = data.b2BUsedCarStockRule.filter(item => item.pnoCode === pnoCode)[0];

                if (data.b2BUsedCarStockRule.length > 0) {
                    setPNOAge(filteredPNO.age);
                    setPNOMileage(filteredPNO.mileage);
                    setCriteria(filteredPNO.criteria);
                    if (filteredPNO.criteria == 'AgeAndMileage') {
                        setValue('age & mileage');
                        setDisplayAge(`${filteredPNO.age} months`);
                        setDisplayMileage(`${formatAndVerifyNumericValue(filteredPNO.mileage)}km`);
                        if (filteredPNO.age > 0 || filteredPNO.mileage > 0) {
                            setPlaceholder(true);
                        };
                    }

                    if (filteredPNO.criteria == 'Age') {
                        setValue('age');
                        setDisplayAge(`${filteredPNO.age} months`);
                        if (filteredPNO.age > 0) {
                            setPlaceholder(true);
                        };
                    }

                    if (filteredPNO.criteria == 'Mileage') {
                        setValue('mileage');
                        setDisplayMileage(`${formatAndVerifyNumericValue(filteredPNO.mileage)}km`);
                        if (filteredPNO.mileage > 0) {
                            setPlaceholder(true);
                        };
                    }

                    if (filteredPNO.criteria == '') {
                        setValue('age');
                        setCriteria('Age');
                    }

                }
            } else {
                setValue('age');
                setCriteria('Age');
            }
        })
    };

    const handleChangeUCA = (value) => (event) => {
        if (value == 'age') {
            setDisplayAge(event.target.value);
        };

        if (value == 'mileage') {
            setDisplayMileage(event.target.value);
        };
    };

    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

    return (
        <>
            <div className={classes.backgroundStyle}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                />

                <Row >
                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        <Card id="CardBodyCardAllowance8">
                            <CardBody>
                                <Row style={{ marginTop: '-20px' }}>
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select Market
                                        </Typography>

                                        <div className="formwidthstyleBMC" style={{ marginLeft: '5px', marginTop: '17px' }}>
                                            <MarketSelect
                                                orgId={userOrg.id}
                                                value={selectedMarket.value}
                                                userMarketIds={userMarketIds}
                                                handleChange={handleChange}
                                                isVariant='true'
                                                hideLabel={hideLabel}
                                                isStandardMarket="true"
                                            />
                                        </div>

                                        {selectMarket &&
                                            <div className="flagstylebmc">
                                                <span
                                                    className={classes.flagStyle}
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                    onClick={() => props.onClick && props.onClick()}
                                                    style={{
                                                        ...{ opacity: hovered ? '0.5' : '1' }
                                                    }}
                                                >
                                                    <img
                                                        alt="Card image cap"
                                                        src={
                                                            queryParamsMarket == 'BE' ? BelgiumFlag :
                                                                queryParamsMarket == 'SE' ? SwedenFlag :
                                                                    queryParamsMarket == 'DE' ? GermanyFlag :
                                                                        queryParamsMarket == 'ES' ? SpainFlag :
                                                                            queryParamsMarket == 'NL' ? NetherlandsFlag :
                                                                                queryParamsMarket == 'FR' ? FranceFlag :
                                                                                    queryParamsMarket == 'IT' ? ItalyFlag : <></>
                                                        }
                                                        className={classes.imageStyleFlag}
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        {
                            displayPNO &&
                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12">
                                            <Col lg="12" md="12" style={{ marginLeft: '-11px' }}>
                                                <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                    Select PNO
                                                </Typography>
                                            </Col>

                                            <div className="formwidthstyleBMC" style={{ marginLeft: '10px', marginTop: '17px' }}>

                                                <FormSelect
                                                    label='PNO'
                                                    name='pnoCode'
                                                    dropdownfont='true'
                                                    value={queryParamsPNOCode}
                                                    menuObjectArray={pnoCode}
                                                    onChange={handleChangeExtended("pnoCode")}
                                                />
                                            </div>

                                            <Row style={{ marginTop: '30px' }}>
                                                <Col md={12} sm={12} xs={12} lg={12}>
                                                    {selectPNO &&
                                                        <>
                                                            {
                                                                pnoCodeDetail !== undefined &&
                                                                <div className="tableFixHead">
                                                                    <Row>
                                                                        <table style={{ width: '100%', color: 'white' }}>
                                                                            <tr>
                                                                                <td id="TabletitlesPoolCars">
                                                                                    {
                                                                                        <img
                                                                                            src={pnoCodeDetail.lcColor == 'Blue' ? BlueCar : BlackCar}
                                                                                            className={classes.imageStyleCar}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <br />
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>Model Year</span> </td>
                                                                                <td className="fontstylePNORight"><span>{pnoCodeDetail.lcModelYear}</span></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span className="fontResize">Color</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcColor === 'Blue' ? 'Blue' : 'Black'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span>Engine Type</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcEngineType}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span>OBC Version</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.obcVersion}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </Row>
                                                                </div>
                                                            }
                                                        </>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        {
                            displaySelect &&
                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12"   >
                                            <Col lg="12" md="12" style={{ marginLeft: '-11px' }}>
                                                <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                    Select Conditions
                                                </Typography>
                                            </Col>

                                            <Row style={{ marginBottom: '-1px', marginTop: '20px', marginLeft: '-20px', paddingRight: '60px' }}>
                                                <Col lg="12" md="12" xs="12" sm="12" >
                                                    <>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            name="radio-buttons-group"
                                                            sx={{ marginBottom: '4px' }}
                                                            value={value}
                                                            onChange={handleChangeRadioButtons}
                                                        >
                                                            <Col lg="4" md="4" xs="4" sm="4">
                                                                <FormControlLabel
                                                                    value="age"
                                                                    sx={{
                                                                        ".MuiFormControlLabel-label": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                        },
                                                                        ".MuiFormControlLabel-label.Mui-disabled": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                            color: '#BFBFBF'
                                                                        }
                                                                    }}
                                                                    control={
                                                                        <Radio
                                                                            icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                                                                            checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                                                                            sx={{
                                                                                color: '#BFBFBF',
                                                                                '&.Mui-checked': {
                                                                                    color: '#1EF1C6',
                                                                                },
                                                                            }}
                                                                            disabled={placeholder ? true : false}
                                                                        />
                                                                    }
                                                                    label="Age"
                                                                />
                                                            </Col>
                                                            <Col lg="4" md="4" xs="4" sm="4">
                                                                <FormControlLabel
                                                                    value="mileage"
                                                                    sx={{
                                                                        ".MuiFormControlLabel-label": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                        },
                                                                        ".MuiFormControlLabel-label.Mui-disabled": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                            color: '#BFBFBF'
                                                                        }
                                                                    }}
                                                                    control={
                                                                        <Radio
                                                                            icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                                                                            checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                                                                            sx={{
                                                                                color: '#BFBFBF',
                                                                                '&.Mui-checked': {
                                                                                    color: '#1EF1C6',
                                                                                }
                                                                            }}
                                                                            disabled={placeholder ? true : false}
                                                                        />
                                                                    }
                                                                    label="Mileage" />
                                                            </Col>
                                                            <Col lg="4" md="4" xs="4" sm="4">
                                                                <FormControlLabel
                                                                    value="age & mileage"
                                                                    sx={{
                                                                        ".MuiFormControlLabel-label": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                        },
                                                                        ".MuiFormControlLabel-label.Mui-disabled": {
                                                                            textWrap: 'nowrap',
                                                                            fontFamily: 'LynkcoTypeRegular',
                                                                            fontSize: 'calc(9px + 0.390625vw)',
                                                                            '@media (min-width: 600px) and (max-width: 1000px)': {
                                                                                fontSize: 'calc(17px + 0.390625vw)'
                                                                            },
                                                                            color: '#BFBFBF'
                                                                        }
                                                                    }}
                                                                    control={
                                                                        <Radio
                                                                            icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                                                                            checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                                                                            sx={{
                                                                                color: '#BFBFBF',
                                                                                '&.Mui-checked': {
                                                                                    color: '#1EF1C6',
                                                                                    textWrap: 'nowrap',

                                                                                }
                                                                            }}
                                                                            disabled={placeholder ? true : false}
                                                                        />
                                                                    }
                                                                    label="Age & Mileage" />
                                                            </Col>
                                                        </RadioGroup>
                                                    </>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12" md="12" >
                                                    <div style={{ textAlign: 'center', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'rgb(255, 102, 0)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                        {
                                                            value == 'age' &&
                                                            <div style={{ width: '50%', marginTop: '15vh', }}>

                                                                <FormSelect
                                                                    label='Age'
                                                                    name='age'
                                                                    value={displayAge}
                                                                    dropdownfont='true'
                                                                    placeholder={'displayAge'}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={[
                                                                        { 'key': '12 months', 'value': '12 months' },
                                                                        { 'key': '13 months', 'value': '13 months' },
                                                                        { 'key': '14 months', 'value': '14 months' },
                                                                        { 'key': '15 months', 'value': '15 months' },
                                                                        { 'key': '16 months', 'value': '16 months' },
                                                                        { 'key': '17 months', 'value': '17 months' },
                                                                        { 'key': '18 months', 'value': '18 months' },
                                                                        { 'key': '19 months', 'value': '19 months' },
                                                                        { 'key': '20 months', 'value': '20 months' },
                                                                        { 'key': '21 months', 'value': '21 months' },
                                                                        { 'key': '22 months', 'value': '22 months' },
                                                                        { 'key': '23 months', 'value': '23 months' },
                                                                        { 'key': '24 months', 'value': '24 months' }
                                                                    ]}
                                                                    onChange={handleChangeUCA("age")}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            value == 'mileage' &&
                                                            <div style={{ width: '61%', marginTop: '15vh', }}>
                                                                <FormSelect
                                                                    label='Mileage'
                                                                    name='mileage'
                                                                    dropdownfont='true'
                                                                    value={displayMileage}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={[
                                                                        { 'key': '10 000km', 'value': '10 000km' },
                                                                        { 'key': '11 000km', 'value': '11 000km' },
                                                                        { 'key': '12 000km', 'value': '12 000km' },
                                                                        { 'key': '13 000km', 'value': '13 000km' },
                                                                        { 'key': '14 000km', 'value': '14 000km' },
                                                                        { 'key': '15 000km', 'value': '15 000km' },
                                                                        { 'key': '16 000km', 'value': '16 000km' },
                                                                        { 'key': '17 000km', 'value': '17 000km' },
                                                                        { 'key': '18 000km', 'value': '18 000km' },
                                                                        { 'key': '19 000km', 'value': '19 000km' },
                                                                        { 'key': '20 000km', 'value': '20 000km' },
                                                                        { 'key': '21 000km', 'value': '21 000km' },
                                                                        { 'key': '22 000km', 'value': '22 000km' },
                                                                        { 'key': '23 000km', 'value': '23 000km' },
                                                                        { 'key': '24 000km', 'value': '24 000km' },
                                                                        { 'key': '25 000km', 'value': '25 000km' },
                                                                        { 'key': '26 000km', 'value': '26 000km' },
                                                                        { 'key': '27 000km', 'value': '27 000km' },
                                                                        { 'key': '28 000km', 'value': '28 000km' },
                                                                        { 'key': '29 000km', 'value': '29 000km' },
                                                                        { 'key': '30 000km', 'value': '30 000km' }
                                                                    ]}
                                                                    onChange={handleChangeUCA("mileage")}
                                                                />
                                                            </div>
                                                        }

                                                        {
                                                            value == 'age & mileage' &&
                                                            <div className="ageMileageHeight">
                                                                <FormSelect
                                                                    label='Age'
                                                                    name='age'
                                                                    value={displayAge}
                                                                    dropdownfont='true'
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={[
                                                                        { 'key': '12 months', 'value': '12 months' },
                                                                        { 'key': '13 months', 'value': '13 months' },
                                                                        { 'key': '14 months', 'value': '14 months' },
                                                                        { 'key': '15 months', 'value': '15 months' },
                                                                        { 'key': '16 months', 'value': '16 months' },
                                                                        { 'key': '17 months', 'value': '17 months' },
                                                                        { 'key': '18 months', 'value': '18 months' },
                                                                        { 'key': '19 months', 'value': '19 months' },
                                                                        { 'key': '20 months', 'value': '20 months' },
                                                                        { 'key': '21 months', 'value': '21 months' },
                                                                        { 'key': '22 months', 'value': '22 months' },
                                                                        { 'key': '23 months', 'value': '23 months' },
                                                                        { 'key': '24 months', 'value': '24 months' }
                                                                    ]}
                                                                    onChange={handleChangeUCA("age")}
                                                                />
                                                                <br /> <br />
                                                                <FormSelect
                                                                    label='Mileage'
                                                                    name='mileage'
                                                                    dropdownfont='true'
                                                                    value={displayMileage}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={[
                                                                        { 'key': '10 000km', 'value': '10 000km' },
                                                                        { 'key': '11 000km', 'value': '11 000km' },
                                                                        { 'key': '12 000km', 'value': '12 000km' },
                                                                        { 'key': '13 000km', 'value': '13 000km' },
                                                                        { 'key': '14 000km', 'value': '14 000km' },
                                                                        { 'key': '15 000km', 'value': '15 000km' },
                                                                        { 'key': '16 000km', 'value': '16 000km' },
                                                                        { 'key': '17 000km', 'value': '17 000km' },
                                                                        { 'key': '18 000km', 'value': '18 000km' },
                                                                        { 'key': '19 000km', 'value': '19 000km' },
                                                                        { 'key': '20 000km', 'value': '20 000km' },
                                                                        { 'key': '21 000km', 'value': '21 000km' },
                                                                        { 'key': '22 000km', 'value': '22 000km' },
                                                                        { 'key': '23 000km', 'value': '23 000km' },
                                                                        { 'key': '24 000km', 'value': '24 000km' },
                                                                        { 'key': '25 000km', 'value': '25 000km' },
                                                                        { 'key': '26 000km', 'value': '26 000km' },
                                                                        { 'key': '27 000km', 'value': '27 000km' },
                                                                        { 'key': '28 000km', 'value': '28 000km' },
                                                                        { 'key': '29 000km', 'value': '29 000km' },
                                                                        { 'key': '30 000km', 'value': '30 000km' },
                                                                    ]}
                                                                    onChange={handleChangeUCA("mileage")}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={value == 'age & mileage' ? "mileageageHeightStyle" : "ageHeightStyle"} >
                                        <Col lg="12" md="12" >
                                            {
                                                matchesPermission &&
                                                <div class="click-to-top" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                    <img
                                                        src={placeholder ? actionButton_dark_lock : actionButton_dark_unlock}
                                                        className={classes.imageStyleButton}
                                                        onClick={handleSubmit}
                                                    />
                                                    <span style={{ color: placeholder ? 'rgb(193, 39, 37)' : '#1EF1C6', fontFamily: "LynkcoTypeRegular" }}>{placeholder ? 'Edit' : 'Confirm'}</span>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(UCAssignment);