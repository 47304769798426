import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import VehiclePage from "src/pages/vsp/VehiclePage";
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import DriverDetailPage from "src/pages/vsp/DriverDetailPage";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import ConnectivityCasesDetail from 'src/pages/vsp/ConnectivityCasesDetail';
import ReactTableBaseConnectivityCasesPage from 'src/components/tables/newtable/table/ReactTableBaseConnectivityCasesPage';
import { GetVehicleConnectivityCases } from 'src/services/cep-http.service';
import { GetConnectivityCaseDetailsByCaseNumber } from 'src/services/cep-http.service';
import { FormmatDate } from 'src/utils/DateFormatter';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import WorkOrder from 'src/pages/vsp/WorkOrder';
import { GetVehicleWorkOrderCountByStatus } from 'src/services/vehicle-pages-http.service';
import { GetWhoIsConnectedData } from 'src/services/provisioning-http.service';
import { FormatHourMinute } from 'src/utils/RvTableExcelFormatter';
import moment from 'moment';
import { permission } from 'src/auth/permissions';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import DefaultPage from 'src/pages/backgrounds/default-page';

let rowsPerPage = 50;
let newPageVariable = 1;

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        position: 'sticky',
        marginLeft: '11px',
    },
    drawer: {
        width: 600,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '115px',
        marginLeft: '34%',
        position: 'relative',
        height: '760px',
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        backgroundColor: 'rgb(19 19 19 / 97%)',
        height: '800px',
        borderRadius: '1px'
    },
}));

const VSPMainPage = (props) => {
    const { userOrg, userMarketIds, statuses = false, inputVIN, searchInputVIN, userPermissions, vspmap = true } = props
    const classes = UseStyles();
    const [vehiclePosition, setVeiclePosition] = useState({});
    const [vehicleDetail, setVehicleDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [message, setMessage] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [rows, setRolesArray] = useState([]);
    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });
    const [connectivityCasesDetail, setConnectivityCasesDetail] = useState({});
    const [getWorkOrderStatus, setWorkOrderStatus] = useState({});
    const [displayDetails, setDisplayDetails] = useState(false);
    const [mapSpinner, setMapSpinner] = useState(false);

    const [provisionedDated, setProvisionedDate] = useState('');

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');

    const customerFullName = customerFirstName + ' ' + customerLastName;

    const [searchInput, setSearchInput] = useState(false);
    const [addressInput, setAddressInput] = useState('');

    let newVIN = localStorage.getItem('vin');
    let vspVIN = newVIN?.split('=')[1];

    let selectedRowVehiclePostion = {};

    const columns = useMemo(
        () => [
            { Header: 'Case Number', accessor: "caseNumber", disableGlobalFilter: true, },
            { Header: 'Status', accessor: "caseStatus", disableGlobalFilter: true, },
            { Header: 'Escalated', accessor: "caseEscalated", disableGlobalFilter: true, },
            { Header: 'Customer ID', accessor: "customerNumber", disableGlobalFilter: true, },
            { Header: 'Created On', accessor: "caseCreationDate", disableGlobalFilter: true, }
        ],
        [],
    );

    useEffect(() => {
        setAddressInput(vspVIN);
        if (vspVIN !== '') {
            handleChangeVIN();
        }
    }, [vspVIN]);

    const handleChangeVIN = () => {
        setSearchInput(true);
        setSearchInput(true);
        QueryConnectivityInformation(vspVIN || addressInput?.trim())
            .then(data => {
                if (data?.ok == true) {
                    setSearchInput(false);
                    setVehicleDetails(data.vspConnectivity);
                    setRolesArray([]);
                    initializeRoles(vspVIN || addressInput.trim());
                    handleTab(vspVIN || addressInput.trim());
                    initializeFleetVehicles(vspVIN || addressInput.trim());
                    setDisplayDetails(true);
                    localStorage.removeItem('vin');
                    GetVehicleWorkOrderCountByStatus(vspVIN || addressInput.trim())
                        .then(data => {
                            if (data) {
                                setWorkOrderStatus(data);
                            }
                        })
                }
                if (data.ok == false) {
                    setMessage(data.title);
                    setDialogMessage(data.errorMessage);
                    setDisplayDetails(false);
                    localStorage.removeItem('vin');
                    setOpenDialog(true);
                }
            })
        setSearchInput(false);
    };

    const initializeFleetVehicles = (inputVIN) => {
        let vehicleFleetArray = [];

        GetWhoIsConnectedData({ vin: inputVIN.trim(), pageNumber: newPageVariable, pageSize: rowsPerPage }).then(data => {

            if (data && Array.isArray(data.items)) {
                if (data.items.length > 0) {
                    data.items.map(vehicle => {
                        vehicleFleetArray.push({
                            firstName: vehicle.firstName,
                            lastName: vehicle.lastName,
                            customerNumber: vehicle.customerNumber,
                            role: vehicle.role == 'MainUser' ? 'Primary Driver' : '',
                            provisionedDateUtc: vehicle.provisionedDateUtc == null ? null : FormatHourMinute(moment(vehicle.provisionedDateUtc).format("YYYY-MM-DD HH:mm").toString()),
                            unprovisionedDateUtc: vehicle.unprovisionedDateUtc == null ? null : FormatHourMinute(moment(vehicle.unprovisionedDateUtc).format("YYYY-MM-DD HH:mm").toString())
                        })
                    })
                    setProvisionedDate(vehicleFleetArray[0]?.provisionedDateUtc);
                    setCustomerFirstName(vehicleFleetArray[0]?.firstName)
                    setCustomerLastName(vehicleFleetArray[0]?.lastName)
                } else {
                    setProvisionedDate('');
                    setCustomerFirstName('');
                    setCustomerLastName('');
                }

            }
        })
    };

    const initializeRoles = (inputVIN) => {
        let vin = inputVIN.trim();
        let roleArray = [];

        setRolesArray([]);

        GetVehicleConnectivityCases(vin)
            .then(data => {
                if (data.ok == true) {
                    if (data.vehicleCases && Array.isArray(data.vehicleCases)) {
                        data.vehicleCases.map(roles => {
                            roleArray.push({
                                caseNumber: roles.caseNumber,
                                caseStatus: roles.caseStatus,
                                caseEscalated: roles.caseEscalated,
                                customerNumber: roles.customerNumber,
                                caseCreationDate: FormmatDate(roles.caseCreationDate)
                            });
                        })
                        setRolesArray(roleArray);
                    }
                }
            })
    }

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const selectedRowValues = (row) => {
        setSelectedRow({
            caseNumber: row.values.caseNumber,
            caseStatus: row.values.caseStatus,
            caseEscalated: row.values.caseEscalated,
            customerNumber: row.values.customerNumber,
            caseCreationDate: row.values.caseCreationDate
        })
        setSelected({
            caseNumber: row.values.caseNumber,
            caseStatus: row.values.caseStatus,
            caseEscalated: row.values.caseEscalated,
            customerNumber: row.values.customerNumber,
            caseCreationDate: row.values.caseCreationDate
        })
        selectedRowVehiclePostion = {
            caseNumber: row.values.caseNumber,
            caseStatus: row.values.caseStatus,
            caseEscalated: row.values.caseEscalated,
            customerNumber: row.values.customerNumber,
            caseCreationDate: row.values.caseCreationDate,
        };
    };

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    const handleDrawar = () => {

        let caseNumber = selectedRowVehiclePostion.caseNumber;

        setOpen(true);
        setOpenModal(true);
        if (caseNumber !== undefined) {
            GetConnectivityCaseDetailsByCaseNumber(caseNumber).then(data => {
                if (data.ok == true) {
                    setConnectivityCasesDetail(data.details)
                }
            })
        }
    };
    const closeHandleDrawer = () => {
        setOpen(false);
    };
    const closeHandleDrawer1 = () => {
        setOpen(false);
        setOpenModal(false);
    };
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        setAddressInput('');
        setSearchInput(false);
    };

    const handleTab = (addressInput) => {
        setMapSpinner(true);
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = addressInput.trim();

        let vehiclePositionInstance = {
            "latitude": 0,
            "longitude": 0,
            "odometer": "",
            "color": "",
            "street": "",
            "postalCode": "",
            "city": "",
            "country": ""
        };

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data?.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data) {
                                if (data.ok == true) {
                                    vehiclePositionInstance.vin = data.vehiclePosition.vin
                                    vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                    vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                    vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                    vehiclePositionInstance.color = data.vehiclePosition.color
                                    vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                    vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                    vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                    vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                    vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                    setVeiclePosition(vehiclePositionInstance);
                                    setMapSpinner(false);
                                }
                            }
                        })
                }
            })
    };

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            handleSearchbar();
        }
    };

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            setSearchInput(!searchInput);
            handleChangeVIN();
        }
    };

    const handleSearchbar = () => {
        setDisplayDetails(false);
        setAddressInput('');
    };

    const matchesPermission = userPermissions.includes(permission.VSP_Reader);

    return (
        <Container>
            <Col md={12} lg={12}>
                <div className='vspMainPageStyle'>
                    <VehiclePageDialog
                        dialogue='true'
                        open={openDialog}
                        message={dialogMessage}
                        handleDialogOk={handleDialogOk}
                        title={message}
                        vspheight='true'
                    />
                    <Modal
                        className={classes.drawer}
                        variant="persistent"
                        anchor="right"
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={handleClose}
                        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
                    >
                        <ConnectivityCasesDetail closeHandleDrawer1={closeHandleDrawer1} data={connectivityCasesDetail} />
                    </Modal>
                    {
                        matchesPermission ?
                            <>
                                <Row>
                                    <Col lg="12" md="12" sm="12" xs="12" >
                                        <div className='searchStyleVSP'>
                                            {matchesPermission &&
                                                <SearchField
                                                    handleSearchbar={handleSearchbar}
                                                    placeholder='VIN or License Plate'
                                                    handleSearch={handleChangeVIN}
                                                    handleSearchInput={handleSearchInput}
                                                    onKeyPress={textFieldKeypress}
                                                    value={vspVIN ? vspVIN : addressInput?.trim()}
                                                    priceBucketActiveTab='true'
                                                    autoFocus='true'
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                {displayDetails &&
                                    <Row className='rowstyleVSP'>
                                        <Col lg="4" md="12" >
                                            <div style={{ marginLeft: '10px' }} >
                                                <div className='vspmapStyle'>
                                                    <PositionTracking
                                                        vin={addressInput?.trim()}
                                                        value={2}
                                                        vehiclePosition={vehiclePosition}
                                                        mapSpinner={mapSpinner}
                                                        vspmap={vspmap}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <>
                                            <Col lg="2" md="6" >
                                                <div className='vspvehicleStyle'>
                                                    <VehiclePage vehicleDetail={vehicleDetail} />
                                                </div>
                                            </Col>
                                            <Col lg="2" md="6" >
                                                <div className='vspdriverStyle'>
                                                    <DriverDetailPage vehicleDetail={vehicleDetail} provisionedDated={provisionedDated} customerFullName={customerFullName} />
                                                </div>
                                            </Col>
                                            <Col lg="4" md="12" >
                                                <Card className="vspconnectivityStyle">
                                                    <CardBody>
                                                        <div style={{ marginTop: '-18px', marginLeft: '-10px' }}>
                                                            <div className="react-table__wrapper">
                                                                <div className="vspVehicleheaderStyle" style={{ marginLeft: '8px', marginBottom: '8px', marginTop: '8px', fontFamily: "LynkcoTypeMedium" }}>
                                                                    Connectivity Cases
                                                                </div>
                                                            </div>
                                                            <ReactTableBaseConnectivityCasesPage
                                                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                                columns={columns}
                                                                data={rows}
                                                                tableConfig={tableConfig}
                                                                isSelected={isSelected}
                                                                selectedRows={selectedRows}
                                                                onSelectedRowsChange={setSelectedRows}
                                                                selectedRowValues={selectedRowValues}
                                                                handleDrawar={handleDrawar}
                                                                closeHandleDrawer={closeHandleDrawer}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Row>
                                                <Col lg="12" md="12" >
                                                    <Row className='workordercardStyle'>
                                                        <Col lg="12" md="12" >
                                                            <WorkOrder getWorkOrderStatus={getWorkOrderStatus} vin={addressInput?.trim()} statuses={statuses} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    </Row>
                                }
                            </>
                            :
                            <DefaultPage />
                    }
                </div>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(VSPMainPage);