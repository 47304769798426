import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DefaultPage from 'src/pages/backgrounds/default-page';
import MapPage from 'src/pages/svt-page/MapPage';
import { Typography } from '@material-ui/core';
import SVTMainComponent from 'src/pages/svt-page/SVTMainComponent';
import DetailsMainPage from 'src/pages/svt-page/vehicleDetails/DetailsMainPage';
import * as AppSettings from 'src/AppSettings.json';
import BMCPricing from 'src/pages/bmc-pricing/BMCPricing';
import M2MTable from 'src/pages/Tables/DataTable/components/M2MTable';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';

function TabPanel(props) {

  const { userPermissions, userToken, children, value, index, ...other } = props;
  const values = [permission.Remarketing_Administrator, permission.Remarketing_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {matchesPermission ? (
        <>
          {value === index && value === 0 && (
            AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <M2MTable />
          )}
          {value === index && value === 1 && (
            AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <BMCPricing />
          )}
          {value === index && value === 2 && (
            <BMCPricing />
          )}
        </>
      ) : (
        <>
          {value === index && value === 0 && (
            <DefaultPage />
          )}
          {value === index && value === 1 && (
            <DefaultPage />
          )}
          {value === index && value === 2 && (
            <DefaultPage />
          )}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    padding: "55px",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)'
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',
  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({

  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  },
})(Tabs);

const MCCWorkshopTab = ({ userPermissions, userToken }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [selectedVIN, setSelectedVIN] = useState('');
  const [selectedVINMap, setSelectedVINMap] = useState('');
  const [addressInput, setAddressInput] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          MCC
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="MCC TABLE" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Pricing" {...a11yProps(1)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        MCC Table
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        PRICING
      </TabPanel>
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(MCCWorkshopTab)