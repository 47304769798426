import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Chart from "react-apexcharts";

const RVChart = ({ selectTable, vehicleDetailsChartValues }) => {

  const [residualValue, setResidualValue] = useState([]);
  const [residualValueMonth, setResidualValueMonth] = useState([]);

  useEffect(() => {
    let residualValues = [];
    let months = [];
    if (vehicleDetailsChartValues.residualValueProjections !== undefined) {
      vehicleDetailsChartValues?.residualValueProjections.map(item => {
        residualValues.push(item.residualValue)
      });
      vehicleDetailsChartValues?.residualValueProjections?.map(item => {
        months.push(item.month)
      })
      setResidualValue(residualValues);
      setResidualValueMonth(months)
    }

  }, []);

  const optionsrevenue = {
    grid: {
      show: true,
      strokeDashArray: 2,
      borderColor: "rgba(0,0,0,.3)",
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "flat",
      },
    },
    colors: ["#DC1860"],
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {

      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      categories: residualValueMonth,
      labels: {
        style: {
          cssClass: "grey--text lighten-1--text fill-color",
          fontSize: '16px',
          fontFamily: 'LynkcoTypeMedium',
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
          fontSize: '16px',
          fontFamily: 'LynkcoTypeMedium',
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent']
    },
    tooltip: {
      theme: "dark",
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.15,
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 0.1,
        }
      },
    },
  };
  const seriesrevenue = [
    {
      name: "Resisual value",
      data: residualValue
    },
  ];

  return (
    <Card id="RVChartCardLCAssets">
      <CardBody >
        <Row >
          <Col xs="12">
            <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular', marginTop: '-7px', marginBottom: '0px', paddingTop: '0px' }}>
              Residual Value
            </div>
          </Col>
          <Col xs="12" className="chartStyleLCAsssets">
            <Chart
              options={optionsrevenue}
              series={seriesrevenue}
              type="bar"
              height='100%'
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default RVChart;