import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePageCashCars from 'src/components/tables/newtable/table/ReactTableBaseCashCarsVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service'
import { GetFleetTypes } from 'src/services/fleetType-http.service'
import { FormmatDate } from 'src/utils/DateFormatter';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import SearchButton from 'src/components/buttons/search-button-component';
import FormSelect from 'src/components/form-elements/custom-select.component';
import FadeLoader from "react-spinners/FadeLoader";
import ChevronLeftIcon from 'src/assets/img/vehicles/ChevronLeftIcon.png';
import ChevronLeftIconGreen from 'src/assets/img/vehicles/ChevronLeftIconGreen.png';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { permission } from 'src/auth/permissions';
import { GetVehicleDetails } from 'src/services/vehicle-pages-http.service';
import Fade from 'react-reveal/Fade';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 2,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 580,
        display: 'flex'
    },
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    }
}));

const MCCLCCTable = ({ userOrg, userMarketIds, soldcarstable = true, handleCloseMCCLCCTable, userPermissions, vehiclesAtMCC, lccmccHeader, mcctable = true }) => {
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [openModal, setOpenModal] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setVehicleFleet] = useState([]);

    let fleetTypes = [];

    const [dataLoaded, setDataLoaded] = useState(false);

    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [vehiclePosition, setVeiclePosition] = useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });

    const [anchorEl, setAnchorEl] = React.useState(null);

    const imgs = [ChevronLeftIcon, ChevronLeftIconGreen];

    const [icon, setIcon] = useState(imgs[0]);
    const [openIcon, setOpenIcon] = useState(false);
    const [disablePagination, setDisablePagination] = useState(false);

    const open = Boolean(anchorEl);

    const handleCloseHomeIcon = () => {
        setIcon(imgs[0]);
        setOpenIcon(false);
    };

    const handleOpenHomeIcon = () => {
        setIcon(imgs[1]);
        setOpenIcon(true);
    };
    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'PNO', accessor: "pnoCode", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'Model Year', accessor: "modelYear", disableGlobalFilter: true },
            { Header: 'Engine Type', accessor: "engineType", disableGlobalFilter: true, },
            { Header: 'OBC', accessor: "obcVersion", disableGlobalFilter: true, },
            { Header: 'Color', accessor: "color", disableGlobalFilter: true, },
            { Header: 'Towbar', accessor: "hasTowbar", disableGlobalFilter: true },
            { Header: 'Odometer', accessor: "odometerKm", disableGlobalFilter: true },
            { Header: 'RV [gross]', accessor: "rvGross", disableGlobalFilter: true },
            { Header: 'Reserved Price', accessor: "reservedPrice", disableGlobalFilter: true },
            { Header: 'Registered', accessor: "registeredDateUtc", disableGlobalFilter: true },
            { Header: 'Age [Months]', accessor: "age", disableGlobalFilter: true },
            { Header: 'Buy My Car Price', accessor: "priceAfterAdjustment", disableGlobalFilter: true },
            { Header: 'Est. De-fleet', accessor: "forecastedDefleetDateUtc", disableGlobalFilter: true },
            { Header: 'Transport Status', accessor: "transportStatus", disableGlobalFilter: true },
            { Header: 'Customer Order Status', accessor: "orderStatus", disableGlobalFilter: true },
            { Header: 'Last Day of Contract', accessor: "membershipEndDateUtc", disableGlobalFilter: true },
            { Header: 'Inventory Status', accessor: "inventoryState", disableGlobalFilter: true },
            { Header: 'Open Work Orders', accessor: "openWorkOrders", disableGlobalFilter: true },
            { Header: 'Used Car Stock', accessor: "usedCar", disableGlobalFilter: true },
            { Header: 'Used Car B2B', accessor: "usedCarIsB2B", disableGlobalFilter: true },
            { Header: 'Market', accessor: "market", disableGlobalFilter: true },
            { Header: 'Fleet Type', accessor: "fleetType", disableGlobalFilter: true },
            { Header: 'Order Type', accessor: "orderType", disableGlobalFilter: true },
            { Header: 'Order Number', accessor: "orderNumber", disableGlobalFilter: true },
            { Header: 'Below Landed Cost', accessor: "belowLandedCost", disableGlobalFilter: true }
        ],
        [],
    );

    useEffect(() => {
        if (vehiclesAtMCC.length >= 0) {
            handleFetchTable();
        }
    }, [vehiclesAtMCC.length]);

    const header = [

        [
            'License Plate',
            'VIN',
            'PNO',
            'Model',
            'Model Year',
            'Engine Type',
            'OBC',
            'Color',
            'Towbar',
            'Odometer',
            'RV [gross]',
            'Reserved Price',
            'Registered',
            'Age [Months]',
            'Buy My Car Price',
            'Est. De-fleet',
            'Transport Status',
            'Customer Order Status',
            'Last Day of Contract',
            'Inventory Status',
            'Open Work Orders',
            'Used Car Stock',
            'Used Car B2B',
            'Market',
            'Fleet Type',
            'Order Type',
            'Order Number'
        ]
    ];

    const handleClickDownloadExcel = () => {
        let vins = vehiclesAtMCC;
        setDownloadSpinner(true);

        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = []
        let marketIds = userMarketIds;

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            GetVehicleDetails({ vins, pageNumber: 0, pageSize: 0, fleetIdArray: fleetIds, marketIds: marketIds })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                openWorkOrders: vehicle.openWorkOrders,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (rows) => {
            const fileName = lccmccHeader;

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (rows, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(rows, fileName);
        }
        setOpenModal(false);
    }

    const handleClickDownloadCSV = () => {
        let vins = vehiclesAtMCC
        setDownloadSpinner(true);

        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = []
        let marketIds = userMarketIds;

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            GetVehicleDetails({ vins, pageNumber: 0, pageSize: 0, fleetIdArray: fleetIds, marketIds: marketIds })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                openWorkOrders: vehicle.openWorkOrders,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (rows) => {
            CSVExport(header, rows, lccmccHeader)
        }
        setOpenModal(false);
    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleFetchTable = () => {
        setDataLoaded(false);

        let fleetIds = []
        let vehicleFleetArray = [];
        let marketIds = userMarketIds;
        let vins = vehiclesAtMCC

        if (vehiclesAtMCC.length == 0) {
            setVehicleFleet([]);
            setDataLoaded(true);
            setTotalItems(0);
        } else {
            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })
                        GetFleetTypes(userOrg.id)
                            .then(data => {
                                if (data && Array.isArray(data)) {
                                    data.map(fleetType => {
                                        fleetTypes.push(fleetType['id'])
                                    })
                                }
                                GetVehicleDetails({ vins, pageNumber: 0, pageSize: 0, fleetIdArray: fleetIds, marketIds: marketIds })
                                    .then(data => {
                                        if (data && Array.isArray(data.items)) {
                                            setTotalItems(data.totalItems ?? 0)
                                            // newPageVariable = data.currentPage;
                                            data.items.map(vehicle => {
                                                vehicleFleetArray.push({
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                    rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                    reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    age: vehicle.age,
                                                    priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                    forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                    transportStatus: vehicle.transportStatus,
                                                    orderStatus: vehicle.orderStatus,
                                                    membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                    inventoryState: vehicle.inventoryState,
                                                    openWorkOrders: vehicle.openWorkOrders,
                                                    usedCar: vehicle.usedCar,
                                                    usedCarIsB2B: vehicle.usedCarIsB2B,
                                                    market: vehicle.market,
                                                    fleetType: vehicle.fleetType,
                                                    orderType: vehicle.orderType,
                                                    orderNumber: vehicle.orderNumber
                                                });
                                            });
                                        }
                                        setVehicleFleet(vehicleFleetArray);
                                        setDataLoaded(true);
                                    })
                            })
                    }
                })
        }
    };

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            handleOpenDownload()
        }
    }

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);
    };

    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

    return (
        <Col md={12} lg={12}>
            <Modal
                className={drawerClasses.drawarModal}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseDownload}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                        Download Table
                    </Typography>
                    <Row className='downloadstyle'>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleCloseDownload}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <>
                <div id='tablecard' >
                    <Row className='headerStyleMCCTable'>
                        <Col lg="12" md="12" >
                            <Row>
                                <Col>
                                    <div className='mccTableName' style={{ fontFamily: "LynkcoTypeMedium" }}>
                                        {lccmccHeader}
                                    </div>
                                </Col>

                                <Col>
                                    {
                                        matchesPermission &&
                                        <div style={{ fontSize: '18px', float: 'right', color: 'darkgray', marginTop: '17px', marginBottom: '20px' }}>
                                            {
                                                downloadSpinner ?
                                                    <div style={{ float: 'right', marginTop: '-10px' }}>
                                                        <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                                    </div>
                                                    :
                                                    <>
                                                        <div style={{ fontSize: '18px', float: 'right', color: 'darkgray', marginTop: '-10px', marginRight: '50px' }}>
                                                            <Tooltip
                                                                title="Return"
                                                                onOpen={handleOpenHomeIcon}
                                                                onClose={handleCloseHomeIcon}
                                                                open={openIcon}
                                                                onClick={handleCloseMCCLCCTable}
                                                            >
                                                                <IconButton
                                                                    size="small"
                                                                    sx={{ ml: 2 }}
                                                                    aria-controls={open ? "account-menu" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? "true" : undefined}
                                                                >
                                                                    <img
                                                                        className="dot"
                                                                        src={icon}
                                                                        alt="Three Dot Image"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="optionsSelectMapAdvance">
                                                            <FormSelect
                                                                menuObjectArray={[
                                                                    { 'key': '1', 'value': 'Download' },
                                                                ]}
                                                                onClick={haandleDropdown}
                                                            />
                                                        </div>
                                                    </>

                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>

                        <Fade bottom duration={1500}>
                            <div className="tableFixHeadMCC">
                                <Row>
                                    <Col md={12} sm={12} xs={12} lg={12}>
                                        <div>
                                            <ReactTableBaseVehiclePageCashCars
                                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                columns={columns}
                                                data={rows}
                                                updateEditableData={updateEditableData}
                                                updateDraggableData={updateDraggableData}
                                                tableConfig={tableConfig}
                                                isColumnHidden={isColumnHidden}
                                                isSelected={isSelected}
                                                vehiclePosition={vehiclePosition}
                                                selected={selected}
                                                soldcarstable={soldcarstable}
                                                disablePagination={!disablePagination}
                                                mcctable={mcctable}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='paginationMapAdvanced' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
                        </Fade>
                    </Row>
                </div>
            </>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(MCCLCCTable);