import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import mapBlackVehicle from 'src/assets/img/vehicles/mapBlackVehicle.png';
import mapBlueVehicle from 'src/assets/img/vehicles/mapBlueVehicle.png';
import InfoWindowComponentVSP from "./infoWindowComponentVSP";
import { styles } from 'src/utils/MapStyle';
import { FormmatDateMap } from 'src/utils/DateFormatter';
import PuffLoader from "react-spinners/PuffLoader";

const MapUnprovisioning = (props) => {
    const { value, data, vehicleColor, mapSpinner } = props
    const [activeMarker, setActiveMarker] = useState(null);
    const [derivedLatLng, setDerivedLatLng] = React.useState({ lat: 57.71089814294498, lng: 11.945829642768519 });
    const [address, setAddress] = React.useState('');

    useEffect(() => {
        let firstpartVehicle = data?.postalCode === null ? '' : data?.postalCode;
        let secondpartVehicle = data?.city === null ? '' : data?.city;
        const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle
        if (value === 3) {
            setDerivedLatLng({ lat: data.latitude, lng: data.longitude })
            setAddress(postalCodeValueVehicle)
        }
    }, [value]);

    const handleActiveMarker = (marker) => {
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        map.setOptions({
            disableDefaultUI: true,
            zoomControl: true,
            styles: styles,
        });
    };

    return (
        <>
            {
                mapSpinner ?
                    <div style={{ position: 'absolute', width: '100%', height: '720px', marginLeft: '-0.9vw', alignItems: 'center', backgroundColor: '#212121', marginTop: '-4px', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                            <PuffLoader color="#1EF1C6" size={200} />
                        </div>
                    </div>
                    :
                    <>
                        {
                            data?.latitude === null || data?.longitude === null ?
                                <div style={{ position: 'absolute', width: '100%', height: '720px', marginLeft: '-0.9vw', alignItems: 'center', backgroundColor: '#212121', marginTop: '-4px', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                        Vehicle Position Not Available
                                    </div>
                                </div>
                                :
                                <GoogleMap
                                    onLoad={handleOnLoad}
                                    onClick={() => setActiveMarker(null)}
                                    mapContainerStyle={{ height: props.height, marginRight: '-1vw', marginLeft: '-0.9vw' }}
                                    center={derivedLatLng}
                                    zoom={13}
                                >
                                    <Marker
                                        key={0}
                                        position={derivedLatLng}
                                        onClick={() => handleActiveMarker()}
                                        icon={vehicleColor === "Blue" ? mapBlueVehicle : mapBlackVehicle}
                                    >
                                        {activeMarker !== null ? (
                                            <InfoWindow
                                                onCloseClick={() => setActiveMarker(null)}
                                            >
                                                <InfoWindowComponentVSP
                                                    heading={'Car Position'}
                                                    street={data.street}
                                                    postalCode={address}
                                                    country={data.country}
                                                    vehicleUpdatedAt={FormmatDateMap(data.vehicleUpdatedAt)}
                                                />
                                            </InfoWindow>
                                        ) : null}
                                    </Marker>
                                </GoogleMap>
                        }
                    </>
            }
        </>

    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(MapUnprovisioning);