import React from "react";
import { makeStyles, styled } from '@material-ui/core/styles';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import MasterVehicleHealth from "./MasterVehicleHealth";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryVehicleHealth } from 'src/services/vehicle-pages-http.service';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-14px',
        overflow: 'auto',
        height: '758px',
        marginRight: '-32px',
        paddingRight: '20px',
        marginLeft: '5px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            opacity: "0.3",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        }
    }
}));

const VehicleHealth = (props) => {
    const { value, vin, userOrg, userMarketIds } = props

    const [vehicleColor, setVehicleColor] = React.useState('')
    const [vehicleHealth, setVehicleHealth] = React.useState({
        "colour": "",
        "distanceToService": {
            "isDistanceToServiceOk": null,
            "value": ""
        },
        "daysToService": {
            "isDaysToServiceOk": null,
            "value": ""
        },
        "serviceWarningStatus": {
            "isServiceWarningStatusOk": null,
            "value": null
        },
        "engineOilLevelStatus": {
            "isEngineOilLevelStatusOk": null,
            "value": null
        },
        "engineOilPressureStatus": {
            "isEngineOilPressureStatusOk": null,
            "value": null
        },
        "washerFluidLevelStatus": {
            "isWasherFluidLevelStatusOk": null,
            "value": null
        }});

    React.useEffect(() => {
        handleVehicleHealth();
    }, [vin]);

    const handleVehicleHealth = () => {
        if (value == 2) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehicleHealth(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok == true) {
                                    setVehicleHealth(data.vehicleMaintenance);
                                    setVehicleColor(data.vehicleMaintenance.colour)
                                }
                            })
                    }
                })
        }

    }
    const classes = UseStyles();

    return (
        <Col md={12} lg={12}>
            <div className={classes.root}>
                <MasterVehicleHealth
                    data={vehicleHealth}
                    color={vehicleColor}
                />
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleHealth);