import React, { Fragment, useState, useEffect } from "react";
import TodoList from "src/components/todos/TodoList";
import TodoListAchived from "src/components/todos/TodoListAchived";
import TodoListDone from "src/components/todos/TodoListDone";
import TodoSearch from "src/components/todos/TodoSearch";
import TodoDetails from "src/components/todos/TodoDetails";
import TodosFilter from "../../components/todos/TodosFilter";
import "./Todo.scss";
import DefaultPage from 'src/pages/backgrounds/default-page';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions } from "src/redux/user/user.selectors";
import { connect } from "react-redux";
import { Col, Container, Row } from 'react-bootstrap';
import SearchButton from "src/components/buttons/search-button-component";
import { Modal, ModalHeader, ListGroup, Card } from "reactstrap";
import AddTodo from "src/components/todos/AddTodo";
import DividerLine from 'src/pages/todo/DividerLine';

const Todo = ({ userPermissions, props }) => {
  //const [Todos, setTodos] = useState([]);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setshowRight] = useState(false);
  const [showText, setShowText] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowText(true);
    }, 300); // Adjust the delay as needed
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const showLeftPart = () => {
    setShowLeft(!showLeft);
  };

  const showRightPart = () => {
    setshowRight(!showRight);
  };

  const matchesPermission = userPermissions.includes(permission.Todo_Reader);

  return (
    <>
      {
        matchesPermission ?
          <Container>
            <Modal
              isOpen={modal}
              toggle={toggle.bind(null)}
              // className={props.className}
              size="md"
              style={{ marginTop: '125px', backgroundColor: 'transparent', border: 'none' }}
            // contentClassName={{ border: '2px solid #2c2c2c', borderRadius: '30px' }}
            >
              <ModalHeader toggle={toggle.bind(null)}>New Task</ModalHeader>
              <AddTodo onButtonClick={toggle.bind(null)} />
            </Modal>
            <Row className='headerStyleTodo'>
              <Col lg="8" md="8" sm="8" xs="8" >
                {
                  showText && <div className="fontStylingProvisioning" style={{ fontFamily: 'LynkcoTypeBold' }}>My Tasks</div>
                }
              </Col>

              <Col lg="4" md="4" sm="4" xs="4" >
                <div style={{ marginTop: '150px' }}>
                  {/* <span
                    // onClick={toggle.bind(null)}
                    style={{
                      backgroundColor: '#1EF1C6',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                    className="btn btn-success d-block text-darky"
                  >
                    Add task
                  </span> */}
                  <SearchButton
                    placeholder={'Create Task'}
                    // handleSearch={() => { toggle.bind(null) }}
                    handleSearch={toggle.bind(null)}

                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="app-drawer"
                  style={{
                    marginTop: '25px', marginLeft: '15px', marginRight: '-25px',
                    height: "72vh",
                    width: "100%",
                    overflow: 'auto',
                    top: "0",
                    left: "0"
                  }}>
                  {/* <div
                    className={
                      "left-part bg-white" + (showLeft === true ? "show-panel" : "")
                    }
                  >
                    <TodosFilter />
                  </div> */}
                  <div className="">
                    <div
                    // className={
                    //   "right-left-part " +
                    //   (showRight === true ? "show-right-left-panel" : "")
                    // }
                    >
                      <span
                        onClick={showLeftPart.bind(null)}
                        className={
                          "bg-primary show-left-part text-white d-block d-lg-none " +
                          (showLeft === true ? "left-part-open" : "")
                        }
                      >
                        <i
                          className={
                            showLeft === true
                              ? "fas fa-chevron-left"
                              : "fas fa-chevron-right"
                          }
                        ></i>
                      </span>
                      <DividerLine title="Active" />
                      <TodoList showRightPart={showRightPart.bind(null)} />
                    </div>

                    <div
                    // className={
                    //   "right-left-part " +
                    //   (showRight === true ? "show-right-left-panel" : "")
                    // }
                    >
                      <span
                        onClick={showLeftPart.bind(null)}
                        className={
                          "bg-primary show-left-part text-white d-block d-lg-none " +
                          (showLeft === true ? "left-part-open" : "")
                        }
                      >
                        <i
                          className={
                            showLeft === true
                              ? "fas fa-chevron-left"
                              : "fas fa-chevron-right"
                          }
                        ></i>
                      </span>
                      <DividerLine title="Done" />
                      <TodoListDone showRightPart={showRightPart.bind(null)} />
                    </div>

                    <div
                    // className={
                    //   "right-left-part " +
                    //   (showRight === true ? "show-right-left-panel" : "")
                    // }
                    >
                      <span
                        onClick={showLeftPart.bind(null)}
                        className={
                          "bg-primary show-left-part text-white d-block d-lg-none " +
                          (showLeft === true ? "left-part-open" : "")
                        }
                      >
                        <i
                          className={
                            showLeft === true
                              ? "fas fa-chevron-left"
                              : "fas fa-chevron-right"
                          }
                        ></i>
                      </span>
                      <DividerLine title="Archived" />
                      <TodoListAchived showRightPart={showRightPart.bind(null)} />
                    </div>
                    {/* <div
                      className={
                        "right-right-part " +
                        (showRight === true
                          ? "show-right-right-panel"
                          : "hide-right-right-part")
                      }
                    >
                      <span
                        onClick={() => {
                          showRightPart();
                        }}
                        className={
                          "hide-right-right-part d-block d-md-none " +
                          (showRight === true ? "right-right-part-open" : "")
                        }
                      >
                        <i className="fas fa-times"></i>
                      </span>
                      <TodoDetails />
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          :
          <DefaultPage variant='true' />
      }
    </>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(Todo);