import React, { useEffect } from "react";
import TodoItem from "./TodoItem";
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { useSelector, useDispatch } from "react-redux";
import { SaveTodo } from 'src/services/todo-http.service';
import { DeleteSelectedTodo, StarSelectedTodo, CompleteSelectedTodo, TransformTodoData } from 'src/utils/TodoFormatter';
import { fetchTodos, todoDetails, deleteTodo, todoCompleted, fetchTodosSuccess } from "src/redux/todos/Action";
import { ListGroup } from "reactstrap";

const TodoListAchived = ({ showRightPart, user }) => {

  let userId = user.id;

  const dispatch = useDispatch();

  const dispatcher = (payload) => {
    dispatch(fetchTodosSuccess(TransformTodoData(payload.todoData)))

    dispatch(todoDetails(payload.id))
  }

  const getVisibleTodos = (todos, filter, todosSearch) => {
    switch (filter) {
      case "all":
        return todos.filter(
          (t) =>
            !t.deleted &&
            t.title
              .toLocaleLowerCase()
              .concat(" ")
              .includes(todosSearch.toLocaleLowerCase())
        );
      case "starred":
        return todos.filter(
          (t) =>
            t.starred &&
            !t.deleted &&
            t.title
              .toLocaleLowerCase()
              .concat(" ")
              .includes(todosSearch.toLocaleLowerCase())
        );
      case "deleted":
        return todos.filter(
          (t) =>
            t.deleted &&
            t.title
              .toLocaleLowerCase()
              .concat(" ")
              .includes(todosSearch.toLocaleLowerCase())
        );
      case "completed":
        return todos.filter(
          (t) =>
            !t.deleted &&
            t.isCompleted &&
            t.title
              .toLocaleLowerCase()
              .concat(" ")
              .includes(todosSearch.toLocaleLowerCase())
        );
      default:
        throw new Error("Unknown filter: " + filter);
    }
  };

  const todos = getVisibleTodos(
    useSelector((state) => state.maintodoReducer.todos),
    useSelector((state) => state.todoReducer.todosVisibilityFilter),
    useSelector((state) => state.todoReducer.todoSearch)
  );

  const todoData = useSelector((state) => state.maintodoReducer.todos);

  const selectedTodos = useSelector(
    (state) =>
      todoData[state.maintodoReducer.todoDetails - 1]
  );

  const active = useSelector((state) => state.maintodoReducer.todoDetails);

  let archivedTodo = todos.filter(item => item.starred == true);

  useEffect(() => {
    dispatch(fetchTodos(userId));
  }, [dispatch, archivedTodo.length]);

  const DeleteTodo = (userId, todoId) => {
    SaveTodo(userId, DeleteSelectedTodo(selectedTodos, todoData, todoId))

      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatcher({ todoData: data.tableData, id: todoId });
        }
      })
  };

  const StarTodo = (userId, todoId) => {
    SaveTodo(userId, StarSelectedTodo(selectedTodos, todoData, todoId))
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatcher({ todoData: data.tableData, id: todoId });
        }
      })
  };

  const CompleteTodo = (userId, todoId) => {
    SaveTodo(userId, CompleteSelectedTodo(selectedTodos, todoData, todoId))
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatcher({ todoData: data.tableData, id: todoId });
        }
      })
  };

  return (
    <div>
      <ListGroup>
        {archivedTodo.map((todo) => (
          <TodoItem
            key={todo.id}
            {...todo}
            active={active}
            onCheckClick={() => {
              CompleteTodo(userId, todo.id);
              dispatch(todoCompleted(todo.id))
            }}
            onStarredClick={() => {
              StarTodo(userId, todo.id);
              // dispatch(toggleStarredTodo(todo.id));

            }}
            onDeleteClick={() => {
              DeleteTodo(userId, todo.id);
              // SaveTodo(userId, deleteSelectedTodo(selectedTodos, todoData))
              dispatch(deleteTodo(todo.id));
              // console.log(todo.id);
            }}
            onTodoClick={() => {
              dispatch(todoDetails(todo.id));
              showRightPart();
            }}
          />
        ))}
      </ListGroup>
    </div>
  );
};

const mapStateToProps = state => ({
  user: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(TodoListAchived);