import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { GetFleets, QueryFleetNamesByIds } from 'src/services/fleet-http.service'
import { GetOtganizationById } from 'src/services/organization-http.service'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  //The getContentAnchorEl and variant below are required to 
  //prevent the dropdown from jumping on select
  getContentAnchorEl: null,
  variant: "menu"
};

const useStyles = makeStyles((theme) => ({
  root: {
    
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: "100%",
      maxWidth: "100%",
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiInputLabel-root": {
        color: "white"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#a3a3a3"
      },

      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiInputLabel-root": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1EF1C6"
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#1EF1C6"
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#1EF1C6"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1EF1C6"
      }
    },
    checkbox : {
      "&.Mui-checked": {
          color: "#1EF1C6"
      },
      color: "#BFBFBF"
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    height: '500px',
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c"
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121"
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    }
  }

}));

export default function FleetMultiselect({ value, onChange, variant, orgId, organization, editMode }) {
  const [selected, setSelected] = React.useState([]);
  const [fleets, setFleets] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    initializeFleets();
  }, [fleets.length]);
  React.useEffect(() => {
    preselectFleets();
  }, [editMode]);

  const initializeFleets = () => {
    let fleetArray = [];
    GetFleets(orgId)
      .then(data => {
        Array.isArray(data) && data.length > 0 ? data.map(fleet => {
          fleetArray.push({ key: fleet.id, value: fleet.name });
        })
          : console.log("Error Occured");
        setFleets(fleetArray);
      });
  }
  const preselectFleets = () => {
    let fleetSelectArray = [];
    setSelected([])

      if(editMode===true){
        GetOtganizationById(organization.id).then(fleetIds => {
          QueryFleetNamesByIds(fleetIds.viewFleetIds)
        .then(data => {
          Array.isArray(data) && data.length > 0 ? data.map(fleet => {
            fleetSelectArray.push(fleet.id);
          })
            : console.log("Error Occured");
          setSelected(fleetSelectArray);
        });  
        })     
      }
      else{
        setSelected([])
      }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onChange(event);
  };

  const renderSelected = () => {
    let nameArray = []
    selected.map(id => {
      fleets.map(fleet => {if(fleet.key == id) nameArray.push(fleet.value)})
    })
    return nameArray.join(', ');
  }

  return (
    <div>
      <FormControl className={classes.root} variant={variant ? variant : "outlined"}>
        <InputLabel id="demo-multiple-checkbox-label">Fleet</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Fleet" />}
          renderValue={renderSelected}
          // MenuProps={MenuProps}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}

        >
          {fleets.map((fleet) => (
            <MenuItem key={fleet.key} value={fleet.key}>
              <Checkbox className={classes.checkbox} checked={selected.indexOf(fleet.key) > -1} />
              <ListItemText primary={fleet.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
