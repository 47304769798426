import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    ListGroup,
    ListGroupItem
} from 'reactstrap';

const TodoList = () => {
    const [todos, setTodos] = useState(
        [
            {
                id: 1,
                content: 'Schedule meeting with Dennis',
                completed: false,
                labelname: 'Pending',
                labelcolor: 'info'
            },
            {
                id: 2,
                content: 'Update RV table for Netherlands',
                completed: false,
                labelname: 'Rejected',
                labelcolor: 'danger'
            },
            {
                id: 3,
                content: 'Book flight for holiday',
                completed: false,
                labelname: 'Completed',
                labelcolor: 'success'
            },
            {
                id: 4,
                content: 'Investigate new price group',
                completed: false,
                labelname: 'Pending',
                labelcolor: 'info'
            },
            {
                id: 5,
                content: 'Recieve shipment',
                completed: false,
                labelname: 'Rejected',
                labelcolor: 'danger'
            },
            {
                id: 6,
                content: 'Send payment today',
                completed: false,
                labelname: 'Pending',
                labelcolor: 'info'
            },
            // {
            //     id: 7,
            //     content: 'Update sales report',
            //     completed: false,
            //     labelname: 'Pending',
            //     labelcolor: 'info'
            // }
        ]
    );

    const toggleComplete = todoId => {
        setTodos(
            todos.map(todo => {
                if (todo.id === todoId) todo.completed = !todo.completed;
                return todo;
            })
        );
    }

    const deleteTodo = todoId => {
        setTodos(
            todos.filter(todo => todo.id !== todoId)
        );
    }

    return (
        /*--------------------------------------------------------------------------------*/
        /* Used In All Dashboards && widget Page                                          */
        /*--------------------------------------------------------------------------------*/
        <Card
            className="w-100"
            style={{
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '4px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                boxShadow: '0 5px 6px #000000',
            }}
        >
            <CardBody>
                <CardTitle style={{ marginBottom: '20px', marginTop: '-3px', fontSize: "1.2vw", fontWeight: 600 }}>ToDo List</CardTitle>
                <CardSubtitle style={{ fontSize: "0.85vw", marginTop: '-19px' }}>List of your next task to complete</CardSubtitle>

                <div style={{ marginTop: "17px" }} className="todo-widget">
                    <ListGroup
                        className="list-task todo-list list-group mb-0"
                        data-role="tasklist"
                    >
                        {todos.map(todo => (
                            <ListGroupItem
                                className={
                                    'border-0 list-group-item todo-item ' +
                                    (todo.completed ? 'completed' : '')
                                }
                                key={todo.id}
                            >
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={todo.complete}
                                        id={todo.id}
                                        className="custom-control-input"
                                        data-toggle="checkbox"
                                        onClick={(todoId) => toggleComplete(todo.id)}
                                    />
                                    <label
                                        className="custom-control-label todo-label mr-3"
                                        htmlFor={todo.id}
                                    >
                                        <span className="todo-desc">{todo.content}</span>
                                    </label>
                                </div>
                                <div className={'mr-auto badge badge-' + todo.labelcolor}>
                                    {todo.labelname}
                                </div>
                                <span onClick={(todoId) => deleteTodo(todo.id)}>&times;</span>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
            </CardBody>
        </Card>
    );
}

export default TodoList;
