import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import UploadButton from 'src/components/buttons/upload-button.component';
import BrowseButton from 'src/components/buttons/browse-button.component';
import { UploadFleet } from 'src/services/maintenance-http.service';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import FleetSelect from 'src/components/form-elements/fleet-select.component';
import SimpleAlertDialog from 'src/components/form-elements/simple-dialog-window.component';

const useStyles = makeStyles((theme) => ({
  presentation: {
    height: theme.spacing(57),
    width: theme.spacing(43),
  },
  cardStyle: {
    "&.MuiCard-root": {
      overflow: 'hidden',
      height: '100%',
      position: 'relative'
    }
  },
  cardActionStyle: {
    "&.MuiCardActions-root": {
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  }
}));

const InternalFleetUpload = ({ onUploadComplete, orgId, fleetVehicle, onChange, handleCloseUpload, userMarketIds }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [isErrorDialog, setErrorDialog] = React.useState(false);

  const handleChange = (event) => {
    onChange(event);
  };

  const [rvSate, setRvState] = React.useState({
    fileName: "Choose file...",
    file: {},
    data: [],
    cols: []
  });

  const handleChangeBrowse = (e) => {
    const files = e.target.files;
    if (files && files[0]) setRvState({ file: files[0], fileName: files[0].name });
  };

  const handleDialogOk = () => {
    setOpenDialog(false)
    handleCloseUpload();
  }

  const handleFile = () => {
    let file = rvSate.file
    let fleetId = fleetVehicle.fleetId;
    let fleetTypeId = null;
    let marketIds = userMarketIds

    UploadFleet(file, fleetId, fleetTypeId, marketIds)
      .then(data => {
        setOpenDialog(true);
        if (data) {
          if (data.ok === true) {
            setDialogMessage('Vins upload successfully completed')
            setErrorDialog(false)
          }
          else {
            setDialogMessage(data.errorMessage)
            setErrorDialog(true)
          }
        }
        onUploadComplete();
      });
  }

  const classes = useStyles();

  return (
    <div role="presentation" className={classes.presentation}>
      <Card classes={{ root: classes.cardStyle }}>
        <SimpleAlertDialog
          open={openDialog}
          message={dialogMessage}
          isErrorDialog={isErrorDialog}
          handleDialogOk={handleDialogOk}
        />
        <CardHeader title={"Upload Fleet File"} />
        <Divider />
        <CardContent>
          <FleetSelect orgId={orgId} value={fleetVehicle.fleetId} onChange={handleChange} />
          <FormTextField
            onChange={handleChange}
            disabled={false}
            value={rvSate.fileName}
          />
        </CardContent>
        <CardActions classes={{ root: classes.cardActionStyle }} >
          <UploadButton
            variant="contained"
            handleFile={handleFile}
            handleCloseUpload={handleCloseUpload}
            color="default"
          />
          <BrowseButton
            handleChangeBrowse={handleChangeBrowse}
          />
        </CardActions>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InternalFleetUpload); 