import React from "react";
import { Row, Col, CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import "./styles.css";
import blackVehicle from 'src/assets/img/vehicles/blackVehicle.png';
import blueVehicle from 'src/assets/img/vehicles/blueVehicle.png';
import { FormmatDateMap } from 'src/utils/DateFormatter';

const InfoWindowComponentMap = (props) => {
    const { vehiclePosition } = props
    let firstpartVehicle = vehiclePosition?.postalCode === null ? '' : vehiclePosition?.postalCode;
    let secondpartVehicle = vehiclePosition?.city === null ? '' : vehiclePosition?.city;
    const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle

    return (
        <Col md={12} lg={12}>
            <div style={{
                marginLeft: '-20px',
                backgroundColor: '#E6E6E6',
                height: '115px',
                width: '450px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                marginBottom: '-8px'
            }}>
                <Row >
                    <Col md={8} sm={8} xs={8} lg={8} >
                        <Row>
                            <Col lg="6" md="6" >
                                <div>
                                    <CardImg
                                        alt="Card image cap"
                                        src={vehiclePosition?.color == 'Blue' ? blueVehicle : blackVehicle}
                                        style={{ transform: 'scale(.95)', marginLeft: '5px', marginTop: '-2px' }}
                                    />
                                </div>
                            </Col>
                            <Col lg="6" md="6" style={{ marginLeft: '-10px' }} >
                                <div style={{ marginBottom: '5px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >VIN</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >{vehiclePosition.vin}</span>
                                </div>

                                <div style={{ marginBottom: '-10px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px', }}  >Odometer</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px', }}  >{vehiclePosition.odometer}</span> <br /><br />

                                </div>

                                <div>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }} >Last Updated</span><br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >{FormmatDateMap(vehiclePosition.vehicleUpdatedAt)}</span> <br />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4} sm={4} xs={4} lg={4} style={{ marginLeft: '-35px' }} >
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginBottom: '5px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >Street</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }} >{vehiclePosition.street}</span><br />
                                </div>
                                <div style={{ marginBottom: '-10px' }}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >Post Code</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }} >{postalCodeValueVehicle}</span><br /><br />
                                </div>
                                <div>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }}  >Country</span> <br />
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', color: '#000', whiteSpace: 'nowrap', fontSize: '12px' }} >{vehiclePosition.country}</span><br />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InfoWindowComponentMap);