export const FormmatDate = (utcDate, dateOnly = false) => {
    if (utcDate == null) {
        return " "
    }
    else if (Date.parse(utcDate) === 0) {
        return " "
    }

    let msec = Date.parse(utcDate);

    if (msec) {
        let datetime = new Date(msec);
        let date = (datetime.getDate());

        // if (datetime.getFullYear() == 1970) {
        //     return " "
        // }
        let month = (datetime.getMonth() + 1);
        let formatedDate = dateOnly ? `${date > 9 ? date : `0${date}`}-${month > 9 ? month : `0${month}`}-${datetime.getFullYear()}`
            : `${date > 9 ? date : `0${date}`}-${month > 9 ? month : `0${month}`}-${datetime.getFullYear()} `
        return formatedDate;
    }

    return utcDate;
}

export const FormmatDateMap = (utcDate, dateOnly = false) => {
    if (utcDate == null) {
        return " "
    }
    else if (Date.parse(utcDate) === 0) {
        return " "
    }

    let msec = Date.parse(utcDate);

    if (msec) {
        let datetime = new Date(msec);
        let date = (datetime.getDate());
        let month = (datetime.getMonth() + 1);
        let minutes = (datetime.getMinutes());
        let hours = (datetime.getHours());
        let formatedDate = dateOnly ? `${date > 9 ? date : `0${date}`}-${month > 9 ? month : `0${month}`}-${datetime.getFullYear()}`
            : `${date > 9 ? date : `0${date}`}-${month > 9 ? month : `0${month}`}-${datetime.getFullYear()} ${hours}:${minutes > 9 ? minutes : `0${minutes}`}`
        return formatedDate;
    }

    return utcDate;
}