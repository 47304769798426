import React from 'react';
import { Col } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import MapUnprovisioning from 'src/components/maps/MapPage.componentUnprovisioning';
import MapPageComponentVSP from 'src/components/maps/MapPage.componentVSP';
import { useLoadScript } from "@react-google-maps/api";
import { environment } from 'src/environments/environment';

const MapPage = (props) => {
    let { value, height, data, vehicleColor, vin, mapSpinner, showSVTMap, setAddressInput, addressInput, setVehicleDetails, setShowSVTMap, handleChangeVIN, setComments, setWorkOrderStatus, valueVIN, selectedVIN, setSelectedVIN, setSelectedVINMap, selectTable, vspmap, detailsmap, detailsMap, rsamap } = props
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: environment.googleMapsApiKey,
        libraries: ["places"],
    });

    return (
        <Col md={12} lg={12}>
            <Grid container  >
                <Grid item xs={12} md={12} lg={12}>
                    {
                        value === 2 || value === 3 ? (<>
                            {isLoaded &&
                                <MapPageComponentVSP
                                    value={value}
                                    height={height}
                                    vin={vin}
                                    data={data}
                                    mapSpinner={mapSpinner}
                                    showSVTMap={showSVTMap}
                                    setAddressInput={setAddressInput}
                                    addressInput={addressInput}
                                    setVehicleDetails={setVehicleDetails}
                                    setShowSVTMap={setShowSVTMap}
                                    handleChangeVIN={handleChangeVIN}
                                    setComments={setComments}
                                    setWorkOrderStatus={setWorkOrderStatus}
                                    valueVIN={valueVIN}
                                    selectedVIN={selectedVIN}
                                    setSelectedVIN={setSelectedVIN}
                                    setSelectedVINMap={setSelectedVINMap}
                                    selectTable={selectTable}
                                    vspmap={vspmap}
                                    detailsmap={detailsmap}
                                    detailsMap={detailsMap}
                                    rsamap={rsamap}
                                />
                            }
                        </>) : (<>
                            {isLoaded ? <MapUnprovisioning value={value} height={height} data={data} vehicleColor={vehicleColor} mapSpinner={mapSpinner} /> : null}
                        </>)
                    }
                </Grid>
            </Grid>
        </Col>
    );
};

export default MapPage;