import React from 'react';
import PropTypes from 'prop-types';
import { SingleSlider } from 'src/components/vehicleDetails/Carousel/components/carousel/CarouselElements';

const CarouselSingle = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 2 } },
      { breakpoint: 1200, settings: { slidesToShow: 3 } }
    ],
  };

  return (
    <SingleSlider {...settings}>
      {children}
    </SingleSlider>
  );
};

CarouselSingle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CarouselSingle;