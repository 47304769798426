import React, { useState } from "react";
import { Card, CardBody, CardSubtitle } from "reactstrap";
import Chart from "react-apexcharts";
import { PieChart, Pie } from 'recharts';
import styled from 'styled-components';

const UtilizationDetailCard = () => {
  const [open, setOpen] = useState(false);


  const handleDrawar = () => {
    setOpen(true);
  }

  const data01 = [{ value: 80, fill: '#1ef1c6' },
  { value: 20, fill: '#eeeeee' }];


  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Utilization Page in Vehicle Details Page                                               */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContents: 'center',
        display: 'flex',
        marginTop: '20px',
      }}
    >
      <CardBody>
        <DashboardStatWrap>
          <DashboardStatChartWrap>
            <PieChart height={120} width={120}>
              <Pie data={data01} dataKey="value" cx={55} cy={55} innerRadius={49} outerRadius={58} stroke="transparent" />
            </PieChart>
            <DashboardStatLabel style={{ color: '#808080' }}>80%</DashboardStatLabel>
          </DashboardStatChartWrap>
        </DashboardStatWrap>
      </CardBody>
    </Card>
  );
};

export default UtilizationDetailCard;


export const DashboardStatLabel = styled.p`
  position: absolute;
  font-size: 28px;
  line-height: 20px;
  top: calc(50% - 10px);
  width: 100%;
  margin: 0;
  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    margin-top: -20px;
  };
  animation: label 1.5s ease-in;
  color: darkgray;

  @keyframes label {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }
`;

export const DashboardStatChartWrap = styled.div`
  position: relative;
  text-align: center;

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    svg, div {
      width: 110px !important;
      height: 110px !important;
      margin-top: -15px;
    }
  }
  
  @media screen and (min-width: 1281px) and (max-width: 1539px) {
    svg, div {
      width: 150px !important;
      height: 150px !important;
    }
  }

  @media screen and (min-width: 1540px) and (max-width: 1800px) {
    svg, div {
      width: 190px !important;
      height: 190px !important;
    }
  }
`;

export const DashboardStatWrap = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
`;