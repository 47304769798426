import React, { useState, useEffect } from 'react';
import './SlideInAnimation.css';
import { Col, Row } from 'react-bootstrap';

const LoadingPage = ({ userName }) => {
    if (userName === 'undefined') {
        userName = ''
    }
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);
    const [isVisible5, setIsVisible5] = useState(false);
    const [isVisible6, setIsVisible6] = useState(false);

    useEffect(() => {

        const timer1 = setTimeout(() => {
            setIsVisible(true);

        }, 1000); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setIsVisible2(true);
        }, 2000); // Adjust the delay as needed

        const timer3 = setTimeout(() => {
            setIsVisible(false);
            setIsVisible2(false);
        }, 4000); // Adjust the delay as needed

        const timer4 = setTimeout(() => {
            setIsVisible3(true);
        }, 4500);
        const timer5 = setTimeout(() => {
            setIsVisible4(true);
        }, 5500);
        const timer6 = setTimeout(() => {
            setIsVisible5(true);
        }, 5500);
        const timer7 = setTimeout(() => {
            setIsVisible6(true);
        }, 6500);

        const timer8 = setTimeout(() => {
            setIsVisible3(false);
            setIsVisible4(false);
            setIsVisible5(false);
            setIsVisible6(false);
        }, 13000);


        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
            clearTimeout(timer5);
            clearTimeout(timer6);
            clearTimeout(timer7);
            clearTimeout(timer8);
        };

    }, []);

    return (
        <div className="black-background">
            <Row style={{ height: '100%', backgroundImage: `url(${'../assets/icons/landingimage.png'})` }} className="containerbg">
                <Col sm={6}>
                    <div style={{ backgroundColor: 'transparent' }}>
                        {isVisible && (<span className="centered-text">
                            {isVisible && (<span style={{ bottom: '-60px' }} className="slide-left text-left centered-textparagraph">Hello</span>)} <br />
                            {isVisible2 && (<span className="slide-right text-border centered-textparagraph">{userName}</span>)}
                        </span>)}

                        {isVisible3 && (<span style={{ height: '100%' }} className="centered-text2">

                            {isVisible3 && (<span style={{ bottom: '-60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-left text-left centered-textparagraph">sit back</span>)}<br />

                            {isVisible4 && (<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-right text-border-three centered-textparagraph">& buckle</span>)}<br />
                            {isVisible5 && (<span style={{ top: '-60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-right text-border-two centered-textparagraph">up!</span>)}<br />

                            {isVisible6 && (<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-bottom text-border-four textcaselower">Your Lynk & Co fleet is loading...</span>)}
                        </span>)}
                    </div>

                </Col>
            </Row>
        </div>
    );
};

export default LoadingPage;