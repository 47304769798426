import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const FleetTypeCard = ({ userOrg, userMarketIds, setPresSelectedFleetTypes, setFleetTypeIdscongfig, setQueryParamsFleetTypeIds, setDataLoaded }) => {
    let marketIds = [];

    const [selected, setSelected] = useState([]);
    const [fleetTypes, setFleetTypes] = useState([]);
    const [sliderUser, setSliderUser] = useState({
        editMode: false
    });

    useEffect(() => {
        initializeFleetTypes();
        setSliderUser({
            editMode: true
        })
    }, [fleetTypes.length]);

    useEffect(() => {
        preselectFleetTypes();
    }, [sliderUser.editMode]);

    /////////////////////////////////////////// 

    const initializeFleetTypes = () => {
        let fleetTypesArray = [];
        GetFleetTypes(userOrg.id)
            .then(data => {
                Array.isArray(data) && data.length > 0 ? data.map(fleetType => {
                    fleetTypesArray.push({ key: fleetType.id, value: fleetType.name });

                })
                    : console.log("Error Occured");
                let newName = fleetTypesArray.find((p) => {
                    return p.value === 'Month to Month';
                });
                newName.value = 'M2M'
                setFleetTypes(fleetTypesArray);
            });


    }
    const preselectFleetTypes = () => {
        let fleetTypesSelectArray = [];
        setSelected([])

        if (sliderUser.editMode === true) {

            GetFleetTypes(userOrg.id)
                .then(data => {
                    Array.isArray(data) && data.length > 0 ? data.map(fleetType => {

                        fleetTypesSelectArray.push(fleetType.id);
                    })
                        : console.log("Error Occured");
                    setSelected(fleetTypesSelectArray);

                });
        }
        else {
            setSelected([])
        }
    };

    const handleChange = (name, isDisabled = true) => (event) => {

        let updatedSelected = []

        if (isDisabled) {
            setPresSelectedFleetTypes(updatedSelected);
            updatedSelected = selected.filter((item) => item !== name);

            marketIds = userMarketIds;
            setQueryParamsFleetTypeIds(updatedSelected);
            setFleetTypeIdscongfig(true);
            setDataLoaded(false);
        };
        if (!isDisabled) {
            setPresSelectedFleetTypes(updatedSelected);
            updatedSelected = [...selected, name];
            marketIds = userMarketIds;
            setQueryParamsFleetTypeIds(updatedSelected);
            setFleetTypeIdscongfig(true);
            setDataLoaded(false);
        };
        setSelected(updatedSelected);
    };

    return (
        <Card id="CardBodyCardAllowance4" style={{ marginTop: '-20px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>FLEET TYPE</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white', marginTop: '-15px' }}>
                    {
                        fleetTypes.map((item) => {
                            return (
                                <tr>
                                    <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '30px' }}>{item.value}</td> <br />
                                    <td style={{ float: 'right', }}>
                                        <ToggleComponent checked={selected.indexOf(item.key) > -1} onChange={handleChange(item.key, selected.indexOf(item.key) > -1)} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </CardBody>
        </Card>
    )
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(FleetTypeCard);