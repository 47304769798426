import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import dots_circle_active from 'src/assets/img/vehicles/dots_circle_active.png';
import dots_circle_disabled from 'src/assets/img/vehicles/dots_circle_disabled.png';
import dots_circle_hover from 'src/assets/img/vehicles/dots_circle_hover.png';

const FormSelect = ({ value, onClick, onChange, menuObjectArray = [{ key: '', value: '' }] }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const imgs = [dots_circle_disabled, dots_circle_hover, dots_circle_active];

  const [icon, setIcon] = useState(imgs[0]);
  const [openIcon, setOpenIcon] = React.useState(false);
  const [openVehicleRangeIconToggle, setOpenVehicleRangeIconToggle] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIcon(imgs[2]);
    setOpenVehicleRangeIconToggle(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIcon(imgs[0]);
  };

  const handleCloseHomeIcon = () => {
    setIcon(imgs[0]);
    setOpenIcon(false)
    setOpenVehicleRangeIconToggle(true);
  };

  const handleOpenHomeIcon = () => {
    setIcon(imgs[1]);
    setOpenIcon(true);
    setOpenVehicleRangeIconToggle(false);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center", marginTop: '-22px' }}
      >
        <Tooltip
          title="Options"
          onOpen={handleOpenHomeIcon}
          onClose={openVehicleRangeIconToggle ? null : handleCloseHomeIcon}
          placement='top'
        >
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              className="dotcircle"
              src={icon}
              alt="Three Dot Image"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        value={value}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: 180,
            backgroundColor: '#000',
            color: '#fff',
            backgroundImage: 'linear-gradient(135deg, #000000 20%, #1b1b1b 99%)',
            marginLeft: -2.4,
            marginTop: -1,
            borderRadius: '15px',
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#212121"
            },
            "& .MuiAvatar-root": {
              width: 52,
              height: 32,
              ml: -0.5,
              mr: 0.5,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "transparent",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        disableScrollLock={true}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {
          menuObjectArray.map((menu, key) => {
            return (<MenuItem style={{ fontSize: '13px', fontFamily: "LynkcoTypeLight" }} onClick={onClick} value={menu.key} key={key}>{menu.value}</MenuItem>);
          })
        }
      </Menu>
    </>
  );
};

export default FormSelect;