import { makeStyles } from '@material-ui/core/styles';
//import '../../../theme';



export const useTableStyles = makeStyles((theme) => ({
    tabeleContainer: {
        minWidth: '1100px',
        maxWidth: '1250px',
        '&::-webkit-scrollbar': { width: 0, height: 6, backgroundColor: '#2c2c2c ', color: 'white' },  //#2c2c2c
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#808080', width: '2px' },
        marginLeft: '30px',
    },
    head: {
        backgroundColor: "black",
        minWidth: "50px",
    },
    root: {
        width: '100%',
        marginTop: '-15px',

    },
    slider: {
        width: 350,
        height: "100%"
    },
    fullList: {
        width: 'auto',
    },
    divider: {
        backgroundColor: "inherit",
        margin: theme.spacing(3),
    },
    SwipeableDrawer: {
        "&.MuiDrawer-paperAnchorRight": {
            top: '79px',
            right: '45px',
            height: '87vh'
        }
    },
    cardStyle: {
        "&.MuiCard-root": {
            overflow: 'hidden',
            height: '80%',
            position: 'relative'
        }
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            padding: '8px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    paper: {
        '&:: -webkit-scrollbar': {
            width: 'Zpx',
            height: '1px',
            backgroundColor: '#aaa',
            background: '#000',
        },
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: '10px',
        marginRight: '-50px'
    },
    table: {
        'webkit-scrollbar-track': {
            backgroundColor: 'black',
        },
        minWidth: 750,
        fontSize: 'large',

        //...LynkcoRegular
        //fontFamily: LynkcoRegular
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tablehead: {
        //...LynkcoMedium,
        fontSize: 'medium',
        color: '#fff',
        //backgroundColor: "#212121",
        fontFamily: 'LynkcoTypeRegular',
        minWidth: 80,
    },
    tableRow: {
        color: "#fff",
        cursor: "pointer",
        "&.Mui-selected": {
            backgroundColor: "#2c2c2c",
            "& > .MuiTableCell-root": {
                color: "white",
                borderBottomStyle: 'solid',
                borderTopStyle: 'solid',
                borderWidth: '0.1px',
                borderColor: '#BFBFBF',

            },
        },
        '&.MuiTableRow-hover:hover': {
            backgroundColor: "#212121",
            fontSize: 'medium',
        },
        fontWeight: '50',
        //fontFamily: 'LynkcoTypeLight',
        //fontFamily: theme.LynkcoTypeLight,
    },
    tableCell: {
        padding: '0px',
        fontSize: 'medium',
        color: "#fff",
        fontWeight: '50',
        fontFamily: 'LynkcoTypeLight',
        //...LynkcoTypeLight
    },

    // tableCellradio: {
    //     borderBottom: "none",
    //     borderTop: "none",
    //     paddingBottom: 3,
    //     paddingTop: 0,
    //     paddingLeft: 0,
    //     paddingRight: 0,

    // },

    tableCheckbox: {
        "&.Mui-checked": {
            color: " #1EF1C6",
        },
        color: "#BFBFBF",

    },
    hover: {},
    selected: {},
    tableModal: {
        //...LynkcoMedium
    },
    title: {
        //...LynkcoBold
    },
    paginationCaption: {
        fontFamily: 'LynkcoTypeLight',
        color: "white",
        //...LynkcoBold
    },

    selectStyle: {
        // background: '#131313',
        backgroundImage: 'linear-gradient(135deg, #000000 20%, #1b1b1b 99%)',
        color: '#fff',
        '& .MuiListItem-root.Mui-selected': {
            background: '#222222',
        },

        '& .MuiListItem-root:hover': {
            backgroundColor: '#2C2C2C',
            color: '#fff',
        }
    },

    paginationToolbar: {
        fontFamily: 'LynkcoTypeLight',
        color: "white",
        "& > p:nth-of-type(2)": {
            color: "white"
            //...LynkcoMedium
        }
    },
    dialog: {
        "&.MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            right: "100px",
            bottom: "0",
            left: "0",
            zIndex: "10040",
            overflow: "auto",
            overflowY: "auto"
        }
    },
}));