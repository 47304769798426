import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#ff0000',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#9a0f0f',
            color: '#FFF'
        }
    }
}));

const CancelButton = ({ handleDeleteDialogCancel }) => {
    const classes = useStyles();
    return (
        <Button onClick={handleDeleteDialogCancel} className={classes.button} variant="contained">
            No way!
        </Button>
    );
}

export default CancelButton;

