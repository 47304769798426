import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { ContactProps, MessageProps } from 'src/prop-types/ChatProps';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DotsIcon from 'mdi-react/DotsHorizontalIcon';
import { DeleteComment } from 'src/services/vehicle-comments-http.service';
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import ChatField from 'src/containers/Chat/components/ChatField';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '-50px',
    backgroundColor: '#2c2c2c',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 120,
    height: 30,
    // fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
  },
}));

const ChatBubble = ({ contact, message, active, date, userEmail, getComments, vin, photo, fullName }) => {
  const [update, setUpdate] = useState(false);
  const [input, setInput] = React.useState('');
  const [editComments, setEditComments] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const handleClickDelete = () => {
    let id = active;
    let userId = userEmail.id;

    if (active) {
      DeleteComment(id, userId).then(data => {
        getComments();
      })
    }
  };

  const toggleEdit = () => {
    if (contact === userEmail.id) {
      setEditComments(true);
      setEnableEdit(true);
      setInput(message);
    }
  }

  return (
    <ChatBubbleWrap active={active}>
      <ChatBubbleAvatar>
        <img src={photo} alt="ava" />
      </ChatBubbleAvatar>
      {editComments ? (<>
        <ChatField
          vin={vin}
          defaultValue={input}
          id={active}
          editComments={enableEdit}
          action={'edit'}
          setEditComments={setEditComments}
          getComments={getComments}
          autoFocus
        />
        </>) : (<>
        {
          update ? (<>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <em>
                    <Tooltip title="Edit"
                      onClose={() => setUpdate(false)}
                      placement='left-end'
                      onClick={toggleEdit}
                    >
                      <span>
                        <IconButton aria-label="edit">
                          <EditIcon style={{ width: '20px', height: '40px', marginTop: '-20px' }} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Delete "
                      onClose={() => setUpdate(false)}
                      placement='right-end'
                    >
                      <span>
                        <IconButton aria-label="delete">
                          <DeleteIcon style={{ width: '20px', height: '40px', marginTop: '-17px' }} onClick={handleClickDelete} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </em>
                </React.Fragment>
              }
            >
              {message?.file ? (
                <ChatBubbleMessageWrap file>
                  <ChatBubbleContactName>{fullName}</ChatBubbleContactName>
                  <img src={message.file.preview} alt={message.message} />
                  <ChatBubbleDate>{moment(message.date).format('LT')}</ChatBubbleDate>
                  <ChatBubbleDownloadWrap>
                    <ChatBubbleFileName>
                      <span>{message.message}</span>
                      <span>{message.file.size}</span>
                    </ChatBubbleFileName>
                    <ChatBubbleDownload href={message.file.preview} download>Download</ChatBubbleDownload>
                  </ChatBubbleDownloadWrap>
                </ChatBubbleMessageWrap>
              ) : (
                <div>
                  <ChatBubbleMessageWrap>
                    <ChatBubbleContactName>{fullName}</ChatBubbleContactName>
                    <ChatBubbleMessage>{message}</ChatBubbleMessage>
                    <ChatBubbleDate>{moment(date).format('LT')}</ChatBubbleDate>
                  </ChatBubbleMessageWrap>
                </div>
              )}
            </HtmlTooltip>
          </>) : (
            <>
              {contact === userEmail.id ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <em>
                        <Tooltip title="Update"
                          onOpen={() => setUpdate(true)}
                          placement='top-start'
                        >
                          <IconButton aria-label="edit">
                            <DotsIcon style={{ width: '40px', height: '60px', marginTop: '-25px' }} />
                          </IconButton>
                        </Tooltip>
                      </em>
                    </React.Fragment>
                  }
                >
                  {message?.file ? (
                    <ChatBubbleMessageWrap file>
                      <ChatBubbleContactName>{contact?.name}</ChatBubbleContactName>
                      <img src={message?.file.preview} alt={message.message} />
                      <ChatBubbleDate>{moment(message?.date).format('LT')}</ChatBubbleDate>
                      <ChatBubbleDownloadWrap>
                        <ChatBubbleFileName>
                          <span>{message?.message}</span>
                          <span>{message?.file.size}</span>
                        </ChatBubbleFileName>
                        <ChatBubbleDownload href={message?.file.preview} download>Download</ChatBubbleDownload>
                      </ChatBubbleDownloadWrap>
                    </ChatBubbleMessageWrap>
                  ) : (
                    <div>
                      <ChatBubbleMessageWrap>
                        <ChatBubbleContactName>{fullName}</ChatBubbleContactName>
                        <ChatBubbleMessage>{message}</ChatBubbleMessage>
                        <ChatBubbleDate>{moment(date).format('LT')}</ChatBubbleDate>
                      </ChatBubbleMessageWrap>
                      {/* </div>)} */}
                    </div>

                  )}
                </HtmlTooltip>

              ) : (<>
                {message?.file ? (
                  <ChatBubbleMessageWrap file>
                    <ChatBubbleContactName>{contact?.name}</ChatBubbleContactName>
                    <img src={message?.file.preview} alt={message.message} />
                    <ChatBubbleDate>{moment(message?.date).format('LT')}</ChatBubbleDate>
                    <ChatBubbleDownloadWrap>
                      <ChatBubbleFileName>
                        <span>{message?.message}</span>
                        <span>{message?.file.size}</span>
                      </ChatBubbleFileName>
                      <ChatBubbleDownload href={message?.file.preview} download>Download</ChatBubbleDownload>
                    </ChatBubbleDownloadWrap>
                  </ChatBubbleMessageWrap>
                ) : (
                  <div>
                    <ChatBubbleMessageWrap>
                      <ChatBubbleContactName>{fullName}</ChatBubbleContactName>
                      <ChatBubbleMessage>{message}</ChatBubbleMessage>
                      <ChatBubbleDate>{moment(date).format('LT')}</ChatBubbleDate>
                    </ChatBubbleMessageWrap>
                  </div>
                )}
              </>)}
            </>)
        }
      </>)}
    </ChatBubbleWrap>
  );
}

ChatBubble.propTypes = {
  contact: ContactProps.isRequired,
  message: MessageProps.isRequired,
  active: PropTypes.bool,
};

ChatBubble.defaultProps = {
  active: false,
};

const mapStateToProps = state => ({
  userEmail: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(ChatBubble);

// region STYLES

const ChatBubbleWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  

  ${props => props.active && `
  
    .chat__bubble-message-wrap {
      background-color: rgba(92, 104, 156, 0.6);
    }
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const ChatBubbleAvatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: none;
  margin-right: 10px;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

const ChatBubbleMessageWrap = styled.div`
  max-width: 570px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 40px 40px 20px;
  position: relative;
  padding-right: 40px;

  padding-left: 20px;
  background-color: #131313;

  
  ${props => props.file && 'padding: 10px 20px 20px 20px'};

  @media screen and (min-width: 1366px) {
    position: static;
  }

  img {
    max-width: 215px;
  }
`;

const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: #4ce1b6;
  padding-right: 50px;
`;

const ChatBubbleDate = styled.p`
  color: #999999;

  font-size: 10px;
  margin: 0;
  text-align: end;
  right: 36px;

  @media screen and (min-width: 1366px) {
    right: 0;
  }
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: #dddddd;
`;

const ChatBubbleDownload = styled.a`
  color: #131313;
  
  font-weight: 500;

  &:hover {
    color: #4ce1b6;
  }
`;

//////////// darken color #4ce1b6 in line -176 !!!!!!!!!!!!!!!!!!!!!!!!

const ChatBubbleDownloadWrap = styled.div`
  max-width: 215px;
  margin-top: 10px;
  font-size: 10px;

  @media screen and (min-width: 1366px) {
    position: absolute;
    top: 0;
    max-width: calc(100% - 375px);
    width: 300px;
    margin-top: 0;
    left: 315px;
  }
`;

const ChatBubbleFileName = styled.p`
  white-space: nowrap;
  display: flex;

  span:first-child {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span:last-child {
    color: #999999;

    margin-left: 5px;
  }
`;

// endregion