import React from 'react';
import { connect } from 'react-redux';
import { selectAdUserDetails } from 'src/redux/user/user.selectors';
import LoadingPage from 'src/pages/loading-page/loading-page';

const Welcome = ({ userData }) => {
    return <LoadingPage showWelcome={true} welcomeText="Welcome to your fleet" userName={userData && userData.graphData ? `${userData.graphData.givenName}` : null} />
};

const mapStateToProps = state => ({
    userData: selectAdUserDetails(state)
});

export default connect(mapStateToProps, null)(Welcome);