import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#1ef1c6',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#088a70',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        borderRadius: '1px 1px 1px 10px'
    }
}));

const ContinueButton = ({ handleDone , placeholder}) => {
    const classes = useStyles();
    return (
        <Button onClick={handleDone} className={classes.button} placeholder={placeholder}  variant="contained">
            <Typography>
            {placeholder} 
            </Typography>
        </Button>
    );
}

export default ContinueButton;

