import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState } from "react";
// import { ReverseGeocoding } from 'src/services/geocoder.service'
import vehicle from 'src/assets/img/icons/vehicle.png';
import { environment } from 'src/environments/environment';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from 'src/utils/MapStyle';

let markers = []

const MapComponent = (props) => {
  const { positionLat, positionLng, shouldRenderMap } = props
  const ref = useRef(null);
  const [map, setMap] = useState();
  const center = { lat: positionLat, lng: positionLng }


  /**
   * Because google.maps.Map requires an Element as a constructor parameter,
   * useRef is needed to maintain a mutable object
   * that will persist for the lifetime of the component.
   */
  useEffect(() => {
    if (ref.current && !map && shouldRenderMap) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map, shouldRenderMap]);


  // Add marker
  useEffect(() => {

    if (map && shouldRenderMap) {
      map.setOptions({
        center, zoom: 11, disableDefaultUI: true,
        zoomControl: true,
        styles: styles
      });
      setTimeout(() => {
        addMarker(map, center)
      }, 500)
    }
  }, [map, center]);

  return (
    <>
      <div ref={ref} style={{ width: "100%", height: "100%" }} />
    </>
  );
}

const addMarker = (map, center) => {
  //clear all current markers and add a new one
  clearMarkers()
  let marker = new window.google.maps.marker.AdvancedMarkerElement({
    position: center,
    map,
    animation: window.google.maps.Animation.DROP,
    icon: vehicle
  });
  markers.push(marker)
}

const clearMarkers = () => {
  markers.map(marker => {
    marker.setAnimation(null)
    marker.setMap(null)
  })
  markers = []
}

const render = (status) => {
  if (status === Status.LOADING) return "Loading...";
  if (status === Status.FAILURE) return "Error";
  return null;
};

const Map = (props) => {
  const { positionLat, positionLng, shouldRenderMap, isExpanded } = props

  const useStyles = makeStyles((theme) => ({
    root: {
      height: (props) => props.isExpanded ? '40.7vh' : '68.7vh',
      width: "100%",
    },

  }));

  const classes = useStyles(props);

  return (
    <div
      className={classes.root}
    >
      <Wrapper apiKey={environment.googleMapsApiKey} render={render} >
        <MapComponent positionLat={positionLat} positionLng={positionLng} shouldRenderMap={shouldRenderMap} />
      </Wrapper>
    </div>

  );
};

export default Map;