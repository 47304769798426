import React from "react";
import { CardGroup, Card, CardBody } from "reactstrap";

import Chart from "react-apexcharts";

const ExcessMileageView = () => {
  const optionsvisit1 = {
    colors: ["#fd7e14"],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "65%",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: { enabled: true },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      stroke: {
        show: false,
      },
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      x: {
        show: false,
      },
    },
  };
  const seriesvisit1 = [
    {
      name: "",
      data: [9, 11, 15, 13, 17, 23, 19],
    },
  ];
  // 2
  const optionsvisit2 = {
    colors: ["#4fc3f7"],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "65%",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: { enabled: true },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      stroke: {
        show: false,
      },
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      x: {
        show: false,
      },
    },
  };
  const seriesvisit2 = [
    {
      name: "",
      data: [2, 5, 6, 10, 9, 12, 4],
    },
  ];


  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Dashboard-4                                                            */
    /*--------------------------------------------------------------------------------*/
    <CardGroup>
      <Card
        className="w-100"
        style={{
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '4px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          boxShadow: '0 5px 6px #000000',
        }}
      >        
      <CardBody className="text-center">
          <h4 className="text-center">Excess Mileage</h4>
          <div style={{ width: "100px", margin: "0 auto" }}>
            <Chart
              options={optionsvisit1}
              series={seriesvisit1}
              type="bar"
              height="65px"
            />
          </div>
        </CardBody>
        <div className="p-2 border-top text-center">
          <h4 className="font-medium mb-0">
            <i className="ti-angle-up text-orange" /> 107
          </h4>
        </div>
      </Card>

      <Card>
        <CardBody className="text-center">
          <h4 className="text-center">{"Idle Vehicles at LCC > 48h"}</h4>
          <div style={{ width: "100px", margin: "0 auto" }}>
            <Chart
              options={optionsvisit2}
              series={seriesvisit2}
              type="bar"
              height="65px"
            />
          </div>
        </CardBody>
        <div className="p-2 border-top text-center">
          <h4 className="font-medium mb-0">
            <i className="ti-angle-down text-cyan" /> 456
          </h4>
        </div>
      </Card>

    </CardGroup>
  );
};

export default ExcessMileageView;