import SidebarTypes from "src/redux/sidebar/sidebar.types";

const INITIAL_STATE = {
  sidebarShow: true,
  anchorEl: null,
  mobileMoreAnchorEl: null
}

const toggleSidebar = (state = INITIAL_STATE, action) => {

  const { type, payload } = action;

  switch (type) {
    case SidebarTypes.TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: payload }
    case SidebarTypes.SET_ANCHOR_El:
      return { ...state, anchorEl: payload }
    case SidebarTypes.SET_MOBILE_MORE_ANCHOR_El:
      return { ...state, mobileMoreAnchorEl: payload }
    default:
      return state
  }
}


export default toggleSidebar;


// import {
//   CHANGE_SIDEBAR_VISIBILITY,
//   CHANGE_MOBILE_SIDEBAR_VISIBILITY,
// } from '../actions/sidebarActions';

// const initialState = {
//   show: false,
//   collapse: false,
// };

// const sidebarReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case CHANGE_SIDEBAR_VISIBILITY:
//       return { ...state, collapse: !state.collapse };
//     case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
//       return { ...state, show: !state.show };
//     default:
//       return state;
//   }
// };

// export default sidebarReducer;
