import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "35px",

        marginTop: (props) => props.adjustTopMargin ? -theme.spacing(0.05) : '',
        visibility: (props) => props.visibility,
        '& .MuiFormLabel-root': {
            fontSize: '100%'

        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#1EF1C6'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'white'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: (props) => props.isNumberOfVins ? props.value > 10 ? '#1EF1C6' : '#DC1860' : 'gray',
            fontSize: '1.2rem'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: (props) => props.priceBucketActiveTab ? "#1EF1C6" : 'rgba(255, 255, 255, 0.2)',
                borderWidth: (props) => props.priceBucketActiveTab ? '1px' : 'thin'
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
        "& .MuiOutlinedInput-input": {
            color: "white",
            textAlign: (props) => props.isPriceBucketField ? 'end' : 'start'
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.25)' : '#a3a3a3',
            },
            '&:hover fieldset': {
                borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.25)' : '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.25)' : '#1EF1C6',
            },
        },
    },
}))

const useStylesPlain = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "35px",
        backgroundColor: 'rgb(19 19 19 / 1%)',


        marginTop: (props) => props.adjustTopMargin ? -theme.spacing(0.05) : '',
        visibility: (props) => props.visibility,
        '& .MuiFormLabel-root': {
            fontSize: '0.85rem',
            backgroundColor: 'rgb(19 19 19 / 1%)',
            display: ''

        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#1EF1C6'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'white'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: (props) => props.isNumberOfVins ? props.value > 10 ? '#1EF1C6' : '#DC1860' : 'white',
            fontSize: (props) => props.updateFontSize ? 'calc(10px + 0.390625vw)' : '16px',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgb(19 19 19 / 1%)',
            '& fieldset': {
                borderColor: (props) => props.priceBucketActiveTab ? "#1EF1C6" : 'rgba(255, 255, 255, 0.001)',
                borderWidth: (props) => props.priceBucketActiveTab ? '0.5px' : 'thin',
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
        "& .MuiOutlinedInput-input": {
            color: "white",
            textAlign: (props) => props.isPriceBucketField ? 'end' : 'start',
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.001)',
            },
            '&:hover fieldset': {
                // borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.05)' : '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: (props) => props.isNumberOfVins ? 'rgba(0, 0, 0, 0.05)' : '#1EF1C6',
            },
        }
    },
}))

export const FormTextFieldSales = (props) => {
    const { classes, name, labelText, value, onChange, variant, disabled, placeholder, color, visibility = 'visible',
        adjustTopMargin = true, isNumberOfVins = false, priceBucketActiveTab = false, isPriceBucketField = false } = props
    const classesA = useStyles(props);

    return (<TextField
        margin="dense"
        id={name}
        name={name}
        label={labelText}
        type="text"
        value={value}
        onChange={onChange}
        variant={variant ? variant : "outlined"}
        disabled={disabled ? true : false}
        classes={classesA}
        placeholder={placeholder}
        fullWidth
    />);
}


export const FormTextFieldSalesPlain = (props) => {
    const { classes, name, labelText, value, onChange, variant, disabled, placeholder, updateFontSize = false,
        color, visibility = 'visible', adjustTopMargin = true, isNumberOfVins = false, priceBucketActiveTab = false, isPriceBucketField = false } = props
    const classesA = useStylesPlain(props);

    return (<TextField
        margin="dense"
        id={name}
        name={name}
        label={labelText}
        type="text"
        value={value == null ? '' : value}
        onChange={onChange}
        variant={variant ? variant : "outlined"}
        disabled={disabled ? true : false}
        classes={classesA}
        placeholder={placeholder}
        fullWidth
    />);
}