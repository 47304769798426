import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DefaultPage from 'src/pages/backgrounds/vehicleDetails-default-page';
import PositionTracking from 'src/pages/postioning/position-tracking-page';
import WorkOrder from 'src/pages/workOrders/WorkOrder';
import { FormmatDateMap } from 'src/utils/DateFormatter';
import VehicleHealth from 'src/pages/vehicle-health-page/VehicleHealth';
import CommentsPage from 'src/pages/comments-page/CommentsPage';
import * as AppSettings from 'src/AppSettings.json';

function TabPanel(props) {
  const { children, value, index, vehiclePosition, closeHandleDrawer, isExpanded, vin, shouldRenderMap, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && value === 0 && (
        <PositionTracking positionLat={vehiclePosition.latitude} positionLng={vehiclePosition.longitude} vehicleUpdatedAt={FormmatDateMap(vehiclePosition.vehicleUpdatedAt)} shouldRenderMap={shouldRenderMap} closeHandleDrawer={closeHandleDrawer} isExpanded={isExpanded} />
      )}
      {value === index && value === 1 && (
        <WorkOrder value={value} vin={vin} />
      )}
      {value === index && value === 2 && (
        <VehicleHealth value={value} vin={vin} />
      )}
      {value === index && value === 3 && (
        <DefaultPage />
      )}
      {value === index && value === 4 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage /> : <CommentsPage />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  vehiclePosition: PropTypes.object,
  closeHandleDrawer: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: '-10px',
    marginRight: '5px',
  },

  panel: {
    marginTop: '-10px',
    position: "relative",
    height: "100%",
    paddingLeft: '5px',
  },
  tabBar: {
    marginLeft: '-30px',
    marginRight: '-18px',
    width: '100px',
  },
  appBar: {
    backgroundColor: "#212121",
    position: 'fixed',
    marginTop: '-2px',
    marginLeft: '11px',
    zIndex: '5',
    width: '577px'
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '100px',
    marginLeft: '30px',
    // marginRight: '-50px'
  },

})(Tabs);

export default function VehicleDetailsTabs(props) {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded, vin } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example" >
          <Tab label="MAP" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="WORK ORDERS" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="HEALTH" {...a11yProps(2)} className={classes.tabBar} />
          <Tab label="SERVICE" {...a11yProps(3)} className={classes.tabBar} />
          <Tab label="COMMENTS" {...a11yProps(4)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel vehiclePosition={vehiclePosition} shouldRenderMap={shouldRenderMap} closeHandleDrawer={closeHandleDrawer} value={value} index={0} className={classes.panel} isExpanded={isExpanded}>
        Map
      </TabPanel>
      <TabPanel value={value} vin={vin} index={1} className={classes.panel}>
        Work Orders
      </TabPanel>
      <TabPanel value={value} vin={vin} index={2} className={classes.panel}>
        Health
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.panel}>
        Service
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.panel}>
        Comments
      </TabPanel>
    </div>
  );
}