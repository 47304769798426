import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardTitle, CardSubtitle, Col, Row } from "reactstrap";
import { QueryWorkOrderScheduleMaintenance, GetCommercialCost } from "src/services/dashboard-http.service";
import Chart from "react-apexcharts";
import Modal from "@mui/material/Modal";

const UseStyles = makeStyles((theme) => ({
  drawer: {
    width: 250,
    flexShrink: 0,
    top: '19.5%',
    marginTop: '80px',
    marginLeft: '43%',
    position: 'absolute',
    height: '20px'
  },
}));

const SalesOverview = ({ marketId }) => {
  const classes = UseStyles();

  const [chartData, setChartData] = useState([]);
  const [chartName, setChartName] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [modalContent, setModalContent] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setChartValues();
  }, [chartData.length, marketId]);

  const setChartValues = () => {
    let chartNames = [];
    let chartValues = [];
    let barChartValues = [];

    if (marketId !== undefined) {
      QueryWorkOrderScheduleMaintenance(marketId).then(data => {
        if (data.ok == true) {
          data.data.map((item, index, arr) => {
            setChartData(data.data);
            chartNames.push(item.name);
            chartValues.push(data);
            if (chartValues.length > 0) {
              data.data.map(item => {
                barChartValues.push({
                  name: item.name,
                  data: item.data.map((item, index, arr) => {
                    return item.count
                  }),
                })
              })
              setChartValue(barChartValues);
            }
          });
          setChartName(chartNames);
        } else {

        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const optionsrevenue = {
    grid: {
      show: true,
      strokeDashArray: 2,
      borderColor: "rgba(0,0,0,.3)",
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: "flat",
      },
    },
    colors: ["#464646", "#808080", "#6c08db", "#1EF1C6"],
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const seriesName = config.w.config.series[config.seriesIndex].name;
          const month = config.w.config.xaxis.categories[config.dataPointIndex];
          const dataPoint = chartData.find(series => series.name === seriesName).data[config.dataPointIndex];
          const data2 = dataPoint.vinData.map((item, index, arr) => {
            return item.vin
          });
          const data3 = dataPoint.vinData.map((item, index, arr) => {
            return item.operationCount
          });
          setModalContent(`Month: ${month}, Series: ${seriesName}, VINs: ${data2.toString()} Count: ${data3.toString()}`);
          setOpen(true);
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    stroke: {
      show: true,
      width: 10,
      colors: ['transparent']
    },
    tooltip: {
      theme: "dark",
    },
  };

  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Maintenance Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/
    <>
      <Modal
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
      >
        <div style={{ position: 'absolute', marginLeft: '-0.9vw', alignItems: 'center', marginTop: '-9px', display: 'flex', justifyContent: 'center' }}>
          <h2>Data Point Details</h2>
          <h3>{modalContent}</h3>
        </div>
      </Modal>
      <div className="w-100">
        <Row>
          <Col xs="12">
            <div className="d-flex flex-wrap">
              <div>
                <CardTitle style={{ marginTop: '-3px', fontSize: "calc(10px + 0.390625vw)", fontWeight: 600, color: '#b3b3b3' }}>Scheduled Maintenance</CardTitle>
                <CardSubtitle style={{ fontSize: "calc(7px + 0.390625vw)", color: '#b3b3b3' }}>Maintenance status per calendar month</CardSubtitle>
              </div>
            </div>
          </Col>
          <Col xs="12" style={{ height: '250px' }}>
            <Chart
              options={optionsrevenue}
              series={chartValue}
              type="bar"
              height="100%"
            />
          </Col>
        </Row>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            <h6 style={{ color: '#646464', textTransform: 'uppercase' }}>
              <i className="fa fa-circle font-10 mr-2" />
              {chartName[0]}
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#b3b3b3', textTransform: 'uppercase' }}>
              <i className="fa fa-circle font-10 mr-2" />
              {chartName[1]}
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#9381FF', textTransform: 'uppercase' }}>
              <i className="fa fa-circle font-10 mr-2" />
              {chartName[2]}
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#1EF1C6', textTransform: 'uppercase' }}>
              <i className="fa fa-circle font-10 mr-2" />
              {chartName[3]}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesOverview;