import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from "src/redux/user/user.selectors";
import BlackCar from "src/assets/img/BackgroundImages/BlackCar.png";
import BlueCar from "src/assets/img/BackgroundImages/BlueCar.png";
import actionButton_dark_lock from "src/assets/img/vehicles/actionButton_dark_lock.png";
import actionButton_dark_unlock from "src/assets/img/vehicles/actionButton_dark_unlock.png";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BelgiumFlag from "src/assets/img/flags/BelgiumFlag.png";
import FranceFlag from "src/assets/img/flags/FranceFlag.png";
import SwedenFlag from "src/assets/img/flags/SwedenFlag.png";
import ItalyFlag from "src/assets/img/flags/ItalyFlag.png";
import GermanyFlag from "src/assets/img/flags/GermanyFlag.png";
import SpainFlag from "src/assets/img/flags/SpainFlag.png";
import NetherlandsFlag from "src/assets/img/flags/Netherlands.png";
import MarketSelect from 'src/components/form-elements/market-select.component';
import FormSelect from 'src/components/form-elements/custom-form-select.component';
import { GetPNOCodesByMarket } from "src/services/pnoCode-http.service";
import { UpdateMarketMarkDownPrice, GetByGemsId } from "src/services/market-http.service";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { permission } from 'src/auth/permissions';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        height: '2px',
        marginBottom: '16px',
    },
    titleStyle: {
        marginLeft: '5px',
        fontSize: 'calc(10px + 0.390625vw)',
        marginTop: '20px',
        fontFamily: "LynkcoTypeMedium",
        '@media (min-width: 600px) and (max-width: 1000px)': {
            fontSize: 'calc(17px + 0.390625vw)'
        },

    },
    imageStyleFlag: {
        width: '90%',
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            width: '65%',
        },
    },
    imageStyleCar: {
        marginTop: '10px',
        width: '65%',
        '@media (min-width: 600px) and (max-width: 850px)': {
            width: '50%',
        },
    },
    imageStyleButton: {
        marginTop: '-80px',
        width: '20%',
        cursor: 'pointer',
        boxShadow: '3px 5px 6px #000000',
        borderRadius: '50%'
    },
    backgroundStyle: {
        backgroundColor: 'transparent',
        marginTop: '80px',
        borderRadius: '1px',
        position: 'relative',
        overflow: 'auto',
        overflowX: 'hidden',
        height: '80vh',
        "&::-webkit-scrollbar": {
            height: "0.1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
        marginRight: '0px',
        marginLeft: '0px',
        '@media (min-width: 600px) and (max-width: 1000px)': {
            marginRight: '8px',
            marginLeft: '0px',
            marginTop: '80px',
            overflowY: 'auto'
        },
        '@media (min-width: 1001px) and (max-width: 1075px)': {
            marginRight: '10px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
        },
        '@media (min-width: 1441px) and (max-width: 1600px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '70px',
        },
        '@media (min-width: 1401px) and (max-width: 1440px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginRight: '0px',
            marginLeft: '0px',
            marginTop: '80px',
        },
    },
    flagStyle: {
        position: 'absolute',
        width: '100%',
        height: '400px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all 0.50s ease',
        "&::-webkit-transition": 'all 0.50s ease',
        "&::-moz-transition:": 'all 0.50s ease'
    }
}));

let queryParams = {};
let markdown = {};

const BMCPricing = (props) => {
    const { userOrg, userMarketIds, userPermissions } = props
    const classes = UseStyles(props);

    const [selectPNO, setSelectPNO] = useState(false);
    const [selectMarket, setSelectMarket] = useState(false);
    const [isMarketSelected, setIsMarkedSelected] = useState(false)
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [pnoCode, setPNOCode] = useState([]);
    const [pnoCodeDetails, setPNOCodeDetails] = useState([]);
    const [pnoCodeDetail, setPNOCodeDetail] = useState({});
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsPriceRange, setQueryParamsPriceRange] = useState('');
    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [updatedQueryParamsPriceRange, setUpdatedQueryParamsPriceRange] = useState('');
    const [placeholder, setPlaceholder] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [displayPNO, setDisplayPNO] = useState(false);
    const [displayPriceSelect, setDisplayPriceSelect] = useState(false);
    const [hideLabel, setHideLabel] = useState('');

    useEffect(() => {
        selectPNOCode();
        getPNOCode();
    }, [queryParamsMarket]);

    let marketId = 'NONE';

    const handleChange = (event, markets) => {
        const { name, value } = event.target;
        let orgsMarkets = [];
        marketId = value.split('#')[0];
        let marketName = value.split('#')[1];
        setHideLabel(event.target.value);

        if (marketId == 'NONE') {
            setIsMarkedSelected(false);
            setSelectMarket(false);
            setDisplayPNO(false);
            setDisplayPriceSelect(false);
        }
        else {
            setIsMarkedSelected(true);
            setSelectMarket(true);
            setDisplayPNO(true);
            setDisplayPriceSelect(false);
        }
        setSelectedMarket({ key: name, value: value });
        setQueryParamsMarket(marketId);
        handleReset();
    };

    const handleReset = () => {
        setSelectPNO(false);
        setDisplayPriceSelect(false);
        setUpdatedQueryParamsPriceRange('');
        setQueryParamsPriceRange('');
        setQueryParamsPNOCode('');
    };

    const selectPNOCode = () => {
        let pnoCodeArray = [];
        let pnoCodeDetails = [];

        GetPNOCodesByMarket(queryParamsMarket).then(data => {
            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeArray.push({
                        key: pnoCode.pnoCode,
                        value: pnoCode.pnoCode
                    });
                })
                : console.log("Error Occured");
            setPNOCode(pnoCodeArray);

            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeDetails.push({
                        pnoCode: pnoCode.pnoCode,
                        lcColor: pnoCode.lcColor,
                        lcEngineType: pnoCode.lcEngineType,
                        lcModelYear: pnoCode.lcModelYear,
                        obcVersion: pnoCode.obcVersion
                    });
                    setPNOCodeDetails(pnoCodeDetails);
                })
                : console.log("Error Occured");
        });
    };

    queryParams = { ...queryParams, marketId: queryParamsMarket };
    queryParams = { ...queryParams, pnoCode: queryParamsPNOCode };
    queryParams = { ...queryParams, markdownAmount: parseFloat(queryParamsPriceRange) };

    const getPNOCode = (pnoCodeValue) => {
        let filteredValue = pnoCodeDetails.filter(item => item.pnoCode === pnoCodeValue)[0];
        setPNOCodeDetail(filteredValue);
    };

    const handleChangeExtended = (name) => (event) => {
        const value = event.target.value
        console.log(event.target)

        if (name == 'pnoCode') {
            setQueryParamsPNOCode(value);
            getPNOCode(value);
            setSelectPNO(true);
            setDisplayPriceSelect(true);
            GetByGemsId(queryParamsMarket).then(data => {
                data = data ?? 0
                markdown = data.settings.buyMyCarCashMarkdown.filter(item => item.pnoCode === value)[0];
                if (markdown) {
                    setUpdatedQueryParamsPriceRange(`${markdown.markdownAmount} EUR`)
                    setPlaceholder(true);
                    setDisplayPriceSelect(true);
                } else {
                    setUpdatedQueryParamsPriceRange(`0 EUR`);
                    setPlaceholder(false);
                }
            })
            if (value == '') {
                setDisplayPriceSelect(false);
            }
        }

        if (name == 'priceRange') {
            setQueryParamsPriceRange(value);
            setUpdatedQueryParamsPriceRange(value);
        }
    };

    const handleSubmit = () => {
        let marketId = queryParamsMarket
        let pnoCode = queryParamsPNOCode
        let markdownAmount = parseFloat(queryParamsPriceRange)

        if (placeholder === true) {
            setPlaceholder(false);
        } else {
            UpdateMarketMarkDownPrice(marketId, pnoCode, markdownAmount).then(data => {
                if (data.ok === true) {
                    setPlaceholder(true);
                    setOpenDialog(true);
                    setDialogMessage(`Your 'Buy My Car' price configuration is applied`);
                } else {
                    setOpenDialog(true);
                    setDialogMessage('Please make sure that Market, PNO and BMC value is selected ');
                }
            })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

    return (
        <>
            <div className={classes.backgroundStyle}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                />
                <Row>
                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        <Card id="CardBodyCardAllowance8">
                            <CardBody>
                                <Row style={{ marginTop: '-20px' }}>
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select Market
                                        </Typography>

                                        <div className="formwidthstyleBMC" style={{ marginLeft: '5px', marginTop: '17px' }}>
                                            <MarketSelect
                                                orgId={userOrg.id}
                                                value={selectedMarket.value}
                                                userMarketIds={userMarketIds}
                                                handleChange={handleChange}
                                                isVariant='true'
                                                hideLabel={hideLabel}
                                                isStandardMarket="true"
                                            />
                                        </div>

                                        {selectMarket &&
                                            <div className="flagstylebmc">
                                                <span
                                                    className={classes.flagStyle}
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                    onClick={() => props.onClick && props.onClick()}
                                                    style={{
                                                        ...{ opacity: hovered ? '0.5' : '1' }
                                                    }}
                                                >
                                                    <img
                                                        alt="Card image cap"
                                                        src={
                                                            queryParamsMarket == 'BE' ? BelgiumFlag :
                                                                queryParamsMarket == 'SE' ? SwedenFlag :
                                                                    queryParamsMarket == 'DE' ? GermanyFlag :
                                                                        queryParamsMarket == 'ES' ? SpainFlag :
                                                                            queryParamsMarket == 'NL' ? NetherlandsFlag :
                                                                                queryParamsMarket == 'FR' ? FranceFlag :
                                                                                    queryParamsMarket == 'IT' ? ItalyFlag : <></>
                                                        }
                                                        className={classes.imageStyleFlag}
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch" >
                        {
                            displayPNO &&

                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12" >
                                            <Col lg="12" md="12" style={{ marginLeft: '-11px' }} >
                                                <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                    Select PNO
                                                </Typography>
                                            </Col>

                                            <div className="formwidthstyleBMC" style={{ marginLeft: '10px', marginTop: '17px' }}>
                                                <FormSelect
                                                    label='PNO'
                                                    name='pnoCode'
                                                    dropdownfont='true'
                                                    value={queryParamsPNOCode}
                                                    menuObjectArray={pnoCode}
                                                    onChange={handleChangeExtended("pnoCode")}
                                                />
                                            </div>

                                            <Row style={{ marginTop: '30px', }}>
                                                <Col md={12} sm={12} xs={12} lg={12}>
                                                    {selectPNO &&
                                                        <>
                                                            {
                                                                pnoCodeDetail !== undefined &&
                                                                <div className="tableFixHead">
                                                                    <Row>
                                                                        <table style={{ width: '100%', color: 'white' }}>
                                                                            <tr>
                                                                                <td id="TabletitlesPoolCars">
                                                                                    {
                                                                                        <img
                                                                                            src={pnoCodeDetail.lcColor == 'Blue' ? BlueCar : BlackCar}
                                                                                            className={classes.imageStyleCar}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>Model Year</span> </td>
                                                                                <td className="fontstylePNORight"><span>{pnoCodeDetail.lcModelYear}</span></td>
                                                                            </tr>
                                                                            <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>Color</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcColor === 'Blue' ? 'Blue' : 'Black'}</td>
                                                                            </tr>
                                                                            <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>Engine Type</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcEngineType}</td>
                                                                            </tr>
                                                                            <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>OBC Version</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.obcVersion}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </Row>
                                                                </div>
                                                            }
                                                        </>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        {
                            displayPriceSelect &&
                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12" >
                                            <Row>
                                                <Col lg="12" md="12" >
                                                    <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                        Select Discount or Markup
                                                    </Typography>
                                                </Col>

                                                <div className="formwidthstyleBMC" style={{ marginLeft: '21px', marginTop: '17px' }}>
                                                    <FormSelect
                                                        label='Discount/Markup'
                                                        name='priceRange'
                                                        dropdownfont='true'
                                                        disabled={placeholder ? true : false}
                                                        value={queryParamsPriceRange}
                                                        menuObjectArray={[
                                                            { 'key': '-5000 EUR', 'value': '-5000 EUR' },
                                                            { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                            { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                            { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                            { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                            { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                            { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                            { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                            { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                            { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                            { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                            { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                            { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                            { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                            { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                            { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                            { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                            { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                            { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                            { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                            { 'key': '0 EUR', 'value': '0 EUR' },
                                                            { 'key': '+250 EUR', 'value': '+250 EUR' },
                                                            { 'key': '+500 EUR', 'value': '+500 EUR' },
                                                            { 'key': '+750 EUR', 'value': '+750 EUR' },
                                                            { 'key': '+1000 EUR', 'value': '+1000 EUR' },
                                                            { 'key': '+1250 EUR', 'value': '+1250 EUR' },
                                                            { 'key': '+1500 EUR', 'value': '+1500 EUR' }
                                                        ]}
                                                        onChange={handleChangeExtended("priceRange")}
                                                    />
                                                </div>
                                            </Row>

                                            <Row>
                                                {
                                                    <div className="rowstyleDiscountcardBMC">
                                                        <Row>
                                                            <Col lg="12" md="12" >
                                                                <span className="fontStyling" style={{ textAlign: 'center', fontFamily: "LynkcoTypeMedium", cursor: 'pointer', color: placeholder ? '#FF131E' : '#1EF1C6', alignItems: 'center', justifyContent: 'center', }}>
                                                                    {updatedQueryParamsPriceRange !== undefined ? updatedQueryParamsPriceRange : queryParamsPriceRange}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                            </Row>
                                            <Row className="bmcheightstyle">
                                                <Col lg="12" md="12" >
                                                    {
                                                        matchesPermission &&

                                                        <div class="click-to-top" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                            <img
                                                                src={placeholder ? actionButton_dark_lock : actionButton_dark_unlock}
                                                                className={classes.imageStyleButton}
                                                                onClick={handleSubmit}
                                                            />
                                                            <span style={{ color: placeholder ? 'rgb(193, 39, 37)' : '#1EF1C6', fontFamily: "LynkcoTypeRegular", alignItems: 'center', justifyContent: 'center' }}>{placeholder ? 'Edit' : 'Confirm'}</span>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(BMCPricing);