import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { whiteColor } from 'src/assets/jss/GlobalStyles';
import OkButton from 'src/components/buttons/ok-button.component';


const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 'medium',
    fontWeight: 'normal',
    fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
    color: whiteColor,
  },
  message: {
    color: whiteColor,
    fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
    textAlign: 'center',
    verticalAlign: 'center',
    marginTop: '25px',
    wordWrap: 'break-word'

  },
  dialogAction: {
    color: whiteColor,
    fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
    fontWeight: 'normal',
    // marginRight: '130px',
    justifyContent: 'center'

  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  paperScrollPaper: {
    backgroundColor: "rgb(19,19,19)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    minHeight: 220,
    minWidth: 350,
    BorderStyle: 'solid'
  }
}));

export default function SimpleAlertDialog(props) {
  const classes = useStyles();
  const { open, message, handleDialogOk } = props;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        classes={{
          paperScrollPaper: classes.paperScrollPaper
        }}
        disableScrollLock
      >
        <DialogTitle id="alert-dialog-title"><span className={classes.title}></span></DialogTitle>

        <DialogContent className={classes.message} >
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.message}>{message} </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <OkButton handleDialogOk={handleDialogOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

SimpleAlertDialog.propTypes = {
  name: PropTypes.string.isRequired,
  handleOkDialog: PropTypes.func.isRequired,
};