import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

/////////////////////////////////// Get Commercail Cost //////////////////////////////////

export async function GetCommercialCost() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendDashboardService}/api/FleetVehicleData/GetCommercialCost`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

/////////////////////////////////// Get Work order Vehicle  //////////////////////////////////

export async function QueryVehicleWorkOrderOpenCases() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendDashboardService}/api/WorkOrderVehicles/QueryVehicleWorkOrderOpenCases`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

/////////////////////////////////// Get Work order Maintenance Schedule  //////////////////////////////////

// export async function QueryWorkOrderScheduleMaintenance() {
//     let fleetToken = await GetToken();

//     const headers = new Headers();
//     headers.append("Authorization", fleetToken);
//     headers.append("Content-Type", "application/json");
//     headers.append("accept", "text/plain");
//     headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

//     const options = {
//         method: "GET",
//         headers: headers
//     };

//     let apiData = await fetch(`${environment.backendDashboardService}/api/WorkOrderVehicles/QueryWorkOrderScheduleMaintenance`, options)
//         .then((response) => response.json())
//         .then(data => {
//             return data;
//         })
//         .catch(error => console.log(error));
//     return apiData;
// };

export async function QueryWorkOrderScheduleMaintenance(marketId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendDashboardService}/api/WorkOrderScheduleMaintenance/QueryWorkOrderScheduleMaintenance?marketId=${marketId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};