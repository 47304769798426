import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: 300,
    "& .MuiOutlinedInput-input": {
      color: (props) => props.standardvalueform ? "#FF131E" : props.packageselect ? "#b3b3b3" : "white",
      backgroundColor: (props) => props.packageselect ? "#2f2f2f" : "rgb(33, 33, 33)",
      borderRadius: '40px',
      height: "1px",
      paddingTop: '10px',
      paddingBottom: '17px',
      fontSize: (props) => props.dropdownfont ? 'calc(10px + 0.390625vw)' : 'calc(7px + 0.390625vw)',
      boxShadow: '3px 5px 6px #000000',
      '@media (min-width: 1501px) and (max-width: 1600px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(8.5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1401px) and (max-width: 1500px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1111px) and (max-width: 1400px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '12px'
      },
      '@media (min-width: 600px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '9px'
      },
    },
    "& .MuiInputLabel-root": {
      color: (props) => props.packageselect ? "rgba(179, 179, 179, 0.5)" : "white",
      backgroundColor: "transparent",
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      '@media (min-width: 600px) and (max-width: 1110px)': {
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      },
      fontFamily: "LynkcoTypeRegular",
      marginTop: '0px',
      zIndex: 10
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(255, 255, 255, 0.01)',
      borderRadius: '40px',
      color: 'white',
      zIndex: 1
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#b3b3b3",
      backgroundColor: '#1c1c1c',
      borderColor: "#1EF1C6"
    },
    "&:hover .MuiInputLabel-root": {
      color: "#b3b3b3",
      backgroundColor: '#1c1c1c',
      borderColor: "#1EF1C6"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '40px'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "#1EF1C6"
    },
    "& .MuiOutlinedInput-root.Mui-selected .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "#1EF1C6"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: (props) => props.packageselect ? "#b3b3b3" : "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.01)"
    },
  },
  dropdownStyle: {
    border: "1px solid black",
    color: '#fff',
    width: '20px',
    backgroundImage: 'linear-gradient(135deg, #000000, #272727 99%)',
    height: (props) => props.tableDropdownHeight ? '210px' : props.towbardropdownheight ? '185px' : props.AdminDropdownHeight ? '275px' : props.reportsdropdownheight ? '170px' : '300px',
    borderRadius: '9px',
    fontFamily: "LynkcoTypeLight",
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c",
      borderRadius: '8px'
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121",
      borderRadius: '8px'
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000',
      borderRadius: '8px'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010",
      borderRadius: '10px'
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    },
    "& .MuiMenuItem-root": {
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      '@media (min-width: 600px) and (max-width: 1110px)': {
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '9px'
      },
      fontFamily: "LynkcoTypeLight",
    },
    "&  .MuiMenuItem-root.Mui-disabled": {
      fontSize: 'calc(11px + 0.390625vw)',
      color: 'white',
      fontFamily: "LynkcoTypeRegular",
      '@media (min-width: 600px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.dropdownfont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '9px'
      },
    },
  }
}));

const DropdownSelect = (props) => {
  const { open, onClose, onOpen, value, onChange, menuObjectArray = [{ key: '', value: '' }], label, id, name, variant, disabled = false, dropdownfont = false, tableDropdownHeight = false, towbardropdownheight = "false", standardvalueform = "false", AdminDropdownHeight = false, reportsdropdownheight, packageselect = false } = props
  const classes = useStyles(props);

  return (
    <div>
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          IconComponent={ChevronDownIcon}
          id={id}
          name={name}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          value={value}
          onChange={onChange}
          disabled={disabled}
          dropdownfont={dropdownfont}
          tableDropdownHeight={tableDropdownHeight}
          towbardropdownheight={towbardropdownheight}
          standardvalueform={standardvalueform}
          AdminDropdownHeight={AdminDropdownHeight}
          reportsdropdownheight={reportsdropdownheight}
          packageselect={packageselect}
          MenuProps={{
            classes: { paper: classes.dropdownStyle }, disableScrollLock: true
          }}
        >
          <MenuItem disabled value="">
            <em style={{ fontStyle: 'normal' }}>{label} </em>
          </MenuItem>
          {
            menuObjectArray.map((menu, key) => {
              return (<MenuItem key={key} value={menu.key}>{menu.value}</MenuItem>);
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownSelect;