import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryVehicleHealth } from 'src/services/vehicle-pages-http.service';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import RVChart from 'src/components/vehicleDetails/RVChart';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '744px',
        borderRadius: '15px',
        marginRight: '-5px',
        marginLeft: '5px',
    },
    cardStyleLarge: {
        height: '605px',
        borderRadius: '15px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '34vh',
        minWidth: '23vh'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '2px',
    },
    tooltipImgButton: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '50px',
    },
    rowheight: {
        marginTop: '-210px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-180px',
        },
    },
    rowheightMiddle: {
        marginTop: '-235px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-200px',
        },
    }
}));

// let vin = ''

const VehicleDetailsPage = (props) => {
    const { userOrg, userMarketIds, vin, value, detailsMap = true, vehicleDetailsChartValues, modelData, selectTable } = props

    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = useState('')
    const [vehicleDetail, setVehicleDetail] = useState({});
    const [message, setMessage] = useState('');

    const [vehicleHealth, setVehicleHealth] = useState({
        "colour": "",
        "distanceToService": {
            "isDistanceToServiceOk": null,
            "value": ""
        },
        "daysToService": {
            "isDaysToServiceOk": null,
            "value": ""
        },
        "serviceWarningStatus": {
            "isServiceWarningStatusOk": null,
            "value": null
        },
        "engineOilLevelStatus": {
            "isEngineOilLevelStatusOk": null,
            "value": null
        },
        "engineOilPressureStatus": {
            "isEngineOilPressureStatusOk": null,
            "value": null
        },
        "washerFluidLevelStatus": {
            "isWasherFluidLevelStatusOk": null,
            "value": null
        }
    });

    useEffect(() => {
        if (vin !== undefined) {
            handleChangeVINorLIcensePlate(vin.trim());
            handleVehicleHealth(vin);
        }
    }, []);

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
    }

    const handleChangeVINorLIcensePlate = (vin) => {
        QueryConnectivityInformation(vin)
            .then(data => {
                if (data.ok == true) {
                    setSearchInputVIN(true);
                    setVehicleDetail(data.vspConnectivity);
                    setDisplaySelectedVehicle(true);
                } else {
                    setSearchVIN();
                    setMessage(data.title);
                    setDialogMessage(data.errorMessage);
                }
            })

    };

    const handleVehicleHealth = (vin) => {
        if (value == 1) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehicleHealth(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok == true) {
                                    setVehicleHealth(data.vehicleMaintenance);
                                }
                            })
                    }
                })
        }
    };

    return (
        <Container >
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />

            <Col md={12} sm={12} xs={12} lg={12}>
                <Row className='detailsRemarket' >
                    <Col lg="8" md="12" sm="12" xs="12">
                        <div className='detailsPageStyle'>
                            <RVChart
                                selectTable={selectTable}
                                vehicleDetailsChartValues={vehicleDetailsChartValues}
                            />
                        </div>
                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row className="vehicleInfoCardRemarketCardDetails">
                            <Col lg="12" md="12" >
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            USED CAR STATUS
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            {modelData.usedCarIsB2B}
                                        </div>
                                    </div>
                                    {/* <SessionShort
                                        name={'RESIDUAL VALUE'}
                                        value={vehicleDetailsChartValues.residualValue == undefined ? '0 EUR' : `${vehicleDetailsChartValues.residualValue}  EUR`}
                                        parcentageValue={vehicleDetailsChartValues.residualValuePercentage}
                                    /> */}
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            BMC PRICE
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            {formatAndVerifyNumericValue(vehicleDetailsChartValues.bmcPrice == undefined ? '0 EUR' : `${vehicleDetailsChartValues.bmcPrice}  EUR`)}
                                        </div>
                                    </div>
                                </Card>

                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            RESERVED PRICE
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            {modelData.market == 'Sweden' ? (formatAndVerifyNumericValue(vehicleDetailsChartValues.reservedPrice == undefined ? '0 SEK' : `${vehicleDetailsChartValues.reservedPrice} SEK`)) : (formatAndVerifyNumericValue(vehicleDetailsChartValues.reservedPrice == undefined ? '0 EUR' : `${vehicleDetailsChartValues.reservedPrice}  EUR`))}
                                        </div>
                                    </div>
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            BOTTOM LINE PRICE
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            {modelData.market == 'Sweden' ? (formatAndVerifyNumericValue(vehicleDetailsChartValues.bottomLinePrice == undefined ? '0 SEK' : `${vehicleDetailsChartValues.bottomLinePrice} SEK`)) : (formatAndVerifyNumericValue(vehicleDetailsChartValues.bottomLinePrice == undefined ? '0 EUR' : `${vehicleDetailsChartValues.bottomLinePrice}  EUR`))}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row id="CardRowStyleRemarket">
                            <Col lg="12" md="12" >
                                <Card id="cardVehicleDetailRemarket" >
                                    <div >
                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Model
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.model}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            License Plate
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.licensePlate}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            VIN
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vin}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Color
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.color}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Engine Type
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.engineType}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Towbar
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.towbarState}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Market
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.market}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleDetailsPage);