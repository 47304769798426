import React from "react";
import { makeStyles, styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, Row, Col, CardBody, CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.driverPage';
import blackVehicle from "src/assets/img/BackgroundImages/ServicePagePicture_Black.png";
import blueVehicle from "src/assets/img/BackgroundImages/ServicePagePicture_Blue.png";

const UseStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        height: '758px',
        marginRight: '-160px',
        paddingRight: '5px',
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
    },
    distanceToServiceStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '60px',
        color: (props) => props.distance == null ? 'grey' : '',
    },
    daysToServiceStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '40px',
        color: (props) => props.daysToService == null ? 'grey' : '',
    },
    serviceWarningStatusStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '40px',
        color: (props) => props.serviceWarning == null ? 'grey' : '',
    },
    engineOilLevelStatusStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '40px',
        color: (props) => props.engineOilLevel == null ? 'grey' : '',
    },
    engineOilPressureStatusStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '40px',
        color: (props) => props.engineOilPressure == null ? 'grey' : '',
    },
    washerFluidLevelStatusStyle: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: '40px',
        color: (props) => props.washerFluidLevel == null ? 'grey' : '',
    },
    imgStyle: {
        marginTop: '-288px',
        transform: 'scale(0.47)',
        opacity: '0.5',
        marginLeft: '18px'
    },
}));

const MasterVehicleHealth = (props) => {
    const { data, color } = props
    const classes = UseStyles();

    return (
        <Col md={12} lg={12}>
            <div className={classes.root}>
                <Card style={{ backgroundColor: 'rgb(19 19 19 / 35%)', height: '800px', }}>
                    <CardBody style={{ padding: '50px' }}>
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginTop: '-45px', marginBottom: '50px', marginLeft: '-50px' }}>
                                    <Grid container className={classes.gridContainerStyle} spacing={1}>
                                        <Grid item xs={5} md={5} lg={5}>
                                            <div style={{ marginTop: '12px', marginLeft: '0px' }}>
                                                <FirstGrid
                                                    data={data}
                                                    distance={data.distanceToService.isDistanceToServiceOk}
                                                    daysToService={data.daysToService.isDaysToServiceOk}
                                                    serviceWarning={data.serviceWarningStatus.isServiceWarningStatusOk}
                                                    engineOilLevel={data.engineOilLevelStatus.isEngineOilLevelStatusOk}
                                                    engineOilPressure={data.engineOilPressureStatus.isEngineOilPressureStatusOk}
                                                    washerFluidLevel={data.washerFluidLevelStatus.isWasherFluidLevelStatusOk}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <div style={{ marginTop: '70px', marginLeft: '2px' }}>
                                                <SecondGrid
                                                    data={data}
                                                    distance={data.distanceToService.isDistanceToServiceOk}
                                                    daysToService={data.daysToService.isDaysToServiceOk}
                                                    serviceWarning={data.serviceWarningStatus.isServiceWarningStatusOk}
                                                    engineOilLevel={data.engineOilLevelStatus.isEngineOilLevelStatusOk}
                                                    engineOilPressure={data.engineOilPressureStatus.isEngineOilPressureStatusOk}
                                                    washerFluidLevel={data.washerFluidLevelStatus.isWasherFluidLevelStatusOk}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <div style={{ marginLeft: '-140px' }}>
                                                <ThirdGrid data={data} color={color} washerFluidLevel={data.washerFluidLevelStatus.isWasherFluidLevelStatusOk} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(MasterVehicleHealth);

const FirstGrid = (props) => {
    const { data } = props
    const classes = UseStyles(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div >
                    <Typography className={classes.distanceToServiceStyle}><span >Distance to Service</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.distanceToService.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography className={classes.daysToServiceStyle}><span >Days to Service</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.daysToService.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography className={classes.serviceWarningStatusStyle}><span >Service Warning Status</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.serviceWarningStatus.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography className={classes.engineOilLevelStatusStyle}><span >Engine Oil Level Status</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.engineOilLevelStatus.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography className={classes.engineOilPressureStatusStyle}><span >Engine Oil PRessure Status</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.engineOilPressureStatus.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography className={classes.washerFluidLevelStatusStyle}><span >Washer Fluid Level Status</span></Typography>
                    <div style={{ marginLeft: '-14px', marginTop: '-10px' }}>
                        <FormTextFieldSalesPlain value={data.washerFluidLevelStatus.value} disabled updateFontSize={true} />
                    </div>
                </div>
                <div >
                    <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeMedium", marginTop: '40px', color: 'gray' }}><span >TPMS</span></Typography>
                </div>
            </Grid>
        </Grid>
    )
}

const SecondGrid = (props) => {
    const { data, distance, daysToService, serviceWarning, engineOilLevel, engineOilPressure, washerFluidLevel } = props

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div>
                    {distance == null ? (<div style={{ marginBottom: '98px' }}></div>)
                        : (
                            <>
                                {distance == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

                <div>
                    {daysToService == null ? (<div style={{ marginBottom: '150px' }}></div>)
                        : (
                            <>
                                {daysToService == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

                <div>
                    {serviceWarning == null ? (<div style={{ marginBottom: '150px' }}></div>)
                        : (
                            <>
                                {serviceWarning == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

                <div>
                    {engineOilLevel == null ? (<div style={{ marginBottom: '150px' }}></div>)
                        : (
                            <>
                                {engineOilLevel == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

                <div>
                    {engineOilPressure == null ? (<div style={{ marginBottom: '150px' }}></div>)
                        : (
                            <>
                                {engineOilPressure == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

                <div>
                    {washerFluidLevel == null ? (<div style={{ marginBottom: '450px' }}></div>)
                        : (
                            <>
                                {washerFluidLevel == true ?
                                    (
                                        <div style={{ marginBottom: '53px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-check" style={{ fontSize: '30px', color: '#1EF1C6' }} />

                                            </span>
                                        </div>
                                    ) :
                                    (
                                        <div style={{ marginBottom: '48px' }}>
                                            <span>
                                                <i className="mr-2 mdi mdi-alert-outline" style={{ fontSize: '35px', color: '#DC1860' }} />

                                            </span>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>

            </Grid>
        </Grid>
    )
}

const ThirdGrid = (props) => {
    const { color } = props
    const classes = UseStyles(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div>
                    <CardImg
                        alt="Card image cap"
                        src={color == 'Blue' ? blueVehicle : blackVehicle}
                        className={classes.imgStyle}
                    />
                </div>
            </Grid>
        </Grid>
    )
}