import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryVehicleHealth, GetVehicleByVinOrLicensePlate } from 'src/services/vehicle-pages-http.service';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import Carousel from 'src/components/vehicleDetails/Carousel/index';
import { QueryRsaReport } from 'src/services/rsa-report-http.service';
import moment from 'moment';

let rowsPerPage = 200;
let newPageVariable = 1;

const VehicleDetailsPageRSA = (props) => {
    const { userOrg, userMarketIds, vin, value, vehiclePosition, mapSpinner, setAddressInput, addressInput, detailsMap = true, rsamap = true } = props

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = useState('')
    const [vehicleDetail, setVehicleDetail] = useState({});
    const [message, setMessage] = useState('');
    const [simCardState, setSIMcardState] = useState('');
    const [rsaData, setRSAData] = useState([]);
    const [vehicleHealth, setVehicleHealth] = useState({
        "colour": "",
        "distanceToService": {
            "isDistanceToServiceOk": null,
            "value": ""
        },
        "daysToService": {
            "isDaysToServiceOk": null,
            "value": ""
        },
        "serviceWarningStatus": {
            "isServiceWarningStatusOk": null,
            "value": null
        },
        "engineOilLevelStatus": {
            "isEngineOilLevelStatusOk": null,
            "value": null
        },
        "engineOilPressureStatus": {
            "isEngineOilPressureStatusOk": null,
            "value": null
        },
        "washerFluidLevelStatus": {
            "isWasherFluidLevelStatusOk": null,
            "value": null
        }
    });

    useEffect(() => {
        if (vin !== undefined) {
            handleChangeVINorLIcensePlate(vin.trim());
            handleVehicleHealth(vin);
            handleRSASearch(vin)
        }
    }, []);

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
    }

    const handleChangeVINorLIcensePlate = (vin) => {
        QueryConnectivityInformation(vin)
            .then(data => {
                if (data.ok == true) {
                    setSearchInputVIN(true);
                    setVehicleDetail(data.vspConnectivity);
                    setDisplaySelectedVehicle(true);
                } else {
                    setSearchVIN();
                    setMessage(data.title);
                    setDialogMessage(data.errorMessage);
                }
            });

        GetVehicleByVinOrLicensePlate(vin)
            .then(data => {
                if (data.ok == true) {
                    setSIMcardState(data.vehicle.simState);
                }
            });
    };

    const handleVehicleHealth = (vin) => {
        if (value == 1) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehicleHealth(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok == true) {
                                    setVehicleHealth(data.vehicleMaintenance);
                                }
                            })
                    }
                })
        }
    };

    const handleRSASearch = (vin) => {
        let vehicleFleetArray = [];
        let vins = [vin]

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                setRSAData(vehicleFleetArray);
            }
        })
    };

    return (
        <Container >
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <Col md={12} sm={12} xs={12} lg={12} >
                <Row className='detailsRSA' >
                    <Col lg="4" md="12" sm="12" xs="12" >
                        <div style={{ marginLeft: '10px' }} >
                            <div className="mapCardRSADetails" >
                                <PositionTracking
                                    vin={vin}
                                    value={3}
                                    vehiclePosition={vehiclePosition}
                                    mapSpinner={mapSpinner}
                                    setAddressInput={setAddressInput}
                                    addressInput={addressInput}
                                    detailsMap={detailsMap}
                                    rsamap={rsamap}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col lg="4" md="12" sm="12" xs="12">
                        <div className="carouselCardRSADetails">
                            <Card id="CardBodyCardAllowanceDetailsCarCard" style={{ marginBottom: '10px' }}>
                                {vehicleDetail.vehicleDetails !== undefined && <Carousel modelData={vehicleDetail?.vehicleDetails?.color} />}
                            </Card>
                            <Card id="CardBodyCardAllowanceDetails" >
                                <div>
                                    <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                        Days to service
                                    </div>
                                    <div className="rsaDaysToService" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                        {vehicleHealth.daysToService.value}
                                    </div>
                                </div>
                            </Card>
                            <Card
                                id="CardBodyCardAllowanceDetails"
                                onClick={() => { }}
                            >
                                <div>
                                    <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                        Vehicle Health
                                    </div>
                                    <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', color: '#dc1860' }}>
                                        {'COMING SOON'}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row className="vehicleInfoCardRSADetails">
                            <Col lg="12" md="12" >
                                <Card id="CardBodyCardAllowanceDetailsCarCard2" >
                                    <div >
                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Model
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.model}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            License Plate
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.licensePlate}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            VIN
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vin}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Color
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.color}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Engine Type
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.engineType}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Towbar
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.towbarState}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Market
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.market}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row id="CardRowStyle">
                            <Col lg="12" md="12" >
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            RSA VALID TO
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {rsaData[0]?.coverageValidTo}
                                        </div>
                                    </div>
                                    {/* <SessionShort
                                        name={'RESIDUAL VALUE'}
                                        value={vehicleDetailsChartValues.residualValue == undefined ? '0 EUR' : `${vehicleDetailsChartValues.residualValue}  EUR`}
                                        parcentageValue={vehicleDetailsChartValues.residualValuePercentage}
                                    /> */}
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Range Left
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', color: '#dc1860' }}>
                                            {'COMING SOON'}
                                        </div>
                                    </div>
                                </Card>

                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Connectivity Package
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            Premium
                                        </div>
                                    </div>
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            SIM Card Status
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {simCardState}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleDetailsPageRSA);