import React, { useState, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import EnhancedTableToolbar from 'src/components/tables/table-toolbar.component';
import AlertDialog from 'src/components/form-elements/dialog-window.component';
import RoleAddEdit from 'src/components/form-elements/role-add-edit.component';
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { CreateRole, UpdateRole, DeleteRole, GetRoles } from 'src/services/role-http.service';
import { GetPermissions } from 'src/services/permissions-http.service';
import Modal from '@mui/material/Modal';
import { permission } from 'src/auth/permissions';
import Fade from 'react-reveal/Fade';

const drawerWidth = 350;

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '45px'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
}));

const RoleTable = ({ userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const drawerClasses = useDrawerStyles();
    const [openDeleteDialog, setDeleteDialog] = React.useState(false);
    const [roles, setRoles] = React.useState({
        id: "",
        name: "",
        description: "",
        permissionIds: [],
        editMode: false
    });
    const [rows, setRolesArray] = React.useState([]);
    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });
    const [permissions, setPermissions] = React.useState([]);
    const [enableSelect, setEnableSelect] = useState(true);

    const columns = useMemo(
        () => [
            { Header: 'Role Name', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Role Description', accessor: "description", disableGlobalFilter: true, }
        ],
        [],
    );

    React.useEffect(() => {
        initializeRoles();
        initializePermissions();
        setDeleteDialog(false);
    }, []);


    const initializeRoles = () => {
        let roleArray = [];
        GetRoles()
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(roles => {
                        roleArray.push({ id: roles.id, name: roles.name, description: roles.description });
                    });
                }
                setRolesArray(roleArray);
            });
    }

    const initializePermissions = () => {
        GetPermissions()
            .then(permissionData => {
                if (permissionData && Array.isArray(permissionData) && permissionData.length > 0) {
                    setPermissions(permissionData);
                }
            });
    };

    const handleClickAdd = () => {
        setOpen(true);
        setRoles({
            ...roles, id: "", name: "", description: "", permissionIds: [], editMode: false
        });

    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setRoles({
                ...roles, id: selectedRow.id, name: selectedRow.name,
                description: selectedRow.description, editMode: true
            });
            setOpen(true);
        }
    };

    const handleDeleteDialogContinue = () => {
        DeleteSelectedRole(selected);
    }

    const handleDeleteDialogCancel = () => {
        setDeleteDialog(false)
        setSelected('');
    }

    const handleClickDelete = () => {
        if (selected.length !== 0) {
            setDeleteDialog(true);
        }
    };

    const DeleteSelectedRole = (selectedValue) => {
        if (selectedValue.id === selectedRow.id) {
            let id = selectedValue.id
            DeleteRole({ id }).then(data => {
                initializeRoles();
                setSelected('');
                setDeleteDialog(false);
                setEnableSelect(true);
            })
        }
    };

    const saveFleet = () => {
        roles.editMode ?
            UpdateRole({
                "id": roles.id,
                "name": roles.name,
                "description": roles.description
            })
                .then(data => {

                    initializeRoles();
                    setSelected('');
                    resetRoles();
                })
            :

            CreateRole({
                "name": roles.name,
                "description": roles.description,
                "permissionIds": []
            })
                .then(data => {
                    initializeRoles();
                    setSelected('');
                    resetRoles();
                });
    };

    const handleClose = () => {
        setOpen(false);
        setEnableSelect(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveFleet();
        setEnableSelect(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRoles({ ...roles, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            id: row.id,
            name: row.name,
            description: row.description,
            permissionIds: row.permissionIds
        });
        setOpen(false);
    };

    const resetRoles = () => {
        setRoles({
            id: "",
            name: "",
            description: "",
            editMode: false
        });
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const selectedRowValues = (row) => {

        setSelectedRow({
            id: row.original.id,
            name: row.original.name,
            description: row.original.description,
            permissionIds: row.original.permissionIds,
        })
        setSelected({
            id: row.original.id,
            name: row.original.name,
            description: row.original.description,
            permissionIds: row.original.permissionIds,

        });
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator)

    return (
        <Col md={12} lg={12}>
            <AlertDialog
                open={openDeleteDialog}
                message={`You are about to delete: ${selectedRow.name} ?`}
                name={selectedRow.name}
                handleDeleteDialogCancel={handleDeleteDialogCancel}
                handleDeleteDialogContinue={handleDeleteDialogContinue}
            />
            <Modal
                className={drawerClasses.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <RoleAddEdit
                    roles={roles}
                    onChange={handleChange}
                    handleClose={handleClose}
                    handleDone={handleDone}
                    title='New Role'
                    edit='Edit Role'
                    heading='Enter Role name'
                    editheading='Edit Role name'
                    description='Role description'
                />
            </Modal>

            <div id="tablecardRolePage" >
                <Row className='headerStyleRolePage'>
                    <Col lg="6" md="6" sm="6" xs="6" ></Col>
                    <Col lg="2" md="2" sm="2" xs="2"></Col >

                    <Col lg="2" md="2" sm="2" xs="2" className='columnstyle'>
                        {
                            matchesPermission &&
                            <div className='toolbarstyleRole'>
                                <EnhancedTableToolbar
                                    selectedRowId={selected}
                                    handleClickDelete={handleClickDelete}
                                    handleClickEdit={handleClickEdit}
                                    handleClickAdd={handleClickAdd}
                                    enableSelect={enableSelect}
                                />
                            </div>
                        }
                    </Col>

                    <Col lg="2" md="2" sm="2" xs="2"></Col>
                </Row>

                <>
                    <Fade bottom duration={1500}>
                        <div className="tableFixHeadAdmin">
                            {
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    selectedRowValues={selectedRowValues}
                                    setEnableSelect={setEnableSelect}
                                />
                            }
                        </div>
                    </Fade>
                </>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(RoleTable);