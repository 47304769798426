import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '150px',
        marginRight: '8px',
        backgroundColor: '#1ef1c6',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#088a70',
            color: '#fff'
        }
    }
}));

const BrowseButton = ({ handleChangeBrowse }) => {
    const classes = useStyles();
    return (
        // <Button onClick={handleDownload} className={classes.button} variant="contained">
        //     <Typography>
        //         Browse
        //     </Typography>
        // </Button>
        <Button
            variant="contained"
            component="label"
            className={classes.button} 
        >
            Browse
            <input
                type="file"
                hidden
                onChange={handleChangeBrowse}
            />
        </Button>
    );
}

export default BrowseButton;

