import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import { Row, Col } from 'reactstrap';
import DoneButton from 'src/components/buttons/done-button.component';

const useStyles = makeStyles((theme) => ({
  presentation: {
    height: theme.spacing(60)
  },
  cardStyle: {
    "&.MuiCard-root": {
      overflow: 'auto',
      position: 'relative',
      borderRadius: '1px',
      backgroundColor: 'rgba(19, 19, 19, .5)'
    },
    "&::-webkit-scrollbar": {
      height: "0.01px",
      width: "0.01px",
      opacity: "0.1",
      backgroundColor: "rgba(19, 19, 19, 0.01)",

    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "none",
      backgroundColor: "rgba(19, 19, 19, 0.01)"
    }
  },
  cardActionStyle: {
    "&.MuiCardActions-root": {
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      marginBottom: -30,
      top: -25,
      right: 0,
      overflow: 'auto',
    },
  },
  tooltipImg2: {
    marginTop: '10px',
    marginLeft: (props) => props.variant ? '5vw' : '2vw',
  },
  contentStyle: {
    marginLeft: (props) => props.variant ? '-378px' : '-175px',
    fontSize: '16px',
    marginBottom: '-10px'
  },
  contentStyle2: {
    marginLeft: (props) => props.variant ? '-425px' : '-175px',
    fontSize: '16px',
    marginBottom: '-10px'
  },
  contentheader: {
    marginLeft: (props) => props.variant ? '-190px' : '-107px',
    marginTop: '3px',
  }
}));

const RoleAddEdit = (props) => {
  const { onChange, handleClose, handleDone, roles, variant = false } = props
  const handleChange = (event) => onChange(event);
  const classes = useStyles(props);

  return (
    <div role="presentation" className={classes.presentation}>
      <Card classes={{ root: classes.cardStyle }} style={{ border: '1px solid rgba(222, 226, 230, 0.35)' }}>
        <Row style={{ marginLeft: '-16px', marginRight: '-30px' }} >
          <Col lg="8" md="12" >
            <div className={classes.contentheader} >
              <CardHeader title={roles?.editMode ? props.edit : props.title} />
            </div>
          </Col>
          <Col lg="4" md="12" >
            <div onClick={handleClose} className={classes.tooltipImg2} title="Cancel" style={{ fontSize: '20px', color: '#DC1860', cursor: 'pointer' }}>
              <span>
                <i className="mr-2 mdi mdi-close" />
              </span>
            </div>
          </Col>
        </Row>

        <CardContent>
          <Typography className={classes.contentStyle2} variant="h4" id="tableTitle" component="div">
            {roles?.editMode ? props.editheading : props.heading}
          </Typography>

          <div style={{ width: '100%', marginTop: '10px' }}>
            <FormTextField
              name="name"
              value={roles?.name}
              onChange={handleChange}
            />
          </div>
          <Typography className={classes.contentStyle} style={{ marginTop: '20px' }} variant="h4" id="tableTitle" component="div">
            {props.description}
          </Typography>
          <div style={{ width: '100%', marginTop: '10px' }}>
            <FormTextField
              name="description"
              onChange={handleChange}
              value={roles?.description}
              multiline={true}
            />
          </div>
        </CardContent>

        <CardActions classes={{ root: classes.cardActionStyle }} >
          <DoneButton handleDone={handleDone} placeholder={variant == 'true' ? 'CREATE' : 'DONE'} />
        </CardActions>
      </Card>
    </div>
  );
}

export default RoleAddEdit;