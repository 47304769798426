import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken, selectUserGraphData } from 'src/redux/user/user.selectors';
import clsx from 'clsx';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import { permission } from 'src/auth/permissions';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import DropdownSelect from 'src/components/form-elements/custom-form-select.componentNew';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import Icon_Edit_hover from 'src/assets/img/icons/Icon_Edit_hover.png';
import Icon_Edit from 'src/assets/img/icons/Icon_Edit.png';
import Icon_Add from 'src/assets/img/icons/Icon_Add.png';
import Icon_Add_hover from 'src/assets/img/icons/Icon_Add_hover.png';
import Icon_Delete from 'src/assets/img/icons/Icon_Delete.png';
import Icon_Delete_hover from 'src/assets/img/icons/Icon_Delete_hover.png';
import Modal from "@mui/material/Modal";
import SearchButton from 'src/components/buttons/search-button-component';
import { FormmatDate, } from 'src/utils/DateFormatter';
import { makeStyles } from "@material-ui/core/styles";
import Services from "src/pages/driver-page/Services";
import { CreatePackage, UpdatePackage, GetPackages, GetPackagesByName, MapServiceToPackage, UnmapServiceFromPackage, GetServicesByMarket, DeletePackage } from "src/services/provisioning-package.service";
import MarketSelect from 'src/components/form-elements/market-select.component';
import closebutton from 'src/assets/img/vehicles/closebutton.png';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        position: 'sticky',
        marginLeft: '11px',
    },
    drawer: {
        width: 350,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        backgroundColor: 'rgb(19 19 19 / 97%)',
        height: '800px',
        borderRadius: '1px',
        position: 'fixed'
    },
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '250px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    }
}));

let selectedRowValue = {}

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, handleClickOpen, handleClickDelete, handleClickEdit, enableSelect } = props;
    const [hovered, setHovered] = useState(false);
    const [hoveredPlus, setHoveredPlus] = useState(false);
    const [hoveredDelete, setHoveredDelete] = useState(false);

    return (
        <Toolbar
            className={clsx(classes.root1, {
                [classes.highlight1]: numSelected > 0,
            })}
        >
            <div className={classes.tooltipDiv}>
                <Tooltip
                    className={classes.tooltipImg}
                    title="Add"
                    onClick={handleClickOpen}
                    onOpen={() => setHoveredPlus(true)}
                    onClose={() => setHoveredPlus(false)}
                >
                    <IconButton aria-label="filter list">
                        <img
                            className="rvIcons"
                            src={hoveredPlus ? Icon_Add_hover : Icon_Add}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    className={classes.tooltipImg1}
                    title="Edit"
                    onClick={handleClickEdit}
                    onOpen={() => setHovered(true)}
                    onClose={() => setHovered(false)}
                    disabled={enableSelect}
                >
                    <IconButton aria-label="edit">
                        <img
                            className="rvIcons"
                            src={hovered ? Icon_Edit_hover : Icon_Edit}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    className={classes.tooltipImg1}
                    title="Delete"
                    onClick={handleClickDelete}
                    onOpen={() => setHoveredDelete(true)}
                    onClose={() => setHoveredDelete(false)}
                    disabled={enableSelect}
                >
                    <IconButton aria-label="delete">
                        <img
                            className="rvIcons"
                            src={hoveredDelete ? Icon_Delete_hover : Icon_Delete}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>

            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.string.isRequired,
};

const PackageTable = ({ userOrg, userMarketIds, userPermissions, graphData, packageSelectTable = true }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [showText, setShowText] = useState(false);
    const [showTable, setShowTable] = useState(false);

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const [displayValue, setDisplayValue] = useState('');

    const [displayValueCarModel, setDisplayValueCarModel] = useState('');

    const [displayUsers, setDisplayUsers] = useState(false);

    const [enableSelect, setEnableSelect] = useState(true);

    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })

    const [toggleSearchBar, setToggleSearchBar] = useState(false);
    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });
    const [openDetailsPage, setOpenDetailsPage] = useState(false);
    const classesAdminPage = UseStyles();
    const [roles, setPackage] = useState({
        id: "",
        name: "",
        isDefault: false,
        description: "",
        modelCode: "",
        marketCode: "",
        marketName: "",
        createdBy: graphData.displayName,
        lastModifiedBy: graphData.displayName,
        editMode: false
    });


    const [selectedPackage, setSelectedPackage] = useState({});

    const [role, setRole] = useState('');
    const [services, setServices] = useState([]);

    const [description, setDescription] = useState('');
    const [updatePackage, setUpdatePackage] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [packageRows, setPackages] = useState([]);
    const [hideLabel, setHideLabel] = useState('');

    const [toggleDialogue, setToggleDialogue] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'Package', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Market', accessor: 'marketName', disableGlobalFilter: true, },
            { Header: 'Model Code', accessor: "modelCode", disableGlobalFilter: true, },
            { Header: 'Modified By', accessor: "lastModifiedBy", disableGlobalFilter: true, },
            { Header: 'Last Modified', accessor: "lastModifiedDateUtc", disableGlobalFilter: true },
        ],
        [],
    );

    //////////////////////////////////// Admininstration logic //////////////////////////////

    useEffect(() => {
        initializeUsers();
    }, []);

    useEffect(() => {
        setDisplayUsers(true);
        // setDisplayValue('Users')
        const timer1 = setTimeout(() => {
            setShowText(true);
        }, 300); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setShowTable(true);
        }, 1000); // Adjust the delay as needed
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    const initializeUsers = () => {
        let packageArray = [];
        GetPackages().then(data => {
            if (data && Array.isArray(data.packages)) {
                data.packages.map(packages => {
                    packageArray.push(
                        {
                            id: packages.id,
                            name: packages.name,
                            isDefault: packages.isDefault,
                            description: packages.description,
                            modelCode: packages.modelCode,
                            marketCode: packages.marketCode,
                            marketName: packages.marketName,
                            lastModifiedBy: packages.lastModifiedBy,
                            lastModifiedDateUtc: FormmatDate(packages.lastModifiedDateUtc)
                        }
                    );
                });
            }
            setPackages(packageArray);
        })
    };

    const handleClickAdd = () => {
        setOpen(true);
        setOpenModal(true);
        setPackage({
            ...roles, name: "", isDefault: false, description: "", modelCode: "", marketCode: "", marketName: "", createdBy: graphData.displayName, editMode: false
        });
    };

    const handleClickEditService = () => {
        let rowSelect = packageRows.filter(item => item.name == selectedRow.name)[0];

        setOpen(true);
        setOpenModal(true);
        if (roles !== null) {
            setPackage({
                ...roles,
                id: rowSelect.id,
                name: rowSelect.name,
                isDefault: rowSelect.isDefault,
                description: rowSelect.description,
                modelCode: rowSelect.modelCode,
                marketCode: rowSelect.marketCode,
                marketName: rowSelect.marketName,
                lastModifiedBy: rowSelect.lastModifiedBy,
                editMode: true
            });
            setEnableSelect(true);
        }
    };

    const saveFleet = () => {
        setUpdatePackage(false);
        roles.editMode ?
            UpdatePackage({
                "id": roles.id,
                "name": roles.name,
                "isDefault": false,
                "description": roles.description,
                "modelCode": roles.modelCode,
                "marketCode": roles.marketCode,
                "marketName": roles.marketName,
                "lastModifiedBy": graphData.displayName
            })
                .then(data => {
                    initializeUsers();
                    setUpdatePackage(true);
                    setOpenModal(false);
                    setEnableSelect(true);
                    setOpen(false);
                })
            :

            CreatePackage({
                "name": roles.name,
                "isDefault": false,
                "description": roles.description,
                "modelCode": roles.modelCode,
                "marketCode": roles.marketCode,
                "marketName": roles.marketName,
                "createdBy": graphData.displayName
            })
                .then(data => {
                    initializeUsers();
                    setUpdatePackage(true);
                    setOpenModal(false);
                    setOpen(false);
                });
    };


    const handleClickDelete = () => {
        setOpenDialog(true);
        setDialogMessage(`You are about to delete ${selectedRow.name} package`);
        setToggleDialogue(true);
    };

    const deletPackage = () => {
        let rowSelect = packageRows.filter(item => item.name == selectedRow.name)[0];

        DeletePackage(rowSelect.id)
            .then(data => {
                initializeUsers();
                setOpenModal(false);
                setEnableSelect(true);
                setOpen(false);
            })
    };

    const selectServices = (selectPackageServices) => {
        setServices([]);
        let serviceArray = [];

        GetServicesByMarket(selectedRowValue.marketCode)
            .then(data => {
                if (data.ok == true) {
                    if (data.services && Array.isArray(data.services)) {
                        data.services.map(service => {
                            serviceArray.push({
                                id: service.id,
                                name: service.name,
                                subscriptionType: service.subscriptionType,
                                serviceScope: service.serviceScope,
                                checked: selectPackageServices?.filter(x => x.id == service.id).length > 0,
                                disabled: matchesPermission ? service.disabled : true
                            });
                        });
                    }
                    setServices(serviceArray);
                }
            });
    }

    const refreshpackageServices = (packageId) => {
        GetPackages()
            .then(data => {
                if (data.ok === true) {
                    let filteredPackage = data?.packages.filter(x => x.id == packageId)[0]
                    setSelectedPackage(filteredPackage)
                    if (filteredPackage !== undefined) {
                        setDescription(filteredPackage.description);
                        selectServices(filteredPackage.services);
                    } else {
                        setDescription('');
                        setShowServices(false);
                    }
                }
            });
    };

    const handleChangeDropdown = (value) => {
        setServices([]);
        setRole(value);
        refreshpackageServices(value);
        setShowServices(true);
    };

    const handleCheckbox = (event) => {
        const { id, checked } = event.target;

        if (checked) {
            let packageId = role;
            let serviceId = id
            MapServiceToPackage(packageId, serviceId).then(data => {
                refreshpackageServices(role);
            })
        } else {
            let packageId = role;
            let serviceId = id
            UnmapServiceFromPackage(packageId, serviceId).then(data => {
                refreshpackageServices(role);
            })
        }
    };

    const matchesPermission = userPermissions.includes(permission.Connectivity_Administrator);

    //////////////////////////////////////////////////////////////////////////////////////////////


    const handleDialogOk = () => {
        setOpenDialog(false);
        deletPackage();
    };

    const handleClose = () => {
        setDisplayValue('');
        setDisplayValueCarModel('');
        setOpen(false);
        setEnableSelect(true);
        setOpenModal(false);
        setOpenDetailsPage(false);
        setShowText(true);
    };

    const handleDone = () => {
        setOpenDialog(false);
    };

    const handleChange = (name1, name2) => (event) => {
        const { name, value } = event.target;
        if (name1) {
            setPackage({ ...roles, [name]: value.split('#')[1], [name2]: value.split('#')[0] });
            setSelectedMarket({ key: name, value: value });
            setHideLabel(event.target.value);
        } else {
            setPackage({ ...roles, [name]: value });
            setHideLabel(event.target.value);
        }
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            package: row.package,
            market: row.market,
            carModel: row.carModel,
            modifiedBy: row.modifiedBy,
            lastModified: row.lastModified,
            id: row.id
        })
        selectedRowValue = {
            package: row.package,
            market: row.market,
            carModel: row.carModel,
            modifiedBy: row.modifiedBy,
            lastModified: row.lastModified,
            id: row.id
        }
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            id: row.original.id,
            name: row.original.name,
            marketCode: row.original.marketCode,
            marketName: row.original.marketName,
            modelCode: row.original.modelCode,
            lastModifiedBy: row.original.lastModifiedBy,
            lastModifiedDateUtc: row.original.lastModifiedDateUtc,
        })
        selectedRowValue = {
            id: row.original.id,
            name: row.original.name,
            marketCode: row.original.marketCode,
            marketName: row.original.marketName,
            modelCode: row.original.modelCode,
            lastModifiedBy: row.original.lastModifiedBy,
            lastModifiedDateUtc: row.original.lastModifiedDateUtc,
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let packageArray = [];
            GetPackagesByName(searchInput.trim()).then(data => {
                if (data && Array.isArray(data.packages)) {
                    data.packages.map(packages => {
                        packageArray.push(
                            {
                                id: packages.id,
                                name: packages.name,
                                isDefault: packages.isDefault,
                                description: packages.description,
                                modelCode: packages.modelCode,
                                marketCode: packages.marketCode,
                                marketName: packages.marketName,
                                lastModifiedBy: packages.lastModifiedBy,
                                lastModifiedDateUtc: FormmatDate(packages.lastModifiedDateUtc)
                            }
                        );
                    });
                }
                setPackages(packageArray);
            })
        }
    }

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        initializeUsers();
        setSearchInput('');
    };

    const handleDrawar = () => {
        let rowSelect = packageRows.filter(item => item.id == selectedRowValue.id)[0];
        if (rowSelect !== undefined) {
            handleChangeDropdown(rowSelect.id);
            setOpenDetailsPage(true);
            setShowText(false);
            setOpenModal(true);
        }
    };

    const closeHandleDrawer = () => {
        setOpenDetailsPage(false);
        setShowText(true);
        setOpenModal(false);
    };

    return (
        <Container>
            <VehiclePageDialog
                open={openDialog}
                onClose={handleDone}
                title={dialogMessage}
                handleDialogOk={handleDialogOk}
                toggleDialogue={toggleDialogue}
            />

            <Modal
                className={classesAdminPage.drawarModal}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <>
                    {
                        open &&
                        <Card id="CardStyleAdminModal">
                            <Row>
                                <div className='' style={{ marginLeft: '160px' }}>
                                    <SearchField
                                        handleSearchbar={handleSearchbar}
                                        placeholder={'Package Name'}
                                        handleSearchInput={handleChange()}
                                        value={roles.name}
                                        priceBucketActiveTab='true'
                                        autoFocus='true'
                                        modalsearch='true'
                                        name='name'
                                    />
                                </div>
                            </Row>

                            <Row>
                                <div style={{ width: '100%', marginTop: '-10px', padding: '15px' }}>

                                    <div className="marketdropdownstyle" style={{ marginLeft: '5px', marginTop: '17px' }}>
                                        <MarketSelect
                                            name="marketName"
                                            name2='marketCode'
                                            orgId={userOrg.id}
                                            value={`${roles.marketCode}#${roles.marketName}`}
                                            userMarketIds={userMarketIds}
                                            handleChange={handleChange('marketSelect', 'marketCode')}
                                            isVariant='true'
                                            adminpage='true'
                                            placeholder='All Markets'
                                            hideLabel={hideLabel}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div style={{ width: '100%', marginTop: '-10px', padding: '15px' }}>
                                    <DropdownSelect
                                        label='Model Code'
                                        name='modelCode'
                                        dropdownfont='true'
                                        value={roles.modelCode}
                                        style={{ zIndex: 10 }}
                                        tableDropdownHeight="true"
                                        packageselect="true"
                                        menuObjectArray={[
                                            { 'key': 'CX11-A1', 'value': 'CX11-A1' },
                                            { 'key': 'CX11-A3', 'value': 'CX11-A3' },
                                            { 'key': 'E335', 'value': 'E335' }
                                        ]}
                                        onChange={handleChange()}
                                    />
                                </div>
                            </Row>
                            <Row style={{ marginTop: '50px', width: '116%' }}>
                                <Col lg="4" md="4">
                                    <SearchButton
                                        placeholder={roles.editMode == true ? 'Edit' : 'Create'}
                                        handleSearch={() => {
                                            saveFleet();
                                        }}
                                        height='true'
                                        loader='true'
                                    />
                                </Col>
                                <Col lg="3" md="3"></Col>

                                <Col lg="5" md="5" >
                                    <SearchButton
                                        placeholder={'Cancel'}
                                        handleSearch={() => handleClose()}
                                        height='true'
                                        cancelprop='true'
                                    />
                                </Col>
                            </Row>
                        </Card>
                    }

                    {
                        openDetailsPage &&
                        <Card id="serviceCard">
                            <Row>
                                <Col lg="11" md="10" sm="10" xs="10">
                                    <div style={{ marginLeft: '30px', fontSize: '20px', marginTop: '21px', marginBottom: '40px', fontFamily: "LynkcoTypeMedium", color: '#bfbfbf' }} variant="h4" id="tableTitle" component="div">
                                        {`Select Services for ${selectedRow.marketName} ${selectedRow.name} Package`}
                                    </div>
                                </Col>
                                <Col lg="1" md="2" sm="2" xs="2">
                                    <div style={{ marginTop: '13px', marginBottom: '40px', color: '#fff' }} variant="h4" id="tableTitle" component="div">
                                        <Tooltip
                                            title="close search"
                                        >
                                            <IconButton>
                                                <img
                                                    src={closebutton}
                                                    className="closebutton"
                                                    onClick={handleClose}

                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>

                            <Row >
                                <Col lg="12" md="12" sm="12" xs="12">
                                    <Services
                                        handleCheckbox={handleCheckbox}
                                        services={services}
                                    />
                                </Col>

                            </Row>
                        </Card>
                    }
                </>
            </Modal>

            <Row>
                <Col md={12} lg={12}>
                    <div id="tablecard">
                        <Row className='headerStyleConnectivityAdmin'>
                            <Col lg="6" md="6" sm="6" xs="6" ></Col>
                            <Col lg="2" md="2" sm="2" xs="2">
                                {
                                    displayUsers && showSearchBar ?
                                        <div className='searchStyleAdmin' style={{ marginBottom: matchesPermission ? '' : '-14px' }}>
                                            <SearchField
                                                handleSearchbar={handleSearchbar}
                                                placeholder={'Package name'}
                                                handleSearch={handleSearch}
                                                handleSearchInput={handleSearchInput}
                                                onKeyPress={textFieldKeypress}
                                                value={searchInput}
                                                priceBucketActiveTab='true'
                                                autoFocus='true'
                                            />
                                        </div>
                                        :
                                        displayUsers &&
                                        <div style={{ marginBottom: matchesPermission ? '' : '20px' }}>
                                            <Tooltip
                                                title="open search"
                                                onOpen={handleOpenMagnifyIcon}
                                                onClose={handleCloseMagnifyIcon}
                                                style={{ zIndex: 200 }}
                                            >
                                                <IconButton
                                                    onClick={handleCloseSearchbar}
                                                >
                                                    <img
                                                        src={iconMagnify}
                                                        className="magnifyIconAdmin"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                }
                            </Col >

                            <Col lg="2" md="2" sm="2" xs="2" className='columnstyle'>
                                {
                                    showTable && displayUsers && matchesPermission &&
                                    <div className='toolbarstyleAdmin'>
                                        <EnhancedTableToolbar
                                            numSelected={selected}
                                            handleClickOpen={handleClickAdd}
                                            handleClickDelete={handleClickDelete}
                                            handleClickEdit={handleClickEditService}
                                            enableSelect={enableSelect}
                                        />
                                    </div>
                                }
                            </Col>

                            <Col lg="2" md="2" sm="2" xs="2" ></Col>
                        </Row>
                        <>
                            <Fade bottom duration={1500}>
                                <div className="tableFixHeadAdmin">
                                    {
                                        showTable && displayUsers &&
                                        <ReactTableBase
                                            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                            columns={columns}
                                            data={packageRows}
                                            tableConfig={tableConfig}
                                            onClick={(event) => handleClick(event, packageRows.email, packageRows)}
                                            onRowSelected={handleClick}
                                            isSelected={isSelected}
                                            selectedRows={selectedRows}
                                            onSelectedRowsChange={setSelectedRows}
                                            selectedRowValues={selectedRowValues}
                                            setEnableSelect={setEnableSelect}
                                            enableSelect={enableSelect}
                                            handleDrawar={handleDrawar}
                                            closeHandleDrawer={closeHandleDrawer}
                                            packageSelectTable={packageSelectTable}
                                        />
                                    }
                                </div>
                            </Fade>
                        </>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state),
    graphData: selectUserGraphData(state)
});

export default connect(mapStateToProps, null)(PackageTable);