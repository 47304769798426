import React from 'react';
import { MsalAuthenticationTemplate, withMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "src/authConfig";

import { Error } from "src/pages/ErrorPage"
import Layout from 'src/containers/Layout/NewLayout'
import {AuthProgress} from 'src/pages/auth-progress-page/auth-progress'

class MasterLayout extends React.Component {

    render() {
        
        const authRequest = {
            ...loginRequest};
            //// TODO Remove Commented code before merging to development
        return (
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={Error} 
                loadingComponent={AuthProgress}
            >
                <Layout msalContext={this.props.msalContext}/>
            </MsalAuthenticationTemplate>
            //<Layout msalContext={this.props.msalContext}/>
        );
    }
  }
  
  export const LayoutWithMsal = withMsal(MasterLayout);