import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/scss/style.scss";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'src/redux/store';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "src/authConfig";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import { CookiesProvider } from "react-cookie";
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});

ReactDOM.render(
  <CookiesProvider>

    <Provider store={store}>
      {/* <React.StrictMode> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App pca={msalInstance} />
      </ThemeProvider>,
      {/* </React.StrictMode> */}
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();