import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function UploadEditRVcurveTable(uploadData, orgId, convertDecimalsToPercentage = true) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "name": "Lynk§Co RV Table",
            "convertDecimalsToPercentage": convertDecimalsToPercentage,
            "createdDateUtc": "2021-10-20T14:42:06.729Z",
            "createdByOrganizationId": orgId,
            "tableData": uploadData
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/RvTable/Save`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log({ errorCode: error }));
    return apiData;
}


export async function GetRVTable(orgId) {
    let fleetToken = await GetToken();

    const supportOrganization = orgId
    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/RvTable/Query?organizationId=${supportOrganization}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

