import React from 'react';
import { Col } from 'react-bootstrap';
import Chat from 'src/containers/Chat/components/Chat';

const ChatCard = ({ vin, comments, getComments, dataLoaded, closeVehicleDetail, showSVTMap, variant, setToggleSearchBar }) => {

  return (
    <Col md={12} lg={12}>
      <Chat
        vin={vin}
        comments={comments}
        getComments={getComments}
        dataLoaded={dataLoaded}
        showSVTMap={showSVTMap}
        closeVehicleDetail={closeVehicleDetail}
        setToggleSearchBar={setToggleSearchBar}
        variant={variant}
        currentUser={{
          avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
          name: 'Roman Johanson',
          userName: 'dragonKing',
        }}
      />
    </Col>
  )
}

export default ChatCard;