import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Scrollbar from 'src/containers/LayoutNew/scrollBar/ScrollBar';
import { CurrentUserProps, ContactsProps } from 'src/prop-types/ChatProps';
import ChatBubble from 'src/containers/Chat/components/ChatBubble';
import ChatField from 'src/containers/Chat/components/ChatField';
import LoadingImagePlaceHolder from 'src/components/placeholder/loadingimageplaceholder';
import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  tooltipImg: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    backgroundColor: 'transparent',
    padding: '4px',
  },
  root: {
    marginBottom: -theme.spacing(4),
    marginTop: '-70px'
  },
}));

const Chat = ({ vin, comments, getComments, dataLoaded, variant }) => {
  const [openContacts, setOpenContacts] = useState(false);
  const messageEl = useRef(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current?.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  const onOpenContacts = () => {
    setOpenContacts(state => !state);
  };

  return (
    <ChatContainer
      open={openContacts}
      onClick={openContacts ? onOpenContacts : null}
      role="presentation"
      variant={variant}
    >
      <ChatDialog>
        <Messages ref={messageEl}>
          <ChatMessagesWrap>
            {dataLoaded ? (
              <ChatMessages >
                {comments.map(item =>
                (
                  <ChatBubble
                    key={item.id}
                    contact={item.userId}
                    message={item.comment}
                    date={item.createdDateUtc}
                    active={item.id}
                    vin={vin}
                    getComments={getComments}
                    photo={item.userPhoto}
                    fullName={item.fullName}
                  />
                )
                )}
              </ChatMessages>
            ) : <LoadingImagePlaceHolder />}

          </ChatMessagesWrap>
        </Messages>

        <ChatField vin={vin}
          getComments={getComments}
        />
      </ChatDialog>
    </ChatContainer>
  );
};

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  contacts: ContactsProps.isRequired,
};

export default Chat;

// region STYLES

const ChatContainer = styled.div`
  height: 615px;
  min-height: 200px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 15px; 
  background-color: #181818;
  margin-left: -20px;
  margin-right: -19px;
  margin-top: 10px;

  @media screen and (min-width: 1801px) and (max-width: 1872px) {
    height: 653px;
    margin-left: -50px;
  };

  @media screen and (min-width: 1500px) and (max-width: 1520px) {
    height: 520px;
    margin-left: -30px;
    margin-right: 265px;
    padding-top: 10px;
    margin-top: 3px;
  };

  @media screen and (min-width: 1421px) and (max-width: 1450px) {
    padding-top: 10px;
    margin-top: 40px;
    margin-right: 302px;
    height: 465px;
  };

  @media screen and (min-width: 1401px) and (max-width: 1420px) {
    padding-top: 10px;
    margin-top: 30px;
    margin-right: 302px;
    height: 465px;
  };

  @media screen and (min-width: 1276px) and (max-width: 1400px) {
    height: 440px;
    margin-left: 0px;
    margin-right: -10px;
  };

  @media screen and (min-width: 1111px) and (max-width: 1275px) {
    height: 350px;
    margin-left: 0px;
    margin-right: -10px;
  };

  @media only screen and (min-width: 1026px) and (max-width: 1110px) {
    height: 563px;
  };

  @media only screen and (min-width: 1001px) and (max-width: 1025px) {
    height: 530px;
  };

  @media only screen and (min-width: 769px) and (max-width: 1000px) {
    height: 710px;
  };

  @media only screen and (min-width: 600px) and (max-width: 768px) {
    height: 690px;
  };

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: #999999;
    }
    
    ${props => props.open && `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatContactList = styled.div`
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  background-color: #232329;

  border-right: 1px solid #33333a;

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    ${props => props.open && `
      transform: translateX(0);
    `}
  }
`;
//////       // ${borderRight(props)}: none;

const ChatContacts = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
  background-color: #212121;
`;

const ChatContactButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  text-align: Left;

`;

const ChatDialog = styled.div`
  width: calc(100% - 5px);
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  margin-top: -35px
`;

const ChatMessages = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 20px;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: #999999;

    background-color: #232329;

  }
`;


const Messages = styled.div`
  height: 380px;

  @media screen and (min-width: 1401px) and (max-width: 1450px) {
    height: 300px;
  };
  @media screen and (min-width: 1276px) and (max-width: 1400px) {
    height: 290px;
  };
  @media screen and (min-width: 1111px) and (max-width: 1275px) {
    height: 180px;
  };
  @media only screen and (min-width: 1001px) and (max-width: 1025px) {
    height: 310px;
  };
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 10px;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    opacity: 0.3;
    background-color: #131313;

  };
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #404040;
  }
`;


const ChatScroll = styled(Scrollbar)`
  text-align: left;

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const ChatContactsScroll = styled(Scrollbar)`
  height: 100%;

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;

    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

// endregion