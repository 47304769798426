import React, { useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import BlueCar_01 from 'src/assets/img/vehicles/BlueCar_01.png';
import BlackCar_01 from 'src/assets/img/vehicles/BlackCar_01.png';
import DOORS_LOCK from 'src/assets/img/vehicles/DOORS_LOCK.png';
import DOORS_UNLOCK from 'src/assets/img/vehicles/DOORS_UNLOCK.png';
import ENGINE_START from 'src/assets/img/vehicles/ENGINE_START.png';
import ENGINE_STOP from 'src/assets/img/vehicles/ENGINE_STOP.png';
import HONK_FLASH from 'src/assets/img/vehicles/HONK_FLASH.png';

const VehicleDetailsPageRemote = ({ modelData }) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredDoor, setHoveredDoor] = useState(false);

    return (
        <Row>
            <Col md={12} sm={12} xs={12} lg={12}>
                <div id="remoteCard" >
                    <Col md={12} sm={12} xs={12} lg={12}>
                        <div
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                position: 'relative',
                                flexWrap: 'wrap',
                            }}
                        >
                            <img
                                src={modelData.color == 'Blue' ? BlueCar_01 : BlackCar_01}
                                className='remoteCarImage'
                                alt={`slide_${process.env.PUBLIC_URL}/assets/icons/BlueCar.png`}
                            />
                        </div>

                    </Col>

                    <Card id='connectedDriverCard_One' >
                        <Row>
                            <Col md={3} sm={3} xs={3} lg={3}></Col>

                            <Col md={6} sm={6} xs={6} lg={6} >
                                <Row className='remoteButtonstyle'>
                                    <Col md={4} sm={4} xs={4} lg={4} className='remoteButtonColumnStyleOne'>
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                position: 'relative',
                                                flexWrap: 'wrap',
                                            }}
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)}
                                        >
                                            <img
                                                src={hovered ? ENGINE_STOP : ENGINE_START}
                                                className='remoteButtonStyle'
                                                alt={`slide_${process.env.PUBLIC_URL}/assets/icons/BlueCar.png`}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4} sm={4} xs={4} lg={4} className='remoteButtonColumnStyleTwo'>
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                position: 'relative',
                                                flexWrap: 'wrap',
                                            }}
                                            onMouseEnter={() => setHoveredDoor(true)}
                                            onMouseLeave={() => setHoveredDoor(false)}
                                        >
                                            <img
                                                src={hoveredDoor ? DOORS_UNLOCK : DOORS_LOCK}
                                                className='remoteButtonStyle'
                                                alt={`slide_${process.env.PUBLIC_URL}/assets/icons/BlueCar.png`}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4} sm={4} xs={4} lg={4} className='remoteButtonColumnStyleTwo'>
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                position: 'relative',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <img
                                                src={HONK_FLASH}
                                                className='remoteButtonStyle'
                                                alt={`slide_${process.env.PUBLIC_URL}/assets/icons/BlueCar.png`}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3} sm={3} xs={3} lg={3}></Col>
                        </Row>
                    </Card>
                </div>
            </Col>
        </Row>
    );
};

export default VehicleDetailsPageRemote;