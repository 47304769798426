import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import MasterWorkOrder from "src/pages/workOrders/MasterWorkOrder";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryWorkOrders } from 'src/services/vehicle-pages-http.service';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-14px',
        overflow: 'auto',
        height: '758px',
        marginRight: '-32px',
        paddingRight: '20px',
        // marginLeft: '5px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            opacity: "0.3",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        }
    },
    dividerStyle: {
        backgroundColor: "#1EF1C6",
        marginLeft: theme.spacing(1.2),
        marginRight: -theme.spacing(1.8),
        marginTop: -theme.spacing(2.9),
        height: '120px',
        width: '0.5px'
        // marginBottom: '-10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        // position: 'sticky',
        // marginTop: '-1px',
        marginLeft: '11px',
    },
    heading: {
        "&.MuiTypography-root": {
            color: "#fff",
            fontFamily: 'LynkcoTypeLight,',
            fontSize: '15px'
        }
    },
    formElement: {
        color: "#ff0000",
        backgroundColor: "#2c2c2c",
        width: "50%",
        marginTop: "0px",
        marginBottom: "0px",
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '-10px'
    },
}));

const WorkOrder = (props) => {
    const { value, vin, userOrg, userMarketIds } = props
    
    const [workshopOrders, setWorkshopOrders] = React.useState([]);

    useEffect(() => {
        handleWorkOrders();
    }, [vin]);

    const handleWorkOrders = () => {
        if (value === 1) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map((item, key) => {
                            fleetIds.push(item.id);
                        })

                        QueryWorkOrders(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok === true) {
                                    setWorkshopOrders(data.vehicleWorkOrderHistory);
                                }
                            })
                    }
                })
        }
    }

    const classes = UseStyles();
    return (

        <Col md={12} lg={12}>
            <div className={classes.root}>
                <MasterWorkOrder data={workshopOrders}
                />
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(WorkOrder);


