import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";

export async function GetFleetVehicles({ pageNumber, pageSize, fleetIdArray, marketIds }) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "fleetIds": fleetIdArray,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Infleeting/Query`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function CreateFleetVehicle(fleetVehicle) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)
    
    //Update the FleetTypeId to the default Month to Month ---- 
    //This change is temporary and should be fixed in the backend
    fleetVehicle.fleetTypeId = environment.defaultFleetType;

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleetVehicle)
    };
    let statusCode = ''
    let apiData = await fetch(`${environment.backendVehicleService}/api/Infleeting/Infleet`, options)
        .then((response) => { statusCode = response.status; return response.json() })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    apiData = { ...apiData, statusCode: statusCode }

    return apiData;
}

export async function UpdateFleetVehicle(fleetVehicle) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(fleetVehicle)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Infleeting/Update`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function DefleteFleetVehicle(fleetVehicle) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleetVehicle)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Infleeting/Defleet`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

