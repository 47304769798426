import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetVehicleByVinOrLicensePlate } from "src/services/vehicle-pages-http.service";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import ConnectivityTable from "src/pages/driver-page/ConnectivityTable";

const UseStyles = makeStyles({
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxWidth: '240px',
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            maxWidth: '208px',
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            maxWidth: '162px',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            maxWidth: '160px',
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            maxWidth: '160px',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            maxWidth: '140px',
        },
        '@media (min-width: 1077px) and (max-width: 1110px)': {
            maxWidth: '560px',
            marginLeft: '35px'
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            maxWidth: '560px',
            marginLeft: '35px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            maxWidth: '175px',
            marginLeft: '35px'
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            maxWidth: '270px',
            marginLeft: '35px',
            marginTop: '100px'
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            maxWidth: '270px',
            marginLeft: '35px',
            marginTop: '100px'
        }
    },
});


const WhoisConnectedPage = (props) => {
    const { userOrg, userMarketIds } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = useState('');
    const [vehicleDetail, setVehicleDetail] = useState({});
    const [message, setMessage] = useState('');
    const [vinTable, setVINTable] = useState('');

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
        setMessage('');
        setDialogMessage('');
    }

    const handleChangeVINorLIcensePlate = () => {
        if (searchVIN?.trim()?.length != 0) {
            GetVehicleByVinOrLicensePlate(searchVIN?.trim())
                .then(data => {
                    if (data.ok == true) {
                        if (data.vehicle.vin !== null) {
                            setSearchInputVIN(true);
                            setVehicleDetail(data.vehicle);
                            setVINTable(data.vehicle.vin);
                            setDisplaySelectedVehicle(true);
                        } else {
                            setOpenDialog(true);
                            setMessage('Vehicle not found!');
                            setDialogMessage('Please check that VIN or license plate is correct.');
                        }
                    } else {
                        setOpenDialog(true);
                        setMessage(data.title);
                        setDialogMessage(data.errorMessage);
                    }
                });
        };
    };

    const handleSearchInputVIN = (event) => {
        setSearchVIN(event.target.value);
        console.log(event.target.value);
        console.log(event.target.value.length);
        if (event.target.value.length === 0) {
            setSearchInputVIN(false);
        }
    };

    const textFieldKeypressVIN = (e) => {
        if (e.key === 'Enter') {
            handleChangeVINorLIcensePlate();
        }
    };

    return (
        <div className="whoisConnectedPageStyle">
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <div className="searchfieldStyleWhoisConnected">
                <SearchBarProvisioning
                    connectivityColor='true'
                    placeholder={'VIN or Registration Number'}
                    handleSearch={handleChangeVINorLIcensePlate}
                    handleSearchInput={handleSearchInputVIN}
                    onKeyPress={textFieldKeypressVIN}
                    value={searchVIN}
                />
            </div>
            {searchInputVIN &&
                <Row className="rowstyleWhoisConnected"  >
                    <Col lg="4" md="12" >
                        <div className="vehiclecardStyleWhoisConnected">
                            <Row>
                                <Col lg="12" md="12" >
                                    <Row style={{ marginRight: '-10px' }}>
                                        {searchInputVIN && <>
                                            {
                                                vehicleDetail !== undefined &&
                                                <Row>
                                                    <Col md={12} sm={12} xs={12} lg={7}>
                                                        <CardImg
                                                            alt="Card image cap"
                                                            src={vehicleDetail.color == 'Blue' ? profilebgBlue : profilebgBlack}
                                                            className={classes.imageStyle}
                                                        />
                                                    </Col>

                                                    <Col lg="5" md="12" >
                                                        <div className="vehiclecardrowStyleWhoisConnected" >
                                                            <div className="vehiclecardWoisConnectedHeight" style={{ marginTop: '62px' }} >
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>License Plate</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.licensePlate}</span> <br /><br />
                                                            </div>

                                                            <div className="vehiclecardWoisConnectedHeight" style={{}}>
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Model</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.model === '01' ? 'Lynk & Co 01' : ''}</span> <br /><br />
                                                            </div>

                                                            <div className="vehiclecardWoisConnectedHeight">
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Model Year</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.modelYear}</span> <br /><br />
                                                            </div>

                                                            <div className="vehiclecardWoisConnectedHeight">
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Engine Type</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.engineType}</span> <br /><br />
                                                            </div>

                                                            <div className="vehiclecardWoisConnectedHeight">
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Color</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.color === 'Blue' ? 'Blue' : 'Black'}</span> <br /><br />
                                                            </div>

                                                            <div className="vehiclecardWoisConnectedHeight">
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Towbar</span><br />
                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.hasTowbar == true ? 'Yes' : 'No'}</span> <br /><br />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </>}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col lg="8" md="12" >
                        {displaySelectvehicle &&
                            <ConnectivityTable
                                searchVIN={vinTable}
                                setDisplaySelectedVehicle={setDisplaySelectedVehicle}
                                setOpenDialog={setOpenDialog}
                                setMessage={setMessage}
                                setDialogMessage={setDialogMessage}
                            />
                        }
                    </Col>
                </Row>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(WhoisConnectedPage);