import React from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { GetRoles } from 'src/services/role-http.service'
import { makeStyles } from '@material-ui/core/styles'


const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "100%",
    },
}));

const RoleSelect = ({ value, onChange, variant }) => {

    const [roles, setRoles] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeRoles();
    }, [roles.length]);

    const initializeRoles = () => {
        let roleArray = [];
        GetRoles()
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(role => {
                        roleArray.push({ key: role.id, value: role.name });
                    });
                }
                setRoles(roleArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            className={classes.SelectControl}
            label="Role"
            id="role"
            name="roleId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={roles}
            variant={variant ? variant : false }
        ></FormSelect>
    )
}

export default RoleSelect