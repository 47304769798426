import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const Feeds = () => {
  return (
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <CardTitle>Feeds</CardTitle>
        <div className="feed-widget">
          <ul className="list-style-none feed-body mb-0 pb-0">
            <li className="feed-item">
              <div className="feed-icon bg-info">
                <i className="far fa-bell"></i>
              </div>
              You have 4 pending tasks.
              <span className="ml-auto font-12 text-muted">Just Now</span>
            </li>
            <li className="feed-item">
              <div className="feed-icon bg-success">
                <i className="ti-server"></i>
              </div>
              4 VINs below landed cost.
              <span className="ml-auto font-12 text-muted">2 Hours ago</span>
            </li>
            <li className="feed-item">
              <div className="feed-icon bg-warning">
                <i className="ti-shopping-cart"></i>
              </div>
              New used car order received.
              <span className="ml-auto font-12 text-muted">15 Nov</span>
            </li>
            <li className="feed-item">
              <div className="feed-icon bg-danger">
                <i className="ti-user"></i>
              </div>
              New user registered.
              <span className="ml-auto font-12 text-muted">30 May</span>
            </li>
            <li className="feed-item">
              <div className="feed-icon bg-info">
                <i className="far fa-bell"></i>
              </div>
              You have 2 pending tasks.
              <span className="ml-auto font-12 text-muted">14 Nov</span>
            </li>
            <li className="feed-item">
              <div className="feed-icon bg-warning">
                <i className="ti-shopping-cart"></i>
              </div>
              Car swap order.
              <span className="ml-auto font-12 text-muted">18 Nov</span>
            </li>
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default Feeds;