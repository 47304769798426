import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import VehicleDetailsPageRSA from 'src/components/vehicleDetails/VehicleDetailsPageRSA';
import VehicleDetailsPageRemote from 'src/components/vehicleDetails/VehicleDetailsPageRemote';
import SVTMainComponent from 'src/pages/svt-page/SVTMainComponent';
import VehicleDetailsPageRemarketing from 'src/components/vehicleDetails/VehicleDetailsPageRemarketing';
import VehicleDetailsPageUtilization from 'src/components/vehicleDetails/VehicleDetailsPageUtilization';
import Chat from 'src/containers/Chat';
import { Container } from 'reactstrap';
import * as AppSettings from 'src/AppSettings.json';
import DefaultPage from 'src/pages/backgrounds/default-page';
// import useSound from 'use-sound';

function TabPanel(props) {
  const { children, value, index, vehiclePosition, closeHandleDrawer, isExpanded, vin, shouldRenderMap, handleChangeVIN, detailsmap,
    mapSpinner,
    setAddressInput,
    addressInput,
    comments,
    getComments,
    dataLoaded,
    vehicleDetailsChartValues,
    modelData,
    selectTable,
    ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && value === 0 && (
        <PositionTracking
          vin={vin}
          value={2}
          vehiclePosition={vehiclePosition}
          mapSpinner={mapSpinner}
          setAddressInput={setAddressInput}
          addressInput={addressInput}
          detailsmap={detailsmap}
        />
      )}
      {value === index && value === 1 && (
        <VehicleDetailsPageRSA value={value} vin={vin} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} />
      )}
      {value === index && value === 2 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <VehicleDetailsPageUtilization value={value} vin={vin} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} />
      )}
      {value === index && value === 3 && (
        <VehicleDetailsPageRemarketing value={value} vin={vin} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} selectTable={selectTable} />
      )}
      {value === index && value === 4 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <VehicleDetailsPageRemote value={value} vin={vin} modelData={modelData} />
      )}
      {value === index && value === 5 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <SVTMainComponent selectedVIN={vin} valueVIN={value} />
      )}
      {value === index && value === 6 && (
        <Chat vin={vin} valueVIN={value} comments={comments} getComments={getComments} dataLoaded={dataLoaded} />
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingLeft: '5px',
    paddingRight: '5px',
  },

  panel: {
    marginTop: '-73vh',
    position: "relative",
    height: "100%",
    paddingLeft: '9px',
    backgroundColor: "transparent",
    width: '100%',
    '@media (min-width: 1801px) and (max-width: 2500px)': {
      marginTop: '-74vh',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginLeft: '-30px',
      width: '105vw',
      marginTop: '-75.8vh',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginLeft: '-19px',
      width: '110.5vw',
      marginTop: '-78.6vh',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginLeft: '-19px',
      width: '110.5vw',
      marginTop: '-83vh',
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      marginTop: '-76vh',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      marginTop: '-76vh',
    },
    '@media (min-width: 1075px) and (max-width: 1110px)': {
      marginTop: '-80vh',
    },
    '@media (min-width: 1001px) and (max-width: 1074px)': {
      marginTop: '-80.5vh',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      marginTop: '-79vh',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      marginTop: '-77.5vh',
    },
  },

  tabBar: {
    minWidth: '8.2vw',
    color: '#b3b3b3',
    fontFamily: 'LynkcoTypeMedium',
    fontSize: '15px',
    zIndex: 10,
    marginTop: '-2px',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      marginTop: '-2px',
      minWidth: '4vw',
      fontSize: '18px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginTop: '4px',
      minWidth: '8vw',
      fontSize: '18px',
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginTop: '1px',
      minWidth: '8vw',
      fontSize: '18px',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginTop: '-1.5px',
      minWidth: '8.55vw',
    },
    '@media (min-width: 1451px) and (max-width: 1500px)': {
      marginTop: '6px',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginTop: '-2px',
      minWidth: '9.9vw',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginTop: '-2px',
      minWidth: '9.9vw',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginTop: '-2px',
      minWidth: '9.2vw',
      fontSize: '13px',
    },
    '@media (min-width: 1233px) and (max-width: 1279px)': {
      marginTop: '-15px',
      paddingTop: '20px',
      minWidth: '9.2vw',
      fontSize: '13px',
    },
    '@media (min-width: 1076px) and (max-width: 1232px)': {
      marginTop: '-15px',
      paddingTop: '20px',
      minWidth: '9.1vw',
      fontSize: '13px',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      marginTop: '-1px',
      minWidth: '10.4vw',
      fontSize: '14px',
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      marginTop: '-5px',
      minWidth: '10.4vw',
      paddingTop: '10px',
      fontSize: '14px',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      marginTop: '2px',
      minWidth: '11.75vw',
      paddingTop: '3px',
      fontSize: '15px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      marginTop: '3px',
      minWidth: '11.2vw',
      paddingTop: '3px',
      fontSize: '15px',
    },
  },

  appBar: {
    backgroundColor: "#181818",
    position: 'relative',
    marginTop: '63vh',
    width: '66%',
    height: '6vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: '15px',
    borderRadius: '30px',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      marginLeft: '-25px',
      width: '28.4vw',
      height: '6vh',
    },
    '@media (min-width: 1873px) and (max-width: 2500px)': {
      width: '57vw',
      height: '6.8vh',
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      width: '57vw',
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginTop: '68vh',
      height: '7vh',
      width: '71.45%',
      marginLeft: '-39px'
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginLeft: '-50px',
      width: '70vw',
      marginTop: '60vh',
      height: '7vh',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginLeft: '-50px',
      width: '70vw',
      marginTop: '59vh',
      height: '7.8vh',
    },
    '@media (min-width: 1800px) and (max-width: 1872px)': {
      height: '6.2vh',
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      height: '8vh',
      marginTop: '58vh',
      width: '65vw',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      height: '9.5vh',
      marginTop: '49vh',
      width: '65vw',
      marginLeft: '-10px',
    },
    '@media (min-width: 1025px) and (max-width: 1110px)': {
      height: '6.5vh',
      marginTop: '64vh',
      marginLeft: '27px',
      width: '73vw',
    },
    '@media (min-width: 1001px) and (max-width: 1024px)': {
      height: '7.5vh',
      marginTop: '60vh',
      marginLeft: '27px',
      width: '73vw',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      height: '5.5vh',
      marginTop: '76vh',
      marginLeft: '-10px',
      width: '86vw',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      height: '6vh',
      marginTop: '76vh',
      marginLeft: '-15px',
      width: '87vw',
    }
  }
});

const StyledTabs = withStyles({
  root: {
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  indicator: {
    backgroundColor: '#b3b3b3',
    height: '45px',
    maxWidth: '120px',
    marginLeft: '14px',
    zIndex: 1,
    borderRadius: '300px',
    '@media (min-width: 2601px) and (max-width: 4500px)': {
      maxWidth: '155px',
      marginLeft: '0px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginLeft: '0px',
      maxWidth: '160px',
      height: '50px'
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginLeft: '0px',
      maxWidth: '160px',
      height: '47px'
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginLeft: '0px',
      maxWidth: '140px',
    },
    '@media (min-width: 1401px) and (max-width: 1450px)': {
      marginLeft: '0px',
      maxWidth: '145px',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginLeft: '4px',
      maxWidth: '110px',
      height: '45px',
    },
    '@media (min-width: 1232px) and (max-width: 1279px)': {
      height: '6vh',
      maxWidth: '105px',
      marginLeft: '5px'
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      height: '5.5vh',
      maxWidth: '102px',
      marginLeft: '5px'
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      height: '5.5vh',
      maxWidth: '90px',
      marginLeft: '7px',
    },
    '@media (min-width: 771px) and (max-width: 1001px)': {
      height: '4.5vh',
      maxWidth: '130px',
      marginLeft: '0px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      height: '5vh',
      maxWidth: '140px',
      marginLeft: '0px',
    },
  },
})(Tabs);

export default function VehicleDetailsTabs(props) {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded, vin, comments, getComments, dataLoaded, mapSpinner, handleChangeVIN, defaultcolor = false, detailsmap, vehicleDetailsChartValues, modelData, selectTable } = props
  const classes = useStyles(props);
  const [value, setValue] = useState(0);

  // const soundUrl = `${process.env.PUBLIC_URL}/assets/ES_Car Engine-Rev- SFX-Producer.mp3`;

  // const [playbackRate, setPlaybackRate] = useState(0.75);

  // const [play] = useSound(soundUrl, {
  //   playbackRate,
  //   volume: 0.5,
  // });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // setPlaybackRate(playbackRate + 0.1);
    // play();
  };

  return (
    <Container>
      <div className={classes.root}>
        <div className='vehicleDetailsTabStyle' >
          <AppBar color='secondary' position="static" className={classes.appBar}>
            <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example"  >
              <Tab label="MAP" {...a11yProps(0)} className={classes.tabBar} />
              <Tab label="Roadside" {...a11yProps(1)} className={classes.tabBar} />
              <Tab label="Utilization" {...a11yProps(2)} className={classes.tabBar} />
              <Tab label="Remarket" {...a11yProps(3)} className={classes.tabBar} />
              <Tab label="Remote" {...a11yProps(4)} className={classes.tabBar} />
              <Tab label="Recover" {...a11yProps(5)} className={classes.tabBar} />
              <Tab label="Notes" {...a11yProps(6)} className={classes.tabBar} />
            </StyledTabs>
          </AppBar>
        </div>

        <TabPanel
          vehiclePosition={vehiclePosition}
          shouldRenderMap={shouldRenderMap}
          vin={vin}
          closeHandleDrawer={closeHandleDrawer}
          value={value}
          index={0}
          className={classes.panel}
          isExpanded={isExpanded}
          mapSpinner={mapSpinner}
          handleChangeVIN={handleChangeVIN}
          detailsmap={detailsmap}
        >
          Map
        </TabPanel>
        <TabPanel value={value} vin={vin} index={1} className={classes.panel} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} >
          Roadside
        </TabPanel>
        <TabPanel value={value} vin={vin} index={2} className={classes.panel} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} >
          Utilization
        </TabPanel>
        <TabPanel value={value} vin={vin} index={3} className={classes.panel} vehiclePosition={vehiclePosition} vehicleDetailsChartValues={vehicleDetailsChartValues} modelData={modelData} selectTable={selectTable} >
          Remarket
        </TabPanel>
        <TabPanel value={value} vin={vin} index={4} className={classes.panel} modelData={modelData}>
          Remote
        </TabPanel>
        <TabPanel value={value} vin={vin} index={5} className={classes.panel}>
          Recover
        </TabPanel>
        <TabPanel value={value} vin={vin} comments={comments} getComments={getComments} dataLoaded={dataLoaded} index={6} className={classes.panel}>
          Notes
        </TabPanel>
      </div>
    </Container>
  );
};