/* ---------------------------How To Use----------------------
    import {RvCurveTableMockData} from 'src/pages/rvCurve-page/mockData';
    import {FormmatRvTableExcelJson} from 'src/utils/RvTableExcelFormatter';
    console.log("FormattedOutput: ", FormmatRvTableExcelJson(RvCurveTableMockData)); 
    --------------------------------------------------------*/

const FormmatRvTableExcelJson = (rvTableExcelToJsonData) => {

    var myString = JSON.stringify(rvTableExcelToJsonData);

    var formattedRvTable = myString
        .replaceAll("-€ (ex VAT)", "EURExVAT")
        .replaceAll("-€", "EUR")
        .replaceAll("m tot. km", "MthKm")
        .replaceAll("Remarketing Cost", "remarketingCost")
        .replaceAll("Market ", "market")
        .replaceAll("Model Year ", "modelYear")
        .replaceAll("Model ", "model")
        .replaceAll("Version ", "version")
        .replaceAll("Consumer price (gross) incl. subsidies", "_ConsumerPriceInclSubsidies")
        .replaceAll("Consumer price (gross) excl. subsidies", "_ConsumerPriceExclSubsidies")
        .replaceAll("Landed Cost (net)", "landedCost")
        .replaceAll("Profit Margin", "profitMargin")
        .replaceAll("Range (km) ", "rangeKm")
        .replaceAll('"1', '"_1')
        .replaceAll('"2', '"_2')
        .replaceAll('"3', '"_3')
        .replaceAll('"4', '"_4')
        .replaceAll('"5', '"_5')
        .replaceAll('"6', '"_6')
        .replaceAll('"7', '"_7')
        .replaceAll('"8', '"_8')
        .replaceAll('"9', '"_9')

    return JSON.parse(formattedRvTable);
}

const UnformmatRvTableExcelJson = (rvTableExcelToJsonData) => {

    var myString = JSON.stringify(rvTableExcelToJsonData);

    var formattedRvTable = myString
        .replaceAll('"_9', '"9')
        .replaceAll('"_8', '"8')
        .replaceAll('"_7', '"7')
        .replaceAll('"_6', '"6')
        .replaceAll('"_5', '"5')
        .replaceAll('"_4', '"4')
        .replaceAll('"_3', '"3')
        .replaceAll('"_2', '"2')
        .replaceAll('"_1', '"1')
        .replaceAll("rangeKm", "Range (km) ")
        .replaceAll("profitMargin", "Profit Margin")
        .replaceAll("landedCost", "Landed Cost (net)")
        .replaceAll("_ConsumerPriceInclSubsidies", "Consumer price (gross) incl. subsidies")
        .replaceAll("_ConsumerPriceExclSubsidies", "Consumer price (gross) excl. subsidies")
        .replaceAll("version", "Version")
        .replaceAll("modelYear", "Model Year")
        .replaceAll("model", "Model")
        .replaceAll("market", "Market")
        .replaceAll("reMarketingCost", "Remarketing Cost")
        .replaceAll("MthKm", "m tot. km")
        .replaceAll("EURExVAT", "-€ (ex VAT)")
        .replaceAll("EUR", "-€")

    var result = JSON.parse(formattedRvTable);
    return result
}

function formatAndVerifyNumericValue(value) {
    value = value ?? 0;
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return parts.join(".");
}

function FormatHourMinute(value) {
    value = value ?? 0;

    let y = value.substr(0, 10) +
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0 " +
        value.substr(10);

    return y;
}

function FormatDropDownValue(value) {
    value = value ?? 0;

    let y = parseInt(value.replace(/[^a-zA-Z0-9*?:.+\-^"_ ]+/g, '').slice(0, -3));

    return y;
}

export { FormmatRvTableExcelJson, UnformmatRvTableExcelJson, formatAndVerifyNumericValue, FormatHourMinute, FormatDropDownValue };