import React, { useState } from "react";
import PropTypes from "prop-types";
import { CustomInput } from "reactstrap";
import { Card, CardBody, } from 'reactstrap';
import styled from 'styled-components';
import PackageVariant from 'mdi-react/PackageVariantIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import PackageVariantClosed from 'mdi-react/PackageVariantClosedIcon';
import TodoDetails from "src/components/todos/TodoDetails";
import Modal from "@mui/material/Modal";

const TodoItem = ({
  onTodoClick,
  onStarredClick,
  onDeleteClick,
  starred,
  onCheckClick,
  title,
  isCompleted,
  desc,
  deleted,
  active,
  id,
}) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card style={{ backgroundColor: '#131313', borderRadius: '20px' }}>
      <Modal
        // className={drawerClasses.drawarModal}
        style={{
          width: '100%',
          flexShrink: 0,
          marginTop: '150px',
          alignItems: 'center',
          position: 'absolute',
          height: '260px',
          display: 'flex',
          justifyContent: 'center',
        }}
        variant="persistent"
        anchor="right"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
      >
        <div>
          {
            open &&
            <div style={{ border: '2px solid #2c2c2c', height: '550px', width: '30%', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px', borderRadius: '20px', marginTop: '150px' }}>
              <TodoDetails handleOpen={handleOpen} handleClose={handleClose} openModal={open} />
            </div>
          }
        </div>
      </Modal>

      {/* <>
          {
            open &&
            <div style={{ border: '2px solid #2c2c2c', height: '550px', width: '30%', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px', borderRadius: '20px', marginTop: '-30px', zIndex: 3000, backgroundColor: '#131313', marginLeft: '600px' }}>
              <TodoDetails handleOpen={handleOpen} handleClose={handleClose} openModal={open} />
            </div>
          }
        </> */}

      <TodoItemContent
        className={active === id ? "bg-light" : ""}
        onClick={onTodoClick}
      >
        <CustomInput
          type="checkbox"
          id={id}
          checked={isCompleted}
          label=" "
          onChange={onCheckClick}
        />
        <TodoInfo>
          <TodoContent >
            <h3>{title}</h3>
            <TodoDescription>{desc}</TodoDescription>
          </TodoContent>
          <TodoAdditionalWrapper>
            <TodoAdditional>
              <TodoDueDate>Due date: {'date'}</TodoDueDate>
              <TodoPriority>
                <span>Priority:</span>
                <TodoPriorityIndicator priority={'priority'} />
              </TodoPriority>
            </TodoAdditional>
            <TodoBtnWrapper>
              {!starred ? (
                <div>
                  <TodoDeleteButton
                    type="button"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   editItem(todoItemData);
                    // }}
                    onClick={() => handleOpen()

                    }
                  >
                    <PencilOutlineIcon />
                  </TodoDeleteButton>
                  <TodoDeleteButton
                    type="button"
                    // onClick={onStarredClick}
                    onClick={() => {
                      onStarredClick();
                    }}
                  >
                    <PackageVariantClosed />
                  </TodoDeleteButton>
                </div>
              ) : (
                <div>
                  <TodoDeleteButton
                    type="button"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   archivedItem(todoItemData.data);
                    // }}
                    // onClick={onDeleteClick}
                    // onClick={() => {
                    //   // onDeleteClick();
                    //   // setOpen(true);
                    //   // console.log('----------------__________________________________________')
                    // }}
                    // onClick={onStarredClick}
                    onClick={() => {
                      onStarredClick();
                    }}

                  >
                    <PackageVariant />
                  </TodoDeleteButton>
                  <TodoDeleteButtonDelete
                    type="button"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   deleteItem(todoItemData);
                    // }}
                    onClick={() => onDeleteClick()}
                  >
                    <DeleteForeverIcon />
                  </TodoDeleteButtonDelete>
                </div>
              )}
            </TodoBtnWrapper>
          </TodoAdditionalWrapper>

          {/* <ListGroupItem
            className={active === id ? "bg-light" : ""}
            onClick={onTodoClick}
          > */}
          {/* <div className="list-group-item-action border-bottom">
              <ListGroupItemHeading>
                <span className="d-flex align-items-center">
                  <CustomInput
                    type="checkbox"
                    id={id}
                    checked={isCompleted}
                    label=" "
                    onChange={onCheckClick}
                  />
                  <span className="d-block"> {title}</span>
                </span>
              </ListGroupItemHeading>
              <ListGroupItemText className="mb-1">
                <span className="text-truncate d-block">{desc}</span>
              </ListGroupItemText>
              <span>
                <i
                  onClick={onStarredClick}
                  className={starred ? "fas fa-star" : "far fa-star"}
                  style={{ color: starred ? "#FFC107" : "#495057" }}
                />
                {!deleted ? (
                  <i onClick={onDeleteClick} className="fas fa-trash-alt ml-3"></i>
                ) : (
                  <i onClick={onDeleteClick} className="icon-action-undo ml-3"></i>
                )}
              </span>
            </div> */}
          {/* </ListGroupItem> */}
        </TodoInfo>

      </TodoItemContent>

    </Card>
  )
}




TodoItem.propTypes = {
  onTodoClick: PropTypes.func.isRequired,
  onStarredClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default TodoItem;


const TodoItemContent = styled(CardBody)`
  display: flex;
`;

const TodoInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: column;

  @media screen and (min-width: 1280px){
    flex-flow: row;
  }
`;

const TodoContent = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  word-break: break-all;

  @media screen and (min-width: 1280px) {
    max-width: 500px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 850px;
  }

  h3 {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  ${props => props.isCompleted && `

    h3 {
      text-decoration: line-through;
    }

    h3, & {
      color: ##999999;
    }
  `}
`;

const TodoDescription = styled.p`
  width: 100%;
  word-break: break-all;
  color: #dddddd;
`;

const TodoAdditionalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-self: baseline;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 400px) {
    flex-flow: row;
  }

  @media screen and (min-width: 576px) {
    flex-flow: column;
  }

  @media screen and (min-width: 768px) {
    flex-flow: row;
  }

  @media screen and (min-width: 1280px) {
    flex-flow: column;
    align-items: flex-end;
    width: initial;
  }
`;

const TodoAdditional = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  white-space: nowrap;
  margin-left: 0;
`;

const TodoDueDate = styled.p`
  background-color: transparent;
  margin-right: 15px;
  margin-top: 0;
`;

const TodoPriority = styled.div`
  display: flex;
  align-self: baseline;
  margin-right: 0;
  color: #dddddd;
  background-color: transparent;
  
  span:not(:last-child) {
    margin-right: 10px;
  }
`;

const TodoDeleteButton = styled.button`
  border: none;
  position: relative;
  color: #999999;
  cursor: pointer;
  align-self: flex-end;
  background-color: transparent;

  svg {
    height: 16px;
    width: 16px;
    fill: #999999;
  }

  &:hover {
    color: #4ce1b6;

    svg {
      fill: #4ce1b6;
    }
  }
`;

const TodoDeleteButtonDelete = styled(TodoDeleteButton)`

  &:hover {
    color: #ff4861;

    svg {
      fill: #ff4861;
    }
  }
`;

const getPriorityColor = (priority) => {
  switch (priority) {
    case 'low':
      return colorAccent;
    case 'medium':
      return colorYellow;
    case 'high':
      return colorRed;

    default:
      return colorAccent;
  }
};

export const colorYellow = '#f6da6e';
export const colorRed = '#ff4861';
export const colorAccent = '#4ce1b6';

const TodoPriorityIndicator = styled.span`
  background-color: ${props => getPriorityColor(props.priority)};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  align-self: center;
  flex-shrink: 0;
`;

const TodoBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-width: 68px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;