import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Col, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from 'src/redux/user/user.selectors';
import RoleSelect from 'src/components/form-elements/role-select.component';
import { QueryRolePermission, UpdateRolePermission } from 'src/services/permissions-http.service';
import Checkbox from '@material-ui/core/Checkbox';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import { permission } from 'src/auth/permissions';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-14px',
        overflow: 'auto',
        height: '758px',
        marginRight: '-32px',
        paddingRight: '20px',
        marginLeft: '-23px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            opacity: "0.3",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        }
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#1EF1C6"
        },
        "&.Mui-disabled": {
            color: "#BFBFBF"
        },
        color: "#BFBFBF"
    },
}));

const PermissionPage = ({ userPermissions }) => {
    const classes = UseStyles();

    const [role, setRole] = useState('');
    const [permissions, setPermission] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [showPermissions, setShowPermissions] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setRole(value);
        setShowPermissions(true);
        if (value == 0) {
            setShowPermissions(false);
        };
        QueryRolePermission({ "id": value }).then(data => {
            setPermission(data?.modulePermissions);
        })
    };

    const handleCheckbox = (event) => {
        const { id, checked } = event.target;
        let tempPermissions = [...permissions];

        for (let i = 0; i < tempPermissions.length; i++) {
            if (tempPermissions[i].administratorPermission?.id === id) {
                tempPermissions[i].administratorPermission.checked = checked;
            }
            if (tempPermissions[i].readonlyPermission?.id === id) {
                tempPermissions[i].readonlyPermission.checked = checked;
            }
        }
        setPermission(tempPermissions);

        UpdateRolePermission({
            "id": role,
            "permissionId": id,
            "checked": checked
        }).then(data => {
            if (data.ok == false) {
                setOpenDialog(true)
                setDialogMessage(data.errorMessage)
            }
        })
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator);

    return (
        <Col md={12} lg={12}>
            <VehiclePageDialog
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
            />
            <div className="permissionPageStyle">
                <div className="roleselectStyle">
                    <RoleSelect value={role.roleId} onChange={handleChange} />
                </div>
                <br />
                {
                    <div className="rowstylePermission">
                        <Table id="customers">
                            {
                                showPermissions &&
                                <>
                                    <thead>
                                        <tr>
                                            <th>Permission</th>
                                            <th>Reader</th>
                                            <th>Administrator</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            permissions != undefined && permissions.map((value, key) => {
                                                return (
                                                    <>
                                                        <tr key={key}>
                                                            <td>{value.name}</td>
                                                            {
                                                                matchesPermission ?
                                                                    <>
                                                                        <td style={{ marginLeft: '-20px' }}>
                                                                            <Checkbox
                                                                                className={classes.checkbox}
                                                                                onChange={handleCheckbox}
                                                                                checked={value.readonlyPermission != null ? value.readonlyPermission.checked : false}
                                                                                disabled={!(value.readonlyPermission != null ? value.readonlyPermission.enabled : false)}
                                                                                id={value.readonlyPermission != null ? value.readonlyPermission.id : ''}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                value.readonlyPermission.name === "Dashboard_Reader" || value.readonlyPermission.name === "VSP_Reader" || value.readonlyPermission.name === "Todo_Reader" ||
                                                                                    value.readonlyPermission.name === "Location_Reader" || value.readonlyPermission.name === "Report_Reader" || value.readonlyPermission.name === "Case_Reader"
                                                                                    ? <></>
                                                                                    :
                                                                                    <Checkbox
                                                                                        className={classes.checkbox}
                                                                                        onChange={handleCheckbox}
                                                                                        checked={value.administratorPermission != null ? value.administratorPermission.checked : false}
                                                                                        disabled={!(value.administratorPermission != null ? value.administratorPermission.enabled : false)}
                                                                                        id={value.administratorPermission != null ? value.administratorPermission.id : ''}
                                                                                    />
                                                                            }
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <td>
                                                                            <Checkbox
                                                                                className={classes.checkbox}
                                                                                onChange={handleCheckbox}
                                                                                checked={value.readonlyPermission != null ? value.readonlyPermission.checked : false}
                                                                                disabled={value.readonlyPermission != null ? value.readonlyPermission.enabled : false}
                                                                                id={value.readonlyPermission != null ? value.readonlyPermission.id : ''}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                value.readonlyPermission.name === "Dashboard_Reader" || value.readonlyPermission.name === "VSP_Reader" || value.readonlyPermission.name === "Todo_Reader" ||
                                                                                    value.readonlyPermission.name === "Location_Reader"
                                                                                    ?
                                                                                    <></>
                                                                                    :
                                                                                    <Checkbox
                                                                                        className={classes.checkbox}
                                                                                        onChange={handleCheckbox}
                                                                                        checked={value.administratorPermission != null ? value.administratorPermission.checked : false}
                                                                                        disabled={value.administratorPermission != null ? value.administratorPermission.enabled : false}
                                                                                        id={value.administratorPermission != null ? value.administratorPermission.id : ''}
                                                                                    />
                                                                            }
                                                                        </td>
                                                                    </>
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </>
                            }
                        </Table>
                    </div>
                }
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(PermissionPage);