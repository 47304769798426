import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UserTable from 'src/pages/Tables/DataTable/indexUser';
import RoleTable from 'src/pages/Tables/DataTable/indexRole';
import PermissionPage from 'src/pages/permission-page/PermissionPage';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';
import InternalFleetsTable from 'src/pages/Tables/DataTable/components/InternalFleetsTable';
import OrganizationTable from 'src/pages/Tables/DataTable/components/OrganizationTable';
import FleetTable from 'src/pages/Tables/DataTable/components/FleetTable';
import { Container } from 'reactstrap';

function TabPanel(props) {
  const { userPermissions, userToken, children, value, index, ...other } = props;
  const values = [permission.Administration_Administrator, permission.Administration_Reader, permission.ManageAsset_Administrator, permission.ManageAsset_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission))
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {matchesPermission ? (
        <>
          {value === index && value === 0 && (
            <UserTable />
          )}
          {value === index && value === 1 && (
            <RoleTable />
          )}
          {value === index && value === 2 && (
            <PermissionPage />
          )}
          {value === index && value === 3 && (
            <OrganizationTable />
          )}
          {value === index && value === 4 && (
            <FleetTable />
          )}
          {value === index && value === 5 && (
            <InternalFleetsTable />
          )}
        </>) : (
        <>
          {value === index && value === 0 && (
            <DefaultPage />
          )}
          {value === index && value === 1 && (
            <DefaultPage />
          )}
          {value === index && value === 2 && (
            <DefaultPage />
          )}
          {value === index && value === 3 && (
            <DefaultPage />
          )}
          {value === index && value === 4 && (
            <DefaultPage />
          )}
          {value === index && value === 5 && (
            <DefaultPage />
          )}
        </>)}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginLeft: '5px',
    marginRight: '5px',
  },

  panel: {
    marginTop: '-40px',
    position: "relative",
    height: "100%",
    paddingLeft: '9px',
    backgroundColor: "transparent",
    width: '100%',
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginLeft: '50px',
      width: '94%',
    },
  },

  tabBar: {
    minWidth: '9vw',
    color: '#b3b3b3',
    fontFamily: 'LynkcoTypeMedium',
    fontSize: '15px',
    zIndex: 10,
    marginTop: '6px',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      marginTop: '8px',
      minWidth: '4vw',
      fontSize: '18px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginTop: '9px',
      minWidth: '8vw',
      fontSize: '18px',
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginTop: '10px',
      minWidth: '8vw',
      fontSize: '18px',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginTop: '5px',
    },
    '@media (min-width: 1451px) and (max-width: 1500px)': {
      marginTop: '6px',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginTop: '5px',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginTop: '3px',
      minWidth: '7.55vw',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginTop: '3px',
      minWidth: '5.4vw',
      fontSize: '13px',
    },
    '@media (min-width: 1233px) and (max-width: 1279px)': {
      marginTop: '-15px',
      paddingTop: '20px',
      minWidth: '5.4vw',
      fontSize: '13px',
    },
    '@media (min-width: 1076px) and (max-width: 1232px)': {
      marginTop: '-15px',
      paddingTop: '20px',
      minWidth: '5vw',
      fontSize: '13px',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      marginTop: '6px',
      minWidth: '6.9vw',
      fontSize: '14px',
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      marginTop: '1px',
      minWidth: '9vw',
      paddingTop: '10px',
      fontSize: '14px',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      marginTop: '14px',
      minWidth: '9.3vw',
      paddingTop: '3px',
      fontSize: '15px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      marginTop: '11px',
      minWidth: '10.4vw',
      paddingTop: '3px',
      fontSize: '15px',
    },
  },

  appBar: {
    backgroundColor: "#181818",
    position: 'relative',
    marginTop: '80px',
    width: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
    marginLeft: '-16px',
    height: '6.4vh',
    borderRadius: '300px',
    '@media (min-width: 1801px) and (max-width: 2600px)': {
      marginTop: '80px',
      height: '6.5vh',
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginTop: '80px',
      height: '7.4vh',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      height: '8vh',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      height: '8.3vh',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      height: '7.8vh',
      marginTop: '65px',
    },
    '@media (min-width: 1232px) and (max-width: 1279px)': {
      marginTop: '65px',
      height: '8.2vh',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      height: '7.3vh',
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      height: '7.2vh',
    },
  }
});

const StyledTabs = withStyles({
  root: {
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  indicator: {
    backgroundColor: '#b3b3b3',
    height: '45px',
    maxWidth: '140px',
    marginLeft: '10px',
    zIndex: 1,
    borderRadius: '300px',
    '@media (min-width: 2601px) and (max-width: 4500px)': {
      maxWidth: '165px',
      marginLeft: '3px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginLeft: '3px',
      maxWidth: '165px',
      height: '50px'
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginLeft: '2px',
      maxWidth: '165px',
      height: '47px'
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginLeft: '2px',
      maxWidth: '145px',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginLeft: '2px',
      maxWidth: '145px',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginLeft: '2px',
      maxWidth: '145px',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginLeft: '4px',
      maxWidth: '125px',
      height: '45px',
    },
    '@media (min-width: 1232px) and (max-width: 1279px)': {
      height: '6vh',
      maxWidth: '120px',
      marginLeft: '5px'
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      height: '5.5vh',
      maxWidth: '130px',
      marginLeft: '5px'
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      height: '5.5vh',
      maxWidth: '135px',
      marginLeft: '2px',
    },
    '@media (min-width: 771px) and (max-width: 1001px)': {
      height: '5vh',
      maxWidth: '140px',
      marginLeft: '4px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      height: '5vh',
      maxWidth: '140px',
      marginLeft: '4px',
    },
  },
})(Tabs);

const AdministrationTabs = ({ userPermissions, userToken }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <div className={classes.root}>
        <div className='AdministrationTabStyle'>
          <AppBar position="static" className={classes.appBar}>
            <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Users" {...a11yProps(0)} className={classes.tabBar} />
              <Tab label="Roles" {...a11yProps(1)} className={classes.tabBar} />
              <Tab label="Permissions" {...a11yProps(2)} className={classes.tabBar} />
              <Tab label="Organizations" {...a11yProps(3)} className={classes.tabBar} />
              <Tab label="Fleets" {...a11yProps(4)} className={classes.tabBar} />
              <Tab label="Internal Assets" {...a11yProps(5)} className={classes.tabBar} />
            </StyledTabs>
          </AppBar>
        </div>
        <TabPanel value={value} index={0} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
          Users
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
          Roles
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
          Permissions
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          Organizations
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          Fleets
        </TabPanel>
        <TabPanel value={value} index={5} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          Internal Assets
        </TabPanel>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(AdministrationTabs);