import React, { useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import { FormmatDate } from 'src/utils/DateFormatter';
import Tooltip from '@material-ui/core/Tooltip';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginTop: '-5px',
        height: '720px',
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            height: "600px",
        },
    },
    root2: {
        overflow: 'auto',
        height: '660px',
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            height: "525px",
        },
        '@media (min-width: 1276px) and (max-width: 1400px)': {
            height: "550px",
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            height: "400px",
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            height: "550px",
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        marginLeft: '1px',
        marginTop: '10px',
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
}));

const VSPVehicleCard = (props) => {
    const { vehicleDetail, vin } = props
    const classes = UseStyles(props);
    const [iconDirection, setIconDirection] = useState(false)
    const div = useRef(null);

    return (
        <Col md={12} lg={12}>

            <div style={{ marginLeft: '1px', marginRight: '13px' }} >
                <Card id="CardBodyCardAllowance2" className={classes.root} >
                    <CardBody  >
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginLeft: '-20px', marginTop: '-10px' }}>
                                    <div>
                                        <Typography style={{ marginLeft: '15px', fontSize: '14px', fontFamily: "LynkcoTypeBold", marginTop: '5px' }} variant="h4" id="tableTitle" component="div">
                                            Vehicle Details
                                        </Typography>
                                    </div>
                                    <Row className={classes.root2}>
                                        <div ref={div} >
                                            <div >
                                                <Row style={{ marginLeft: '-1px', marginRight: '-30px', marginTop: '10px' }} >
                                                    <Col lg="12" md="12" >
                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>VIN</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vin}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>License Plate</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.licensePlate}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>PNO</span> <br />
                                                        <span style={{ fontSize: 'calc(5px + 0.390625vw)', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.pnoCode}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Model</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.model}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Model Year</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.modelYear}</span> <br /><br />


                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Engine Type</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.engineType}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Colour</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.color}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>TowBar</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.towbarState}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Registered</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{FormmatDate(vehicleDetail?.vehicleDetails?.registeredDateUtc)}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Age [Months]</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.age}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Odometer</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.odometerKm}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>OBC Version</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.obcVersion}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Market</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.market}</span> <br /><br />

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '55px' }}  >
                                        <Tooltip title={iconDirection ? "Scroll Up" : "Scroll Down"}
                                            className={classes.rotateIcon}
                                            onClick={() => {
                                                iconDirection ?
                                                    div.current?.scrollIntoView({ behavior: "smooth", alignToTop: "true" }) : div.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' })
                                                setIconDirection(!iconDirection)
                                            }}
                                            style={{ marginTop: '-100px', fontSize: '20px', letterSpacing: '20px', cursor: 'pointer', marginLeft: '50px' }}
                                        >
                                            {
                                                iconDirection ?
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-up" />
                                                    </span> :
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-down" />
                                                    </span>
                                            }
                                        </Tooltip>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VSPVehicleCard);