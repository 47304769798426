import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Col, Row } from 'reactstrap';

const UseStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        height: '610px',
        paddingRight: '5px',
        marginLeft: '5px',
        marginTop: '-40px',
        overflowX: 'hidden',
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            height: '530px'
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            height: '450px'
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            height: '450px'
        },
        '@media (min-width: 1274px) and (max-width: 1280px)': {
            height: '450px'
        },
        '@media (min-width: 1112px) and (max-width: 1273px)': {
            height: '350px'
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            height: '390px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            height: '390px'
        },
        "&::-webkit-scrollbar": {
            height: "3px",
            width: "8px",
            opacity: "0.5",
            backgroundColor: "#212121"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "#000"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#1EF1C6"
        }
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#1EF1C6"
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: '#1EF1C6'
          },
        "&.Mui-disabled": {
            color: "#BFBFBF"
        },
        color: "#BFBFBF"
    },
}));

const Services = ({ handleCheckbox, services }) => {
    const classes = UseStyles();

    return (
        <Row className={classes.root}>
            {
                services.length > 0 && services.map(value => {
                    return (
                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                            <table>
                                <tr >
                                    <td>
                                        <span>
                                            <Checkbox
                                                className={classes.checkbox}
                                                onChange={handleCheckbox}
                                                checked={value?.checked}
                                                disabled={value?.disabled}
                                                id={value?.id}
                                            />
                                        </span>
                                    </td>
                                    <td style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight", color: '#fff' }}><span>{value?.name}</span></td>
                                </tr>
                            </table>
                        </Col>
                    )
                })
            }
        </Row>
    )
};

export default Services;