import React, { useMemo } from 'react';
import {  GetRVTable } from 'src/services/rvcurve-http.service';

const CreateDataVehiclePage = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        Footer: 'Middle age:',
        disableGlobalFilter: true,
        width: 65,
      },
      // {
      //   Header: 'First name',
      //   accessor: 'first',
      // },
      // {
      //   Header: 'Last name',
      //   accessor: 'last',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Username',
      //   accessor: 'user',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Age',
      //   accessor: 'age',
      //   disableGlobalFilter: true,
      //   Footer: (info) => {
      //     const { rows, flatRows } = info;
      //     const totalAge = useMemo(
      //       () => rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
      //       [rows],
      //     );
      //     const age = Math.round(totalAge / flatRows.length);
      //     return <span>{age}</span>;
      //   },
      // },
      // {
      //   Header: 'Date',
      //   accessor: 'date',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Location',
      //   accessor: 'location',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Work',
      //   accessor: 'work',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
      { Header: 'license Plate', accessor: "licensePlate", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Model', accessor: "model", Footer: 'Middle age:', disableGlobalFilter: true,  },
      { Header: 'Color', accessor: "color", Footer: 'Middle age:', disableGlobalFilter: true,  },
      { Header: 'Towbar', accessor: "hasTowbar", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Winter Tyres', accessor: "hasWinterTyres", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Odometer', accessor: "odometerKm", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'M2M Balance', accessor: "currentBalanceKm", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Registered', accessor: "registeredDateUtc", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'De-fleet', accessor: "forecastedDefleetDateUtc", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Membership ends', accessor: "membershipEnds", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Delivery Status', accessor: "deliveryStatus", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Fleet Status', accessor: "fleetStatus", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'RV Curve [%]', accessor: "rvCurve", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'RV Range [km]', accessor: "rvRange", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Order Number', accessor: "orderNumber", Footer: 'Middle age:', disableGlobalFilter: true},
    ],
    [],
  );

    

  React.useEffect(() => {
    rows();
    // initializeFleetTypes();
    // setDeleteDialog(false);
}, []);
  const data = [];
  const rows = () => {
  //   GetRVTable().then(data => {
  //     if (data && Array.isArray(data.tableData)) {
  //       data = data.tableData
  //     }   
  // }
  // );
  };


  // const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
  //   - start.getTime()))).toLocaleDateString();

  // const data = [];
  // const rows = () => {
  //   for (let i = 1; i < 36; i += 1) {
  //     data.push({
  //       id: i,
  //       first: ['Maria', 'Bobby  ', 'Alexander'][Math.floor((Math.random() * 3))],
  //       last: ['Morrison', 'Brown  ', 'Medinberg'][Math.floor((Math.random() * 3))],
  //       user: ['@dragon', '@hamster', '@cat'][Math.floor((Math.random() * 3))],
  //       age: Math.min(100, Math.round(Math.random() * 30) + 20),
  //       date: getRandomDate(new Date(2002, 3, 1), new Date(1954, 3, 1)),
  //       location: ['Melbourne', 'Tokio', 'Moscow', 'Rome'][Math.floor((Math.random() * 4))],
  //       work: ['Nova Soft', 'Dog Shop', 'Aspirity', 'Business Bro', 'Starlight'][Math.floor((Math.random() * 5))],
  //       "60 (EUR) ": 17710,
  //       "60 (EUR ex VAT) ": 14882.35  
  //     });
  //   }
  // };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateDataVehiclePage;
