import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: (props) => props.adjustTopMargin ? -theme.spacing(0.05) : '',
        visibility: (props) => props.visibility,
        '& .MuiFormLabel-root': {
            backgroundColor: "rgb(33, 33, 33)",
            fontSize: '14px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#1EF1C6'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'white'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: 'gray'
        },
        '& .MuiInputBase-input': {
            fontSize: '14px'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "rgb(33, 33, 33)",
            borderRadius: '1px',
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.01)',
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.01)',
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        }
    },
}))

const FormTextField = (props) => {
    const { classes, name, labelText, value, onChange, onKeyPress, variant, disabled,  placeholder, color, visibility = 'visible', adjustTopMargin = true } = props
    const classesA = useStyles(props);

    return (<TextField
        margin="dense"
        id={name}
        name={name}
        label={labelText}
        type="text"
        value={value}
        onChange={onChange}
        variant={variant ? variant : "outlined"}
        disabled={disabled ? true : false}
        classes={classesA}
        placeholder={placeholder}
        fullWidth
        onKeyPress={onKeyPress}
    />);
}

export default FormTextField;