import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import MarketSelect from 'src/components/form-elements/market-select.dashboard.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { SalesOverview, LandedCostInView, VinsBelowLandedCost, UsedCarStock } from "src/components/dashboard/index.js";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from 'reactstrap';
import { GetMarketByOrganizationId } from 'src/services/market-http.service';

const UseStyles = makeStyles({
  root: {
    overflow: 'auto',
    height: '90vh',
    backgroundColor: 'transparent',
    marginTop: '-20px',
    paddingLeft: '15px',
    '@media (min-width: 1232px) and (max-width: 1280px)': {
      height: '75vh',
      overflowX: 'hidden',
      marginTop: '-50px',
      paddingLeft: '3px'
    },
    '@media (min-width: 1401px) and (max-width: 1500px)': {
      height: '78vh',
      overflowX: 'hidden',
      marginTop: '-40px',
      overflowY: 'scroll'
    },
    '@media (min-width: 600px) and (max-width: 1110px)': {
      height: '78vh',
      overflowX: 'hidden',
      marginTop: '-40px',
      overflowY: 'scroll'
    },
    padding: '40px 0 12px 0',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "0.1px",
      opacity: "0.3",
      backgroundColor: 'rgb(19 19 19/ 1%)'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      backgroundColor: 'rgb(19 19 19/ 1%)',
      border: 'none'
    },
  },
  rowstyle: {
    marginTop: '10px',
    '@media (min-width: 1232px) and (max-width: 1279px)': {
      marginTop: '30px',

    },
  }
});

const ScheduledMaintenanceDashboard = ({ userOrg, userMarketIds, isStandardMarket = false }) => {
  const classes = UseStyles();

  const [isMarketSelected, setIsMarkedSelected] = useState(false);
  const [hideLabel, setHideLabel] = useState('');
  // let selectedMarket = userMarketIds.toString();
  const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
  const [queryParamsMarket, setQueryParamsMarket] = useState('');
  const [markets, setMarkets] = useState([]);
  let marketId = 'ALL';

  useEffect(() => {
    initializeMarkets();
  }, [markets.length]);

  const initializeMarkets = () => {
    let orgsMarkets = [];
    let userMarketArray = [];

    GetMarketByOrganizationId(userOrg.id)
      .then(data => {
        Array.isArray(data) && data.length > 0 ? data.map(market => {
          if (market.settings.isStandardMarket == true) {
            orgsMarkets.push({ key: market.gemsId, value: market.name });
          }
        })
          : console.log("Error Occured");
        userMarketIds.map(id => {
          orgsMarkets.map(market => {
            // if (userMarketArray.length == 0)
            //     userMarketArray.push({ key: "ALL", value: " All markets" });
            if (market.key == id) {
              userMarketArray.push(market.key);
            }
          })
        })
        setMarkets(userMarketArray);
      });
  };

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketNmae = value.split('#')[1];
    setHideLabel(event.target.value);

    if (marketId == 'ALL') {
      // selectedMarket = userMarketIds
    } else {
      // selectedMarket = [marketId];
      setIsMarkedSelected(true);
      setHideLabel(event.target.value);
    }
    setQueryParamsMarket(marketId);
    setSelectedMarket({ key: name, value: value });
  };

  return (
    <Container>
      <Row>
        <Col lg="12" md="12" xl="12" className="col-xlg-12">
          <div className={classes.root}>
            <div style={{ marginTop: '-50px', width: '15%' }}>
              <MarketSelect
                orgId={userOrg.id}
                value={selectedMarket.value}
                userMarketIds={userMarketIds}
                handleChange={handleChange}
                adminpage='true'
                placeholder='All Markets'
                hideLabel={hideLabel}
                isStandardMarket='true'
              />
            </div>
            {/* --------------------------------------------------------------------------------*/}
            {/* Row-1                                                                          */}
            {/* --------------------------------------------------------------------------------*/}

            <Row style={{ marginTop: '30px', marginLeft: '-15px' }}>
              <Col lg="4" md="12" >
                <UsedCarStock />
              </Col>
              <Col lg="4" md="12">
                <LandedCostInView />
              </Col>
              <Col lg="4" md="12">
                <VinsBelowLandedCost />
              </Col>
            </Row>

            {/* --------------------------------------------------------------------------------*/}
            {/* Row-2                                                                          */}
            {/* --------------------------------------------------------------------------------*/}

            <Row className={classes.rowstyle}>
              <Col lg="12" md="12" xl="12" className="col-xlg-12">
                <SalesOverview marketId={queryParamsMarket == '' ? markets.toString() : queryParamsMarket} createdByOrganizationId={userOrg.id} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(ScheduledMaintenanceDashboard);