import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { selectSideBarShow } from 'src/redux/sidebar/sidebar.selectors';
import { selectAdUserDetails } from 'src/redux/user/user.selectors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import routes from 'src/routes';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Content = ({ open, userData, content }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (

    <div className="black-background">
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <route.component {...props} />
                )} />
            )
          })}
          <Redirect from="/" to="/admin/users" />
        </Switch>
      </Suspense>
    </div>
  );
}

const mapStateToProps = state => ({
  userData: selectAdUserDetails(state),
  open: selectSideBarShow(state)
});

export default connect(mapStateToProps, null)(React.memo(Content));
