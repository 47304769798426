import {
  CHANGE_CONTENT_VISIBILITY,
  CHANGE_MOBILE_CONTENT_VISIBILITY,
} from 'src/redux/mainContentActions';

const initialState = {
  show: false,
  collapse: false,
};

const mainContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CONTENT_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case  CHANGE_MOBILE_CONTENT_VISIBILITY:
      return { ...state, show: !state.show };
    default:
      return state;
  }
};

export default mainContentReducer;
