import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const EngineCard = ({ setQueryParamsEngine, queryParamsEngine, setDataLoaded }) => {

    const [checkedHEV, setCheckedHEV] = useState(true);
    const [checkedPHEV, setCheckedPHEV] = useState(true);
    const [checkedBEV, setCheckedBEV] = useState(false);

    let updatedEngineTypeArray = [];

    const switchHandlerHEV = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedEngineTypeArray = [...queryParamsEngine, 'HEV'];
            setQueryParamsEngine(updatedEngineTypeArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedEngineTypeArray = queryParamsEngine.filter((item) => item !== 'HEV');
            setQueryParamsEngine(updatedEngineTypeArray);
            setDataLoaded(false);
        }
        setCheckedHEV(event.target.checked);
    };

    const switchHandlerPHEV = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedEngineTypeArray = [...queryParamsEngine, 'PHEV']
            setQueryParamsEngine(updatedEngineTypeArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedEngineTypeArray = queryParamsEngine.filter((item) => item !== 'PHEV');
            setQueryParamsEngine(updatedEngineTypeArray);
            setDataLoaded(false);
        }
        setCheckedPHEV(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance6" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>ENGINE</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr style={{ marginTop: '20px' }}>
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', }}>HEV<br /></td>
                        <td style={{ float: 'right', marginTop: '-13px' }}>
                            <ToggleComponent checked={checkedHEV} onChange={switchHandlerHEV} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>PHEV<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedPHEV} onChange={switchHandlerPHEV} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'rgba(255, 255, 255, 0.2)', marginTop: '25px' }}>BEV<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedBEV} disabled={true} />
                        </td>
                    </tr>

                </table>
            </CardBody>
        </Card>
    )
};

export default EngineCard;