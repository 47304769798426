import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import Chart from "react-apexcharts";

const EngineTypeView = () => {
  const optionsuserviews = {
    grid: {
      show: true,
      borderColor: "rgba(0, 0, 0, .3)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#80ffdb", "#9437ff"],
    fill: {
      type: "gradient",
      opacity: ["0.1", "0.1"],
    },
    xaxis: {
      categories: ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    markers: {
      size: 3,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      theme: "dark",
    },
    legend: {
      show: false,
    },
  };
  const seriesuserviews = [
    {
      name: "PHEV",
      data: [30, 50, 60, 80, 25, 90],
    },
    {
      name: "HEV",
      data: [20, 30, 10, 20, 18, 10],
    },
  ];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Dashboard-3                                                            */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <div className="d-flex flex-wrap">
          <div>
            <CardTitle>Engine Type</CardTitle>
            <CardSubtitle>Last six Months</CardSubtitle>
          </div>
        </div>
        <div className="campaign ct-charts mt-4">
          <Chart
            options={optionsuserviews}
            series={seriesuserviews}
            type="area"
            height="235px"
          />
        </div>
        <div className="d-flex no-block align-items-center justify-content-center mt-4 mb-2">
          <div>
            <h6 className="text-success">
              <i className="fa fa-circle font-10 mr-2" />
              PHEV
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-brightpurple">
              <i className="fa fa-circle font-10 mr-2" />
              HEV
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default EngineTypeView;
