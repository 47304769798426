import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from 'src/redux/user/user.selectors';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SearchButton from 'src/components/buttons/search-button-component';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import ReactTableBaseVehiclePageCashCars from 'src/components/tables/newtable/table/ReactTableBaseCashCarsVehiclePage';
import FormSelect from 'src/components/form-elements/custom-select.component';
import Tooltip from '@material-ui/core/Tooltip';
import { QueryRsaReport } from 'src/services/rsa-report-http.service';
import ManualCaseCreationIcon from 'src/assets/img/vehicles/ManualCaseCreationIcon.png';
import ManualCaseCreationIconGreen from 'src/assets/img/vehicles/ManualCaseCreationIconGreen.png';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from "@mui/material/IconButton";
import { permission } from 'src/auth/permissions';
import moment from 'moment';
import DefaultPage from 'src/pages/backgrounds/default-page';

let rowsPerPage = 200;
let newPageVariable = 1;

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },

    root2: {
        overflow: 'auto',
        height: '675px',
        paddingRight: '6px',
        marginLeft: '-23px',
        overflowX: 'hidden',
        marginTop: '220px',
        marginRight: '-15px',
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginTop: '180px',
            marginLeft: '-60px',
            marginRight: '-25px',
            height: '717px',
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            marginTop: '180px',
            marginLeft: '-60px',
            marginRight: '-25px',
            height: '748px',
        },
        '@media (min-width: 1001px) and (max-width: 1110px)': {
            marginTop: '-80px',
            marginLeft: '20px',
            marginRight: '-75px',
            height: '365px',
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            marginTop: '165px',
            marginLeft: '-70px',
            marginRight: '-45px',
            height: '530px',
        },
        '@media (min-width: 1026px) and (max-width: 1077px)': {
            marginTop: '170px',
            marginLeft: '-70px',
            marginRight: '-45px',
            height: '570px',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            marginTop: '180px',
            marginLeft: '-5px',
            marginRight: '-75px',
            height: '335px',
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginTop: '193px',
            marginLeft: '20px',
            marginRight: '-95px',
            height: '425px',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            marginTop: '190px',
            marginLeft: '20px',
            marginRight: '-75px',
            height: '457px',
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            marginTop: '210px',
            marginLeft: '55px',
            marginRight: '-120px',
            height: '475px',
        },
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            marginTop: '210px',
            marginLeft: '55px',
            marginRight: '-120px',
            height: '515px',
        },
        '@media (min-width: 1801px) and (max-width: 2600px)': {
            marginTop: '220px',
            marginLeft: '-7px',
            marginRight: '-15px',
            height: '650px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginTop: '210px',
            marginLeft: '110px',
            marginRight: '-151px',
            height: '710px',
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        }
    },
    rotateIcon: {
        marginTop: '-100px',
        cursor: 'pointer',
        marginLeft: '0px',
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginLeft: '-35px',
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            marginLeft: '-35px',
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            marginLeft: '-25px',
        },
        '@media (min-width: 1026px) and (max-width: 1077px)': {
            marginLeft: '-35px',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            marginLeft: '65px',
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginLeft: '105px',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            marginLeft: '120px',
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            marginLeft: '170px',
        },
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            marginLeft: '175px',
        },
        '@media (min-width: 1801px) and (max-width: 2600px)': {
            marginLeft: '0px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginLeft: '250px'
        },
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
}));

const ReportsMainPage = ({ userPermissions, userMarketIds, undefinedstocktable = true }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [inputVIN, setInputVIN] = useState('');

    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const [isEnableSave, setIsEnableSave] = useState(false);

    const [openDetailsPage, setOpenDetailsPage] = useState(false);

    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);

    const [rows, setVehicleFleet] = useState([]);

    const [pageSelectAll, setPageSelectAll] = useState([]);
    const [isPageSelectAll, setIsPageSelectAll] = useState(false);

    const [selectedRow, setSelectedRow] = useState({});
    const [unSuccessfulVINs, setUnsuccessfulVINs] = useState([]);

    const [presSelectedMarketIds, setPresSelectedMarketIds] = useState([]);

    let selectedRowVehiclePostion = {};

    let marketIds = [];

    const [earMarked, setEarMarked] = useState([]);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isErrorDialog, setErrorDialogFlag] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [shouldRenderMap, setShouldRenderMap] = useState(false)
    const [vehiclePosition, setVeiclePosition] = useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = useState(false);

    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })

    const [queryParamsVin, setQueryParamsVin] = useState('');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [displayValue, setDisplayValue] = useState('');
    const [displayRSA, setDisplayRSA] = useState(false);
    const [displayUsedCars, setDisplayUsedCars] = useState(false);
    const [displayReservedPriceList, setDisplayReservedPriceList] = useState(false);

    const [toggleSearchBar, setToggleSearchBar] = useState(false);

    const [showText, setShowText] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const imgs = [ManualCaseCreationIcon, ManualCaseCreationIconGreen];

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);

    const [counterPagination, setCounterPagination] = useState(1);

    const [disablePagination, setDisablePagination] = useState(false);
    const [spinnerLength, setSpinnerLength] = useState(false);


    const div = useRef(null);

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'Make', accessor: "make", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'First Date of Registration', accessor: "firstRegDate", disableGlobalFilter: true },
            { Header: 'Coverage Valid From', accessor: "coverageValidFrom", disableGlobalFilter: true, },
            { Header: 'Coverage Valid To', accessor: "coverageValidTo", disableGlobalFilter: true, },
            { Header: 'Reg. Country', accessor: "regCountry", disableGlobalFilter: true, },
            { Header: 'Length of RSA Month', accessor: "rsaMonthLength", disableGlobalFilter: true },
            { Header: 'Vehicle Type', accessor: "vehicleType", disableGlobalFilter: true },
            { Header: 'Contract Type', accessor: "contractType", disableGlobalFilter: true },
        ],
        [],
    );

    useEffect(() => {
        setDisplayRSA(true);
        setDisplayValue('RSA')
        const timer1 = setTimeout(() => {
            setShowText(true);
        }, 300); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setShowTable(true);
        }, 1000); // Adjust the delay as needed
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    useEffect(() => {
        if (totalItems <= rows.length) {
            setDisablePagination(true);
        }
    }, [rows.length, totalItems]);

    useEffect(() => {
        setSpinnerLength(true);
        marketIds = userMarketIds;
        setPresSelectedMarketIds(marketIds)
        setOpenDialog(false);
        if (queryParamsVin.length === 0) {
            setQueryParamsVin('')
            initializeFleetVehicles();
        }
    }, [queryParamsVin]);


    const initializeFleetVehicles = () => {
        let vins = [];
        let vehicleFleetArray = [];
        newPageVariable = 1;
        setDisablePagination(false);
        setCounterPagination(0);

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                setTotalPages(data.totalPages);
                setTotalItems(data.itemCount ?? 0);
                newPageVariable = data.currentPage;
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                if (data.totalPages == 0) {
                    newPageVariable = 1;
                }
                setDataLoaded(true)
                setVehicleFleet(vehicleFleetArray);
            }
        })
    };

    const PaginationFleetVehicles = (page) => {
        let vins = [];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: page, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                setTotalPages(data.totalPages);
                setTotalItems(data.itemCount ?? 0);
                newPageVariable = data.currentPage;
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                if (data.totalPages == 0) {
                    newPageVariable = 1;
                }
                setDataLoaded(true)
                setVehicleFleet([...rows, ...vehicleFleetArray]);
            }
        })
    };

    const header = [
        [
            'License Plate',
            'VIN',
            'Make',
            'Model',
            'First Date of Registration',
            'Coverage Valid From',
            'Coverage Valid To',
            'Reg. Country',
            'Length of RSA Month',
            'Vehicle Type',
            'Contract Type'
        ]
    ];

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);

        let vins = queryParamsVin.split(/[ ,]+/);
        if (queryParamsVin == '') {
            vins = []
        };
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: 0, pageSize: 0, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                initiateDownload(vehicleFleetArray);
                setDownloadSpinner(false);
            }
        })

        const initiateDownload = (row) => {
            const fileName = 'RSA Report';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (row, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, row, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(row, fileName);
        }
        setOpenModal(false);
    }

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);

        let vins = queryParamsVin.split(/[ ,]+/);
        if (queryParamsVin == '') {
            vins = []
        };
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: 0, pageSize: 0, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                initiateDownload(vehicleFleetArray);
                setDownloadSpinner(false);
            }
        })

        const initiateDownload = (row) => {
            CSVExport(header, row, 'RSA Report')
        }
        setOpenModal(false);
    };

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);
    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    ///////////////////////////////////////// Search by VIN

    const handleExtendedSearch = () => {
        let vehicleFleetArray = [];
        let vins = queryParamsVin.split(/[ ,]+/);
        if (queryParamsVin == '') {
            vins = []
        };

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                setTotalItems(data.itemCount ?? 0);
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                setVehicleFleet(vehicleFleetArray);
            }
        })
    };

    /////////////////////////////////////////////////////////////////////

    const handleChangePage = () => {
        newPageVariable = newPageVariable + 1;
        setCounterPagination(newPageVariable);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        PaginationFleetVehicles(newPageVariable);
        setOpen(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        setIsPageSelectAll(false);
        setOpenDialog(false);
        setIsEnableSave(false);
        setIsColumnHidden(false);
        initializeFleetVehicles(marketIds);
    };

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    let selectAllArray = [];

    const selectAllRows = (value) => {
        selectAllArray = [];
        setIsPageSelectAll(value.target.checked);

        let filteredPageNumber = pageSelectAll.filter(item => item.pageNumber !== page)
        setPageSelectAll([...filteredPageNumber, { pageNumber: page, selectAllStatus: value.target.checked }]);

        let rowVins = rows.map(item => item.vin);

        rows.map((row) => {
            let status = row.usedCarIsB2B == 'Yes' ? true : false;
            if (status != value.target.checked) {
                earmarkedRowValues(row, true, value.target.checked)
            }
        });

        let filterArray = earMarked.filter((item) => !rowVins.includes(item.id));
        if (filterArray.length > 0) {
            selectAllArray = selectAllArray.concat(filterArray);
        }
        setEarMarked(selectAllArray);
    }

    const earmarkedRowValues = (row, isHeader, isSelectAll) => {
        if (isHeader) {
            selectAllArray.push({ id: row.vin, status: isSelectAll });
        } else {
            let currentRow = earMarked.find((item) => item.id === row.values.vin);
            let usedCar = row.values.usedCarIsB2B == 'Yes' ? true : false;

            if (currentRow) {
                let filterArray = earMarked.filter((item) => item.id != row.values.vin);
                setEarMarked([...filterArray]);
            }
            else {
                setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }]);
            };
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const closeHandleDrawer = () => {
        setOpenDetailsPage(false);
        setToggleSearchBar(!toggleSearchBar);
        setShowText(true);
    }

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            handleOpenDownload();
        }
        setCounterPagination(1);
    };

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value)
        setInputVIN(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            // setInputVIN('');
        }
    };

    const textFieldKeypressSearch = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        }
    };

    const handleChangeUCA = (value) => (event) => {
        setDisplayRSA(false);
        setIsEnableSave(false);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        let selected = event.target.value;
        if (value == 'reports') {
            if (selected == 'RSA') {
                setDisplayRSA(true);
                setDisplayReservedPriceList(false);
                setDisplayUsedCars(false);
                setToggleSearchBar(false);
                setIsEnableSave(false);
                setDownloadSpinner(false);
                setIsColumnHidden(false);
                initializeFleetVehicles(marketIds);
            }
            if (selected == 'Used Cars Stock') {
                setDisplayRSA(false);
                setDisplayUsedCars(true);
                setDisplayReservedPriceList(false);
                setIsExpanded(false);
            }
            if (selected == 'Reserved Price List') {
                setDisplayRSA(false);
                setDisplayUsedCars(false);
                setDisplayReservedPriceList(true);
                setToggleSearchBar(false);
                setIsExpanded(false);
            }
            setDisplayValue(event.target.value);
        };
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        setDataLoaded(false);
        setQueryParamsVin('');

        let vins = [];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                setTotalPages(data.totalPages);
                setTotalItems(data.itemCount ?? 0);
                newPageVariable = data.currentPage;
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                        contractType: vehicle.contractType
                    })
                })
                if (data.totalPages == 0) {
                    newPageVariable = 1;
                }
                setDataLoaded(true)
                setVehicleFleet(vehicleFleetArray);
            }
        })
    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const matchesPermission = userPermissions.includes(permission.Report_Reader);

    return (
        <Container>
            <Row>
                <Col md={12} lg={12}>
                    <VehiclePageDialog
                        open={openDialog}
                        title={dialogMessage}
                        handleDialogOk={handleDialogOk}
                        isErrorDialog={isErrorDialog}
                        unSuccessfulVINs={unSuccessfulVINs}
                        variant='true'
                    />
                    <Modal
                        className={drawerClasses.drawarModal}
                        variant="persistent"
                        anchor="right"
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={handleCloseDownload}
                        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
                    >
                        <div style={{
                            border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                        }}>
                            <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                                Select format
                            </Typography>
                            <Row className='downloadstyle'>
                                <Col lg="4" md="4">
                                    <SearchButton
                                        placeholder={'Excel'}
                                        handleSearch={() => handleClickDownloadExcel(rows)}
                                        height='true'
                                        loader='true'
                                    />
                                </Col>

                                <Col lg="4" md="4">
                                    <SearchButton
                                        placeholder={'CSV'}
                                        handleSearch={() => handleClickDownloadCSV(rows)}
                                        height='true'
                                        loader='true'
                                    />
                                </Col>

                                <Col lg="4" md="4">
                                    <SearchButton
                                        placeholder={'Cancel'}
                                        handleSearch={handleCloseDownload}
                                        height='true'
                                        rsa='true'
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                    {
                        <>
                            {
                                matchesPermission ?
                                    <div id='tablecard' >
                                        <Row className='headerStyleMyOpenCases'>
                                            <Col lg="8" md="8" sm="8" xs="8" ></Col>
                                            <Col lg="4" md="4" sm="4" xs="4" >
                                                {
                                                    <Row className='rsaReportrowheight'>
                                                        <Col lg="4" md="4" sm="4" xs="4"></Col >
                                                        <Col lg="4" md="4" sm="4" xs="4" >
                                                            {
                                                                <Row className='rowheight'>
                                                                    {
                                                                        <>
                                                                            <Col lg="4" md="4" sm="4" xs="4">
                                                                                {
                                                                                    displayRSA && showSearchBar ?
                                                                                        <div className='searchStyleUdefined'>
                                                                                            <SearchField
                                                                                                handleSearchbar={handleSearchbar}
                                                                                                placeholder='VIN or License Plate'
                                                                                                handleSearch={handleExtendedSearch}
                                                                                                handleSearchInput={handleSearchInput}
                                                                                                onKeyPress={textFieldKeypressSearch}
                                                                                                value={queryParamsVin}
                                                                                                priceBucketActiveTab='true'
                                                                                                autoFocus='true'
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        displayRSA &&
                                                                                        <Tooltip
                                                                                            title="open search"
                                                                                            onOpen={handleOpenMagnifyIcon}
                                                                                            onClose={handleCloseMagnifyIcon}
                                                                                            style={{ zIndex: 200 }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={handleCloseSearchbar}
                                                                                            >
                                                                                                <img
                                                                                                    src={iconMagnify}
                                                                                                    className="magnifyIconUndefined"
                                                                                                />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                }
                                                                            </Col >
                                                                            <Col lg="4" md="4" sm="4" xs="4">
                                                                                {
                                                                                    showText && displayRSA &&
                                                                                    <div className='optionsSelectUndefined'>
                                                                                        <FormSelect
                                                                                            menuObjectArray={[
                                                                                                { 'key': '1', 'value': 'Download' },
                                                                                            ]}
                                                                                            onClick={haandleDropdown}
                                                                                        />

                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                            {
                                                <Fade bottom duration={3000}>
                                                    <div className="tableFixHeadUndefined">
                                                        {
                                                            showTable && displayRSA &&
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} lg={12}>
                                                                    <Fade bottom duration={3000}>
                                                                        <ReactTableBaseVehiclePageCashCars
                                                                            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                                            columns={columns}
                                                                            data={rows}
                                                                            updateEditableData={updateEditableData}
                                                                            updateDraggableData={updateDraggableData}
                                                                            tableConfig={tableConfig}
                                                                            isColumnHidden={isColumnHidden}
                                                                            isSelected={isSelected}
                                                                            selectedRowValues={selectedRowValues}
                                                                            earmarkedRowValues={earmarkedRowValues}
                                                                            earMarkedRows={earMarked}
                                                                            closeHandleDrawer={closeHandleDrawer}
                                                                            vehiclePosition={vehiclePosition}
                                                                            selected={selected}
                                                                            isExpanded={isExpanded}
                                                                            selectAllRows={selectAllRows}
                                                                            isPageSelectAll={isPageSelectAll}
                                                                            undefinedstocktable={undefinedstocktable}
                                                                            handleChangePage={() => handleChangePage(counterPagination)}
                                                                            disablePagination={disablePagination}
                                                                        />
                                                                    </Fade>

                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                    <>
                                                        {
                                                            showTable && displayRSA && dataLoaded && <div className='paginationUndefined' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
                                                        }
                                                    </>
                                                </Fade>
                                            }
                                        </Row>
                                    </div>
                                    :
                                    <DefaultPage />
                            }
                        </>
                    }
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(ReportsMainPage);