import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: (props) => props.toggleDialogue ? '#dc1860' : '#1ef1c6',
        color: (props) => props.toggleDialogue ? '#fff' : '#000',
        '&:hover': {
            backgroundColor: '#339586',
            color: (props) => props.toggleDialogue ? '#fff' : '#FFF'
        },
        marginTop: (props) => props.dialogue ? '-20px' : '-50px',
        borderRadius: '200px',
        textTransform: 'inherit'
    }
}));

const OkButton = (props) => {
    const { handleDialogOk, okButtondKeypress, variant, toggleDialogue, dialogue } = props
    const classes = useStyles(props);
    return (
        <Button onClick={handleDialogOk} onKeyPress={okButtondKeypress} className={classes.button}>
            {variant ? 'Ok' : toggleDialogue ? 'Delete' : 'Close'}
        </Button>
    );
}

export default OkButton;