import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const WorkOrdersCard = ({ setQueryParamsNoOpenWorkOrders, noOpenWorkOrderCount, setDataLoaded }) => {
    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        let value = event.target.checked;

        if (value == true) {
            setQueryParamsNoOpenWorkOrders(true);
            setDataLoaded(false);
        } else {
            setQueryParamsNoOpenWorkOrders(false);
            setDataLoaded(false);
        }
        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1">
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>NO OPEN WORK ORDERS</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{noOpenWorkOrderCount}<br /></td>
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} onChange={switchHandler} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    )
};

export default WorkOrdersCard;