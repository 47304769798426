// import axios from "axios";
import {
  FETCH_TODO_REQUEST,
  FETCH_TODO_SUCCESS,
  FETCH_TODO_FAILURE,
  SET_VISIBILITY_FILTER_TODO,
  DELETE_TODO,
  TOGGLE_STARRED_TODO,
  TODO_DETAILS,
  FILTER_TODO,
  COMPLETE_TODO,
  UPDATE_TODO,
  ADD_TODO,
} from "../constants/";

import { fetchUserTodos } from 'src/services/todo-http.service';


// import { History } from "../../jwt/_helpers/";

import { TransformTodoData } from 'src/utils/TodoFormatter';

//////// make api get call here //////////////

export const fetchTodos = (userId) => {
  return (dispatch) => {
    fetchUserTodos(userId)
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          const todos = TransformTodoData(data.tableData);
          dispatch(fetchTodosSuccess(todos));
        }
      })
  };
};

/////////////////////////////////////////////
//Axios part Reducers
////////////////////////////////////////////
export const fetchTodosRequest = () => {
  return {
    type: FETCH_TODO_REQUEST,
  };
};

export const fetchTodosSuccess = (todos) => {
  return {
    type: FETCH_TODO_SUCCESS,
    payload: todos,
  };
};

export const fetchTodosFailure = (error) => {
  return {
    type: FETCH_TODO_FAILURE,
    payload: error,
  };
};
/////////////////////////////////////////////
//Filter part Reducers
////////////////////////////////////////////

export const setVisibilityFilterTodo = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_VISIBILITY_FILTER_TODO, filter });
    // History.push(`/todos/${filter}`);
  };
};
/////////////////////////////////////////////
//Starred Todo Toggle Reducers
////////////////////////////////////////////
export const toggleStarredTodo = (id) => ({
  type: TOGGLE_STARRED_TODO,
  id,
});

/////////////////////////////////////////////
//Delete Todo part Reducers
////////////////////////////////////////////
export const deleteTodo = (id) => ({
  type: DELETE_TODO,
  id,
});
/////////////////////////////////////////////
//Detail Todo part Reducers
////////////////////////////////////////////
export const todoDetails = (id) => ({
  type: TODO_DETAILS,
  id,
});

export const todoSearch = (searchTerm) => ({
  type: FILTER_TODO,
  payload: searchTerm,
});

export const todoCompleted = (id) => ({
  type: COMPLETE_TODO,
  id,
});

export const updateTodo = (id, field, value) => ({
  type: UPDATE_TODO,
  id: id,
  field: field,
  value: value,
});

export const addTodo = (id, title, desc) => ({
  type: ADD_TODO,
  id: id++,
  title: title ? title : "",
  desc: desc ? desc : "",
});

/////////////////////////////////////////////
//Detail Todo part Reducers
////////////////////////////////////////////
export const todoVisiblityFilter = {
  SHOW_ALL_TODO: "all",
  STARRED_TODO: "starred",
  DELETED_TODO: "deleted",
  COMPLETED_TODO: "completed",
};