import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import Chart from "react-apexcharts";

const UtilizationLevelOverview = () => {
  const optionssalesprediction = {
    colors: ["#7460ee"],
    chart: {
      type: "radialBar",
      offsetY: -20,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          shadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#b2b9bf",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
    tooltip: {
      theme: "dark",
    },
  };
  const seriessalesprediction = [46];
  //   2
  const optionssalesdifference = {
    labels: ["Increase", "Decrease"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {},
    plotOptions: {
      pie: {
        donut: {
          size: "60px",
        },
      },
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    colors: ["rgb(38, 198, 218)", "rgb(242, 244, 248)"],
    tooltip: {
      enabled: false,
      theme: "dark",
    },
  };
  const seriessalesdifference = [25, 10];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Dashboard-4                                                            */
    /*--------------------------------------------------------------------------------*/
    <Row>
      <Col xs="12">
        <Card
          className="w-100"
          style={{
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            boxShadow: '0 5px 6px #000000',
          }}
        >
          <CardBody>
            <CardTitle style={{ fontSize: "20px" }} className="mb-0">Operational Utilisation Level</CardTitle>
            <Row className="mt-3 mb-4">
              <Col lg="6" md="6" className="align-self-center">
                <div className="mt-2">
                  <span className="display-6 text-white">47%</span>
                  <h6 style={{ fontSize: "17px" }} className="text-muted">9% Increased</h6>
                  {/* <h5>(150-165 Sales)</h5> */}
                </div>
              </Col>
              <Col lg="6" md="6" className="align-self-center">
                <div className="ml-auto">
                  <div style={{ height: "95px", width: "150px" }}>
                    <Chart
                      options={optionssalesprediction}
                      series={seriessalesprediction}
                      type="radialBar"
                      height="200px"
                      width="150px"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xs="12">
        <Card
          className="bg-black"
          style={{
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            boxShadow: '0 5px 6px #000000',
          }}
        >
          <CardBody>
            <CardTitle style={{ fontSize: "20px" }} className="mb-0">Financial Utilisation Level</CardTitle>
            <Row className="mt-3 mb-4">
              <Col lg="6" md="6" className="align-self-center">
                <div className="mt-2">
                  <span className="display-6 text-white">59%</span>
                  <h6 style={{ fontSize: "17px" }} className="text-muted">20% Decreased</h6>
                  {/* <h5>(150-165 Sales)</h5> */}
                </div>
              </Col>
              <Col lg="6" md="6" className="align-self-center">
                <div className="ml-auto">
                  <div style={{ height: "112px", width: "150px" }}>
                    <Chart
                      options={optionssalesprediction}
                      series={seriessalesprediction}
                      type="radialBar"
                      height="200px"
                      width="150px"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UtilizationLevelOverview;