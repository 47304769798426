import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useHistory
} from "react-router-dom";
import { LayoutWithMsal } from "src/containers/Layout/AuthLayout";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "src/utils/NavigationClient";

const App = ({ pca }) => {

    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    return (
        
            <MsalProvider instance={pca}>
            {/* <ProvideAppContext> */}
                <div >
                <Router>
                        <Switch>
                            {/* <MsalProvider instance={pca}> */}
                                <Route path="/" component={LayoutWithMsal} />
                                {/* </MsalProvider> */}
                        </Switch>
                </Router>
                </div>
            {/* </ProvideAppContext> */}
        </MsalProvider>
       
        
    );
};

export default App;