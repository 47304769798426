import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";

export async function SaveTodo(userId, tableData) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "userId": userId,
            "tableData": tableData
        })
    };

    console.log('saving todo item to db ', tableData)

    let apiData = await fetch(`${environment.backendTodoService}/api/Todo/Save`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


export async function fetchUserTodos(userId) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    console.log('fetching todo item from db for user ', userId)

    let apiData = await fetch(`${environment.backendTodoService}/api/Todo/Query?userId=${userId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}