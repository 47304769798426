import React, { useState, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable, useGlobalFilter, usePagination, useSortBy, useResizeColumns, useRowSelect } from 'react-table';
import ReactTableHeaderVehiclePage from './ReactTableHeaderVehiclePage';
import ReactTableBodyVehiclePageCashcars from './ReactTableBodyCashCars';
import ReactTableFilter from './ReactTableFilter';
import ReactTablePagination from './ReactTablePagination';
import SearchButton from 'src/components/buttons/search-button-component';
import Fade from 'react-reveal/Fade';

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    let rowValues = typeof rest.rowValues != "undefined" ? rest.rowValues.values : undefined
    let earMarkedArray = typeof rest.earMarkedRows != "undefined" ? rest.earMarkedRows : undefined
    let earMarkedRow = typeof earMarkedArray != "undefined" ? rest.earMarkedRows.find((item) => item.id === rowValues.vin) : null;
    let usedCar = typeof rowValues != "undefined" ? rowValues.usedCarIsB2B == 'Yes' ? true : false : null;
    let isChecked = earMarkedRow ? earMarkedRow.status : usedCar;

    return (
      <input type="checkbox" ref={resolvedRef} {...rest} checked={isChecked} />
    )
  }
);

const ReactTableConstructorVehiclePageCashCars = (props) => {
  const { tableConfig, tableOptions, tableOptionalHook, onChangeRow, isColumnHidden = true, earMarkedRows, handleDrawar, closeHandleDrawer, selectedRowValues, soldcarstable, handleChangePage, disablePagination, mcctable } = props
  const [rowBackGroundColor, setRowBackGroundColor] = useState('');
  const [rowBorderColor, setRowBorderColor] = useState('');
  const [rowIndexState, setRowIndexState] = useState(null);
  const [drawerToggle, setdrawerToggle] = useState(null);

  const {
    isEditable,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    state: { pageIndex, pageSize },

  } = useTable(

    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,

    ...tableOptionalHook,
    (hooks) => {
      if (isColumnHidden) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Cell: ({ row }) => (
              <div className="checkboxVehiclePage" >
                <IndeterminateCheckbox earMarkedRows={earMarkedRows} rowValues={row} {...row.getToggleRowSelectedProps()}
                  onClick={() => {
                    onChangeRow(row);
                  }}
                />
              </div>
            ),
          },
          ...columns,
        ])
      }
    }
  );

  return (
    <div>
      {withSearchEngine && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
        />
      )}

      <div className={withPagination ? 'tableConnectivity react-table' : mcctable ? ' tableConnectivity react-table table--not-pagination-mcc' : 'tableConnectivity react-table table--not-pagination1'}>
        <table
          {...getTableProps()}
          className={isEditable ? 'react-table editable-table' : (soldcarstable ? 'react-table resizable-tableVehiclePageSoldCars' : 'react-table resizable-tableVehiclePage')}
        >
          <ReactTableHeaderVehiclePage
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <ReactTableBodyVehiclePageCashcars
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
            withDragAndDrop={withDragAndDrop}
            rowIndexState={rowIndexState}
            setRowIndexState={setRowIndexState}
            rowBackGroundColor={rowBackGroundColor}
            setRowBackGroundColor={setRowBackGroundColor}
            handleDrawar={handleDrawar}
            drawerToggle={drawerToggle}
            setdrawerToggle={setdrawerToggle}
            closeHandleDrawer={closeHandleDrawer}
            rowBorderColor={rowBorderColor}
            setRowBorderColor={setRowBorderColor}
            onChangeRow={onChangeRow}
            selectedRowValues={selectedRowValues}
          />
          <tr>
            <Fade bottom duration={10000}>
              {
                !disablePagination &&
                <div className='tablebuttonheight'>
                  <SearchButton
                    placeholder={'continue...'}
                    handleSearch={() => handleChangePage()}
                    loader='true'
                    table='true'
                  />
                </div>
              }
            </Fade>
          </tr>
        </table>
      </div>
      {(withPagination && rows.length > 0) && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      )}
    </div>
  );
};

ReactTableConstructorVehiclePageCashCars.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,

};

ReactTableConstructorVehiclePageCashCars.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    tableOptionalHook: false
  },
  tableOptions: [{
    columns: [],
    data: [],
    setFilterValue: () => { },
    updateDraggableData: () => { },
    updateEditableData: () => { },
    defaultColumn: [],
    withDragAndDrop: false,
    dataLength: null,
    disableSortBy: false,
    manualSortBy: false,
    manualGlobalFilter: false,
    manualPagination: false,
  }],
};

export default ReactTableConstructorVehiclePageCashCars;