import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const OrderTypeCard = ({ setQueryParamsOrderType, queryParamsOrderType, setDataLoaded }) => {

    const [checkedB2B, setCheckedB2B] = useState(true);
    const [checkedB2C, setCheckedB2C] = useState(true);
    const [checkedWithoutOrder, setCheckedWithoutOrder] = useState(true);

    let updatedOrderTypeArray = [];

    const switchHandlerB2B = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedOrderTypeArray = [...queryParamsOrderType, 'B2B'];
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedOrderTypeArray = queryParamsOrderType.filter((item) => item !== 'B2B');
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }
        setCheckedB2B(event.target.checked);
    };

    const switchHandlerB2C = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedOrderTypeArray = [...queryParamsOrderType, 'B2C']
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedOrderTypeArray = queryParamsOrderType.filter((item) => item !== 'B2C');
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }
        setCheckedB2C(event.target.checked);
    };

    const switchHandlerWithoutOrder = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedOrderTypeArray = [...queryParamsOrderType, '']
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }
        if (value == false) {
            updatedOrderTypeArray = queryParamsOrderType.filter((item) => item !== '');
            setQueryParamsOrderType(updatedOrderTypeArray);
            setDataLoaded(false);
        }
        setCheckedWithoutOrder(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance10" style={{ marginTop: '-5px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>ORDER TYPE</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr style={{ marginTop: '20px' }}>
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', }}>B2B<br /></td>
                        <td style={{ float: 'right', marginTop: '-13px' }}>
                            <ToggleComponent checked={checkedB2B} onChange={switchHandlerB2B} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>B2C<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedB2C} onChange={switchHandlerB2C} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>Without Order<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedWithoutOrder} onChange={switchHandlerWithoutOrder} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(OrderTypeCard);