import React from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { GetFleetTypes } from 'src/services/fleetType-http.service'
import { makeStyles } from '@material-ui/core/styles'

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "100%",
    },
}));

const FleetTypeSelect = ({ value, onChange, orgId, variant }) => {
    const [fleetTypes, setFleetTypes] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeFleetTypes();
    }, [fleetTypes.length]);

    const initializeFleetTypes = () => {
        let fleetTypeArray = [];
        GetFleetTypes(orgId)
            .then(data => {
                Array.isArray(data) && data.length > 0 ? data.map(fleetType => {
                    fleetTypeArray.push({ key: fleetType.id, value: fleetType.name });
                })
                    : console.log("Error Occured");
                setFleetTypes(fleetTypeArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            label="Fleet Type"
            id="fleetType"
            name="fleetTypeId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={fleetTypes}
            variant={variant ? variant : false}
            className={classes.SelectControl}
        ></FormSelect>
    )
}

export default FleetTypeSelect