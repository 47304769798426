import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import LoadingImagePlaceHolderRVTable from 'src/components/placeholder/loadingimageplaceholderRVTable';
import mapIcon from 'src/assets/img/vehicles/mapIcon.png';
import mapIcon_green from 'src/assets/img/vehicles/mapIcon_green.png';
import tableIcon from 'src/assets/img/vehicles/tableIcon.png';
import tableIcon_green from 'src/assets/img/vehicles/tableIcon_green.png';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchButton from "src/components/buttons/search-button-component";
import FadeLoader from "react-spinners/FadeLoader";

const UseStyles = makeStyles({
    gridContainerStyle: {
        marginTop: '-25px',
        marginLeft: '-8px',
        marginRight: '5px'
    },
    contentStyle: {
        marginLeft: '15px',
        fontSize: '14px',
        color: '#000',
        marginTop: '20px',
        fontFamily: 'LynkcoTypeRegular',
        whiteSpace: 'nowrap',
        fontWeight: 'light',
    },
    image: {
        cursor: 'pointer',
        marginTop: '-1px',
        height: '30px',
        width: '30px'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '2px',
    }
});

const InfoWindowComponentVSP = (props) => {
    const { heading, country, vehicleUpdatedAt, postalCode, street, handleChange, toggleInfoWindowMarker, vehicleCountLCC, vehicleCountMCC, vehicleCountCCC, loaderLCC, loaderMCC, loaderCCC, handleMCCLCCTable, setLCCMCCheader, vspType } = props
    const classes = UseStyles();

    const [openMapIcon, setOpenMapIcon] = useState(false);
    const [openTableIcon, setOpenTableIcon] = useState(false);

    const imgs = [mapIcon, mapIcon_green];
    const imgsTable = [tableIcon, tableIcon_green];
    const [mapImg, setMapImg] = useState(imgs[0]);
    const [tableImg, setTableImg] = useState(imgsTable[0]);

    const [openHomeIconToggle, setOpenHomeIconToggle] = useState(false);
    const [openCarIconToggle, setOpenCarIconToggle] = useState(false);

    const handleCloseMapIcon = () => {
        setMapImg(imgs[0]);
        setOpenMapIcon(false)
    };

    const handleOpenMapIcon = () => {
        setMapImg(imgs[1]);
        setOpenMapIcon(true);
        setLCCMCCheader(heading);
    };

    const handleCloseTableIcon = () => {
        setTableImg(imgsTable[0]);
        setOpenTableIcon(false)
    };

    const handleOpenTableIcon = () => {
        setTableImg(imgsTable[1]);
        setOpenTableIcon(true);
        setLCCMCCheader(heading);
    };

    return (
        <Col md={12} lg={12}>
            <div style={{
                marginLeft: '-14px',
                backgroundColor: '#E6E6E6',
                display: 'flex',
                overflowX: 'hidden',
                overflowY: 'hidden',
                borderRadius: '1px',
                marginBottom: '2px'
            }}>
                <Row>
                    <Col lg="12" md="12" style={{ marginRight: heading.length >= 21 ? '50px' : '10px' }}>
                        <div style={{ marginRight: '-50px' }}>
                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography className={classes.contentStyle} style={{ fontWeight: 'bold' }} variant="h4" id="tableTitle" component="div">
                                        {heading}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                        {street === undefined || street === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {street}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>}

                        {postalCode === undefined || postalCode === '' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {postalCode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        }

                        {country === undefined || country === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {country}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>}

                        {vehicleUpdatedAt === undefined || vehicleUpdatedAt === 'null' ? <></> :
                            <div>
                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography className={classes.contentStyle} variant="h4" id="tableTitle" component="div">
                                            {vehicleUpdatedAt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {
                            vspType == 'address' ?
                                <div style={{ marginTop: '15px', marginLeft: '10px', marginBottom: '10px' }}>
                                    {
                                        // spinnerConfirmButtonProvision ?
                                        //     <div style={{ float: 'right', marginTop: '3px' }}>
                                        //         <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                        //     </div>
                                        //     :
                                        <SearchButton
                                            placeholder={'Send POI'}
                                        // handleSearch={handleProvisioning}
                                        />
                                    }
                                </div>
                                :
                                <></>
                        }
                        {
                            loaderLCC ?
                                <LoadingImagePlaceHolderRVTable />
                                :
                                toggleInfoWindowMarker && vehicleCountLCC >= 1 &&
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ float: 'left' }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show map"
                                                onOpen={handleOpenMapIcon}
                                                onClose={openHomeIconToggle ? null : handleCloseMapIcon}
                                                onClick={() => handleChange()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={mapImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td style={{ float: 'right', }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show table"
                                                onOpen={handleOpenTableIcon}
                                                onClose={openCarIconToggle ? null : handleCloseTableIcon}
                                                onClick={() => handleMCCLCCTable()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={tableImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                        }
                        {
                            loaderMCC ?
                                <LoadingImagePlaceHolderRVTable />
                                :
                                toggleInfoWindowMarker && vehicleCountMCC >= 1 &&
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ float: 'left' }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show map"
                                                onOpen={handleOpenMapIcon}
                                                onClose={openHomeIconToggle ? null : handleCloseMapIcon}
                                                onClick={() => handleChange()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={mapImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td style={{ float: 'right', }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show table"
                                                onOpen={handleOpenTableIcon}
                                                onClose={openCarIconToggle ? null : handleCloseTableIcon}
                                                onClick={() => handleMCCLCCTable()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={tableImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                        }
                        {
                            loaderCCC ?
                                <LoadingImagePlaceHolderRVTable />
                                :
                                toggleInfoWindowMarker && vehicleCountCCC >= 1 &&
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ float: 'left' }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show map"
                                                onOpen={handleOpenMapIcon}
                                                onClose={openHomeIconToggle ? null : handleCloseMapIcon}
                                                onClick={() => handleChange()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={mapImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td style={{ float: 'right', }}>
                                            <Tooltip
                                                className={classes.tooltipImg}
                                                title="show table"
                                                onOpen={handleOpenTableIcon}
                                                onClose={openCarIconToggle ? null : handleCloseTableIcon}
                                                onClick={() => handleMCCLCCTable()}
                                            >
                                                <IconButton>
                                                    <img
                                                        alt="Card image cap"
                                                        src={tableImg}
                                                        className={classes.image}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                        }
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InfoWindowComponentVSP);