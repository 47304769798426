import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import {
  colorBackground, colorHover, colorText, colorBorder,
} from 'src/containers/LayoutNew/scrollBar/palette';
import { marginLeft, left } from 'src/containers/LayoutNew/scrollBar/directions';
import { TopbarBack, TopbarDownIcon } from 'src/containers/LayoutNew/components/topbar/BasicTopbarComponents';
import TopbarMenuLink, { TopbarLink } from 'src/containers/LayoutNew/components/topbar/TopbarMenuLink';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontFamily: "LynkcoTypeRegular",
    marginTop: '-32px',
    marginRight: '16px',
  },
}))

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;


const TopbarProfile = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userName = props.adUserDetails.displayName
  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };
  const classes = useStyles(props);

  const [userimg, setUserimg] = useState('');

  let displayName = ''

  useEffect(() => {
    setUserimg(props.adUserDetails.thumbnailPhoto);
  }, []);



  const stringAvatar = (name) => {
    if (props.adUserDetails.thumbnailPhoto == null) {
      displayName = name
    } else {
      displayName = ''
    }
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: displayName == '' ? '' : `${displayName.split(' ')[0][0]}${displayName.split(' ')[1][0]}`,
    };
  }

  return (
    <TopbarProfileWrap>
      <TopbarAvatarImage>
        <Avatar alt="Profile Picture" src={userimg ? userimg : ''} style={{ height: "35px", width: "35px", backgroundColor: '#b3b3b3', color: '#000', fontFamily: 'LynkcoTypeMedium' }} {...stringAvatar(userName)} className="rounded-circle my-4" />
      </TopbarAvatarImage>
      {/* <TopbarAvatarName >
        <Typography className={classes.root}><span >{userName}</span></Typography>
      </TopbarAvatarName> */}
      {isCollapsed && (
        <TopbarBack
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarMenuWrap>
          <TopbarMenu>
            <TopbarMenuLink
              title="My Profile"
              icon="user"
              path="/account/profile"
              onClick={toggleProfile}
            />
            <TopbarMenuLink
              title="Calendar"
              icon="calendar-full"
              path="/default_pages/calendar"
              onClick={toggleProfile}
            />
            <TopbarMenuLink
              title="Tasks"
              icon="list"
              path="/todo"
              onClick={toggleProfile}
            />
            <TopbarMenuLink
              title="Inbox"
              icon="inbox"
              path="/mail"
              onClick={toggleProfile}
            />
            <TopbarMenuDivider />
            <TopbarMenuLink
              title="Account Settings"
              icon="cog"
              path="/account/profile"
              onClick={toggleProfile}
            />
            <TopbarMenuLink
              title="Lock Screen"
              icon="lock"
              path="/lock_screen"
              onClick={toggleProfile}
            />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/log_in"
            // onClick={logout}
            />
          </TopbarMenu>
        </TopbarMenuWrap>
      </Collapse>
    </TopbarProfileWrap>
  );
};

export default TopbarProfile;

// region STYLES

export const TopbarProfileWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  // ${marginLeft}: 0;
  margin-right: 0;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const TopbarAvatarImage = styled.div`
  // margin: auto 0;
  margin-top: -11px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
`;

export const TopbarAvatarName = styled.p`
  margin: auto 0;
  font-size: 13px;
  line-height: 18px;
  // font-weight: 400;
  display: none;
  margin-left: 50px;
  // margin-top: -10px;
  color: #dddddd;

  @media screen and (min-width: 480px) {
      display: block;
  }
`;

const TopbarMenuWrap = styled.div`
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 210px;
  right: 0;

  @media screen and (max-width: 320px) {
    left: -50px;
  }
`;

const TopbarMenu = styled.div`
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: ${colorBackground};

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }

    &${TopbarLink} {
      background-color: transparent;
      border: none;
      padding: 9px 20px;
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`;

const TopbarMenuDivider = styled.div`
  margin: 15px 0;
  border-top: 1px solid ${colorBorder};
`;

// endregion
