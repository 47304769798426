import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";

///////////////////////// Create Comment

export async function CreateComment(UserId, Comment, Files, Vin) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account!</div>)
    }

    const headers = new Headers();
   
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const formData = new FormData();

    formData.append('userId', UserId);
    formData.append('comment', Comment);
    formData.append('files', Files);
    formData.append('vin', Vin);

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/VehicleComment/CreateVehicleComment`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log({ errorCode: error }));
    return apiData;
};


/////////////////////////////////// Update Comments

export async function UpdateComment(Id, UserId, Comment, Attachments, Files, Vin) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account!</div>)
    }

    const headers = new Headers();

    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const formData = new FormData();

    formData.append('id', Id);
    formData.append('userId', UserId);
    formData.append('comment', Comment);
    formData.append('attachments', Attachments);
    formData.append('files', Files);
    formData.append('vin', Vin);

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/VehicleComment/UpdateVehicleComment`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log({ errorCode: error }));
    return apiData;
};


/////////////////////// Delete Comment

export async function DeleteComment(Id, UserId) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "id": Id,
            "userId": UserId,
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/VehicleComment/DeleteVehicleComment`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log({ errorCode: error }));
    return apiData;
};

////////////////////////// Get Comments

export async function GetComments(vin) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/VehicleComment/GetAllVehicleCommentsByVin?vin=${vin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}