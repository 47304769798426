import UserTypes from "src/redux/user/user.types"

export const SetUserProfilePicture = (item) => {
    return ({ type: UserTypes.SET_PROFILE_PICTURE, payload: item }) 
}

export const SetAdUserDetails = (item) => {
    return ({ type: UserTypes.SET_AD_USER_DETAILS, payload: item }) 
}

export const SetUserServiceInfo = (item) => {
    return ({ type: UserTypes.SET_USER_SERVICE_INFO, payload: item }) 
}

export const SetUserRoles = (item) => {
    return ({ type: UserTypes.SET_USER_ROLES, payload: item }) 
}

export const SetUserGraphData = (item) => {
    return ({ type: UserTypes.SET_USER_GRAPH_DATA, payload: item }) 
}
export const SetUserPermission = (item) => {
    return ({ type: UserTypes.SET_USER_SERVICE_PERMISSION, payload: item }) 
}