import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePageCashCars from 'src/components/tables/newtable/table/ReactTableBaseCashCarsVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from 'src/redux/user/user.selectors';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SearchButton from 'src/components/buttons/search-button-component';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import '../Tables/DataTable/components/AllCarsList.css';
import FormSelect from 'src/components/form-elements/custom-select.component';
import { GetAllUcPricings } from 'src/services/UCPricing-http.service';
import Fade from 'react-reveal/Fade';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    }
}));

const ReservedPriceListTable = ({ soldcarstable = true, userPermissions }) => {

    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState('');
    const [rows, setVehicleFleet] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [disablePagination, setDisablePagination] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'Market', accessor: "marketName", disableGlobalFilter: true },
            { Header: 'PNO', accessor: "pnoCode", disableGlobalFilter: true },
            { Header: 'Standard Value', accessor: "standardValue", disableGlobalFilter: true, },
            { Header: '0 to 9 999 km', accessor: "km0To9999", disableGlobalFilter: true },
            { Header: '10 000 to 14 999 km', accessor: "km10000To14999", disableGlobalFilter: true },
            { Header: '15 000 to 24 999 km', accessor: "km15000To24999", disableGlobalFilter: true },
            { Header: '25 000 to 29 999 km', accessor: "km25000To29999", disableGlobalFilter: true },
            { Header: '30 000 to 39 999 km', accessor: "km30000To39999", disableGlobalFilter: true },
            { Header: '40 000 to 49 999 km', accessor: "km40000To49999", disableGlobalFilter: true },
            { Header: '50 000 to 59 999 km', accessor: "km50000To59999", disableGlobalFilter: true },
            { Header: '0 to 11 months', accessor: "month0To11", disableGlobalFilter: true, },
            { Header: '12 to 23 months', accessor: "month12To23", disableGlobalFilter: true },
            { Header: '24 to 36 months', accessor: "month24To36", disableGlobalFilter: true },
            { Header: 'Markup', accessor: "markUp", disableGlobalFilter: true },
            { Header: 'Towbar', accessor: "towBar", disableGlobalFilter: true }
        ],
        [],
    );

    useEffect(() => {
        initializeFleetVehicles();
    }, []);

    const initializeFleetVehicles = () => {
        let valuesArray = [];

        GetAllUcPricings().then(data => {
            if (data.ucPricing && Array.isArray(data.ucPricing)) {
                data.ucPricing.map(values => {
                    valuesArray.push(
                        {
                            marketName: values.marketName,
                            pnoCode: values.pnoCode,
                            standardValue: values.market == 'SE' ? formatAndVerifyNumericValue(values.standardValue * 10) : formatAndVerifyNumericValue(values.standardValue),
                            km10000To14999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km10000To14999 * 10) : formatAndVerifyNumericValue(values.km10000To14999),
                            km15000To24999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km15000To24999 * 10) : formatAndVerifyNumericValue(values.km15000To24999),
                            km25000To29999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km25000To29999 * 10) : formatAndVerifyNumericValue(values.km25000To29999),
                            km30000To39999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km30000To39999 * 10) : formatAndVerifyNumericValue(values.km30000To39999),
                            km40000To49999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km40000To49999 * 10) : formatAndVerifyNumericValue(values.km40000To49999),
                            km50000To59999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km50000To59999 * 10) : formatAndVerifyNumericValue(values.km50000To59999),
                            month0To11: values.market == 'SE' ? formatAndVerifyNumericValue(values.month0To11 * 10) : formatAndVerifyNumericValue(values.month0To11),
                            month12To23: values.market == 'SE' ? formatAndVerifyNumericValue(values.month12To23 * 10) : formatAndVerifyNumericValue(values.month12To23),
                            month24To36: values.market == 'SE' ? formatAndVerifyNumericValue(values.month24To36 * 10) : formatAndVerifyNumericValue(values.month24To36),
                            km0To9999: values.market == 'SE' ? formatAndVerifyNumericValue(values.km0To9999 * 10) : formatAndVerifyNumericValue(values.km0To9999),
                            markUp: values.markUp,
                            towBar: values.market == 'SE' ? formatAndVerifyNumericValue(values.towBar * 10) : formatAndVerifyNumericValue(values.towBar)
                        }
                    );
                });
            }
            setDataLoaded(true)
            setVehicleFleet(valuesArray);
        });
    };

    const header = [
        [
            'Market',
            'PNO',
            'Standard Value',
            '0 to 9 999 km',
            '10 000 to 14 999 km',
            '15 000 to 24 999 km',
            '25 000 to 29 999 km',
            '30 000 to 39 999 km',
            '40 000 to 49 999 km',
            '50 000 to 59 999 km',
            '0 to 11 months',
            '12 to 23 months',
            '24 to 36 months',
            'Markup',
            'Towbar',
        ]
    ];

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);
        let valuesArray = [];

        GetAllUcPricings().then(data => {
            if (data.ucPricing && Array.isArray(data.ucPricing)) {
                data.ucPricing.map(values => {
                    valuesArray.push(
                        {
                            marketName: values.marketName,
                            pnoCode: values.pnoCode,
                            standardValue: values.market == 'SE' ? values.standardValue * 10 : values.standardValue,
                            km10000To14999: values.market == 'SE' ? values.km10000To14999 * 10 : values.km10000To14999,
                            km15000To24999: values.market == 'SE' ? values.km15000To24999 * 10 : values.km15000To24999,
                            km25000To29999: values.market == 'SE' ? values.km25000To29999 * 10 : values.km25000To29999,
                            km30000To39999: values.market == 'SE' ? values.km30000To39999 * 10 : values.km30000To39999,
                            km40000To49999: values.market == 'SE' ? values.km40000To49999 * 10 : values.km40000To49999,
                            km50000To59999: values.market == 'SE' ? values.km50000To59999 * 10 : values.km50000To59999,
                            month0To11: values.market == 'SE' ? values.month0To11 * 10 : values.month0To11,
                            month12To23: values.market == 'SE' ? values.month12To23 * 10 : values.month12To23,
                            month24To36: values.market == 'SE' ? values.month24To36 * 10 : values.month24To36,
                            km0To9999: values.market == 'SE' ? values.km0To9999 * 10 : values.km0To9999,
                            markUp: values.markUp,
                            towBar: values.market == 'SE' ? values.towBar * 10 : values.towBar
                        }
                    );
                });
            }
            initiateDownload(valuesArray);
            setDownloadSpinner(false);
        });

        const initiateDownload = (rows) => {
            const fileName = 'Reserved Price List';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (rows, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(rows, fileName);
        }
        setOpenModal(false);
    };

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);
        let valuesArray = [];

        GetAllUcPricings().then(data => {
            if (data.ucPricing && Array.isArray(data.ucPricing)) {
                data.ucPricing.map(values => {
                    valuesArray.push(
                        {
                            marketName: values.marketName,
                            pnoCode: values.pnoCode,
                            standardValue: values.market == 'SE' ? values.standardValue * 10 : values.standardValue,
                            km10000To14999: values.market == 'SE' ? values.km10000To14999 * 10 : values.km10000To14999,
                            km15000To24999: values.market == 'SE' ? values.km15000To24999 * 10 : values.km15000To24999,
                            km25000To29999: values.market == 'SE' ? values.km25000To29999 * 10 : values.km25000To29999,
                            km30000To39999: values.market == 'SE' ? values.km30000To39999 * 10 : values.km30000To39999,
                            km40000To49999: values.market == 'SE' ? values.km40000To49999 * 10 : values.km40000To49999,
                            km50000To59999: values.market == 'SE' ? values.km50000To59999 * 10 : values.km50000To59999,
                            month0To11: values.market == 'SE' ? values.month0To11 * 10 : values.month0To11,
                            month12To23: values.market == 'SE' ? values.month12To23 * 10 : values.month12To23,
                            month24To36: values.market == 'SE' ? values.month24To36 * 10 : values.month24To36,
                            km0To9999: values.market == 'SE' ? values.km0To9999 * 10 : values.km0To9999,
                            markUp: values.markUp,
                            towBar: values.market == 'SE' ? values.towBar * 10 : values.towBar
                        }
                    );
                });
            }
            initiateDownload(valuesArray);
            setDownloadSpinner(false);
        });

        const initiateDownload = (rows) => {
            CSVExport(header, rows, 'Reserved Price List')
        }
        setOpenModal(false);
    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            handleOpenDownload()
        }
    };

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);
    };

    return (
        <Col md={12} lg={12}>
            <Modal
                className={drawerClasses.drawarModal}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseDownload}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                        Select format
                    </Typography>
                    <Row className='downloadstyle'>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleCloseDownload}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div id='tablecard' >
                <Row className='headerStyleMyOpenCasesLCAssets'>
                    <Col lg="8" md="8" sm="8" xs="8" ></Col>
                    <Col lg="4" md="4" sm="4" xs="4" >
                        {
                            <Row className='rowheightLCAssets'>

                                <Col lg="4" md="4" sm="4" xs="4" >
                                    {
                                        <Row>
                                            <Col lg="4" md="4" sm="4" xs="4">
                                                {
                                                    <div style={{ marginTop: '20px' }}>
                                                        <div className='optionsSelectLCAssets'>
                                                            {
                                                                <FormSelect
                                                                    menuObjectArray={[
                                                                        { 'key': '1', 'value': 'Download' }
                                                                    ]}
                                                                    onClick={haandleDropdown}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </Col >
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        }
                    </Col>
                    {
                        <Fade bottom duration={1500}>
                            <div className="tableFixHeadLCAssets">
                                <Row>
                                    <Col md={12} sm={12} xs={12} lg={12}>

                                        <ReactTableBaseVehiclePageCashCars
                                            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                            columns={columns}
                                            data={rows}
                                            updateEditableData={updateEditableData}
                                            updateDraggableData={updateDraggableData}
                                            tableConfig={tableConfig}
                                            isColumnHidden={isColumnHidden}
                                            isSelected={isSelected}
                                            selected={selected}
                                            soldcarstable={soldcarstable}
                                            disablePagination={!disablePagination}

                                        />

                                    </Col>
                                </Row>
                            </div>
                        </Fade>
                    }
                </Row>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(ReservedPriceListTable);