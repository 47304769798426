import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RvReactTable from 'src/pages/Tables/DataTable/indexRV';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';
import BMCPricing from 'src/pages/bmc-pricing/BMCPricing';
import UCAssignment from 'src/pages/bmc-pricing/UCAssignment';
import ReservedPricingAssignment from 'src/pages/bmc-pricing/ReservedPricingAssignment';
import { Container } from 'reactstrap';

function TabPanel(props) {
  const { userPermissions, userToken, children, value, index, ...other } = props;
  const values = [permission.Remarketing_Administrator, permission.Remarketing_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission))

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {matchesPermission ? (
        <>
          {value === index && value === 0 && (
            <RvReactTable />
          )}
          {value === index && value === 1 && (
            <BMCPricing />
          )}
          {value === index && value === 2 && (
            <UCAssignment />
          )}
          {value === index && value === 3 && (
            <ReservedPricingAssignment />
          )}
        </>
      ) : (
        <>
          {value === index && value === 0 && (
            <DefaultPage />
          )}
          {value === index && value === 1 && (
            <DefaultPage />
          )}
          {value === index && value === 2 && (
            <DefaultPage />
          )}
          {value === index && value === 3 && (
            <DefaultPage />
          )}
        </>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginLeft: '5px',
    marginRight: '5px',
  },

  panel: {
    marginTop: '-40px',
    position: "relative",
    height: "100%",
    paddingLeft: '9px',
    backgroundColor: "transparent",
    width: '100%',
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginLeft: '50px',
      width: '94%',
    },
  },

  tabBar: {
    minWidth: '13.9vw',
    color: '#b3b3b3',
    fontFamily: 'LynkcoTypeMedium',
    fontSize: '15px',
    zIndex: 10,
    marginTop: '7px',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      marginTop: '7px',
      minWidth: '6.4vw',
      fontSize: '18px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginTop: '9px',
      minWidth: '13.5vw',
      fontSize: '18px',
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginTop: '10px',
      minWidth: '13.5vw',
      fontSize: '18px',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginTop: '5px',
    },
    '@media (min-width: 1451px) and (max-width: 1500px)': {
      marginTop: '8px',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginTop: '6px',
      minWidth: '13.5vw',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginTop: '4px',
      minWidth: '13.5vw',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginTop: '3px',
      minWidth: '11.6vw',
      fontSize: '13px',
    },
    '@media (min-width: 1272px) and (max-width: 1279px)': {
      marginTop: '6px',
      minWidth: '11.4vw',
      fontSize: '13px',
    },
    '@media (min-width: 1232px) and (max-width: 1271px)': {
      marginTop: '5px',
      minWidth: '10.9vw',
      fontSize: '13px',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      marginTop: '5px',
      minWidth: '14.5vw',
      fontSize: '14px',
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      marginTop: '1px',
      minWidth: '13.5vw',
      fontSize: '14px',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      marginTop: '14px',
      minWidth: '14vw',
      fontSize: '15px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      marginTop: '11px',
      minWidth: '14vw',
      fontSize: '15px',
    },
  },

  appBar: {
    backgroundColor: "#181818",
    position: 'relative',
    marginTop: '80px',
    width: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
    marginLeft: '-15px',
    height: '6.4vh',
    borderRadius: '300px',
    '@media (min-width: 1801px) and (max-width: 2600px)': {
      marginTop: '80px',
      height: '6.5vh',
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginTop: '80px',
      height: '7.4vh',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      height: '8vh',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      height: '8.3vh',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      height: '7.8vh',
      marginTop: '65px',
    },
    '@media (min-width: 1232px) and (max-width: 1279px)': {
      marginTop: '65px',
      height: '10.5vh',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      height: '7vh',
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      height: '7vh',
    },
  }
});

const StyledTabs = withStyles({
  root: {
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
  },
  indicator: {
    backgroundColor: '#b3b3b3',
    height: '45px',
    maxWidth: '150px',
    marginLeft: '50px',
    zIndex: 1,
    borderRadius: '300px',
    '@media (min-width: 2601px) and (max-width: 4500px)': {
      marginLeft: '48px',
    },
    '@media (min-width: 1873px) and (max-width: 2600px)': {
      marginLeft: '54px',
      maxWidth: '150px',
      height: '50px'
    },
    '@media (min-width: 1801px) and (max-width: 1872px)': {
      marginLeft: '52px',
      maxWidth: '150px',
      height: '47px'
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginLeft: '30px',
      maxWidth: '151px',
    },
    '@media (min-width: 1421px) and (max-width: 1450px)': {
      marginLeft: '38px',
      maxWidth: '120px',
    },
    '@media (min-width: 1401px) and (max-width: 1420px)': {
      marginLeft: '36px',
      maxWidth: '120px',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      marginLeft: '20px',
      maxWidth: '110px',
      height: '45px',
    },
    '@media (min-width: 1233px) and (max-width: 1279px)': {
      height: '8vh',
      maxWidth: '110px',
      marginLeft: '18px',
    },
    '@media (min-width: 1076px) and (max-width: 1232px)': {
      height: '8vh',
      maxWidth: '110px',
      marginLeft: '12px',
    },
    '@media (min-width: 1026px) and (max-width: 1075px)': {
      height: '5.5vh',
      maxWidth: '110px',
      marginLeft: '23px'
    },
    '@media (min-width: 1001px) and (max-width: 1025px)': {
      height: '5.5vh',
      maxWidth: '112px',
      marginLeft: '13px',
    },
    '@media (min-width: 771px) and (max-width: 1001px)': {
      height: '5vh',
      maxWidth: '125px',
      marginLeft: '7px',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      height: '5vh',
      maxWidth: '125px',
      marginLeft: '7px',
    },
  },
})(Tabs);


const RvTabs = ({ userPermissions, userToken }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <div className={classes.root}>
        <div className='remarketingTabStyle'>
          <AppBar position="static" className={classes.appBar}>
            <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Residual Value" {...a11yProps(0)} className={classes.tabBar} />
              <Tab label="BMC Pricing" {...a11yProps(1)} className={classes.tabBar} />
              <Tab label="UC Assignment" {...a11yProps(2)} className={classes.tabBar} />
              <Tab label="Reserved Price" {...a11yProps(3)} className={classes.tabBar} />
            </StyledTabs>
          </AppBar>
        </div>
        <TabPanel value={value} index={0} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          RV CURVE
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          BMC PRICING
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          UC ASSIGNMENT
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
          RESERVED PRICE
        </TabPanel>
      </div>
    </Container>

  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(RvTabs);