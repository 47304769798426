import React from 'react';
import { Card, CardBody } from "reactstrap";

const VehicleLocationCard = ({ vehiclePosition }) => {

    let firstpartVehicle = vehiclePosition?.postalCode === null ? '' : vehiclePosition?.postalCode;
    let secondpartVehicle = vehiclePosition?.city === null ? '' : vehiclePosition?.city;
    const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle;

    return (
        <Card id="CardBodyCardAllowance">
            <CardBody>
                <span>
                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>VEHICLE LOCATION</span>
                </span>
                <br /><br />
                <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeLight' }}>
                    {vehiclePosition.street}
                </span> <br />
                <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeLight' }}>
                    {postalCodeValueVehicle == 'undefined undefined' ? '' : postalCodeValueVehicle}
                </span> <br />
                <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeLight' }}>
                    {vehiclePosition.country}
                </span> <br />
                <br />
            </CardBody>
        </Card>
    );
};

export default VehicleLocationCard;