import {createStore, applyMiddleware} from 'redux'
import rootReducer from 'src/redux/root-reducer'
import thunk from "redux-thunk";

import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares, thunk));

export const persistor = persistStore(store);

export default { store, persistStore };


// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// import Reducers from "./Reducers";

// export function configureStore(InitialState) {
//   const Store = createStore(
//     Reducers,
//     InitialState,
//     composeWithDevTools(applyMiddleware(thunk))
//   );
//   return Store;
// }