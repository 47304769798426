import React from 'react';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { whiteColor } from 'src/assets/jss/GlobalStyles';
import OkButton from 'src/components/buttons/ok-button.component';
import CancelButton from 'src/components/buttons/cancel-button.component';
import ContinueButton from 'src/components/buttons/continue-button.component';

const useStyles = makeStyles((theme) => ({
  title: {
      fontSize: 'large',
      fontWeight: 'normal',
      fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
      color: whiteColor
  },
  message: {
      color: whiteColor,
      fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
      marginTop: theme.spacing(1)
  },
  dialogAction: {
    color: whiteColor,
    fontFamily: '"LynkcoTypeLight", "LynkcoTypeMedium", "LynkcoTypeRegular"',
    fontWeight: 'normal'
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  paperScrollPaper: {
    backgroundColor: "rgb(19,19,19)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    minHeight: 220,
    BorderStyle: 'solid'
}
}));

export default function AlertDialog({ open, message, handleDeleteDialogCancel, handleDeleteDialogContinue, handleDialogOk, isErrorDialog = false }) {
  const classes = useStyles();

  const handleClose = () => {
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        classes={{ paperScrollPaper: classes.paperScrollPaper }}
        disableScrollLock
      >
    {        
      isErrorDialog ? <DialogTitle id="alert-dialog-title"><span className={classes.title}>{"Sorry"}</span></DialogTitle>
      : <DialogTitle id="alert-dialog-title"><span className={classes.title}>{"Are you sure?"}</span></DialogTitle>
    }       
        <Divider light variant="middle" className={classes.divider}/> 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.message}>{message}</Typography>
          </DialogContentText>
        </DialogContent>
        {
          isErrorDialog ?
            <DialogActions>
              <OkButton handleDialogOk={handleDialogOk}></OkButton>
            </DialogActions>
            :
            <DialogActions>
              <CancelButton handleDeleteDialogCancel={handleDeleteDialogCancel}></CancelButton>
              <ContinueButton handleDeleteDialogContinue={handleDeleteDialogContinue}></ContinueButton>
            </DialogActions>
        }
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  // handleDeleteDialogCancel: PropTypes.func.isRequired,
  // handleDeleteDialogContinue: PropTypes.func.isRequired
};