import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#1ef1c6',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#088a70',
            color: '#FFF'
        }
    }
}));

const ContinueButton = ({ handleDeleteDialogContinue }) => {
    const classes = useStyles();
    return (
        <Button onClick={handleDeleteDialogContinue} className={classes.button} variant="contained">
            Continue
        </Button>
    );
}

export default ContinueButton;

