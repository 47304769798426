import React, { useMemo } from 'react';
import {  GetRVTable } from 'src/services/rvcurve-http.service';


const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   width: 65,
      // },
      // {
      //   Header: 'First name',
      //   accessor: 'first',
      // },
      // {
      //   Header: 'Last name',
      //   accessor: 'last',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Username',
      //   accessor: 'user',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Age',
      //   accessor: 'age',
      //   disableGlobalFilter: true,
      //   Footer: (info) => {
      //     const { rows, flatRows } = info;
      //     const totalAge = useMemo(
      //       () => rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
      //       [rows],
      //     );
      //     const age = Math.round(totalAge / flatRows.length);
      //     return <span>{age}</span>;
      //   },
      // },
      // {
      //   Header: 'Date',
      //   accessor: 'date',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Location',
      //   accessor: 'location',
      //   disableGlobalFilter: true,
      // },
      // {
      //   Header: 'Work',
      //   accessor: 'work',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
      { Header: 'Market', accessor: 'market', Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Model', accessor: "model", Footer: 'Middle age:', disableGlobalFilter: true,  },
      { Header: 'Model Year', accessor: "modelYear", Footer: 'Middle age:', disableGlobalFilter: true,  },
      { Header: 'Version', accessor: "version", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Consumer price ', accessor: "consumerPrice", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Purchase price', accessor: "purchasePrice", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: 'Range (km)', accessor: "rangeKm", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '1', accessor: "_1", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '1 (EUR)', accessor: "_1EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '1 (EUR ex VAT)', accessor: "_1EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '2', accessor: "_2", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '2 (EUR)', accessor: "_2EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '2 (EUR ex VAT)', accessor: "_2EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '3', accessor: "_3", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '3 (EUR)', accessor: "_3EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '3 (EUR ex VAT)', accessor: "_3EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '4', accessor: "_4", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '4 (EUR)', accessor: "_4EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '4 (EUR ex VAT)', accessor: "_4EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '5', accessor: "_5", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '5 (EUR)', accessor: "_5EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '5 (EUR or VAT)', accessor: "_5EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '6', accessor: "_6", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '6 (EUR)', accessor: "_6EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '6 (EUR or VAT)', accessor: "_6EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '7', accessor: "_7", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '7 (EUR)', accessor: "_7EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '7 (EUR or VAT)', accessor: "_7EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '8', accessor: "_8", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '8 (EUR)', accessor: "_8EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '8 (EUR or VAT)', accessor: "_8EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '9', accessor: "_9", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '9 (EUR)', accessor: "_9EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '9 (EUR or VAT)', accessor: "_9EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '10', accessor: "_10", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '10 (EUR)', accessor: "_10EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '10 (EUR or VAT)', accessor: "_10EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '11', accessor: "_11", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '11 (EUR)', accessor: "_11EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '11 (EUR or VAT)', accessor: "_11EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '12', accessor: "_12", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '12 (EUR)', accessor: "_12EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '12 (EUR or VAT)', accessor: "_12EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '13', accessor: "_13", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '13 (EUR)', accessor: "_13EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '13 (EUR or VAT)', accessor: "_13EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '14', accessor: "_14", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '14 (EUR)', accessor: "_14EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '14 (EUR or VAT)', accessor: "_14EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '15', accessor: "_15", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '15 (EUR)', accessor: "_15EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '15 (EUR or VAT)', accessor: "_15EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '16', accessor: "_16", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '16 (EUR)', accessor: "_16EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '16 (EUR or VAT)', accessor: "_16EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '17', accessor: "_17", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '17 (EUR)', accessor: "_17EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '17 (EUR or VAT)', accessor: "_17EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '18', accessor: "_18", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '18 (EUR)', accessor: "_18EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '18 (EUR or VAT)', accessor: "_18EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '19', accessor: "_19", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '19 (EUR)', accessor: "_19EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '19 (EUR or VAT)', accessor: "_19EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '20', accessor: "_20", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '20 (EUR)', accessor: "_20EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '20 (EUR or VAT)', accessor: "_20EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '21', accessor: "_21", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '21 (EUR)', accessor: "_21EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '21 (EUR or VAT)', accessor: "_21EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '22', accessor: "_22", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '22 (EUR)', accessor: "_22EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '22 (EUR or VAT)', accessor: "_22EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '23', accessor: "_23", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '23 (EUR)', accessor: "_23EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '23 (EUR or VAT)', accessor: "_23EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '24', accessor: "_24", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '24 (EUR)', accessor: "_24EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '24 (EUR or VAT)', accessor: "_24EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '36', accessor: "_36", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '36 (EUR)', accessor: "_36EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '36 (EUR or VAT)', accessor: "_36EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '48', accessor: "_48", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '48 (EUR)', accessor: "_48EUR", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '48 (EUR or VAT)', accessor: "_48EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '60', accessor: "_60", Footer: 'Middle age:', disableGlobalFilter: true},
      { Header: '60 (EUR)', accessor: "_60EUR", Footer: 'Middle age:', disableGlobalFilter: true, },
      { Header: '60 (EUR ex VAT)', accessor: "_60EURExVAT", Footer: 'Middle age:', disableGlobalFilter: true }
    ],
    [],
  );

    

  React.useEffect(() => {
    rows();
    // initializeFleetTypes();
    // setDeleteDialog(false);
    
}, []);
const [dataRows, setVehicleDataFleet] = React.useState([]);

  
  let rows = () => {
    const data = [];
  //   GetRVTable().then(value => {
  //     if (value && Array.isArray(value.tableData)) {
  //       value.tableData.map(vehicle => {
  //         // console.log('...............cghh........', vehicle)

  //         data.push({
  //           market: vehicle.market,
  //           //model: ['model']

  //         }


  //         )
            

  //     });
  //     }  
  //     setVehicleDataFleet(data)
      
  // }); 
  //setVehicleDataFleet(data)
  };


  // const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
  //   - start.getTime()))).toLocaleDateString();

  // const data = [];
  // const rows = () => {
  //   for (let i = 1; i < 36; i += 1) {
  //     data.push({
  //       id: i,
  //       first: ['Maria', 'Bobby  ', 'Alexander'][Math.floor((Math.random() * 3))],
  //       last: ['Morrison', 'Brown  ', 'Medinberg'][Math.floor((Math.random() * 3))],
  //       user: ['@dragon', '@hamster', '@cat'][Math.floor((Math.random() * 3))],
  //       age: Math.min(100, Math.round(Math.random() * 30) + 20),
  //       date: getRandomDate(new Date(2002, 3, 1), new Date(1954, 3, 1)),
  //       location: ['Melbourne', 'Tokio', 'Moscow', 'Rome'][Math.floor((Math.random() * 4))],
  //       work: ['Nova Soft', 'Dog Shop', 'Aspirity', 'Business Bro', 'Starlight'][Math.floor((Math.random() * 5))],
  //       "60 (EUR) ": 17710,
  //       "60 (EUR ex VAT) ": 14882.35  
  //     });
  //   }
  // };


//   React.useEffect(() => {
//     rows();
//     // initializeFleetTypes();
//     // setDeleteDialog(false);
// }, []);
//   const data = [];
//   const rows = () => {
//     GetRVTable().then(data => {
//       if (data && Array.isArray(data.tableData)) {
//         data = data.tableData
//         console.log('...............', data)
//       }   
      
//   }); 
//   };

  //rows();
  // console.log('.....vf...', dataRows )

  const reactTableData = { tableHeaderData: columns, tableRowsData: dataRows };
  // console.log('.....reactTableData...', reactTableData )
  return reactTableData;
};

export default CreateTableData;
