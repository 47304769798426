import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TopbarProfile from 'src/containers/LayoutNew/components/topbar/TopbarProfile';
import TopbarSidebarButton from 'src/containers/LayoutNew/components/topbar/TopbarSidebarButton';
import { TopbarContainer, TopbarLeft, TopbarLogo, TopbarRight, TopbarRightOver, TopbarSearchWrap, TopbarLogoContainer } from 'src/containers/LayoutNew/components/topbar/BasicTopbarComponents';
import TopbarNotification from 'src/containers/LayoutNew/components/topbar/TopbarNotification';
import TopbarMetaMask from 'src/containers/LayoutNew/components/topbar/TopbarMetaMask';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

const Topbar = (props) => {
  const { changeMobileSidebarVisibility, changeSidebarVisibility, adUserDetails, setUserIsLoggedIn, userPermissions } = props;
  const wallet = useSelector(state => state.wallet);
  const [toggleSidebarButton, setToggleSidebarButton] = useState(false);
  const values = [permission.Connectivity_Administrator, permission.Connectivity_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission));

  return (
    <TopbarContainer>
      <TopbarLogoContainer>
        <TopbarLogo to='/vehicles/undefinedStock' />

        <TopbarRight>
          <TopbarSearchWrap>
            {/* <TopbarSearch /> */}
          </TopbarSearchWrap>
          <TopbarRightOver>
            {matchesPermission && <TopbarNotification />}
            <TopbarProfile adUserDetails={adUserDetails} />
            {/* {wallet && <TopbarMetaMask />} */}
            {/* <TopbarHelpLogo /> */}
            {/* <TopbarLanguage /> */}
            {wallet && <TopbarMetaMask />}
          </TopbarRightOver>
        </TopbarRight>
      </TopbarLogoContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          setUserIsLoggedIn={setUserIsLoggedIn}
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
          setToggleSidebarButton={setToggleSidebarButton}
          toggleSidebarButton={toggleSidebarButton}
        />
      </TopbarLeft>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(Topbar);