import React, { useState } from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Icon_Edit_hover from 'src/assets/img/icons/Icon_Edit_hover.png';
import Icon_Edit from 'src/assets/img/icons/Icon_Edit.png';
import Icon_Add from 'src/assets/img/icons/Icon_Add.png';
import Icon_Add_hover from 'src/assets/img/icons/Icon_Add_hover.png';
import Icon_Delete from 'src/assets/img/icons/Icon_Delete.png';
import Icon_Delete_hover from 'src/assets/img/icons/Icon_Delete_hover.png';

export default function EnhancedTableToolbar(props) {
    const [hovered, setHovered] = useState(false);
    const [hoveredPlus, setHoveredPlus] = useState(false);
    const [hoveredDelete, setHoveredDelete] = useState(false);
    const classes = useToolbarStyles();
    const { selectedRowId = '', handleClickDelete, handleClickEdit, handleClickAdd, enableSelect } = props;

    return (
        <Toolbar
            className={clsx(classes.root2, {
                [classes.highlight1]: selectedRowId.length > 0,
            })}
        >

            <div className={classes.tooltipDiv}>
                <Tooltip
                    className={classes.tooltipImg}
                    title="Add"
                    onClick={handleClickAdd}
                    onOpen={() => setHoveredPlus(true)}
                    onClose={() => setHoveredPlus(false)}
                >
                    <IconButton aria-label="filter list">
                        <img
                            className="rvIcons"
                            src={hoveredPlus ? Icon_Add_hover : Icon_Add}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    className={classes.tooltipImg1}
                    title="Edit"
                    onClick={handleClickEdit}
                    onOpen={() => setHovered(true)}
                    onClose={() => setHovered(false)}
                >
                    <IconButton disabled={enableSelect} aria-label="edit">
                        <img
                            className="rvIcons"
                            src={hovered ? Icon_Edit_hover : Icon_Edit}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    className={classes.tooltipImg1}
                    title="Delete"
                    onClick={handleClickDelete}
                    onOpen={() => setHoveredDelete(true)}
                    onClose={() => setHoveredDelete(false)}
                >
                    <IconButton disabled={enableSelect} aria-label="delete">
                        <img
                            className="rvIcons"
                            src={hoveredDelete ? Icon_Delete_hover : Icon_Delete}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};