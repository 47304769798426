import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card } from 'reactstrap';

const UseStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        overflowX: 'hidden',
        height: '150px',
        backgroundColor: 'transparent',
        marginTop: '-30px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "3px",
            opacity: "0.3",
            backgroundColor: '#212121'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.1)"
        }
    }
}));

const UnsuccessfulVINsList = ({ unSuccessfulVINs }) => {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <table style={{ width: '100%', color: 'white' }}>
                <tr style={{ marginTop: '20px' }}>
                    <td style={{ float: 'left', fontSize: '14px', fontFamily: 'LynkcoTypeBold', color: '#fff', }}>VIN<br /></td>
                    <td style={{ float: 'right', fontSize: '14px', fontFamily: 'LynkcoTypeBold', color: '#fff' }}>REASON</td>
                </tr>
                {
                    unSuccessfulVINs !== undefined &&
                    unSuccessfulVINs.map(value => {
                        return (
                            <tr >
                                <td style={{ float: 'left', fontSize: '14px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{value.vin}<br /></td>
                                <td style={{ float: 'right', fontSize: '14px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{value.message}</td>
                            </tr>
                        )
                    })
                }
            </table>
        </Card>
    );
};

export default UnsuccessfulVINsList;