import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function GetB2BUsedCarStockRuleByMarketAndPnoCode(marketCode, pnoCode) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/B2BUsedCarStockRule/GetB2BUsedCarStockRuleByMarketAndPnoCode?marketCode=${marketCode}&pnoCode=${pnoCode}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function CreateB2BUsedCarStockRule(market, pnoCode, age, mileage, criteria) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "market": market,
            "pnoCode": pnoCode,
            "age": age,
            "mileage": mileage,
            "criteria": criteria
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/B2BUsedCarStockRule/CreateB2BUsedCarStockRule`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}