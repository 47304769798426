import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function GetPermissions() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Module/GetAll`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


////////////////////// QueryRolePermission in Permission Page

export async function QueryRolePermission(id) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(id)
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Role/QueryRolePermission`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

/////////////////////////// Update Role Permission

export async function UpdateRolePermission(role) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(role)
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Role/UpdateRolePermission`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}