import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function CreateSalesSetting(salesSettingGemsMarketId, gemsMarketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "salesSettingGemsMarketId": salesSettingGemsMarketId,
            "gemsMarketIds": gemsMarketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/Query`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function UpdateSalesSetting(gemsMarketIds, salesSetting, verificationType) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            {
                gemsMarketIds,
                salesSetting,
                verificationType
            }
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/Update`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


export async function VerifySalesSetting(gemsMarketIds, salesSetting, type, count) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    let verificationType = type
    let verifyVinCount = count

    let salesSettingRequest =
    {
        gemsMarketIds,
        salesSetting,
    }

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            {
                salesSettingRequest,
                verificationType, 
                verifyVinCount
            }
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/Verify`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

// Update Used car Status - For Sale //

export async function UpdateUsedCar({ usedCars, fleetIds, marketIds }) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ usedCars, fleetIds, marketIds })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/UpdateB2BUsedCarStock`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

// Refresh Number of Vins

export async function refreshNumberofVins(gemsMarketId, organizationId, priceValue, priceRange, variant, verificationType) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)    

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ gemsMarketId, organizationId, priceValue, priceRange, variant, verificationType })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/ComputeVariantVINCount`, options)

        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

// Delete Prodcut Variant

export async function DeleteSalesSettingVariant(gemsMarketIds, salesSetting, verificationType) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            {
                gemsMarketIds,
                salesSetting,
                verificationType
            }
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/DeleteSalesSettingVariant`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


// Query Vehicles in Prodcut Variant

export async function QueryVehiclesInProductVariant(variantIdOfInterest, includeOtherMarketVariantVehicles, marketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            {
                variantIdOfInterest,
                includeOtherMarketVariantVehicles,
                marketIds
            }
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/SalesSetting/QueryVehiclesInProductVariant`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}