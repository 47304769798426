import React, { Fragment, useState } from "react";
import { Input, CustomInput, } from "reactstrap";
//import todoDetails from "../../redux/todoDetails";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { DeleteSelectedTodo, TransformTodoData, AddTodoData, UpdateSelectedTodo } from 'src/utils/TodoFormatter';
import { SaveTodo, fetchUserTodos } from 'src/services/todo-http.service';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import { Form, FormGroup, Label, Button, ModalBody, ModalFooter, Col, Row } from "reactstrap";
import SearchButton from 'src/components/buttons/search-button-component';
import { updateTodo, todoCompleted, fetchTodosSuccess } from "../../redux/todos/Action";
import DropdownSelect from 'src/components/form-elements/custom-form-select.componentNew';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#dc1860',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#893257',
      color: '#FFF'
    },
    marginTop: '25px'
  }
}));


const renderDatePicker = ({ dueDate, handleDateChange }) => (
  <DatePicker
    dateFormat="MM/dd/yyyy"
    selected={dueDate}
    onChange={handleDateChange}
    minDate={new Date()}
  />
);

renderDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  dueDate: PropTypes.shape().isRequired,
};

const TodoDetails = ({ user, handleClose, openModal, onButtonClick, dueDate, handleDateChange }) => {
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState('');

  let userId = user.id
  const todoData = useSelector((state) => state.maintodoReducer.todos);

  const todoDetails = useSelector((state) => state.maintodoReducer.todoDetails);
  const selectedTodos = useSelector(
    (state) =>
      todoData[todoDetails - 1]
  );

  const dispatch = useDispatch();


  let title, desc;

  const id = useSelector((state) => state.maintodoReducer.todos.length + 1);

  // const AddTodo = (userId, title, desc, todoData) => {
  //   SaveTodo(userId, AddTodoData(title, desc, todoData))
  //     .then(data => {
  //       if (data && data.tableData && Array.isArray(data.tableData)) {
  //         dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
  //       }
  //     })
  // };

  const UpdateTodo = (userId, id, title, desc, selectedTodos, todoData) => {
    SaveTodo(userId, UpdateSelectedTodo(id, title, desc, selectedTodos, todoData))
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
        }
      })
  };

  const handleChangeUCA = (value) => (event) => {
    // setDisplayUndefinedStock(false);
    // setIsEnableSave(false);
    // marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
    let selected = event.target.value;
    if (value == 'priority') {
      if (selected == 'High') {
      }
      if (selected == 'Medium') {
      }
      if (selected == 'Low') {
      }

      setDisplayValue(event.target.value);
    };
  };

  return (
    <>
      {/* {selectedTodos && !selectedTodos.deleted ? (
        <div className="p-4">
          <div>
            <CustomInput
              type="checkbox"
              id={selectedTodos.id}
              checked={selectedTodos.isCompleted}
              label=" "
              onChange={() => dispatch(todoCompleted(selectedTodos.id))}
            />
            <h5 className="mt-4">Task title</h5>
            <Input
              type="text"
              id="title"
              name="title"
              rows="5"
              value={selectedTodos.title}
              onChange={(e) =>
                dispatch(updateTodo(selectedTodos.id, "title", e.target.value))
              }
            />
            <h5 className="mt-4">Task Description</h5>
            <Input
              type="textarea"
              id="title"
              name="title"
              rows="5"
              value={selectedTodos.desc}
              onChange={(e) =>
                dispatch(updateTodo(selectedTodos.id, "desc", e.target.value))
              }
            />
            <Button            
              onClick={() => {
                SaveTodo(userId, DeleteSelectedTodo(selectedTodos, todoData, selectedTodos.id))
                  .then(data => {
                    if (data && data.tableData && Array.isArray(data.tableData)) {
                      dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
                    }
                  })
              }}
              className={classes.button}
              type="submit"
            >
              Delete
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
          <i className="far fa-address-card display-5"></i>
          <h4 className="mt-2">Please select a Todo to see details.</h4>
        </div>
      )} */}
      {/* <Modal
        // className={drawerClasses.drawarModal}
        variant="persistent"
        anchor="right"
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
      >
        <div style={{
          border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
        }}>
          <div style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
            Select format
          </div>
          
        </div>

        
      </Modal> */}

      <div style={{ width: '100%', marginTop: '-50px' }}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            // AddTodo(userId, selectedTodos?.title, selectedTodos?.desc, todoData)
            // title.value = "";
            // desc.value = "";
          }}
        >
          < >
            <FormGroup>
              <Label for="title" style={{ color: '#fff' }}>Title</Label>
              <Input
                type="text"
                id="title"
                name="title"
                rows="5"
                value={selectedTodos?.title}
                onChange={(e) => {
                  dispatch(updateTodo(selectedTodos?.id, "title", e.target.value))
                  // AddTodo(userId, selectedTodos.title, selectedTodos.desc, todoData)
                }}
                style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6 ', color: '#fff', borderRadius: '20px' }}

              />
            </FormGroup>
            <FormGroup>
              <Label for="desc" style={{ color: '#fff' }}>Description</Label>
              <Input
                type="textarea"
                id="title"
                name="title"
                rows="5"
                value={selectedTodos?.desc}
                onChange={(e) =>
                  dispatch(updateTodo(selectedTodos?.id, "desc", e.target.value))
                }
                style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6 ', color: '#fff', borderRadius: '20px' }}

              />
            </FormGroup>
            <FormGroup className="Datepicker">
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={dueDate}
                onChange={handleDateChange}
                minDate={new Date()}
                // calendarClassName="calendarstyle"
                wrapperClassName="calendercontainer"
                placeholderText={'Please select due date'}
              />
            </FormGroup>
          </>

          <Row>
            <div style={{ width: '100%', marginTop: '-10px', padding: '15px' }}>
              <DropdownSelect
                label='Priority'
                name='priority'
                dropdownfont='true'
                value={displayValue}
                style={{ zIndex: 10 }}
                tableDropdownHeight="true"
                menuObjectArray={[
                  { 'key': 'High', 'value': 'High' },
                  { 'key': 'Medium', 'value': 'Medium' },
                  { 'key': 'Low', 'value': 'Low' }
                ]}
                onChange={handleChangeUCA("priority")}
              />
            </div>
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Col lg="3" md="3">
              <SearchButton
                placeholder={'Edit'}
                handleSearch={() => {
                  handleClose()
                  // AddTodo(userId, selectedTodos.title, selectedTodos.desc,  todoData)
                  UpdateTodo(userId, selectedTodos.id, selectedTodos.title, selectedTodos.desc, selectedTodos, todoData)

                }}

                // handleSearch={onButtonClick}
                height='true'
                loader='true'
              />
            </Col>
            <Col lg="6" md="6"></Col>

            <Col lg="3" md="3">
              <SearchButton
                placeholder={'Cancel'}
                handleSearch={() => handleClose()}
                height='true'
                loader='true'
              />
            </Col>
          </Row>
        </Form>
      </div>

    </>
  );
};

const mapStateToProps = state => ({
  user: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(TodoDetails);

export const FormGroupLabel = styled.span`
  margin-bottom: 4px;
  display: inline-block;
  color: #dddddd;

  span {
    color: #dddddd;
  }
`;

export const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
`;