import React, { useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Tooltip from '@material-ui/core/Tooltip';

const UseStyles = makeStyles({
    root2: {
        overflow: 'auto',
        height: '410px',
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            height: "350px",
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            height: "290px",
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            height: '270px',
        },
        '@media (min-width: 1111px) and (max-width: 1272px)': {
            height: "210px",
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            height: "350px",
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        marginLeft: '1px',
        marginTop: '10px',
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
});

const DriverDetailPage = ({ vehicleDetail, provisionedDated, customerFullName }) => {
    const classes = UseStyles();
    const div = useRef(null);
    const [iconDirection, setIconDirection] = useState(false);

    return (
        <Col md={12} lg={12}>

            <div style={{ marginLeft: '1px', marginRight: '13px' }}>
                <Card id="CardBodyCardAllowanceVSP" >
                    <CardBody>
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginLeft: '-20px', marginTop: '-10px' }}>
                                    <div>
                                        <div className="vspVehicleheaderStyle" style={{ fontFamily: "LynkcoTypeMedium" }} variant="h4" id="tableTitle" component="div">
                                            DRIVER DETAILS
                                        </div>
                                    </div>
                                    <Row className={classes.root2}>
                                        <div ref={div}>
                                            <div ref={div}>
                                                <Row style={{ marginLeft: '-1px', marginRight: '-30px', marginTop: '10px' }} >
                                                    <Col lg="12" md="12" >
                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Customer ID</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.customerDetails?.customerId}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Customer Name</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{customerFullName}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Provisioned Date</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{provisionedDated}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Order Number</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderNumber}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Order Type</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderType}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Order Status</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderStatus}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>End of Contract</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ color: 'rgb(255, 102, 0)', fontFamily: 'LynkcoTypeRegular' }}>Coming soon</span> <br /><br />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '80px' }}  >
                                        <Tooltip title={iconDirection ? "Scroll Up" : "Scroll Down"}
                                            className={classes.rotateIcon}
                                            onClick={() => {
                                                iconDirection ?
                                                    div.current?.scrollIntoView({ behavior: "smooth", alignToTop: "true" }) : div.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' })
                                                setIconDirection(!iconDirection)
                                            }}
                                            style={{ marginTop: '-100px', fontSize: '20px', letterSpacing: '20px', cursor: 'pointer', marginLeft: '50px' }}
                                        >
                                            {
                                                iconDirection ?
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-up mdi-2x" />
                                                    </span> :
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-down mdi-2x" />
                                                    </span>
                                            }
                                        </Tooltip>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(DriverDetailPage);