import { ExportToCsv } from 'export-to-csv';

export default function CSVExport(headers, data, filename='generated'){
    const options = { 
        filename: filename,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers
      };
    const csvExporter = new ExportToCsv(options);
     
    csvExporter.generateCsv(data);
}