import DefaultPage from './pages/backgrounds/default-page';
import DashboardTabs from 'src/components/tabs/dashboard-tabs.component';
import MapPage from 'src/pages/maps/MapPage';
import Todo from 'src/pages/todo/Todo';
import SVTTab from 'src/components/tabs/svt-tabs.component';
import MCCWorkshopTab from 'src/components/tabs/MCCWorkshopTab';
import MapPageMainPage from 'src/pages/maps/MapPageMainPage';
import VSPMainPage from 'src/pages/vsp/VSPMainPage';
import vehicleTabsComponentNew from 'src/components/tabs/vehicle-tabs.component.New';
import reportTabsComponent from 'src/components/tabs/report-tabs.component';
import connectivityTabsComponent from 'src/components/tabs/connectivity-tabs.component';
import rvCurveTabsNewComponent from 'src/components/tabs/rvCurve-tabs-New.component';
import AdministrationTabs from 'src/components/tabs/administration-tabs.componentNew'

const routes = [
  { path: '/', exact: true, name: 'Home', component: vehicleTabsComponentNew },
  { path: '/dashboard', name: 'Dashboards', component: DashboardTabs, exact: true },
  { path: '/vehicles', name: 'Vehicles', component: vehicleTabsComponentNew, exact: true },
  { path: '/locations', name: 'Locations', component: MapPageMainPage, exact: true },
  { path: '/locations/map', name: 'Maps', component: MapPage, exact: true },
  { path: '/locations/geofence', name: 'Geofence', component: DefaultPage, exact: true },
  { path: '/connectivity', name: 'Drivers', component: connectivityTabsComponent, exact: true },
  { path: '/remarketing', name: 'Remarketing', component: rvCurveTabsNewComponent, exact: true },
  { path: '/vsp', name: 'VSP', component: VSPMainPage, exact: false },
  { path: '/reports', name: 'Reports', component: reportTabsComponent, exact: true },
  { path: '/svt', name: 'Stolen Vehicle Tracker', component: SVTTab, exact: true },
  { path: '/mcc', name: 'MCC Workshops', component: MCCWorkshopTab, exact: true },
  { path: '/todos', name: 'Todo', component: Todo, exact: true },
  { path: '/admin', name: 'Administration', component: AdministrationTabs, exact: true },
];

export default routes;