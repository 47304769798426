import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        minWidth: "100%",
        marginTop: "16px",
        marginBottom: "16px",
        backgroundColor: '#222222',
        borderRadius: '1px',
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#1EF1C6'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'white'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(19, 19, 19, .3)',
                // backgroundColor: '#2c2c2c',
                color: 'green',
                whiteSpace: 'normal'
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                // borderColor: '#a3a3a3',
                color: 'green'

            },
            '&:hover fieldset': {
                borderColor: (props) => props.outline ? 'rgba(19, 19, 19, .3)' : '#1EF1C6'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
    },
};

const FormTextField = withStyles(styles)(function (props) {
    const { classes, name, labelText, value, onChange, variant, disabled, multiline, inputProps, outline = false } = props;
    return (<TextField
        margin="dense"
        id={name}
        name={name}
        label={labelText}
        type="text"
        value={value}
        onChange={onChange}
        variant={variant ? variant : "outlined"}
        disabled={disabled ? true : false}
        classes={classes}
        fullWidth
        multiline={multiline}
        inputProps={inputProps}
    />);
})

export default FormTextField;