import React from 'react';
import { Card, CardBody, Row } from "reactstrap";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";


const CoordinatesCard = ({ vehiclePosition }) => (

    <Card id="CardBodyCardAllowance" style={{ marginBottom: '40px' }}>
        <CardBody >
            <span>
                <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>CRIME REFERENCE NO</span> <br /><br />
            </span>

            <table style={{ width: '100%', color: 'white' }}>
               
                <tr>
                    <div style={{ width: '506%', marginTop: '15px', marginLeft: '-15px' }}>
                        <SearchBarProvisioning
                            connectivityColor='true'
                            placeholder={'Reference number'}

                        // handleSearch={handleTab}
                        // handleSearchInput={handleSearchInputSVT}
                        // onKeyPress={textFieldKeypressSVT}
                        // value={searchVIN || selectedVINMap}
                        />
                    </div>
                </tr>
            </table>
        </CardBody>
    </Card>

);

export default CoordinatesCard;
