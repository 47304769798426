import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

const LandedCostInView = () => {
  const optionsdownload = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: "rgba(255, 255, 255, 0.5)",
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
    },
  };
  /*--------------------------------------------------------------------------------*/
  /* Used In Maintenance Dashboard                                                  */
  /*--------------------------------------------------------------------------------*/
  return (
    <Card
      style={{
        borderRadius: '30px',
        boxShadow: '0 5px 6px #000000',
        marginTop: '5px',
        backgroundColor: '#181818',
        marginTop: '3px'
      }}
    >
      <CardBody>
        <div className="d-flex">
          <div className="mr-3 align-self-center">
          </div>
          <div>
            <CardTitle style={{ fontSize: "calc(12px + 0.390625vw)", fontWeight: 600, textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-10px', color: '#b3b3b3' }} >Terminations within 14 days</CardTitle>
            <CardSubtitle style={{ fontSize: "calc(9px + 0.390625vw)", marginLeft: '-10px', color: '#b3b3b3' }}>Upcoming terminations if maintenance not assigned</CardSubtitle>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          <div className="mr-auto">
            <h2 className="dashboardFont">21</h2>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LandedCostInView;