import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';
import FadeLoader from "react-spinners/FadeLoader";

const CarsAtCCC = ({ handlePosition, setShowCCC, disableButtons, setVehicleDisplayFlag, cccCount, setCCCType, vehicleCountCCC, toggleCountCCC, activeMarker, loader, handleCloseMCCLCCTable }) => {

    useEffect(() => {
        if (!disableButtons) {
            setChecked(false);
        }
    }, [disableButtons])

    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        let value = event.target.checked;

        if (value == true) {
            handlePosition('CCC');
            setShowCCC(true);
            setCCCType('CCC');
        } else {
            handlePosition('false');
            setShowCCC(false);
            setVehicleDisplayFlag(false);
            setCCCType('');
            handleCloseMCCLCCTable();
        }

        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(10px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>CARS AT CCC</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        {
                            loader ?
                                <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '10px', marginLeft: '5px' }}>
                                    <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                </td>
                                :
                                <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{checked ? (activeMarker !== null && toggleCountCCC && vehicleCountCCC >= 0 ? vehicleCountCCC : cccCount) : null}<br /></td>
                        }
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} disabled={!disableButtons} onChange={switchHandler} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(CarsAtCCC);