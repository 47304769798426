import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { GetToken } from 'src/auth/userToken';

export async function QueryVehicles(pageNumber, pageSize, fleetIdArray, fleetTypeArray, marketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "fleetIds": fleetIdArray,
            "fleetTypeIds": fleetTypeArray,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/QueryFleetAssetsByFleetTypes`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function GetVehicleDetails({ vins, pageNumber, pageSize, fleetIdArray, marketIds }) {

    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vins": vins,
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "fleetIds": fleetIdArray,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleDetails`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function QueryVehiclesForDownload(fleetIdArray, fleetTypeArray, marketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "fleetIds": fleetIdArray,
            "fleetTypeIds": fleetTypeArray,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/DownloadFleetAssets`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

//ToDo: Not used anymore, remove when the SearchBar 
// and its handlers are removed from the vehicle pages and other pages 
export async function QueryLicensePlate(licensePlate, fleetIdArray, fleetTypeArray, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "licensePlate": licensePlate,
            "fleetIds": fleetIdArray,
            "fleetTypeIds": fleetTypeArray,
            "marketIds": marketIds

        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/SearchByLicensePlate`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////////// To search in Internal Fleet Table

export async function QueryLicensePlateOrVin(licensePlateOrVin, fleetIdArray, fleetTypeArray, marketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "licensePlateOrVin": licensePlateOrVin,
            "fleetIds": fleetIdArray,
            "fleetTypeIds": fleetTypeArray,
            "marketIds": marketIds

        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/SearchByLicensePlateOrVin`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


///////////////////////////////////////  Search by VIN for Provisioning and Un-provisioning  ///////////////////////////////////////

export async function QueryByVIN(licensePlateOrVin, fleetIdArray, fleetTypeArray, marketIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "licensePlateOrVin": licensePlateOrVin,
            "fleetIds": fleetIdArray,
            "fleetTypeIds": fleetTypeArray,
            "marketIds": marketIds

        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/FleetAssetByLicensePlateOrVin`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

///////////////////////////////////////////////////////////////////////////////////////////////////  For M2M Table and Extended Search Component


export async function SearchByExtendedSearch(QueryFleetAssetListRequest) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(QueryFleetAssetListRequest)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/QueryFleetAssets`, options)

        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


/////////////////////////////////////////////////////////////////////////////// Advanced Search count

export async function FleetAssetsExtendedSearchKpis(QueryFleetAssetListRequest) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(QueryFleetAssetListRequest)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/FleetAssetsExtendedSearchKpis`, options)

        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


////////////////////////////////////////////// Vehicle Position in Vehicle List View ////////////////////////////

export async function QueryVehiclePosition(vin, fleetIds, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "fleetIds": fleetIds,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehiclePosition`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////// Work Orders in Vehicle List View ////////////////////////////

export async function QueryWorkOrders(vin, fleetIds, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "fleetIds": fleetIds,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleWorkOrderHistory`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////// Vehicle Health in Vehicle List View ////////////////////////////

export async function QueryVehicleHealth(vin, fleetIds, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "fleetIds": fleetIds,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleMaintennanceStatus`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////// Connectivity Page in Driver Tab ////////////////////////////

export async function GetVehicleCustomer(vin, fleetIds, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "fleetIds": fleetIds,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleCustomer`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////// Get vehicle position by market in Map component //////////////////////

export async function QueryVehiclePositionByMarket(fleetIds, marketIds, userMarketIds, viewTypes, fleetTypeIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "fleetIds": fleetIds,
            "marketIds": marketIds,
            "userMarketIds": userMarketIds,
            "viewTypes": viewTypes,
            "fleetTypeIds": fleetTypeIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehiclePositionByMarket`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////Dummy API For QueryVehiclePositionByMarket ///////////////////

export async function QueryVehiclePositionByMarketDummy(fleetIds, marketIds, marketId) {

    let apiData = {
        "vehiclePosition": {
            "vin": 0,
            "latitude": 0,
            "longitude": 0,
            "vehicleUpdatedAt": "",
            "color": "",
            "odometer": 0,
        },
        "ok": true,
        "errorMessage": ""
    }
    return apiData;

}


////////////////////////////////// Get vehicle position for Map View //////////////////////

export async function QueryVehiclePositionForMapView(vin, fleetIds, marketIds) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "fleetIds": fleetIds,
            "marketIds": marketIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehiclePositionForMapView`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

///////////////////////////////////// Dummy API fir QueryVehiclePositionForMapView /////////////////

export async function QueryVehiclePositionForMapViewDummy(vin, fleetIds, marketIds) {

    let apiData = {
        "vehiclePositions": {
            "vin": 0,
            "latitude": 0,
            "longitude": 0,
        },
        "ok": true,
        "errorMessage": ""
    }
    return apiData;
};

///////////////////////////////////// Get Order Type List ////////////////////////////////////////////////////

export async function GetOrderTypeList() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetOrderTypeList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////////////// Get Vehicle WorkOrder Count By Status

export async function GetVehicleWorkOrderCountByStatus(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleWorkOrderCountByStatus?vin=${vin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////////////////// Get Vehicle Work order Details///////////////////////////////////////

export async function GetVehicleWorkOrderDetails(vin, status) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "status": status
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleWorkOrderDetails`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};


////////////////////////////////////////// Get Order Status List //////////////////////////////////////////////////////////////

export async function GetOrderStatusList() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetOrderStatusList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

///////////////////////////// Get RV Range List   ///////////////////////////////////////////////

export async function GetRVRangeList() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetRVRangeList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};


//////////////////////////////////////// Get Inventory Status List ////////////////////////////////////////////////


export async function GetInventoryStatusList() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetInventoryStatusList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

//////////////////////////////////// Get Last Mile Status List ////////////////////////////////////////////////////////


export async function GetLastMileStatusList() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    // headers.append("Authorization", bearer);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetLastMileStatusList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////// Get Vehicle Model Years ////////////////////////////////////////////////////

export async function GetVehicleModelYears() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleModelYears`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

///////////////////////////////////// Get Upcoming Car Swap /////////////////////////////////////////

export async function GetUpcomingCarSwap(fleetTypeIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "fleetTypeIds": fleetTypeIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetUpcomingCarSwap`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

/////////////////////////////////// Get Commercail Cost //////////////////////////////////

export async function GetCommercialCost(fleetTypeIds) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "fleetTypeIds": fleetTypeIds
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetCommercialCost`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////// Get Vehicle Details for Price Chart

export async function GetVehiclePriceChart(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehiclePriceChart?vin=${vin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////////////// Get Vehicle Details in Provisioning, Unprovisioning and Who's Connected Page

export async function GetVehicleByVinOrLicensePlate(licensePlateOrVin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetAsset/GetVehicleByVinOrLicensePlate?licensePlateOrVin=${licensePlateOrVin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};