import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { setTheme } from 'src/redux/settings/Action';
import ReactTableDnDBody from './ReactTableDnDBody';

const ReactTableDefaultBodyCashCars = ({ page, getTableBodyProps, prepareRow, rowIndexState,
  setRowIndexState, rowBackGroundColor, setRowBackGroundColor, setdrawerToggle, 
  drawerToggle, setRowBorderColor, rowBorderColor }) => (

  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {
      page.map((row) => {
        let newIndex = row.index
        prepareRow(row);
        return (
          <tr className="tr-hover"
            {...row.getRowProps({
              onClick: (e) => {
                if (row) {
                  if (row.index == drawerToggle) {
                    setRowBackGroundColor('');
                    setRowBorderColor('');
                    setdrawerToggle(null);
                  }
                  else {
                    setRowIndexState(newIndex);
                    setRowBackGroundColor('#2c2c2c');
                    setRowBorderColor('solid 0.3px #595959')
                    setdrawerToggle(row.index);
                  }
                }
                else {
                  setRowBackGroundColor('');
                  setRowBorderColor('');
                }
              },
             
            })}
          >
            {row.cells.map(cell => (
              cell.getCellProps().key.toLocaleLowerCase().includes("rvvalue") ? 
              row.values.belowLandedCost ? 
              <td className="td1 lynkcoRed" {...cell.getCellProps()} >{cell.render('Cell')} </td>
              : <td className="td1" {...cell.getCellProps()} >{cell.render('Cell')} </td>
              : cell.getCellProps().key.toLocaleLowerCase().includes("belowlandedcost") ? 
              <></>
              : <td className="td1" {...cell.getCellProps()} >{cell.render('Cell')} </td>

            ))}
          </tr>
        );
      })}
  </tbody>
);

ReactTableDefaultBodyCashCars.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBodyVehiclePageCashcars = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, selectedRowValues,
  rowBackGroundColor, rowIndexState, setRowIndexState, setRowBackGroundColor, handleDrawar, 
  setdrawerToggle, drawerToggle, closeHandleDrawer, rowBorderColor, setRowBorderColor
}) => (

  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          rowIndexState={rowIndexState}
          rowBackGroundColor={rowBackGroundColor}
          setRowIndexState={setRowIndexState}
          setRowBackGroundColor={setRowBackGroundColor}
          // handleDrawar={handleDrawar}
          setdrawerToggle={setdrawerToggle}
          drawerToggle={drawerToggle}
          // closeHandleDrawer={closeHandleDrawer}
          rowBorderColor={rowBorderColor}
          setRowBorderColor={setRowBorderColor}
          selectedRowValues={selectedRowValues}
        // theme={theme}
        />
      ) : (
        <ReactTableDefaultBodyCashCars
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rowIndexState={rowIndexState}
          setRowBackGroundColor={setRowBackGroundColor}
          rowBackGroundColor={rowBackGroundColor}
          setRowIndexState={setRowIndexState}
          // handleDrawar={handleDrawar}
          setdrawerToggle={setdrawerToggle}
          drawerToggle={drawerToggle}
          // closeHandleDrawer={closeHandleDrawer}
          rowBorderColor={rowBorderColor}
          setRowBorderColor={setRowBorderColor}
          selectedRowValues={selectedRowValues}
        />
      )}
  </Fragment>
);

ReactTableBodyVehiclePageCashcars.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  ReactTableDefaultBody: PropTypes.func.isRequired,
};

export default ReactTableBodyVehiclePageCashcars