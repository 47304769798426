import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import MarketSelect from 'src/components/form-elements/market-select.dashboard.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { makeStyles } from '@material-ui/core/styles';

import {
  TotalExpected,
  PDI,
  RFP,
  InTransit,
  LccAvailable,
  LccInfleeted,
  MccAvailable,
  PendingForVin
} from "src/components/dashboard/index.js";

const UseStyles = makeStyles({
  root: {
    overflow: 'auto', height: '96vh', backgroundColor: 'transparent', marginTop: '-10px', padding: '40px 0 10px 0',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "0.1px",
      opacity: "0.3",
      backgroundColor: 'rgb(19 19 19/ 1%)'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      backgroundColor: 'rgb(19 19 19/ 1%)',
      border: 'none'
    },
  },
});

const AssetMovementDashboard = ({ userOrg, userMarketIds }) => {
  const classes = UseStyles();

  const [isMarketSelected, setIsMarkedSelected] = useState(false);

  let selectedMarket = userMarketIds.toString();
  const [hideLabel, setHideLabel] = useState('');

  let marketId = 'ALL';

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketNmae = value.split('#')[1];
    setHideLabel(event.target.value);

    if (marketId == 'ALL') { selectedMarket = userMarketIds }
    else {
      selectedMarket = [marketId];
      setIsMarkedSelected(true);
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ marginTop: '30px', width: '15%' }}>
      <MarketSelect
          orgId={userOrg.id}
          value={selectedMarket.value}
          userMarketIds={userMarketIds}
          handleChange={handleChange}
          adminpage='true'
          placeholder='All Markets'
          hideLabel={hideLabel}
          isStandardMarket="true"
        />
      </div>

      {/* --------------------------------------------------------------------------------*/}
      {/* Row-1                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="3" md="12">
          <PDI />
        </Col>
        <Col lg="3" md="12">
          <RFP />
        </Col>
        <Col lg="3" md="12">
          <InTransit />
        </Col>
        <Col lg="3" md="12" >
          <LccAvailable />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-2                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>

        <Col lg="3" md="12" >
          <LccInfleeted />
        </Col>
        <Col lg="3" md="12" >
          <MccAvailable />
        </Col>
        <Col lg="3" md="12" >
          <TotalExpected />
        </Col>
        <Col lg="3" md="12" >
          <PendingForVin />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(AssetMovementDashboard);