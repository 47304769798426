import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Chart from "react-apexcharts";
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MCCAvailableTable from "src/pages/Tables/DataTable/components/MCCAvailableTable";

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '40%',
    height: '66%',

    flexShrink: 0,
    "&.MuiDrawer-paperAnchorDockedRight": {
      top: '10px'
    }
  },
  drawerPaper: {
    marginRight: '42px',
    marginLeft: '98px',
    height: '84.5%',
    "&.MuiDrawer-paperAnchorRight": {
      top: '225px',
    },
    zIndex: 1,
    backgroundColor: 'rgb(19 19 19 / 93%)',
    display: 'grid',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#212121"
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 2,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 580,
    display: 'flex'
  },
}));

const MccAvailable = () => {
  const [open, setOpen] = React.useState(false);

  const optionsvisitors = {
    labels: ["Black", "Blue"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
              fontSize: "30px",
              fontFamily: "LynkcoTypeMedium",
            },
            total: {
              show: true,
              label: "Total",
              color: "#99abb4",
              fontSize: "14px",
              fontFamily: "LynkcoTypeRegular",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    chart: {
      events: {
        click: function (event, chartContext, config) {
          handleDrawar();
        }
      }
    },
    colors: ["#373737", "#50C2F7",],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };

  const optionsvisitors1 = {
    labels: ["Without Towbar", "With Towbar"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
              fontSize: "30px",
              fontFamily: "LynkcoTypeMedium",
            },
            total: {
              show: true,
              label: "Total",
              color: "#99abb4",
              fontSize: "14px",
              fontFamily: "LynkcoTypeRegular",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    chart: {
      events: {
        click: function (event, chartContext, config) {
          handleDrawar();
        }
      }
    },
    colors: ["#354D4F", "#1EF1C6",],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };
  const seriesvisitors = [63, 95];
  const seriesvisitors1 = [48, 110];
  const seriesvisitors2 = [43, 52];

  const handleDrawar = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const drawerClasses = useDrawerStyles();

  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Asset Movement Dashboard                                               */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >      <CardBody>
        <CardTitle>MCC Available</CardTitle>
        <CardSubtitle className="mb-3">
        </CardSubtitle>
        <Chart
          options={optionsvisitors1}
          series={seriesvisitors}
          type="donut"
          height="180px"

        />
        <CardSubtitle style={{ marginBottom: '30px', textAlign: 'center' }}>Cars and Towbars</CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors1}
          type="donut"
          height="180px"
        />
        <CardSubtitle style={{ marginBottom: '30px', textAlign: 'center' }}>Cars per Colour</CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors2}
          type="donut"
          height="180px"
        />
        <CardSubtitle style={{ marginBottom: '30px', textAlign: 'center' }}>Towbars per Car Colour</CardSubtitle>
      </CardBody>
      <Drawer
        // className={drawerClasses.drawer}
        variant="persistent"
        anchor="bottom"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <Card>
          <CardBody>
            <MCCAvailableTable handleClose={handleClose} />
          </CardBody>
        </Card>
      </Drawer>
    </Card>
  );
};

export default MccAvailable;