export const CHANGE_CONTENT_VISIBILITY = 'CHANGE_CONTENT_VISIBILITY';
export const CHANGE_MOBILE_CONTENT_VISIBILITY = 'CHANGE_MOBILE_CONTENT_VISIBILITY';

export function changeContentVisibility() {
  return {
    type: CHANGE_CONTENT_VISIBILITY,
  };
}

export function changeMobileContentVisibility() {
  return {
    type: CHANGE_MOBILE_CONTENT_VISIBILITY,
  };
}
