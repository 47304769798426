import React from 'react';
import VehicleLocationCard from 'src/pages/svt-page/VehicleLocationCard';
import CoordinatesCard from 'src/pages/svt-page/CoordinatesCard';
import VehicleMovementCard from 'src/pages/svt-page/VehicleMovementCard';

const SVTCards = ({ vehiclePosition }) => (
    <div className='recovercardsstyle'>
        <VehicleLocationCard vehiclePosition={vehiclePosition} />
        <CoordinatesCard vehiclePosition={vehiclePosition} />
        <VehicleMovementCard vehiclePosition={vehiclePosition} />
    </div >
);

export default SVTCards;
