import React from 'react';
import LoadingViewPlaceHolder from './loadingviewplaceholder';

function LoadingImagePlaceHolder(props) {
  let loaderViewCountFour = props.counter ? [0, 0, 0, 0] : [0, 0, 0, 0, 0, 0, 0, 0]
  let loadViewCounterOne = [0]

  return (
    <div>
      {loadViewCounterOne.map(o =>
        <>
          <table style={{ width: "100%" }}>
            <tr>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '125px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '225px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '15px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
            </tr>
          </table>
        </>
      )}

      <br />

      {loaderViewCountFour.map(o =>
        <>
          <table style={{ width: "100%" }}>
            <tr>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '125px', borderRadius: '10px',
                  margin: '20px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '225px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>

              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '65px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '105px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
              <td>
                <LoadingViewPlaceHolder extraStyles={{
                  height: '10px', width: '175px', borderRadius: '10px',
                  margin: '4px 0'
                }} />
              </td>
            </tr>
          </table>

          <br />

        </>
      )}
    </div>
  );
}

export default LoadingImagePlaceHolder;