import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { QueryVehicles, QueryVehiclesForDownload, QueryLicensePlate, SearchByExtendedSearch, QueryVehiclePosition } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service'
import { GetFleetTypes } from 'src/services/fleetType-http.service'
import { UpdateUsedCar } from 'src/services/sales-settings-http.service'
import { FormmatDate } from 'src/utils/DateFormatter';
import { Typography } from '@material-ui/core';
import EnhancedVehicleTableToolbar from 'src/components/tables/assetmovement-table-toolbar.component'
import ExtendedSearch from 'src/components/form-elements/extended-search.component';
// import CSVExport from 'src/services/csv-export.service';
import TablePagination from '@material-ui/core/TablePagination';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import VehicleDetailsTabs from 'src/components/tabs/vehicleDetails-tabs.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import swal from 'sweetalert';


let rowsPerPage = 50;

let newPageVariable = 1;

const drawerWidth = '240';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        zIndex: '1',

    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: '40%',
        height: '36%',
        flexShrink: 0,
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '10px'
        }
    },
    drawerPaper: {
        marginRight: '68px',
        height: '260px',
        width: '570px',
        "&.MuiDrawer-paperAnchorRight": {
            top: '585px',
        },
        backgroundColor: 'rgb(19 19 19 / 93%)',
        display: 'grid',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            opacity: "0.3",
            backgroundColor: "#101010"
        },

        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "1px solid transparent",
            backgroundColor: "#212121"
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        zIndex: '1',

    },
    content: {

        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 2,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 580,
        display: 'flex'
    },
}));

const RFPTable = ({ userOrg, userMarketIds, handleClose }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [isSortable, setIsSortable] = useState(false);
    const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
    const [isDisabledEditable, setIsDisabledEditable] = useState(false);
    const [isDisabledResizable, setIsDisabledResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withPagination, setWithPaginationTable] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const classes = useTableStyles();
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState(0);

    const [selected, setSelected] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [rows, setVehicleFleet] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});

    let selectedRowVehiclePostion = {}

    const [earMarked, setEarMarked] = React.useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const [licensePlateInput, setLicensePlateInput] = React.useState('')
    const [newHeight, setNewHeight] = React.useState('450px')
    const [toggleOdometerVisibility, setToggleOdometerVisibility] = React.useState('visible')
    const [toggleBalanceVisibility, setToggleBalanceVisibility] = React.useState('visible')
    const [toggleAgeVisibility, setToggleAgeVisibility] = React.useState('visible')
    const [toggleRVRangeVisibility, setToggleRVRangeVisibility] = React.useState('visible')
    const [toggleRVPercentVisibility, setToggleRVPercentVisibility] = React.useState('visible')
    const [isEnableSave, setIsEnableSave] = React.useState(false);
    const [isColumnHidden, setIsColumnHidden] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [isErrorDialog, setErrorDialogFlag] = React.useState(false);
    const drawerClasses = useDrawerStyles();
    const [shouldRenderMap, setShouldRenderMap] = React.useState(false)
    const [vehiclePosition, setVeiclePosition] = React.useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = React.useState(false);

    const [fleetVehicle, setFleetVehicle] = React.useState({
        licensePlate: "",
        vin: "",
        model: "",
        modelYear: "",
        engineType: "",
        color: "",
        hasTowbar: "",
        hasWinterTyres: "",
        odometerKm: "",
        rvValue: "",
        registeredDateUtc: "",
        forecastedDefleetDateUtc: "",
        membershipEndDateUtc: "",
        lastMileStatus: "",
        orderStatus: "",
        // fleetType: "",
        rvCurve: "",
        rvRangeKm: "",
        age: "",
        usedCar: "",
        pendingForVIN: "",
        market: "",
        orderType: "",
        orderNumber: "",
        fleetIds: "",
        belowLandedCost: ""

    });
    const [queryParamsOdometer, setQueryParamsOdometer] = React.useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })
    const [queryParamsBalance, setQueryParamsBalance] = React.useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsRVRange, setQueryParamsRVRange] = React.useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsAge, setQueryParamsAge] = React.useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsRVPercent, setQueryParamsRVPercent] = React.useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsEngine, setQueryParamsEngine] = React.useState('')
    const [queryParamsColor, setQueryParamsColor] = React.useState('')
    const [queryParamsVin, setQueryParamsVin] = React.useState('')
    const [queryParamsLicensePlate, setQueryParamsLicensePlate] = React.useState('')
    const [queryParamsOrderNumber, setQueryParamsOrderNumber] = React.useState('')
    const [queryParamsModel, setQueryParamsModel] = React.useState('')
    const [queryParamsHasTowbar, setQueryParamsHasTowbar] = React.useState('')
    const [queryParamsHasWinterTyres, setQueryParamsHasWinterTyres] = React.useState('')

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'Model Year', accessor: "modelYear", disableGlobalFilter: true },
            { Header: 'Engine Type', accessor: "engineType", disableGlobalFilter: true, },
            { Header: 'Color', accessor: "color", disableGlobalFilter: true, },
            { Header: 'Towbar', accessor: "hasTowbar", disableGlobalFilter: true },
            { Header: 'Registered', accessor: "registeredDateUtc", disableGlobalFilter: true },
        ],
        [],
    );

    let queryParams = {}

    React.useEffect(() => {
        initializeFleetVehicles();
        setOpenDialog(false);

    }, []);

    const initializeFleetVehicles = () => {
        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = [];
        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id, userMarketIds)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                fleetTypes.push(data.filter(item => item.name.toLowerCase() == "month to month")[0]['id'])
                            }

                            QueryVehicles(newPageVariable, rowsPerPage, fleetIds, fleetTypes, userMarketIds)
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                }
                                            );
                                        });
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                });
                        })
                }
            })
    };

    const handleClickDownload = () => {
        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = []
        let marketIds = userMarketIds;

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                fleetTypes.push(data.filter(item => item.name.toLowerCase() == "month to month")[0]['id'])
                            }

                            expanded ?
                                SearchByExtendedSearch({ ...queryParams, pageNumber: page, pageSize: rowsPerPage, fleetTypeIds: fleetTypes, fleetIds: fleetIds })
                                    .then(data => {
                                        if (data && Array.isArray(data.items)) {
                                            data.items.map(vehicle => {
                                                vehicleFleetArray.push({
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                });
                                            });
                                        }
                                        initiateDownload(vehicleFleetArray);
                                    })
                                :
                                QueryVehiclesForDownload(fleetIds, fleetTypes, marketIds)
                                    .then(data => {
                                        if (data && Array.isArray(data)) {
                                            data.map(vehicle => {
                                                vehicleFleetArray.push({
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                });
                                            });
                                        }
                                        initiateDownload(vehicleFleetArray);
                                    })
                        })
                }
            })
    }

    const initiateDownload = (rows) => {

        const fileName = 'DRP Report';

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';

        const header = [

            [
                'License Plate',
                'VIN',
                'Model',
                'Model Year',
                'Engine Type',
                'Color',
                'Towbar',
                'Registered',
            ]

        ]

        const exportToExcel = (rows, fileName) => {

            const ws = XLSX.utils.book_new();

            XLSX.utils.sheet_add_aoa(ws, header);

            XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            const data = new Blob([excelBuffer], { type: fileType });

            FileSaver.saveAs(data, fileName + fileExtension);

        }

        exportToExcel(rows, fileName)

    };

    const handleClickDropdown = () => {
        setExpanded(true);
        setNewHeight('350px')
    }

    const handleClickDropup = () => {
        setExpanded(false);
        setNewHeight('450px')
    }

    //////////////////////////////////////////////////////////////////////

    const handleChangeExtended = (name, filterGroup) => (event) => {
        const value = event.target.value
        console.log(event.target)
        if (filterGroup == 'odometer') {
            setQueryParamsOdometer({ ...queryParamsOdometer, [name]: value })
        }
        else if (filterGroup == 'balance') {
            setQueryParamsBalance({ ...queryParamsBalance, [name]: value })
        }
        else if (filterGroup == 'rvRange') {
            setQueryParamsRVRange({ ...queryParamsRVRange, [name]: value })
        }
        else if (filterGroup == 'age') {
            setQueryParamsAge({ ...queryParamsAge, [name]: value })
        }
        else if (filterGroup == 'rvPercent') {
            setQueryParamsRVPercent({ ...queryParamsRVPercent, [name]: value })
        }
        else if (name == 'engine') {
            setQueryParamsEngine(value)
        }
        else if (name == 'color') {
            setQueryParamsColor(value)
        }
        else if (name == 'vin') {
            setQueryParamsVin(value.trim())
        }
        else if (name == 'licensePlate') {
            setQueryParamsLicensePlate(value.trim())
        }
        else if (name == 'orderNumber') {
            setQueryParamsOrderNumber(value.trim())
        }
        else if (name == 'model') {
            setQueryParamsModel(value)
        }
        else if (name == 'hasTowbar') {
            setQueryParamsHasTowbar(value)
        }
        else if (name == 'hasWinterTyres') {
            setQueryParamsHasWinterTyres(value)
        }
        else {
            queryParams = { ...queryParams, [name]: value }
        }

        queryParams = { ...queryParams, odometer: queryParamsOdometer }
        queryParams = { ...queryParams, balance: queryParamsBalance }
        queryParams = { ...queryParams, rvRange: queryParamsRVRange }
        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, rvPercent: queryParamsRVPercent }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, hasTowbar: queryParamsHasTowbar }
        queryParams = { ...queryParams, hasWinterTyres: queryParamsHasWinterTyres }

    }

    const handleExtendedSearch = () => {
        queryParams = { ...queryParams, odometer: queryParamsOdometer }
        queryParams = { ...queryParams, balance: queryParamsBalance }
        queryParams = { ...queryParams, rvRange: queryParamsRVRange }
        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, rvPercent: queryParamsRVPercent }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, hasTowbar: queryParamsHasTowbar }
        queryParams = { ...queryParams, hasWinterTyres: queryParamsHasWinterTyres }

        console.log(queryParams)

        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = [];
        let marketIds = userMarketIds;

        GetFleets(userOrg.id, userMarketIds)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                fleetTypes.push(data.filter(item => item.name.toLowerCase() == "month to month")[0]['id'])
                            }
                            queryParams = { ...queryParams, pageNumber: page, pageSize: rowsPerPage, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds }
                            SearchByExtendedSearch(queryParams)
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                hasWinterTyres: vehicle.hasWinterTyres,
                                                odometerKm: vehicle.odometerKm,
                                                rvValue: vehicle.rvDetails.rvValue,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                lastMileStatus: vehicle.lastMileStatus,
                                                orderStatus: vehicle.orderStatus,
                                                age: vehicle.age,
                                                usedCar: vehicle.usedCar,
                                                pendingForVIN: vehicle.pendingForVIN,
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                });
                        })
                }
            })

    }

    const handleConditionalChoice = name => (event) => {
        const value = event.target.value
        const stateUpdateMethods = {
            odometer: setToggleOdometerVisibility,
            balance: setToggleBalanceVisibility,
            age: setToggleAgeVisibility,
            rvRange: setToggleRVRangeVisibility,
            rvPercent: setToggleRVPercentVisibility
        }

        if (value === 'less than') {
            if (name === 'odometer') {
                setQueryParamsOdometer({ ...queryParamsOdometer, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            else if (name === 'balance') {
                setQueryParamsBalance({ ...queryParamsBalance, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            else if (name === 'rvRange') {
                setQueryParamsRVRange({ ...queryParamsRVRange, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            else if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            else if (name === 'rvPercent') {
                setQueryParamsRVPercent({ ...queryParamsRVPercent, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            stateUpdateMethods[name]('hidden')
        }
        else if (value === 'greater than') {
            if (name === 'odometer') {
                setQueryParamsOdometer({ ...queryParamsOdometer, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            else if (name === 'balance') {
                setQueryParamsBalance({ ...queryParamsBalance, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            else if (name === 'rvRange') {
                setQueryParamsRVRange({ ...queryParamsRVRange, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            else if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            else if (name === 'rvPercent') {
                setQueryParamsRVPercent({ ...queryParamsRVPercent, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            stateUpdateMethods[name]('hidden')
        }
        else if (value === 'between') {
            if (name === 'odometer') {
                setQueryParamsOdometer({ ...queryParamsOdometer, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            else if (name === 'balance') {
                setQueryParamsBalance({ ...queryParamsBalance, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            else if (name === 'rvRange') {
                setQueryParamsRVRange({ ...queryParamsRVRange, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            else if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            else if (name === 'rvPercent') {
                setQueryParamsRVRange({ ...queryParamsRVPercent, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            stateUpdateMethods[name]('visible')
        }
        else {
            if (name === 'odometer') {
                setQueryParamsOdometer({ ...queryParamsOdometer, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            else if (name === 'balance') {
                setQueryParamsBalance({ ...queryParamsBalance, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            else if (name === 'rvRange') {
                setQueryParamsRVRange({ ...queryParamsRVRange, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            else if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            else if (name === 'rvPercent') {
                setQueryParamsRVRange({ ...queryParamsRVPercent, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            stateUpdateMethods[name]('visible')
        }
    }

    /////////////////////////////////////////////////////////////////////

    const closeHandleDrawer = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const handleDone = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFleetVehicle({ ...fleetVehicle, [name]: value });
    };

    const handleChangePage = (event, newPage) => {
        newPageVariable = newPage + 1
        setPage(newPage);
        initializeFleetVehicles();

    };

    const handleChangeRowsPerPage = (event) => {
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        initializeFleetVehicles();
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    const handleClickIsEditable = () => {
        setIsEnableSave(true);
        setIsColumnHidden(true);
        initializeFleetVehicles();
    };

    const handleClickSave = () => {
        swal({
            title: "Are you sure?",
            text: "You are about to update a vehicle to Used car Stock!",
            icon: "warning",
            className: "swal-modal",
            buttons: {
                cancel: {
                    text: "NO WAY!",
                    data: false,
                    visible: true,
                    className: "swal-buttons",
                    closeModal: true,
                },
                confirm: ({
                    text: "CONTINUE",
                    value: true,
                    visible: true,
                    className: "swal-button",
                    closeModal: true
                })
            },
        }).then((willSave) => {
            if (earMarked.length <= 0 || earMarked == null) {
                setIsColumnHidden(false);
                setIsEnableSave(false);
                return false;
            }

            let usedCars = earMarked
            let fleetIds = []
            let marketIds = userMarketIds;
            let vehicleFleetArray = [];

            if (willSave) {
                GetFleets(userOrg.id, userMarketIds)
                    .then(data => {
                        if (data && Array.isArray(data)) {
                            data.map(item => {
                                fleetIds.push(item.id);
                            })
                            UpdateUsedCar({ usedCars, fleetIds, marketIds })
                                .then(data => {
                                    if (data && Array.isArray(data)) {
                                        data.map(vehicle => {
                                            vehicleFleetArray.push({
                                                responseMessage: vehicle.responseMessage
                                            });
                                        });
                                    }
                                    setDialogMessage(data.responseMessage);
                                    setIsEnableSave(false);
                                    setIsColumnHidden(false);
                                });
                            setEarMarked([]);
                            initializeFleetVehicles();
                            setOpenDialog(true);
                        }
                    })

            } else {
                setEarMarked([]);
                initializeFleetVehicles();
                setIsColumnHidden(false);
                setIsEnableSave(false);
            }

        });
    }

    const handleClickIsResizable = () => {
        setIsEditable(false);
        setWithDragAndDrop(false);
        setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
        setIsDisabledEditable(!isDisabledEditable);
        setIsResizable(!isResizable);
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };
    const handleClickWithDragAndDrop = () => {
        if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setWithDragAndDrop(!withDragAndDrop);
    };
    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const earmarkedRowValues = (row) => {
        let currentRow = earMarked.find((item) => item.id === row.values.vin);
        let usedCar = row.values.usedCar == 'Yes' ? true : false;

        if (currentRow) {
            let filterArray = earMarked.filter((item) => item.id != row.values.vin)
            setEarMarked([...filterArray])
        }
        else {
            setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }])
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            hasWinterTyres: row.values.hasWinterTyres,
            odometerKm: row.values.odometerKm,
            rvValue: row.values.rvValue,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            age: row.values.age,
            usedCar: row.values.usedCar,
            pendingForVIN: row.values.pendingForVIN,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber,
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            hasWinterTyres: row.values.hasWinterTyres,
            odometerKm: row.values.odometerKm,
            rvValue: row.values.rvValue,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            age: row.values.age,
            usedCar: row.values.usedCar,
            pendingForVIN: row.values.pendingForVIN,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber,
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            hasWinterTyres: row.values.hasWinterTyres,
            odometerKm: row.values.odometerKm,
            rvValue: row.values.rvValue,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            age: row.values.age,
            usedCar: row.values.usedCar,
            pendingForVIN: row.values.pendingForVIN,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = selectedRowVehiclePostion.vin
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "altitude": 0,
            "canBeTrusted": false,
            "vehicleUpdatedAt": ""
        }
        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePosition(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true || data.ok == false) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                // vehiclePositionInstance.latitude = data.vehiclePosition.latitude != 0 ? data.vehiclePosition.latitude : data.vehiclePosition.vin == '10023989223924' ? 57.7063469511633 : 52.36024118939894  //To be used for Development only
                                // vehiclePositionInstance.longitude = data.vehiclePosition.longitude != 0 ? data.vehiclePosition.longitude : data.vehiclePosition.vin == '10023989223924' ? 11.940632860416727 : 4.885066435353499 //To be used for Development only
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.altitude = data.vehiclePosition.altitude
                                vehiclePositionInstance.canBeTrusted = data.vehiclePosition.canBeTrusted
                                // vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt != null ? data.vehiclePosition.vehicleUpdatedAt : '2022-03-11T08:36:27Z'
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                setVeiclePosition(vehiclePositionInstance);
                            }
                        })
                }
            })

        setOpen(true);
        setShouldRenderMap(true);
    }

    return (
        <Col md={12} lg={12}>
            <Card style={{ backgroundColor: 'rgb(19 19 19 / 93%)', marginTop: '5px' }} className={clsx(drawerClasses.content, {
                [drawerClasses.contentShift]: open,
            })}>
                <VehiclePageDialog
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    isErrorDialog={isErrorDialog} />
                <CardBody>
                    {expanded ?
                        <ExtendedSearch orgId={userOrg ? userOrg.id : ""}
                            fleetVehicle={fleetVehicle} handleChange={handleChange}
                            handleClose={handleClose} handleDone={handleDone}
                            handleClickDropup={handleClickDropup}
                            handleClickDownload={handleClickDownload}
                            toggleOdometerVisibility={toggleOdometerVisibility}
                            toggleBalanceVisibility={toggleBalanceVisibility}
                            toggleAgeVisibility={toggleAgeVisibility}
                            toggleRVRangeVisibility={toggleRVRangeVisibility}
                            toggleRVPercentVisibility={toggleRVPercentVisibility}
                            handleChangeExtended={handleChangeExtended}
                            handleExtendedSearch={handleExtendedSearch}
                            handleConditionalChoice={handleConditionalChoice}
                        >
                        </ExtendedSearch>
                        :
                        <div style={{ marginRight: '-15px' }}>
                            <EnhancedVehicleTableToolbar selectedRowId={selected}
                                handleClickDownload={handleClickDownload}
                                handleClickDropdown={handleClickDropdown}
                                handleClickIsEditable={handleClickIsEditable}
                                handleClickSave={handleClickSave}
                                isEnableSave={isEnableSave}
                                handleClose={handleClose}
                            />
                        </div>
                    }
                    <div className="react-table__wrapper" style={{ marginTop: '-40px', marginBottom: '30px' }}>
                    <div className="card__title" style={{ marginLeft: '9px', fontSize: '16px' }}>
                            RFP Table
                        </div>
                    </div>
                    <ReactTableBaseVehiclePage
                        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                        columns={columns}
                        data={rows}
                        updateEditableData={updateEditableData}
                        updateDraggableData={updateDraggableData}
                        tableConfig={tableConfig}
                        isColumnHidden={isColumnHidden}
                        isSelected={isSelected}
                        selectedRowValues={selectedRowValues}
                        earmarkedRowValues={earmarkedRowValues}
                        earMarkedRows={earMarked}
                        handleDrawar={handleDrawar}
                        vehiclePosition={vehiclePosition}
                        selected={selected}
                        closeHandleDrawer={closeHandleDrawer}
                    />
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ fontFamily: 'LynkcoTypeLight', marginTop: '45px', color: '#fff' }}>Total Records: {totalItems}</Typography>
                        <TablePagination
                            style={{ marginTop: '30px', flex: 'auto' }}
                            classes={{
                                toolbar: classes.paginationToolbar,
                                caption: classes.paginationCaption
                            }}
                            rowsPerPageOptions={[50, 100, 150, 200]}
                            component="div"
                            count={totalPages * rowsPerPage}
                            rowsPerPage={rowsPerPage}
                            page={newPageVariable - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={<span>Rows per page:</span>} labelDisplayedRows={({ page }) => { return `Page: ${newPageVariable} of ${totalPages}` }}
                        />
                    </div>
                    <Drawer
                        // className={drawerClasses.drawer}
                        variant="persistent"
                        anchor="right"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        classes={{
                            paper: drawerClasses.drawerPaper,
                        }}

                    >
                        <Card>
                            <CardBody>
                                <VehicleDetailsTabs vehiclePosition={vehiclePosition} closeHandleDrawer={closeHandleDrawer} shouldRenderMap={shouldRenderMap} />
                            </CardBody>
                        </Card>
                    </Drawer>
                </CardBody>
            </Card>
        </Col>
    );
};

RFPTable.propTypes = {
    reactTableData: PropTypes.shape({
        tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })),
        tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(RFPTable);