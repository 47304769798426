import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Modal from "@mui/material/Modal";
import WorkOrderDetail from "src/pages/vsp/WorkOrderDetail";
import { GetVehicleWorkOrderDetails } from 'src/services/vehicle-pages-http.service';
import Link from '@material-ui/core/Link';

const UseStyles = makeStyles({
    drawer: {
        width: '100%',
        flexShrink: 0,
        marginTop: '100px',
        alignItems: 'center',
        position: 'absolute',
        height: '460px',
        display: 'flex',
        justifyContent: 'center'
    },
    link: {
        color: 'white',
        position: 'relative',

        '&:before': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '2px',
            bottom: '-20px',
            left: '50%',
            transform: 'translate(-50%,0%)',
            backgroundColor: '#DC1860',
            visibility: 'hidden',
            transition: 'all 0.3s ease-in-out',
        },
        '&:hover:before': {
            visibility: 'visible',
            width: '60%',
        },
    },
});

const WorkOrder = (props) => {
    const { getWorkOrderStatus, vin, statuses } = props
    const [openModal, setOpenModal] = useState(false);
    const [workOrderDetails, setWorkOrderStatus] = useState([]);
    const [statusName, setStatusName] = useState('');

    const classes = UseStyles();

    const handleDrawar = (status) => {

        if (status === 'str') {
            GetVehicleWorkOrderDetails(vin.trim(), status)
                .then(data => {
                    if (data.ok == true) {
                        setOpenModal(true);
                        setWorkOrderStatus(data.vehicleWorkOrderDetails);
                        setStatusName('Started');
                    }
                    if (getWorkOrderStatus.str == 0) {
                        setOpenModal(false);
                    }
                })
        }

        if (status === 'asg') {
            GetVehicleWorkOrderDetails(vin.trim(), status)
                .then(data => {
                    if (data.ok == true) {
                        setOpenModal(true);
                        setWorkOrderStatus(data.vehicleWorkOrderDetails);
                        setStatusName('Assigned');
                    }
                    if (getWorkOrderStatus.asg == 0) {
                        setOpenModal(false);
                    }
                })
        }

        if (status === 'fnl') {
            GetVehicleWorkOrderDetails(vin.trim(), status)
                .then(data => {
                    if (data.ok == true) {
                        setOpenModal(true);
                        setWorkOrderStatus(data.vehicleWorkOrderDetails);
                        setStatusName('Finalized');
                    }
                    if (getWorkOrderStatus.fnl == 0) {
                        setOpenModal(false);
                    }
                })
        }

        if (status === 'upc') {
            GetVehicleWorkOrderDetails(vin.trim(), status)
                .then(data => {
                    if (data.ok == true) {
                        setOpenModal(true);
                        setWorkOrderStatus(data.vehicleWorkOrderDetails);
                        setStatusName('Upcoming');
                    }
                    if (getWorkOrderStatus.upc == 0) {
                        setOpenModal(false);
                    }
                })
        }

        if (status === 'prp') {
            GetVehicleWorkOrderDetails(vin.trim(), status)
                .then(data => {
                    if (data.ok == true) {
                        setOpenModal(true);
                        setWorkOrderStatus(data.vehicleWorkOrderDetails);
                        setStatusName('Proposed');
                    }
                    if (getWorkOrderStatus.prp == 0) {
                        setOpenModal(false);
                    }
                })
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };


    return (
        /*--------------------------------------------------------------------------------*/
        /* Used In VSP & Vehicle Detail Pages                                             */
        /*--------------------------------------------------------------------------------*/
        <Card className={statuses ? "workOrderStyle" : "workOrderStyleVSP"} >

            <CardBody style={{ marginTop: '3px', marginRight: '-70px', marginLeft: '-22px' }}>
                <div className="vspVehicleheaderStyle" style={{ fontFamily: "LynkcoTypeMedium", color: '#787985', marginBottom: '10px' }}>WORK ORDERS</div>
                {
                    statuses ?

                        <Grid container spacing={1} style={{ marginLeft: '-40px' }} >
                            <Grid item xs={4} md={4} lg={4}>
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('asg')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.asg}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: "LynkcoTypeMedium" }}>ASSIGNED</div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('str')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.str}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: "LynkcoTypeMedium" }}>STARTED</div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('fnl')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.fnl}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: "LynkcoTypeMedium" }}>FINALIZED</div>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={10} style={{ marginLeft: '-80px', marginTop: '-60px' }}>
                            <Grid item xs={1} md={1} lg={1} >
                            </Grid>
                            <Grid item xs={2} md={2} lg={2} >
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('upc')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.upc}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>UPCOMING</div>
                            </Grid>
                            <Grid item xs={2} md={2} lg={2} >
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('prp')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.prp}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>PROPOSED</div>
                            </Grid>
                            <Grid item xs={2} md={2} lg={2} >
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('asg')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.asg}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>ASSIGNED</div>
                            </Grid>
                            <Grid item xs={2} md={2} lg={2} >
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('str')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.str}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>STARTED</div>
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                <Link underline="never" className={classes.link}>
                                    <div className="workorderfontstyle" style={{ fontFamily: "LynkcoTypeMedium" }} onClick={() => handleDrawar('fnl')} variant="h4" id="tableTitle" component="div">
                                        {getWorkOrderStatus.fnl}
                                    </div>
                                </Link>
                                <div className="workordertitleStyle">FINALIZED</div>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} >
                            </Grid>
                        </Grid>
                }

            </CardBody>
            <Modal
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <WorkOrderDetail handleClose={handleClose} workOrderDetails={workOrderDetails} statusName={statusName} />
            </Modal>
        </Card>
    );
};

export default WorkOrder;