import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import Slider from '@mui/material/Slider';

const CustomSliderStyles = {
    width: '9vw',
    marginLeft: '15px',
    '@media (min-width: 1401px) and (max-width: 1600px)': {
        width: '10vw'
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
        width: '9vw'
    },
    '@media (min-width: 1601px) and (max-width: 1800px)': {
        width: '10vw'
    },
    '@media (min-width: 1025px) and (max-width: 1077px)': {
        width: '13.5vw'
    },
    '@media (min-width: 1001px) and (max-width: 1024px)': {
        width: '14.7vw'
    },
    '@media (min-width: 700px) and (max-width: 1000px)': {
        width: '14vw'
    },
    '& .MuiSlider-thumb': {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/icons/SliderIcon.png"})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: 30,
        height: 30,
        boxShadow: '2px 2px 3px 1px rgba(0,0,0, 0.6)',
        borderRadius: '50%'
    },
    '& .MuiSlider-track': {
        color: "#1EF1C6",
        height: 10,
        boxShadow: 'inset 3px 3px 5px rgba(0,0,0,0.8)',
        border: 'none',
        opacity: 1,
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#BFBFBF',
        borderRadius: 20 / 2,
        height: 10,
        boxShadow: 'inset 0px 2px 4px rgba(0,0,0,0.9)',
    },
    '& .MuiSlider-active': {
        color: "gray"
    },
    '& .MuiSlider-markLabel': {
        color: "#BFBFBF",
        fontSize: '16px',
        fontFamily: "LynkcoTypeMedium",
    },
};

const AgeRangeCCard = ({ setQueryParamsAge, setDataLoaded }) => {

    const [queryParamsAge, setQueryParamsAgeCard] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [value, setValue] = React.useState([0, 60]);

    const handleChange = (event, newValue) => {
        if (newValue[0] !== 0 && newValue[1] === 60) {
            setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: newValue[0].toString(), [`endValue`]: '60' });
            setDataLoaded(false);
        };

        if (newValue[0] !== 0 && newValue[1] !== 60) {
            setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: newValue[0].toString(), [`endValue`]: newValue[1].toString() });
            setDataLoaded(false);
        };

        if (newValue[0] == 0 && newValue[1] !== 60) {
            setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: '0', [`endValue`]: newValue[1].toString() });
            setDataLoaded(false);
        };

        if (newValue[0] == 0 && newValue[1] == 60) {
            setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: false, [`startValue`]: '0', [`endValue`]: '0' });
            setDataLoaded(false);
        }

        setValue(newValue);
    }

    const marks = [
        {
            value: value[0],
            label: value[0],
        },

        {
            value: value[1],
            label: value[1],
        },
    ];

    return (
        <Card id="CardBodyCardAllowance1">
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(7px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>AGE [Months]</span> <br /><br />
                </span>
                <Slider
                    valueLabelDisplay="auto"
                    marks={marks}
                    max={60}
                    aria-labelledby="track-inverted-range-slider"
                    value={value}
                    onChange={handleChange}
                    sx={CustomSliderStyles}
                />
            </CardBody>
        </Card>
    );
};

export default AgeRangeCCard;