import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SidebarLink, { SidebarLinkTitle } from 'src/containers/LayoutNew/components/sidebar/SidebarLink';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

const SidebarContent = ({ onClick, collapse }) => {

  return (
    (
      <SidebarContentWrap collapse={collapse}>
        <SidebarBlock collapse={collapse}>
          <SidebarLink title="Dashboards" icon="Dashboard.png" route="/dashboard" onClick={onClick} />
          <SidebarLink title="Vehicles" icon="LynkandCoCar.png" route="/vehicles" onClick={onClick} />
          <SidebarLink title="Location" icon="Location.png" route="/locations" onClick={onClick} />
          <SidebarLink title="Connectivity" icon="Connectivity.png" route="/connectivity" onClick={onClick} />
          <SidebarLink title="Remarketing" icon="Remarketing.png" route="/remarketing" onClick={onClick} />
          <SidebarLink title="VSP" icon="VSP.png" route="/vsp" onClick={onClick} />
          <SidebarLink title="Reports" icon="Report.png" route="/reports" onClick={onClick} />
          {/* <SidebarLink title="My Tasks" icon="ToDo.png" route="/todos" onClick={onClick} /> */}
          <SidebarLink title="Administration" icon="Administration.png" route="/admin" onClick={onClick} />

        </SidebarBlock>
      </SidebarContentWrap>
    )
  )
}

SidebarContent.propTypes = {
  // changeToDark: PropTypes.func.isRequired,
  // changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(SidebarContent);

const SidebarContentWrap = styled.div`
  
  overflow: auto;
  padding-top: 0;
  background: #000;
  // margin-right: 700px;
  border-radius: 13px;
  height: 100vh;
  margin-top: 170px;
  margin-left: -120px;
  width: 26vw;
  @media screen and (min-width: 1026px) and (max-width: 1075px) {
    width: 30vw;
  };
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  };

  @media screen and (min-width: 1451px) and (max-width: 1800px) {
    margin-top: 120px;
  };

  @media screen and (min-width: 1401px) and (max-width: 1450px) {
    margin-top: 80px;
  };

  @media screen and (min-width: 1025px) and (max-width: 1275px) {
    margin-left: -150px;
    margin-top: 100px
  };

  @media screen and (min-width: 1001px) and (max-width: 1024px) {
    margin-left: -100px;
    margin-top: 100px;
    width: 30vw;
  };

  @media screen and (min-width: 771px) and (max-width: 1000px) {
    width: 40vw;
    margin-left: 10px;
  };

  @media screen and (min-width: 600px) and (max-width: 770px) {
    width: 45vw;
    margin-left: -20px;
  };
  
  @media screen and (min-width: 576px) {
    ${props => props.collapse && `
      // width: 55px;
      width: 26vw;
      margin-top: 200px;
      margin-bottom: 65px;
      overflow: visible !important;
      transition: width 0.3s;
      
    `}
  }
`;

const SidebarBlock = styled.ul`
  
  margin-bottom: -5px;
  list-style-type: none;

  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {

    ${props => props.collapse && `

      & > li > a,
      & > li > button {
        overflow: hidden;
        // width: 55px;
        width: 250px;

        
        /*     padding: 31px 18px 35px;*/

        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          left: 70px;
        }
  
        &:hover {
          background: #000;

        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 235px;
        border-bottom: 3px solid #1EF1C6;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;