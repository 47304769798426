import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import DoneButton from 'src/components/buttons/done-button.component';

const UseStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        marginRight: '-32px',
        paddingRight: '20px',
        marginLeft: '23px',
        marginTop: '70px'
    },
    root2: {
        overflow: 'scroll',
        height: '63vh',
        marginLeft: '-30px',

        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },
    gridContainerStyle: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        marginTop: '-17px',
        marginLeft: '-30px',
        display: 'flex'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '-10px'
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            // padding: '16px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
            marginBottom: -30,
            top: -25,
            right: -51,
            overflow: 'auto',
        },
    },
    contentStyle: {
        marginLeft: '1px',
        fontSize: '14px',
        marginBottom: '-10px'
    },
    contentStyle2: {
        marginLeft: '1px',
        fontSize: '14px',
        marginBottom: '-10px'
    },
    contentheader: {
        marginLeft: '-20px',
        marginTop: '3px',
    }
}));

const CommentsDetails = (props) => {
    const { onChange, roles, closeHandleDrawer1, handleClickEdit } = props
    const handleChange = (event) => { onChange(event) };
    const classes = UseStyles(props);

    return (
        <div>
            <Row>
                <Col lg="12" md="12" >
                    <Card className={classes.root} style={{ marginLeft: '80px', marginRight: '20px', borderRadius: '1px' }}>
                        <Row>
                            <Col lg="12" md="12" >
                                <div>
                                    <Grid container className={classes.gridContainerStyle} spacing={1}>
                                        <Grid item xs={11} md={11} lg={11}>
                                        </Grid>
                                        <Grid item xs={1} md={1} lg={1}>
                                            <Toolbar>
                                                <Tooltip title="Close comment" style={{ fontSize: '20px', marginLeft: '58px', marginTop: '-40px', cursor: 'pointer', color: '' }} onClick={closeHandleDrawer1}>
                                                    <span>
                                                        <i className="mr-2 mdi mdi-close" />
                                                    </span>
                                                </Tooltip>

                                            </Toolbar>

                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                        </Row>
                        <CardContent style={{ marginTop: '-78px', marginLeft: '-80px', marginRight: '-60px' }}>
                            <Typography className={classes.contentStyle2} variant="h4" id="tableTitle" component="div">
                                Title
                            </Typography>

                            <div style={{ width: '102%', marginTop: '10px', marginBottom: '30px', marginLeft: '-10px' }}>
                                <FormTextField
                                    name="name"
                                    value={roles?.name}
                                    onChange={handleChange}
                                    disabled
                                    outline='true'
                                />
                            </div>

                            <Typography className={classes.contentStyle2} variant="h4" id="tableTitle" component="div">
                                Created
                            </Typography>

                            <div style={{ width: '102%', marginTop: '10px', marginBottom: '40px', marginLeft: '-10px' }}>
                                <FormTextField
                                    name="name"
                                    value={roles?.createdDate}
                                    onChange={handleChange}
                                    disabled
                                    outline='true'
                                />
                            </div>

                            <Typography className={classes.contentStyle} style={{ marginTop: '20px' }} variant="h4" id="tableTitle" component="div">
                                Description
                            </Typography>
                            <div style={{ width: '102%', marginTop: '10px', marginLeft: '-10px', marginRight: '-10px' }}>
                                <FormTextField
                                    name="description"
                                    onChange={handleChange}
                                    value={roles?.description}
                                    multiline={true}
                                    inputProps={{ style: { height: "355px" } }}
                                />
                            </div>
                        </CardContent>
                        <CardActions classes={{ root: classes.cardActionStyle }} >
                            <DoneButton handleDone={handleClickEdit} placeholder='SAVE' />
                        </CardActions>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(CommentsDetails);

