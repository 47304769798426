import React, { useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { FormmatDate } from 'src/utils/DateFormatter';
import Tooltip from '@material-ui/core/Tooltip';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginTop: '-5px',
        height: '490px',
    },
    root2: {
        overflow: 'auto',
        height: '410px',
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            height: "350px",
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            height: "290px",
        },
        '@media (min-width: 1276px) and (max-width: 1400px)': {
            height: "550px",
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            height: '270px',
        },
        '@media (min-width: 1273px) and (max-width: 1275px)': {
            height: "400px",
        },
        '@media (min-width: 1111px) and (max-width: 1272px)': {
            height: "210px",
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            height: "350px",
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        marginLeft: '1px',
        marginTop: '10px',
        overflowX: 'hidden'
    },
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: theme.spacing(1.2),
        marginRight: -theme.spacing(1.8),
        marginTop: -theme.spacing(5),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        marginLeft: '11px',
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
}));

const VSPVehicleCard = ({ vehicleDetail }) => {
    const classes = UseStyles();
    const [iconDirection, setIconDirection] = useState(false);
    const div = useRef(null);

    return (
        <Col md={12} lg={12}>

            <div style={{ marginLeft: '1px', marginRight: '13px' }} >
                <Card id="CardBodyCardAllowanceVSP" >
                    <CardBody>
                        <Row >
                            <Col lg="12" md="12" >
                                <div style={{ marginLeft: '-20px', marginTop: '-10px' }}>
                                    <div>
                                        <div className="vspVehicleheaderStyle" style={{ fontFamily: "LynkcoTypeMedium" }} variant="h4" id="tableTitle" component="div">
                                            VEHICLE DETAILS
                                        </div>
                                    </div>

                                    <Row className={classes.root2}>
                                        <div ref={div} >
                                            <div >
                                                <Row style={{ marginLeft: '-1px', marginRight: '-30px', marginTop: '10px' }} >
                                                    <Col lg="12" md="12" >
                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>License Plate</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.licensePlate}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Model</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.model}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Model Year</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.modelYear}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>PNO</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.pnoCode}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Engine Type</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.engineType}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Colour</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.color}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>TowBar</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.towbarState}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Registered</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{FormmatDate(vehicleDetail?.vehicleDetails?.registeredDateUtc)}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Age [Months]</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.age}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Odometer</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.odometerKm}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>OBC Version</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.obcVersion}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>Market</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.vehicleDetails?.market}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>MSISDN</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.connectivityDetails?.msisdn}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>First Activation</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{FormmatDate(vehicleDetail?.connectivityDetails?.firstActivationDate)}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>IHU Version</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.connectivityDetails?.ihuVersion}</span> <br /><br />

                                                        <span className="vspVehiclefontsizeStyle" style={{ fontFamily: 'LynkcoTypeLight' }}>TEM Version</span> <br />
                                                        <span className="vspVehiclefontsizeStyleResponse" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.connectivityDetails?.temVersion}</span> <br /><br />

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>

                                    <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '80px' }}  >
                                        <Tooltip title={iconDirection ? "Scroll Up" : "Scroll Down"}
                                            className={classes.rotateIcon}
                                            onClick={() => {
                                                iconDirection ?
                                                    div.current?.scrollIntoView({ behavior: "smooth", alignToTop: "true" }) : div.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' })
                                                setIconDirection(!iconDirection)
                                            }}
                                            style={{ marginTop: '-100px', fontSize: '20px', letterSpacing: '20px', cursor: 'pointer', marginLeft: '50px' }}
                                        >
                                            {
                                                iconDirection ?
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-up mdi-2x" />
                                                    </span> :
                                                    <span>
                                                        <i className="mr-2 mdi mdi-chevron-double-down mdi-2x" />
                                                    </span>
                                            }
                                        </Tooltip>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VSPVehicleCard);
