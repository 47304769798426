import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon_Edit_hover from 'src/assets/img/icons/Icon_Edit_hover.png';
import Icon_Edit from 'src/assets/img/icons/Icon_Edit.png';
import Icon_Upload from 'src/assets/img/icons/Icon_Upload.png';
import Icon_Upload_hover from 'src/assets/img/icons/Icon_Upload_hover.png';
import Icon_Download from 'src/assets/img/icons/Icon_Download.png';
import Icon_Download_hover from 'src/assets/img/icons/Icon_Download_hover.png';
import Icon_Save from 'src/assets/img/icons/Icon_Save.png';
import Icon_Save_hover from 'src/assets/img/icons/Icon_Save_hover.png';

const EnhancedRvCurveTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { handleClickDownload, handleClickUpload, handleClickIsEditable, handleClickSave, isEnableSave } = props;

    const [hoveredUpload, setHoveredOpload] = useState(false);
    const [hoveredDownload, setHoveredDownload] = useState(false);
    const [hoveredEdit, setHoveredEdit] = useState(false);
    const [hoveredSave, setHoveredSave] = useState(false);

    return (
        <div>
            <Toolbar className={classes.root3} >
                <div className={classes.tooltipDiv3}>
                    <Tooltip
                        className={classes.tooltipImg3}
                        title="Upload"
                        onClick={handleClickUpload}
                        style={{ marginTop: '-107px', cursor: 'pointer', zIndex: 20 }}
                        onOpen={() => setHoveredOpload(true)}
                        onClose={() => setHoveredOpload(false)}
                    >
                        <span>
                            <IconButton aria-label="Upload">
                                <img
                                    className="rvIcons"
                                    src={hoveredUpload ? Icon_Upload_hover : Icon_Upload}
                                    alt="RV Image"
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        className={classes.tooltipImg3}
                        title="Download"
                        onClick={handleClickDownload}
                        style={{ marginTop: '-100px', marginLeft: '15px', cursor: 'pointer', zIndex: 20 }}
                        onOpen={() => setHoveredDownload(true)}
                        onClose={() => setHoveredDownload(false)}
                    >
                        <span>
                            <img
                                className="rvIcons"
                                src={hoveredDownload ? Icon_Download_hover : Icon_Download}
                                alt="RV Image"
                            />
                        </span>
                    </Tooltip>
                    {isEnableSave ?
                        <Tooltip
                            className={classes.tooltipImg3}
                            title="save"
                            onClick={handleClickSave}
                            style={{ marginTop: '-110px', marginRight: '-15px', marginLeft: '10px', zIndex: 20 }}
                            onOpen={() => setHoveredSave(true)}
                            onClose={() => setHoveredSave(false)}
                        >
                            <IconButton aria-label="save">
                                <img
                                    className="rvIcons"
                                    src={hoveredSave ? Icon_Save_hover : Icon_Save}
                                    alt="RV Image"
                                />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip
                            className={classes.tooltipImg3}
                            title="Edit"
                            onClick={handleClickIsEditable}
                            style={{ marginTop: '-105px', marginRight: '-15px', marginLeft: '10px', zIndex: 20 }}
                            onOpen={() => setHoveredEdit(true)}
                            onClose={() => setHoveredEdit(false)}
                        >
                            <span>
                                <IconButton aria-label="edit">
                                    <img
                                        className="rvIcons"
                                        src={hoveredEdit ? Icon_Edit_hover : Icon_Edit}
                                        alt="RV Image"
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                </div>
            </Toolbar>
        </div>
    );
};

export default EnhancedRvCurveTableToolbar;