import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '150px',
        marginRight: '7px',
        backgroundColor: '#1ef1c6',
        flex: 'auto', 

        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#088a70',
            color: '#fff',
        }
    }
}));

const UploadButton = ({ handleFile }) => {
    const classes = useStyles();
    return (
        <Button onClick={handleFile}  className={classes.button} variant="contained">
            <Typography>
                Upload
            </Typography>
        </Button>
    );
}

export default UploadButton;

