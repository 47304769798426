import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    zIndex: 10,
    minWidth: "100%",
    backgroundColor: (props) => props.adminpage ? "#272727" : "#000",
    color: (props) => props.adminpage ? "rgba(179, 179, 179, 0.5)" : "white",
    borderRadius: '300px',
    "& .MuiOutlinedInput-input": {
      color: (props) => props.adminpage ? "rgba(179, 179, 179, 0.5)" : "white",
      backgroundColor: (props) => props.adminpage ? "#272727" : "#000",
      borderRadius: '30px',
      fontSize: 'calc(9px + 0.390625vw)',
      '@media (min-width: 600px) and (max-width: 850px)': {
        height: "1px",
        fontSize: (props) => props.isVariant ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.isVariant ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    },
    "& .MuiInputLabel-root": {
      color: (props) => props.adminpage ? "rgba(179, 179, 179, 0.5)" : "white",
      backgroundColor: (props) => props.adminpage ? "#272727" : "#000",
      fontSize: 'calc(9px + 0.390625vw)',
      '@media (min-width: 600px) and (max-width: 850px)': {
        height: "1px",
        fontSize: (props) => props.isVariant ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.isVariant ? 'calc(15px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(255, 255, 255, 0.01)',
      borderRadius: '300px',
    },

    "&:hover .MuiOutlinedInput-input": {
      color: (props) => props.adminpage ? "rgba(179, 179, 179, 0.5)" : "white",
    },
    "&:hover .MuiInputLabel-root": {
      color: (props) => props.adminpage ? "rgba(179, 179, 179, 0.5)" : "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '300px',
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#b3b3b3",
      borderRadius: '300px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#b3b3b3",
      borderRadius: '300px',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '300px',
    }
  },
  dropdownStyle: {
    border: "1px solid black",
    color: (props) => props.adminpage ? "#fff" : "white",
    width: '20px',
    borderRadius: '30px',
    backgroundColor: '#000',
    height: '310px',
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c",
      borderRadius: '30px',
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121",
      borderRadius: '30px',
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000',
      borderRadius: '30px',
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    },
    "& .MuiMenuItem-root": {
      fontSize: 'calc(9px + 0.390625vw)',
      fontFamily: "LynkcoTypeLight",
      '@media (min-width: 600px) and (max-width: 850px)': {
        fontSize: (props) => props.isVariant ? 'calc(17px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 851px) and (max-width: 1110px)': {
        fontSize: (props) => props.isVariant ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      }
    }
  }
}));

const FormSelectSalesSettings = (props) => {
  const { open, onClose, onOpen, value, onChange, menuObjectArray, label, id, name, variant, isVariant = false, disabled = false, adminpage = 'false', hideLabel } = props
  const classes = useStyles(props);

  return (
    <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
      <InputLabel id={`${label}-label`}>{hideLabel === '' ? label : ''}</InputLabel>
      <Select
        labelId={`${label}-label`}
        IconComponent={ChevronDownIcon}
        id={id}
        name={name}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        value={value}
        onChange={onChange}
        disabled={disabled}
        adminpage={adminpage}
        MenuProps={{ classes: { paper: classes.dropdownStyle }, disableScrollLock: true }}
      >
        <MenuItem disabled value="#">
          <em style={{ fontStyle: 'normal' }}>{label} </em>
        </MenuItem>
        {
          menuObjectArray.map((menu, key) => {
            return (<MenuItem key={key} value={menu.key + '#' + menu.value}>{menu.value}</MenuItem>);
          })
        }
      </Select>
    </FormControl>
  );
};

export default FormSelectSalesSettings;