import React from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import SearchButton from "src/components/buttons/button.component";

const VehicleMovementCard = () => (

    <Card id="CardBodyCardAllowance">
        <CardBody >
            <span>
                <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>MOBILISATION CONTROL</span> <br /><br />
            </span>

            <Row style={{ marginBottom: '8px', marginTop: '10px', }}>
                <Col lg="6" md="6">
                    <div style={{ float: 'left' }}>
                        <SearchButton
                            placeholder={'IMMOBILIZE'}
                            iconProp='true'
                        // handleSearch={
                        //     // setDisplaySelectedVehicle(true);
                        //     // setSearchVIN('');
                        //     closeVehicleDetail

                        // }
                        />
                    </div>
                </Col>
                <Col lg="6" md="6">
                    <div style={{ float: 'right', marginLeft: '40px' }}>
                        <SearchButton
                            placeholder={'MOBILIZE'}
                            height='true'
                            widthProp='true'
                        // handleSearch={() => {
                        //     setDisplaySelectedVehicle(true);
                        //     setSearchVIN('');
                        // }
                        // }
                        />
                    </div>
                </Col>
            </Row>
        </CardBody>
    </Card>
);

export default VehicleMovementCard;
