import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        fontFamily:'lynkcoTypeRegular',
        minWidth: "100%",
        marginTop: "16px",
        marginBottom: "16px",

        '& .MuiFormLabel-root.Mui-focused': {
            color: '#1EF1C6'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'unset'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: 'gray'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#a3a3a3',
            },
            '&:hover fieldset': {
                borderColor: '#1EF1C6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1EF1C6',
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: '#a3a3a3',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
};

const FormEmailField = withStyles(styles)(function (props) {
    const { classes, name, labelText, value, onChange, variant, disabled } = props;
    return (<TextField
        margin="dense"
        id={name}
        name={name}
        label={labelText}
        type="email"
        value={value}
        onChange={onChange}
        variant={variant ? variant : "outlined"}
        disabled={disabled ? true : false}
        classes={classes}
        fullWidth
    />);
})

export default FormEmailField;