import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import FleetTypeSelect from 'src/components/form-elements/fleet-type-select.component';
import FleetSelect from 'src/components/form-elements/fleet-select.component';
import DoneButton from 'src/components/buttons/done-button.component';

const useStyles = makeStyles((theme) => ({
    presentation: {
        height: theme.spacing(60)
    },
    cardStyle: {
        "&.MuiCard-root": {
            overflow: 'hidden',
            height: '100%',
            position: 'relative'
        }
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            padding: '8px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    }
}));

const InfleetingAddEdit = ({ orgId, fleetVehicle, onChange, handleClose, handleDone }) => {
    const handleChange = (event) => {
        onChange(event);
    };

    const classes = useStyles();

    return (
        <div role="presentation" className={classes.presentation}>
            <Card classes={{ root: classes.cardStyle }}>
                <CardHeader title={fleetVehicle.editMode ? "Edit Vehicle" : "Add Vehicle"} />
                <Divider />
                <CardContent>
                    <FormTextField name="vin" labelText="Vin" value={fleetVehicle.vin}
                        disabled={fleetVehicle.editMode} onChange={handleChange}></FormTextField>
                    {
                        fleetVehicle.editMode ?
                            <FormTextField name="licensePlate" labelText="License Plate"
                                value={fleetVehicle.licensePlate} onChange={handleChange} disabled={fleetVehicle.editMode} />
                            :
                            <div></div>
                    }
                    {
                        fleetVehicle.editMode ?
                            <FleetTypeSelect name="licensePlate" labelText="License Plate"
                                value={fleetVehicle.fleetTypeId} orgId={orgId} onChange={handleChange} disabled={fleetVehicle.editMode} />
                            :
                            <FormTextField labelText="Fleet Type" orgId={orgId}
                                value={'Month to Month'}  onChange={handleChange} disabled={!fleetVehicle.editMode} />
                    }
                    {
                        !fleetVehicle.editMode ?
                            <FleetSelect orgId={orgId} value={fleetVehicle.fleetId} onChange={handleChange} /> :
                            <div></div>
                    }
                </CardContent>
                <CardActions classes={{ root: classes.cardActionStyle }} >
                    <Button variant="contained" onClick={handleClose} color="default">
                        <Typography className={classes.cardFont} component="div">
                            Cancel
                        </Typography>
                    </Button>
                    <DoneButton handleDone={handleDone} placeholder='Done'/>
                </CardActions>
            </Card>
        </div>
    );
}

export default InfleetingAddEdit;