import React from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { GetOtganizations } from 'src/services/organization-http.service'
import { makeStyles } from '@material-ui/core/styles'

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
}));

const OrganizationSelect = ({ value, onChange, variant }) => {

    const [organizations, setOrganizations] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeOrganizations();
    }, [organizations.length]);

    const initializeOrganizations = () => {
        let organizationArray = [];
        GetOtganizations()
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(organization => {
                        organizationArray.push({ key: organization.id, value: organization.name });
                    });
                }
                setOrganizations(organizationArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            className={classes.SelectControl}
            label="Organization"
            id="organization"
            name="organizationId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={organizations}
            variant={variant ? variant : false}
        ></FormSelect>
    )
}

export default OrganizationSelect

