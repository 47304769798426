import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import SendIcon from 'mdi-react/SendOutlineIcon';
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { CreateComment, UpdateComment } from 'src/services/vehicle-comments-http.service';

const ChatField = ({ userEmail, vin, getComments, action, defaultValue, id, setEditComments }) => {

  const [input, setInput] = React.useState('');
  const ref = useRef(null);
  const onClear = () => {
    ref.current.value = "";
  };

  React.useEffect(() => {
    if (action === 'edit') {
      setInput(defaultValue)
    }

  }, [])

  const handleClickEdit = () => {
    let userId = userEmail.id;
    let comment = input;
    let files = '';
    let attachments = '';

    UpdateComment(id, userId, comment, attachments, files, vin).
      then(data => {

        if (data.ok == true) {
          setEditComments(false);
          getComments();
          setInput('');
        }
      })
  }

  const saveComments = () => {
    let userId = userEmail.id;
    let comment = input;
    let files = '';
    CreateComment(userId, comment, files, vin)
      .then(data => {
        if (data.ok == true) {
          getComments();
          setInput('');
          onClear();
        }
      });
  };

  const handleSearchInput = (event) => {
    setInput(event.target.value)
    console.log(event.target.value)
    console.log(event.target.value.length)
  }

  const handleClickAdd = () => {
    if (input !== '' || null) {
      if (action === 'edit') {
        handleClickEdit();
      } else {
        saveComments();
      }
    };
  }


  const textFieldKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClickAdd();
    }
  };



  return (
    <ChatTextField>
      <ChatForm>
        <ChatFieldInput
          type="text"
          name="chatField"
          placeholder="Type here…"
          onChange={handleSearchInput}
          defaultValue={input}
          ref={ref}
          setEditComments={setEditComments}
          onKeyPress={textFieldKeypress}
          autoFocus
        />
        <ChatFieldControls>
          <ChatIconButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleClickAdd();
            }}
          >
            <SendIcon />
          </ChatIconButton>
        </ChatFieldControls>
      </ChatForm>
    </ChatTextField>
  )
};

const mapStateToProps = state => ({
  userEmail: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(ChatField);

// region STYLES

const ChatTextField = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid #33333a;
  margin-top: 165px;

  @media screen and (min-width: 1801px) and (max-width: 1872px) {
    margin-top: 200px;
  };

  @media screen and (min-width: 1501px) and (max-width: 1520px) {
    margin-top: 65px;
  };

  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    margin-top: 90px;
  };

  @media screen and (min-width: 1276px) and (max-width: 1280px) {
    margin-top: 85px;
  };

  @media screen and (min-width: 1111px) and (max-width: 1275px) {
    margin-top: 105px;
  };

  @media only screen and (min-width: 1026px) and (max-width: 1110px) {
    margin-top: 115px;
  };

  @media only screen and (min-width: 1001px) and (max-width: 1025px) {
    margin-top: 155px;
  };

  @media only screen and (min-width: 769px) and (max-width: 1000px) {
    margin-top: 265px;
  };

  @media only screen and (min-width: 600px) and (max-width: 768px) {
    margin-top: 250px;
  };
`;

const ChatForm = styled.form`
  width: 100%;
  position: relative;
  padding: 12px 20px;
`;

const ChatFieldInput = styled(Form.Control)`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  padding-left: 15px;
  padding-right: 65px;
  background-color: #2a2a31;

  border: 1px solid #2a2a31;
  color: #dddddd;

  &:focus, &:hover, &:active {
    border-color: #4ce1b6;
    outline: none;
    box-shadow: none;
  }
`;

const ChatFieldControls = styled.div`
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  right: 35px;
`;

const ChatIconButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: #999999;
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: #4ce1b6;

    }
  }
`;