import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import Map from 'src/components/maps/map-positioning.component';
import { ReverseGeocoding } from 'src/services/geocoder.service';


const UseStyles = makeStyles((theme) => ({
    gridContainerStyle: {
        width: '100%',
        paddingTop: '52px',
        paddingLeft: '10px'
    },
    heading: {
        "&.MuiTypography-root": {
            color: "rgba(255, 255, 255, 0.7)",
            fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium',
            marginLeft: -theme.spacing(0.2),
            marginTop: -theme.spacing(1),
            width: '560px',
            fontSize: '14px'
        }
    },
    topMargin: {
        marginBottom: '5px',
        marginTop: theme.spacing(2),
        fontSize: '14px'
    }
}));

const PositionTracking = (props) => {
    const { positionLat, positionLng, vehicleUpdatedAt, shouldRenderMap, isExpanded } = props
    const [address, setAddress] = React.useState('');
    const classes = UseStyles(props);

    React.useEffect(() => {
        if (shouldRenderMap) {
            ReverseGeocoding(positionLat, positionLng).then((res) => {
                if (res != undefined) {
                    setAddress(res['message'])
                }
            })
        }
    })

    return (
        <div style={{ backgroundColor: 'rgb(19 19 19 / 1%)' }}>
            <Grid container className={classes.gridContainerStyle} >
                <Grid item xs={12} md={12}>
                    <Map positionLat={positionLat} positionLng={positionLng} shouldRenderMap={shouldRenderMap} isExpanded={isExpanded}></Map>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={`${classes.topMargin} `}>{"Address:"} </Typography>
                    <Typography className={`${classes.heading}`}>{address}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={`${classes.topMargin} ${classes.topMargin}`}>Updated at:</Typography>
                    <Typography className={`${classes.heading} `}>{vehicleUpdatedAt}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}


export default PositionTracking;