import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import DoneButton from 'src/components/buttons/done-button.component';

const useStyles = makeStyles((theme) => ({
    presentation: {
        height: theme.spacing(60)
    },
    cardStyle: {
        "&.MuiCard-root": {
            overflow: 'hidden',
            height: '100%',
            position: 'relative'
        }
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            padding: '8px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    }
}));

const FleetAddEdit = ({ fleet, onChange, handleClose, handleDone }) => {

    const handleChange = (event) => {
        onChange(event);
    };

    const classes = useStyles();

    return (
        <div role="presentation" className={classes.presentation}>
            <Card classes={{ root: classes.cardStyle }}>
                <CardHeader title={fleet.editMode ? "Edit Fleet" : "New Fleet"} />
                <Divider />
                <CardContent>
                    <FormTextField name="name" labelText= {fleet.editMode ? "Edit fleet name" : "Enter fleet name"} value={fleet.name} 
                       onChange={handleChange}></FormTextField>
                </CardContent>
                <CardActions classes={{ root: classes.cardActionStyle }} >
                    <Button variant="contained" onClick={handleClose} color="default">
                        <Typography className={classes.cardFont} component="div">
                            Cancel
                        </Typography>
                    </Button>
                    <DoneButton handleDone={handleDone} placeholder='Done'></DoneButton>
                </CardActions>
            </Card>
        </div>
    );
}

export default FleetAddEdit;