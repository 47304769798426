import React from 'react';
import { Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Map from 'src/components/maps/MapPage.component';
// import { ReverseGeocoding } from 'src/services/geocoder.service';
import { useLoadScript } from "@react-google-maps/api";
import { environment } from 'src/environments/environment';

const UseStyles = makeStyles((theme) => ({
    gridContainerStyle: {
        marginLeft: '-32px',
        marginTop: '-85px'
    }
}));

const MapPage = (props) => {
    const { mapheight, vehiclePosition, setVeiclePosition, selectedVIN, setSelectedVIN, valueVIN, selectedVINMap, setAddressInput, setSelectedVINMap } = props;
    const classes = UseStyles(props);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: environment.googleMapsApiKey,
        libraries: ["places"]
    });

    return (
        <Col md={12} lg={12}>
            <Grid container className={classes.gridContainerStyle} >
                <Grid item xs={12} md={12}>
                    {isLoaded &&
                        <Map
                            mapheight={mapheight}
                            vehiclePositionSVT={vehiclePosition}
                            setVeiclePositionSVT={setVeiclePosition}
                            selectedVIN={selectedVIN}
                            setSelectedVIN={setSelectedVIN}
                            valueVIN={valueVIN}
                            selectedVINMap={selectedVINMap}
                            setAddressInputSVT={setAddressInput}
                            setSelectedVINMap={setSelectedVINMap}
                        />
                    }
                </Grid>
            </Grid>
        </Col>
    );
};

export default MapPage;