import React, { useState } from 'react';
import styled from 'styled-components';
import SidebarRightSide from 'src/containers/LayoutNew/components/sidebar/SidebarRightSide';
import Backdrop from '@mui/material/Backdrop';
import { Squash as Hamburger } from 'hamburger-react';

const icon = `../assets/icons/Menu-icon.png`;

const TopbarSidebarButton = ({ onClickDesktop, onClickMobile, setUserIsLoggedIn, setToggleSidebarButton, toggleSidebarButton }) => {

  const [showSidebar, setShowSidebar] = useState(false);
  const [sliderProp, setSlideProp] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setSlideProp(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setShowSidebar(true);
    setSlideProp(true);
  };

  return (
    <div>
      {
        showSidebar &&
        <Backdrop open={sliderProp && isOpen} onClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
          <SidebarRightSide showSidebar={showSidebar} handleClose={handleClose} setToggleSidebarButton={setToggleSidebarButton} setUserIsLoggedIn={setUserIsLoggedIn} handleOpen={handleOpen} sliderProp={sliderProp} isOpen={isOpen} />
        </Backdrop>
      }
      <TopbarDesktopButton
        // onClick={onClickDesktop}
        onClick={() => {
          setShowSidebar(true);
          setSlideProp(true);
          setToggleSidebarButton(true);
        }}
        type="button"
      >
        <div style={{ zIndex: 1000, margin: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
          <Hamburger toggled={isOpen} size={20} toggle={() => { setOpen(!isOpen) }} color='#fff' duration={1} />
        </div>
        {/* <TopbarButtonIcon src={icon} style={{ height: '20px', width: '48%', marginLeft: '11px' }} alt="" /> */}
      </TopbarDesktopButton>
      <TopbarMobileButton
        onClick={onClickMobile}
        type="button"
      >
        <TopbarButtonIcon src={icon} alt="" />
      </TopbarMobileButton>
    </div>
  );
};

TopbarSidebarButton.propTypes = {
  // onClickDesktop: PropTypes.func.isRequired,
  // onClickMobile: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;

// region STYLES

const TopbarButton = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    background: transparent;
  }
`;

export const TopbarDesktopButton = styled(TopbarButton)`
  
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const TopbarMobileButton = styled(TopbarButton)`
  
  @media screen and (min-width: 576px) {
    display: none;
  }
`;

const TopbarButtonIcon = styled.img`
  margin: auto;
  transition: all 0.3s;
  width: 16px;
  z-index: 1000;
`;