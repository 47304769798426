import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { QueryUserByEmail } from 'src/services/user-http.service'
import { GetMarketByOrganizationId } from 'src/services/market-http.service';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: "100%",
    maxWidth: "100%",
    "& .MuiOutlinedInput-input": {
      color: "white",
      backgroundColor: "rgb(33, 33, 33)",
      fontSize: '90%',
      borderRadius: '1px'
    },
    "& .MuiInputLabel-root": {
      color: "white",
      fontSize: '100%'

    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.01)",
    },

    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiInputLabel-root": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '1px'

    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1EF1C6"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#1EF1C6"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    }
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#1EF1C6"
    },
    color: "#BFBFBF",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    height: '300px',
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c"
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121"
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    },
    "&:hover .Mui-checked": {
      color: "#101010"
    },
    "& .MuiTypography-body1": {
      fontSize: '14px',
      fontFamily: "LynkcoTypeLight",
    }
  }
}));

const MarketMultiselect = ({ onChange, variant, orgId, editMode, email, userMarketIds }) => {
  const [selected, setSelected] = React.useState([]);
  const [markets, setMarkets] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    initializeMarkets();
  }, [markets.length]);
  React.useEffect(() => {
    preselectMarkets();
  }, [editMode]);

  const initializeMarkets = () => {
    let orgsMarkets = [];
    let userMarketArray = [];

    GetMarketByOrganizationId(orgId)
      .then(data => {

        Array.isArray(data) && data.length > 0 ? data.map(market => {
          orgsMarkets.push({ key: market.gemsId, value: market.name });
        })
          : console.log("Error Occured");
        if (userMarketIds) {
          userMarketIds.map(id => {
            orgsMarkets.map(market => {
              if (market.key == id) {
                userMarketArray.push({ key: market.key, value: market.value });
              }
            })
          })
          setMarkets(userMarketArray);
        }
        if (!userMarketIds) {
          setMarkets(orgsMarkets);
        }
      });
  }
  const preselectMarkets = () => {
    let marketSelectArray = [];
    setSelected([])

    if (editMode === true) {

      QueryUserByEmail(email).then(apiData => {
        Array.isArray(apiData.marketIds) && apiData.marketIds.length > 0 ? apiData.marketIds.map(market => {
          marketSelectArray.push(market);
        })
          : console.log("Error Occured");
        setSelected(marketSelectArray);
      })
    }
    else {
      setSelected([])
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onChange(event);
  };

  const renderSelected = () => {
    let nameArray = []
    selected.map(id => {
      markets.map(market => { if (market.key == id) nameArray.push(market.value) })
    })
    return nameArray.join(', ');
  }

  return (
    <div>
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense' >
        <InputLabel id="demo-multiple-checkbox-label">Market</InputLabel>
        <Select
          IconComponent={ChevronDownIcon}
          name="marketIds"
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Market" />}
          renderValue={renderSelected}
          MenuProps={{
            classes: { paper: classes.dropdownStyle }, disableScrollLock: true
          }}        >
          {markets.map((market) => (
            <MenuItem key={market.key} value={market.key}>
              <Checkbox className={classes.checkbox} checked={selected.indexOf(market.key) > -1} />
              <ListItemText primary={market.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MarketMultiselect;