import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
// import Chart from "react-apexcharts";

const OrdersPendingForVin = () => {
  // const optionsdownload = {
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: "20%",
  //     },
  //   },
  //   grid: {
  //     show: false,
  //     padding: {
  //       left: 0,
  //       right: 0,
  //     },
  //   },
  //   colors: "rgba(255, 255, 255, 0.5)",
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //     sparkline: {
  //       enabled: true,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   tooltip: {
  //     theme: "dark",
  //   },
  // };
  // const seriesdownload = [
  //   {
  //     name: "Good",
  //     data: [4, 5, 2, 10,  9, 12, 4, 9],
  //   },
  // ];
  /*--------------------------------------------------------------------------------*/
  /* Used In Remarketing Dashboard                                                  */
  /*--------------------------------------------------------------------------------*/
  return (
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <div className="d-flex">
          <div className="mr-3 align-self-center">
            <h1 className="text-white">
              {/* <i className="icon-cloud-download" /> */}
            </h1>
          </div>
          <div>
            <CardTitle style={{ fontSize: "1.2vw", fontWeight: 600, textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-10px' }} className="text-white">Used Car Orders Pending for VIN</CardTitle>
            <CardSubtitle style={{ fontSize: "0.85vw", marginLeft: '-8px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-10px' }} className="text-white op-6">Number of open customer orders that are pending for VIN</CardSubtitle>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          <div className="mr-auto">
            <h2 className=" text-white" style={{ marginLeft: '1px', fontSize: "4.2vw" }}>11</h2>
          </div>
          <div>
            {/* <Chart
              options={optionsdownload}
              series={seriesdownload}
              type="bar"
              height="75px"
              width="65%"
            /> */}
          </div>
          <div className="text-white" >
            {/* <TrendingUpIcon className="dashboard__trend-icon" style={{ marginLeft: '1px', fontSize: "4.2vw" }} size={50} /> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default OrdersPendingForVin;