import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function ReverseGeocoding(lat, lng) {
  let responseMessage = {
    status: "",
    message: "",
    errorMessage: ""
  }

  const latlng = {
    lat: lat,
    lng: lng,
  };

  const geocoder = new window.google.maps.Geocoder();

  let responseData = geocoder.geocode({ location: latlng })
    .then((response) => {
      if (response.results[0]) {
        responseMessage['status'] = 'ok'
        responseMessage['message'] = response.results[0].formatted_address
        return responseMessage
      } else {
        responseMessage['status'] = 'no content'
        responseMessage['errorMessage'] = "No results found"
        return responseMessage
      }
    })
    .catch((e) => {
      responseMessage['status'] = 'error'
      responseMessage['errorMessage'] = "Geocoder failed due to: " + e
      return responseMessage
    });

  return responseData
}


export async function Geocoding(address = '') {
  let responseMessage = {
    status: "",
    message: "",
    errorMessage: ""
  }

  const geocoder = new window.google.maps.Geocoder();

  let responseData = geocoder.geocode(address)
    .then((response) => {
      if (response.results[0]) {
        responseMessage['status'] = 'ok'
        responseMessage['message'] = response.results
        return responseMessage
      } else {
        responseMessage['status'] = response.status
        responseMessage['errorMessage'] = "No results found"
        return responseMessage
      }
    })
    .catch((e) => {
      responseMessage['status'] = 'error'
      responseMessage['errorMessage'] = "Geocoder failed due to: " + e
      return responseMessage
    });

  return responseData
}

/////////////////////////////////// To fetch LCC && MCC data

export async function GetCarCompoundsInMarket(marketIds, compoundType) {
  let fleetToken = await GetToken();

  const headers = new Headers();
  headers.append("Authorization", fleetToken);
  headers.append("Content-Type", "application/json");
  headers.append("accept", "text/plain");
  headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      "marketIds": marketIds,
      "compoundType": compoundType
    })
  };

  let apiData = await fetch(`${environment.backendVehicleService}/api/GeoFencing/GetCarCompoundsInMarket`, options)
    .then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.log(error));
  return apiData;
}

///////////////////////////////// To fetch vehicles in LCC and MCC

export async function GetVehiclesInCompound(marketId, compoundId, viewTypes, fleetTypeIds) {
  let fleetToken = await GetToken();

  const headers = new Headers();
  headers.append("Authorization", fleetToken);
  headers.append("Content-Type", "application/json");
  headers.append("accept", "text/plain");
  headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      "marketId": marketId,
      "compoundId": compoundId,
      "viewTypes": viewTypes,
      "fleetTypeIds": fleetTypeIds
    })
  };

  let apiData = await fetch(`${environment.backendVehicleService}/api/GeoFencing/GetVehiclesInCompound`, options)
    .then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.log(error));
  return apiData;
};

//////////////////////////////////// To fetch vehicle count in LCC and MCC

export async function GetCarsInCompoundKpi(marketIds, viewTypes, fleetTypeIds) {
  let fleetToken = await GetToken();

  const headers = new Headers();
  headers.append("Authorization", fleetToken);
  headers.append("Content-Type", "application/json");
  headers.append("accept", "text/plain");
  headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      "marketIds": marketIds,
      "viewTypes": viewTypes,
      "fleetTypeIds": fleetTypeIds
    })
  };

  let apiData = await fetch(`${environment.backendVehicleService}/api/GeoFencing/GetCarsInCompoundKpi`, options)
    .then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.log(error));
  return apiData;
}

///////////////////////////////// To fetch vehicles count in each LCC and MCC

export async function GetVehicleCountInCompoundByCompound(marketId, compoundId, viewTypes, fleetTypeIds) {
  let fleetToken = await GetToken();

  const headers = new Headers();
  headers.append("Authorization", fleetToken);
  headers.append("Content-Type", "application/json");
  headers.append("accept", "text/plain");
  headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      "marketId": marketId,
      "compoundId": compoundId,
      "viewTypes": viewTypes,
      "fleetTypeIds": fleetTypeIds
    })
  };

  let apiData = await fetch(`${environment.backendVehicleService}/api/GeoFencing/GetVehicleCountInCompoundByCompound`, options)
    .then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.log(error));
  return apiData;
};