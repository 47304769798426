import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
// import DataReactTable from './components/DataReactTable';
// import CreateDataVehiclePage from '../CreateDataVehiclePage';
import CreateTableData from '../CreateData'
import RvReactTable from './components/RvReactTable';

const indexRV = () => {
  // const { t } = useTranslation('common');
  const reactTableData = CreateTableData();

  return (
    <div>
      <Row>
        {/* <Col md={12}>
          <h3 className="page-title">{('tables.data_table.title')}</h3>
          <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3>
        </Col> */}
      </Row>
      <Row>
        <RvReactTable reactTableData={reactTableData} />
      </Row>
    </div>
  );
};

export default indexRV;
