import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import Tooltip from '@material-ui/core/Tooltip';

export default function EnhancedVehicleTableToolbar(props) {
    const classes = useToolbarStyles();
    const { selectedRowId, handleClickDownload, handleClickDropdown, isEnableSave, handleClickSave, handleClickIsEditable, handleClose } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: selectedRowId.length > 0,
            })}
        >
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">

            </Typography>
            <div className={classes.tooltipDiv}>
                {/* {isEnableSave ? (<Tooltip className={classes.tooltipImg3} onClick={handleClickSave} title="save" style={{ marginTop: '70px', fontSize: '20px', letterSpacing: '20px' }}>
                    <span>
                        <i className="mr-2 mdi mdi-content-save" style={{ marginTop: '70px', fontSize: '20px', letterSpacing: '20px' }} />
                    </span>
                </Tooltip>) :
                    (<Tooltip className={classes.tooltipImg} title="Select Car" onClick={handleClickIsEditable} style={{ marginTop: '70px', fontSize: '20px', letterSpacing: '20px' }}>
                        <span>
                            <i className="mr-2 mdi mdi-currency-usd" style={{ marginTop: '70px', fontSize: '20px', letterSpacing: '20px' }} />
                        </span>
                    </Tooltip>)} */}
                <Tooltip className={classes.tooltipImg} title="Downnload" onClick={handleClickDownload} style={{ marginTop: '70px', fontSize: '20px', letterSpacing: '20px' }}>
                    <span>
                        <i className="mr-2 mdi mdi-download" />
                    </span>
                </Tooltip>
                <Tooltip className={classes.tooltipImg} title="Close Table" onClick={handleClose} style={{ marginTop: '69px', fontSize: '22px', color: '#dc1860' }}>
                    < span>
                    <i className="mr-2 mdi mdi-close" />
                    </span>

                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedVehicleTableToolbar.propTypes = {
    handleClickDownload: PropTypes.func.isRequired,
    handleClickDropdown: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleClickIsEditable: PropTypes.func.isRequired,
};
