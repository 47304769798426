import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from 'src/assets/img/BackgroundImages/FleetWallpaper05.jpg'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    background: {
        position: "relative",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat", 
    },

}));

const DefaultPage = () => {
    const classes = useStyles();
    return (
        <div>
            {bgImage !== undefined ? (
                <div
                    className={classes.background}
                    style={{ backgroundImage: "url(" + bgImage + ")" }}
                />
            ) : null}
        </div>
    );
}

export default DefaultPage;