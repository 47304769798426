import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, Row, Col, CardBody } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.driverPage';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { FormmatDate } from 'src/utils/DateFormatter';

const UseStyles = makeStyles((theme) => ({
    root: {
        height: '744px',
        marginRight: '-32px',
        paddingRight: '20px',
        backgroundColor: '#212121'
    },
    root2: {
        overflow: 'scroll',
        height: '635px',
        marginLeft: '-30px',
        marginTop: '20px',
        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },

    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        marginLeft: -theme.spacing(0.2),
        marginRight: -theme.spacing(2),
        marginTop: theme.spacing(3),
        height: '1px',
        marginBottom: '10px'
    },
    dividerStyle1: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        marginLeft: theme.spacing(4),
        marginRight: -theme.spacing(2),
        marginTop: -theme.spacing(1.8),
        height: '1px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        marginTop: '-17px',
        display: 'flex'
    },
    gridContainerStyle1: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        marginTop: '22px',
        marginLeft: '-3px',
        display: 'flex'

    },
}));

const ConnectivityCasesDetail = ({ closeHandleDrawer1, data }) => {
    const classes = UseStyles();

    return (
        <div>
            <Row>
                <Col lg="12" md="12" >
                    <Card className={classes.root} style={{ borderRadius: '1px' }}>
                        <CardBody>
                            <Row>
                                <Col lg="12" md="12" >
                                    <div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11} md={11} lg={11}>
                                                <Typography style={{ fontSize: '16px', color: 'white', marginLeft: '-380px', }} variant="h4" id="tableTitle" component="div">
                                                    {data?.caseNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} md={1} lg={1}>
                                                <Toolbar>
                                                    <Tooltip title="Close Case" style={{ fontSize: '20px', marginLeft: '16px', marginTop: '-40px', cursor: 'pointer', color: '#DC1860' }} onClick={closeHandleDrawer1}>
                                                        <span>
                                                            <i className="mr-2 mdi mdi-close" />
                                                        </span>
                                                    </Tooltip>

                                                </Toolbar>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div style={{ marginRight: '-20px' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={8} lg={8}>
                                                <Typography style={{ fontSize: '16px', marginLeft: '-287px', marginTop: '-20px', color: 'white' }} variant="h4" id="tableTitle" component="div">
                                                    Escalated: {data?.caseEscalated}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography style={{ fontSize: '16px', color: '#DC1860', marginLeft: '138px', marginTop: '-20px' }} variant="h4" id="tableTitle" component="div">
                                                    {''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={classes.root2}>
                                <Col >
                                    <div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Typography style={{ fontSize: '16px', marginLeft: '-485px', color: 'white', marginBottom: '-40px', }} variant="h4" id="tableTitle" component="div">
                                                    Summary
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div style={{ marginLeft: '17px' }}>
                                        <Divider className={classes.dividerStyle} orientation='horizontal' />
                                    </div>

                                    <Row>
                                        <Col lg="5" md="12" >
                                            <div style={{ marginLeft: '18px', marginRight: '10px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-126px' }}><span >Case Type</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.caseType} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '3px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-97px' }}><span >Subject Area 1</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.subjectArea1} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '30px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-97px' }}><span >Subject Area 2</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.subjectArea2} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '35px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-122px' }}><span >Open Date</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={FormmatDate(data?.caseCreationDate)} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '35px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-113px' }}><span >Closed Date</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={FormmatDate(data?.caseClosedDate)} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Col>

                                        <Col lg="7" md="12" >
                                            <div style={{ marginLeft: '50px', marginRight: '-18px' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '12px', }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", marginLeft: '-200px', color: 'white' }}><span >Escalation Reason</span></Typography>
                                                            <div style={{ marginLeft: '-0.8vw', fontSize: '20px', marginTop: '-8px' }}>
                                                                <Card style={{ border: '1px solid #212121', backgroundColor: '#131313', marginTop: '20px', display: 'flex', position: 'relative', borderRadius: '1px' }}>
                                                                    <CardBody>
                                                                        <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeRegular", marginLeft: '-14px', color: 'white', textAlign: 'left' }}>
                                                                            {data?.escalationReason}
                                                                        </Typography>
                                                                    </CardBody>
                                                                </Card>

                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '50px', marginRight: '-18px' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '30px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", marginLeft: '-245px', color: 'white' }}><span >Description</span></Typography>
                                                            <div style={{ marginLeft: '-0.8vw', fontSize: '20px', marginTop: '-8px' }}>

                                                                <Card style={{ border: '1px solid #212121', backgroundColor: '#131313', marginTop: '20px', display: 'flex', position: 'relative', borderRadius: '1px' }}>
                                                                    <CardBody>
                                                                        <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeRegular", marginLeft: '-14px', color: 'white', textAlign: 'left' }}>
                                                                            {data?.caseDescription}
                                                                        </Typography>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" md="12" >
                                            <div>
                                                <Grid container className={classes.gridContainerStyle1} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <Typography style={{ fontSize: '16px', marginLeft: '-498px', color: 'white', marginTop: '10px', }} variant="h4" id="tableTitle" component="div">
                                                            Booking
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div style={{ marginLeft: '-17px' }}>

                                                <Divider className={classes.dividerStyle1} orientation='horizontal' />
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-395px' }}><span >Cancellation Requested</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.bookingCancelationRequested} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '3px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-418px' }}><span>Case Booking Status</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.caseBookingStatus} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '30px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-358px' }}><span>Car Swap Address Validation</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.carSwapAddressValidation} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div style={{ marginLeft: '18px', marginRight: '10px', marginTop: '35px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '-20px', marginTop: '25px' }}>
                                                        <div >
                                                            <Typography style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular", color: 'white', marginLeft: '-384px' }}><span >Car Swap Booking Status</span></Typography>
                                                            <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                <FormTextFieldSalesPlain value={data?.carSwapBookingStatus} disabled updateFontSize={true} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ConnectivityCasesDetail;