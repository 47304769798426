import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const TowbarCard = ({ setQueryParamsFilteredTowbar, setDataLoaded, towBarCount }) => {
    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        let value = event.target.checked;
        if (value == true) {
            setQueryParamsFilteredTowbar(true);
            setDataLoaded(false);
        } else {
            setQueryParamsFilteredTowbar(false);
            setDataLoaded(false);
        }
        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginTop: '-20px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>TOWBAR</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{towBarCount}<br /></td>
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} onChange={switchHandler} />
                        </td>
                    </tr>

                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(TowbarCard);