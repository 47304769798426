import React, { useState, useEffect } from 'react';
import { Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { GetComments } from 'src/services/vehicle-comments-http.service';
import { GetVehicleWorkOrderCountByStatus } from 'src/services/vehicle-pages-http.service';
import returnIcon_green from 'src/assets/img/vehicles/returnIcon_green.png';
import returnIcon from 'src/assets/img/vehicles/returnIcon.png';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import VehicleDetailsTabs from 'src/components/tabs/vehicleDetails-tabs-arcEurope.component.jsx';

const DetailsMainPage = (props) => {
    const { userOrg, userMarketIds, closeVehicleDetail, selectTable, modelData, vehicleDetailsChartValues, detailsmap = true, } = props

    const [vehiclePosition, setVeiclePosition] = useState({});
    const [displayDetails, setDisplayDetails] = useState(false)
    const [vehicleDetail, setVehicleDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [getWorkOrderStatus, setWorkOrderStatus] = useState({});
    const [mapSpinner, setMapSpinner] = useState(false);
    const [addressInput, setAddressInput] = useState('');
    const [toggleReturnButton, setToggleReturnButton] = useState(false);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        setComments([]);
        setVehicleDetails({});
        setVeiclePosition({});
        setWorkOrderStatus({});

        handleChangeVIN();

    }, [modelData.vin.length]);

    const getComments = () => {
        GetComments(modelData.vin.trim())
            .then(data => {
                if (data.ok == true) {
                    setComments(data.comments)
                }
                setDataLoaded(true);
            })
    };

    const handleChangeVIN = () => {
        if (modelData.vin.length !== 0) {
            handleTab(modelData.vin.trim());
            setDataLoaded(false);

            QueryConnectivityInformation(modelData.vin.trim())
                .then(data => {
                    if (data.ok == true) {
                        setVehicleDetails(data.vspConnectivity);
                        setDisplayDetails(true)
                    } else {
                        setOpenDialog(true);
                        setMessage(data.title);
                        setDialogMessage(data.errorMessage);
                        setDisplayDetails(false);
                    }
                })
            GetComments(modelData.vin.trim())
                .then(data => {
                    if (data.ok == true) {
                        setComments(data.comments)
                    }
                    setDataLoaded(true);
                })
            GetVehicleWorkOrderCountByStatus(modelData.vin.trim())
                .then(data => {
                    if (data.ok == true) {
                        setWorkOrderStatus(data)
                    }
                })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        closeVehicleDetail();
    };

    const handleTab = (vspVIN, addressInput) => {
        setMapSpinner(true);
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = addressInput || vspVIN;

        let vehiclePositionInstance = {
            "latitude": 0,
            "longitude": 0,
            "odometer": "",
            "color": "",
            "street": "",
            "postalCode": "",
            "city": "",
            "country": "",
        };

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data?.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                vehiclePositionInstance.color = data.vehiclePosition.color
                                vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                setVeiclePosition(vehiclePositionInstance);
                                setMapSpinner(false);
                            }

                        })
                }
            })
    };

    return (
        <Container>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <Col md={12} lg={12}>
                <div>
                    <Tooltip
                        title="return"
                        onOpen={() => setHovered(true)}
                        onClose={() => setHovered(false)}
                        style={{ zIndex: 10000 }}
                    >
                        {
                            toggleReturnButton ?
                                <IconButton
                                    onClick={() => {
                                        setDisplayDetails(true);
                                        setToggleReturnButton(false);
                                    }}
                                >
                                    <img
                                        src={hovered ? returnIcon_green : returnIcon}
                                        className="returnIconLCAsset"
                                    />
                                </IconButton>
                                :
                                <IconButton
                                    onClick={() => {
                                        closeVehicleDetail();
                                    }}
                                >
                                    <img
                                        src={hovered ? returnIcon_green : returnIcon}
                                        className="returnIconLCAsset"
                                    />
                                </IconButton>
                        }

                    </Tooltip>
                </div>
                <Col md={12} lg={12}>
                    {displayDetails && modelData.vin &&
                        <Col lg="12" md="12" >
                            <div id="archEuropeDetailscard" >
                                <VehicleDetailsTabs
                                    vehiclePosition={vehiclePosition}
                                    vin={modelData.vin}
                                    value={2}
                                    mapSpinner={mapSpinner}
                                    setAddressInput={setAddressInput}
                                    addressInput={addressInput}
                                    comments={comments}
                                    getComments={getComments}
                                    dataLoaded={dataLoaded}
                                    handleChangeVIN={handleChangeVIN}
                                    detailsmap={detailsmap}
                                    vehicleDetailsChartValues={vehicleDetailsChartValues}
                                    modelData={modelData}
                                    selectTable={selectTable}
                                />
                            </div>
                        </Col>
                    }
                </Col>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(DetailsMainPage);