import React, { useState, useEffect } from "react";
import { CardTitle, CardSubtitle, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { QueryVehicleWorkOrderOpenCases } from "src/services/dashboard-http.service";

const UsedCarStock = () => {

  const [chartValue, setChartValue] = useState([]);
  const [chartName, setChartName] = useState([]);

  // useEffect(() => {
  //   setChartValues();
  //   setChartNames();
  // }, []);

  const setChartValues = () => {
    let chartValues = [];

    QueryVehicleWorkOrderOpenCases().then(data => {
      data.data.map((item) => {
        chartValues.push(item.count);
      })
      setChartValue(chartValues)
    });
  };

  const setChartNames = () => {
    let chartNames = [];

    QueryVehicleWorkOrderOpenCases().then(data => {
      if (data.data.length > 0) {
        data.data.map((item) => {
          chartNames.push(item.name);
        })
        setChartName(chartNames);
      }
    });
  };

  const optionsvisitors = {
    labels: chartName,
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              color: '#b3b3b3',
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#b3b3b3",
              fontSize: "18px",
            },
            total: {
              show: true,
              label: "Total",
              color: "#b3b3b3",
              fontSize: "calc(10px + 0.390625vw)",
              zIndex: 1000
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    colors: ["#181818", "#b3b3b3", "#dc1860"],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };

  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Maintenance Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/

    <div>
      <Row  >
        <Col lg="12" md="12" >
          <CardTitle style={{ marginTop: '-3px', fontSize: "calc(10px + 0.390625vw)", fontWeight: 600, color: '#b3b3b3' }}>Open Cases</CardTitle>
        </Col>
      </Row>
      <div>
      </div>
      <CardSubtitle className="mb-3" style={{ fontSize: "calc(7px + 0.390625vw)", marginTop: '-12px', color: '#b3b3b3' }}>
        Triggered maintenance and Reminders
      </CardSubtitle>
      <Col lg="12" md="12" className="openCasesStyle">
        <>
          {
            chartValue.length > 0 &&
            <Chart
              options={optionsvisitors}
              series={chartValue}
              type="donut"
              height="100%"
            />
          }
        </>

      </Col>

      <div className="d-flex no-block align-items-center justify-content-center">
        <div className="ml-3">
          <h6 style={{ color: '#646464', textTransform: 'uppercase', fontSize: "calc(7px + 0.390625vw)" }}>
            <i className="fa fa-circle font-10 mr-2" />
            {chartName[0]}
          </h6>
        </div>
        <div className="ml-3">
          <h6 style={{ color: '#b3b3b3', textTransform: 'uppercase', fontSize: "calc(7px + 0.390625vw)" }}>
            <i className="fa fa-circle font-10 mr-2" />
            {chartName[1]}
          </h6>
        </div>

        <div className="ml-3">
          <h6 style={{ color: '#dc1860', textTransform: 'uppercase', fontSize: "calc(7px + 0.390625vw)" }}>
            <i className="fa fa-circle font-10 mr-2" />
            {chartName[2]}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default UsedCarStock;