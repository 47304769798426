import * as React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import FormTextField from 'src/components/form-elements/custom-form-text-field.component'
import FormSelect from 'src/components/form-elements/custom-form-select.component';
import SearchButton from '../buttons/search-button-component';
import Tooltip from '@material-ui/core/Tooltip';

const UseStyles = makeStyles((theme) => ({
  dividerStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(5),
    height: '205px'
  },
  gridContainerStyle: {
    height: '50%',
    backgroundColor: "rgb(19 19 19 / 50%)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'sticky',
  },
  heading: {
    "&.MuiTypography-root": {
      color: "#fff",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium'
    }
  },
  formElement: {
    color: "#ff0000",
    backgroundColor: "#2c2c2c",
    width: "50%",
    marginTop: "0px",
    marginBottom: "0px",
  },
  tooltipImg: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '-10px'
  },
}));

export default function ExtendedSearch(props) {
  const { handleClose, handleClickDropup, handleClickDownload, toggleOdometerVisibility
    , toggleBalanceVisibility, toggleAgeVisibility, toggleRVRangeVisibility, toggleRVPercentVisibility
    , handleChangeExtended, handleExtendedSearch, handleConditionalChoice } = props

  const classes = UseStyles();

  return (
    <div>
      <Grid container className={classes.gridContainerStyle}>
        <Grid item xs={4} md={2} lg={2}>
          <InnerGrid handleChangeExtended={handleChangeExtended}></InnerGrid>
        </Grid>
        <Grid item xs={0}>
          <Divider className={classes.dividerStyle} orientation='vertical'></Divider>
        </Grid>
        <Grid item xs={4} md={3} lg={3}>
          <InnerGridCarOptions handleChangeExtended={handleChangeExtended}></InnerGridCarOptions>
        </Grid>
        <Grid item xs={0}>
          <Divider className={classes.dividerStyle} orientation='vertical'></Divider>
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <InnerGridMileage handleChangeExtended={handleChangeExtended}
            handleConditionalChoice={handleConditionalChoice}
            toggleOdometerVisibility={toggleOdometerVisibility}
            toggleBalanceVisibility={toggleBalanceVisibility}></InnerGridMileage>
        </Grid>
        <Grid item xs={0}>
          <Divider className={classes.dividerStyle} orientation='vertical'></Divider>
        </Grid>
        <Grid item xs={4} md={3} lg={3}>
          <InnerGridValue handleChangeExtended={handleChangeExtended}
            handleConditionalChoice={handleConditionalChoice}
            toggleAgeVisibility={toggleAgeVisibility} toggleRVRangeVisibility={toggleRVRangeVisibility}
            toggleRVPercentVisibility={toggleRVPercentVisibility}
            handleClickDropup={handleClickDropup}
            handleExtendedSearch={handleExtendedSearch}
            handleClickDownload={handleClickDownload}
          ></InnerGridValue>
        </Grid>
      </Grid>
    </div>
  );
}

const InnerGrid = (props) => {
  const { handleChangeExtended } = props
  const classes = UseStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Order details</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormTextField labelText='VIN' classes={classes.formElement} name={"vin"}
          onChange={handleChangeExtended("vin")}></FormTextField>
      </Grid>
      <Grid item xs={12}>
        <FormTextField labelText='License plate' name={"licensePlate"}
          onChange={handleChangeExtended("licensePlate")}></FormTextField>
      </Grid>
      <Grid item xs={12}>
        <FormTextField labelText='Order number' name={"orderNumber"}
          onChange={handleChangeExtended("orderNumber")}></FormTextField>
      </Grid>
    </Grid>
  )
}

const InnerGridCarOptions = (props) => {
  const { handleChangeExtended } = props
  const classes = UseStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Car options</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormSelect label='Model' name='model'
          menuObjectArray={[{ 'key': '01', 'value': '01' }, { 'key': '02', 'value': '02' }]}
          onChange={handleChangeExtended("model")}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormSelect label='Towbar' name='hasTowbar'
          menuObjectArray={[{ 'key': 'Yes', 'value': 'Yes' }, { 'key': 'No', 'value': 'No' }]}
          onChange={handleChangeExtended("hasTowbar")}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormSelect label='Engine' name='engine'
          menuObjectArray={[{ 'key': 'PHEV', 'value': 'PHEV' }, { 'key': 'HEV', 'value': 'HEV' }]}
          onChange={handleChangeExtended("engine")}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormSelect label='W tyres' name='hasWinterTyres'
          menuObjectArray={[{ 'key': 'Yes', 'value': 'Yes' }, { 'key': 'No', 'value': 'No' }]}
          onChange={handleChangeExtended("hasWinterTyres")}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormSelect label='Color' name='color'
          menuObjectArray={[{ 'key': 'Blue', 'value': 'Blue' }, { 'key': 'Black', 'value': 'Black' }]}
          onChange={handleChangeExtended("color")}>
        </FormSelect>
      </Grid>
    </Grid>
  )
}

const InnerGridMileage = (props) => {
  const { handleChangeExtended, handleConditionalChoice, toggleOdometerVisibility, toggleBalanceVisibility } = props
  const classes = UseStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Mileage</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormSelect label='Odometer' name='odometer' onChange={handleConditionalChoice('odometer')}
          menuObjectArray={[{ 'key': 'less than', 'value': 'less than' },
          { 'key': 'greater than', 'value': 'greater than' }, { 'key': 'between', 'value': 'between' }]}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("startValue", "odometer")} placeholder='500'></FormTextField>
      </Grid>
      <Grid item xs={6}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("endValue", "odometer")} visibility={toggleOdometerVisibility} placeholder='12000'></FormTextField>
      </Grid>
      <Grid item xs={12}>
        <FormSelect label='Balance' name='balance' onChange={handleConditionalChoice('balance')}
          menuObjectArray={[{ 'key': 'less than', 'value': 'less than' },
          { 'key': 'greater than', 'value': 'greater than' }, { 'key': 'between', 'value': 'between' }]} disabled={true}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("startValue", "balance")} placeholder='0' disabled={true}></FormTextField>
      </Grid>
      <Grid item xs={6}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("endValue", "balance")} placeholder='1250' visibility={toggleBalanceVisibility} disabled={true}></FormTextField>
      </Grid>
    </Grid>
  )
}

const InnerGridValue = (props) => {
  const { handleChangeExtended, handleConditionalChoice, toggleAgeVisibility, toggleRVRangeVisibility, toggleRVPercentVisibility, handleClickDropup, handleExtendedSearch, handleClickDownload } = props
  const classes = UseStyles();


  return (
    <Grid container spacing={1}>
      <Grid item xs={6} style={{ height: '33px' }}>
        <Typography className={classes.heading}>Value</Typography>
      </Grid>
      <Grid item xs={6} style={{ height: '33px' }}>
        <Tooltip className={classes.tooltipImg} title="Downnload" onClick={handleClickDownload} style={{ marginTop: '35px', fontSize: '20px', marginRight: '50px', color: '#BFBFBF', }}>
          <span>
            <i className="mr-2 mdi mdi-download" ></i>

          </span>
        </Tooltip>
        <Tooltip className={classes.tooltipImg} title="Drop up" onClick={handleClickDropup} style={{ marginTop: '35px', fontSize: '20px', color: '#BFBFBF' }}>
          <span>
            <i className="mr-2 mdi mdi-magnify" ></i>

          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <FormSelect size={'small'} label='RV Range' name='rvRange' onChange={handleConditionalChoice('rvRange')} menuObjectArray={[{ 'key': 'less than', 'value': 'less than' },
        { 'key': 'greater than', 'value': 'greater than' }, { 'key': 'between', 'value': 'between' }]} disabled={true}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}>
        <FormSelect size={'small'} label='Age' name='age' onChange={handleConditionalChoice('age')} menuObjectArray={[{ 'key': 'less than', 'value': 'less than' },
        { 'key': 'greater than', 'value': 'greater than' }, { 'key': 'between', 'value': 'between' }]}>
        </FormSelect>
      </Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("startValue", "rvRange")} placeholder='500' disabled={true}></FormTextField>
      </Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("endValue", "rvRange")} placeholder='12000' visibility={toggleRVRangeVisibility} disabled={true}></FormTextField>
      </Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("startValue", "age")} placeholder='6'></FormTextField>
      </Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("endValue", "age")} placeholder='20' visibility={toggleAgeVisibility}></FormTextField>
      </Grid>
      <Grid item xs={6}>
        <FormSelect size={'small'} label='RV %' name='rvPercent' onChange={handleConditionalChoice('rvPercent')} menuObjectArray={[{ 'key': 'less than', 'value': 'less than' },
        { 'key': 'greater than', 'value': 'greater than' }, { 'key': 'between', 'value': 'between' }]} disabled={true}>
        </FormSelect>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("startValue", "rvPercent")} placeholder='86' disabled={true}></FormTextField>
      </Grid>
      <Grid item xs={3}>
        <FormTextField adjustTopMargin={true} onChange={handleChangeExtended("endValue", "rvPercent")} placeholder='95' visibility={toggleRVPercentVisibility} disabled={true}></FormTextField>
      </Grid>
      <Grid item xs={6}>
        <SearchButton handleSearch={handleExtendedSearch}></SearchButton>
      </Grid>
    </Grid>
  )
}