import React, { useState } from "react";
import { Form, FormGroup, Label, Button, ModalBody,ModalFooter , Row} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { SaveTodo } from 'src/services/todo-http.service';
import { AddTodoData, TransformTodoData } from 'src/utils/TodoFormatter';
import { fetchTodosSuccess } from 'src/redux/todos/Action';
import { makeStyles } from '@material-ui/core/styles';
import DropdownSelect from 'src/components/form-elements/custom-form-select.componentNew';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#1ef1c6',
    width: '4vw',
    color: '#000',
    borderRadius: '200px',
    '&:hover': {
      backgroundColor: '#339586',
      color: '#FFF'
    }
  }
}));

const AddContact = ({ onButtonClick, user , dueDate, handleDateChange}) => {
  const classes = useStyles();

  const [displayValue, setDisplayValue] = useState('');

  let title, desc;
  const todoData = useSelector((state) => state.maintodoReducer.todos);

  const id = useSelector((state) => state.maintodoReducer.todos.length + 1);
  const dispatch = useDispatch();
  let userId = user.id

  const AddTodo = (userId, title, desc, todoData) => {
    SaveTodo(userId, AddTodoData(title, desc, todoData))
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
        }
      })
  };

  const handleChangeUCA = (value) => (event) => {
    // setDisplayUndefinedStock(false);
    // setIsEnableSave(false);
    // marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
    let selected = event.target.value;
    if (value == 'priority') {
      if (selected == 'High') {
      }
      if (selected == 'Medium') {
      }
      if (selected == 'Low') {
      }

      setDisplayValue(event.target.value);
    };
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          AddTodo(userId, title.value, desc.value, todoData)
          title.value = "";
          desc.value = "";
        }}
      >
        <ModalBody >
          <FormGroup>
            <Label for="title">Title</Label>
            <input
              className="form-control"
              type="text"
              name="title"
              id="title"
              ref={(node) => (title = node)}
              required
              style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6 ', color: '#fff', borderRadius: '20px' }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="desc">Description</Label>
            <textarea
              className="form-control"
              name="desc"
              rows="5"
              id="desc"
              ref={(node) => (desc = node)}
              required
              style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6', color: '#fff', borderRadius: '20px' }}
            />
          </FormGroup>

          <FormGroup className="Datepicker">
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={dueDate}
                onChange={handleDateChange}
                minDate={new Date()}
                // calendarClassName="calendarstyle"
                wrapperClassName="calendercontainer"
                placeholderText={'Please select due date'}
              />
            </FormGroup>

            <Row>
            <div style={{ width: '100%', marginTop: '-10px', padding: '15px' }}>
              <DropdownSelect
                label='Priority'
                name='priority'
                dropdownfont='true'
                value={displayValue}
                style={{ zIndex: 10 }}
                tableDropdownHeight="true"
                menuObjectArray={[
                  { 'key': 'High', 'value': 'High' },
                  { 'key': 'Medium', 'value': 'Medium' },
                  { 'key': 'Low', 'value': 'Low' }
                ]}
                onChange={handleChangeUCA("priority")}
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onButtonClick} className={classes.button} type="submit">
            Create
          </Button>
        </ModalFooter>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(AddContact)