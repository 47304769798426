import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';
import { GetVehiclesInCompound } from 'src/services/geocoder.service';

const FleetSelectionCard = ({ openTable, selectedMarkets, carsCompoundName, fleetTypeIds, setVehiclesAtMCC, disableButtons, selectedViewType, setSelectedViewType }) => {
    const [checkedUndefined, setCheckedUndefined] = useState(true);
    const [checkedLCAssets, setCheckedLCAssets] = useState(true);
    const [checkedUsedCars, setCheckedUsedCars] = useState(true);
    const [checkedSoldCars, setCheckedSoldCars] = useState(true);

    let updatedViewTypeArray = [];

    useEffect(() => {
        if (!openTable) {
            setCheckedUndefined(false);
            setCheckedLCAssets(false);
            setCheckedUsedCars(false);
            setCheckedSoldCars(false);
        } else {
            setCheckedUndefined(true);
            setCheckedLCAssets(true);
            setCheckedUsedCars(true);
            setCheckedSoldCars(true);
        }
    }, [openTable])

    const switchHandlerUndefined = (event) => {
        let value = event.target.checked;
        if (value == true) {
            updatedViewTypeArray = [...selectedViewType, 1];
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        };

        if (value == false) {
            updatedViewTypeArray = selectedViewType.filter((item) => item !== 1);
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        }
        setCheckedUndefined(event.target.checked);
    };

    const switchHandlerLCAssets = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedViewTypeArray = [...selectedViewType, 2];
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        };

        if (value == false) {
            updatedViewTypeArray = selectedViewType.filter((item) => item !== 2);
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        }
        setCheckedLCAssets(event.target.checked);
    };

    const switchHandlerUsedCars = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedViewTypeArray = [...selectedViewType, 3];
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        };

        if (value == false) {
            updatedViewTypeArray = selectedViewType.filter((item) => item !== 3);
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        }
        setCheckedUsedCars(event.target.checked);
    };

    const switchHandlerSoldCars = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedViewTypeArray = [...selectedViewType, 4];
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        };

        if (value == false) {
            updatedViewTypeArray = selectedViewType.filter((item) => item !== 4);
            setSelectedViewType(updatedViewTypeArray);
            let vehicleVINArray = [];
            if (carsCompoundName !== undefined) {
                GetVehiclesInCompound(selectedMarkets, carsCompoundName, updatedViewTypeArray, fleetTypeIds)
                    .then(data => {
                        if (data.ok == true) {
                            data.vehiclesPostion.map(vehicle => {
                                vehicleVINArray.push(
                                    vehicle['vin']
                                );
                            });
                        }
                        setVehiclesAtMCC(vehicleVINArray);
                    })
            }
        }
        setCheckedSoldCars(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance10" style={{ marginTop: '-5px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>FLEET SELECTION</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr style={{ marginTop: '20px' }}>
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', }}>Undefined<br /></td>
                        <td style={{ float: 'right', marginTop: '-13px' }}>
                            <ToggleComponent checked={checkedUndefined} disabled={!openTable} onChange={switchHandlerUndefined} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>L&C Assets<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedLCAssets} disabled={!openTable} onChange={switchHandlerLCAssets} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>Used Cars<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedUsedCars} disabled={!openTable} onChange={switchHandlerUsedCars} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>Sold Cars<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedSoldCars} disabled={!openTable} onChange={switchHandlerSoldCars} />
                        </td>
                    </tr>

                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(FleetSelectionCard);