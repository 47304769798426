import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
// import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
// import Chart from "react-apexcharts";
// import Tooltip from '@material-ui/core/Tooltip';

const VinsBelowLandedCost = () => {
    // const optionsdownload = {
    //     plotOptions: {
    //         bar: {
    //             horizontal: false,
    //             columnWidth: "20%",
    //         },
    //     },
    //     grid: {
    //         show: false,
    //         padding: {
    //             left: 0,
    //             right: 0,
    //         },
    //     },
    //     colors: "rgba(255, 255, 255, 0.5)",
    //     chart: {
    //         toolbar: {
    //             show: false,
    //         },
    //         sparkline: {
    //             enabled: true,
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false,
    //     },
    //     tooltip: {
    //         theme: "dark",
    //     },
    // };
    // const seriesdownload = [
    //   {
    //     name: "Good",
    //     data: [4, 5, 2, 10,  9, 12, 4, 9],
    //   },
    // ];
    /*--------------------------------------------------------------------------------*/
    /* Used In Remarketing Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/
    return (
        <Card
            style={{
                backgroundColor: '#B3B3B3',
                borderRadius: '30px',
                boxShadow: '0 5px 6px #000000',
                marginTop: '3px'
            }}
        >
            <CardBody>
                <div className="d-flex">
                    <div className="mr-3 align-self-center">
                        <h1 className="text-white">
                            {/* <i className="icon-cloud-download" /> */}
                        </h1>
                    </div>
                    <div>
                        <CardTitle style={{ fontSize: "calc(12px + 0.390625vw)", fontWeight: 600, textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-10px' }} className="text-darky">Enforced Terminations</CardTitle>
                        <CardSubtitle style={{ fontSize: "calc(9px + 0.390625vw)", marginLeft: '-10px' }} className="text-darky op-6">Asset repossession in progress</CardSubtitle>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <div className="mr-auto">
                        <h2 className="text-darky dashboardFont" >13</h2>
                    </div>
                    <div>
                        {/* <Chart
                            options={optionsdownload}
                            series={seriesdownload}
                            type="bar"
                            height="75px"
                            width="65%"
                         /> */}
                    </div>
                    {/* <div style={{ fontSize: '30px', color: '#000', cursor: 'pointer', marginTop: '-241px' }} >
                        <span>
                            <i className="mdi mdi-filter-variant" />
                        </span>
                    </div> */}
                </div>
            </CardBody>
        </Card>
    );
};

export default VinsBelowLandedCost;