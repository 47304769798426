import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import ReactTableBaseConnectivityCasesPage from 'src/components/tables/newtable/table/ReactTableBaseConnectivityCasesPage';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import CommentsDetails from 'src/pages/comments-page/CommentsDetails';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import SearchButton from "src/components/buttons/search-button-component";
import RoleAddEdit from 'src/components/form-elements/role-add-edit.component';
import Modal from '@mui/material/Modal';
import CardActions from '@material-ui/core/CardActions';
import DoneButton from 'src/components/buttons/done-button.component';


let vin = ''

const rowMock = [
    {
        "name": "Vehicle reported damaged",
        "createdDate": "23.10.22"
    },
    {
        "name": "Vehicle insurance pending",
        "createdDate": "10.01.22"
    },
    {
        "name": "Vehicle reported stolen",
        "createdDate": "24.11.21"
    },
    {
        "name": "Position data not accurate - follow up needed",
        "createdDate": "19.07.21"
    }
]

const drawerWidth = 500;

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '45px'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px',
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            // padding: '16px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
            // marginBottom: -50,
            top: 158,
            right: 199,
            overflow: 'auto',
        },
    }
}));

const CommentsPage = ({ userPermissions, userToken }) => {

    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [openDeleteDialog, setDeleteDialog] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [rows, setRolesArray] = React.useState([]);
    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });
    const drawerClasses = useDrawerStyles();
    const [roles, setRoles] = React.useState({
        id: "",
        name: "Vehicle reported stolen",
        createdDate: "24.11.2021",
        description: "Vehcicle reported stolen 23rd Novemeber 21. Last know position was at central station in Gothenburg. Vehicle is no longer reporting its postion. Police investigation ongoing. Case ref no: 1234569  ",
        editMode: false
    });
    let selectedRowVehiclePostion = {}

    const columns = useMemo(
        () => [
            { Header: 'Title', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Created', accessor: "createdDate", disableGlobalFilter: true, },
        ],
        [],
    );

    React.useEffect(() => {
        initializeRoles();
        setDeleteDialog(false);
        setIsExpanded(true)
    }, []);

    const initializeRoles = () => {
        let roleArray = [];
        rowMock.map(roles => {
            roleArray.push({
                name: roles.name,
                createdDate: roles.createdDate
            });
        });
        setRolesArray(roleArray);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const selectedRowValues = (row) => {

        setSelectedRow({
            id: row.id,
            name: row.name,
            description: row.description,
            createdDate: row.createdDate,
        })
        setSelected({
            id: row.id,
            name: row.name,
            description: row.description,
            createdDate: row.createdDate,

        })
        selectedRowVehiclePostion = {
            id: row.id,
            name: row.name,
            description: row.description,
            createdDate: row.createdDate,
        };
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    const handleDrawar = () => {
        vin = selectedRowVehiclePostion.caseNumber
        setOpen(true);
    }

    const closeHandleDrawer = () => {
        setOpen(false);
    }
    const closeHandleDrawer1 = () => {
        setOpen(false);
    }

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleDone = () => {
        setOpenModal(false);
        // saveFleet();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRoles({ ...roles, [name]: value });
    };

    const handleClickAdd = () => {
        setOpenModal(true);
        setRoles({
            ...roles, id: "", name: "", createdDate: "24.11.2021", description: "", editMode: false
        });

    };

    /////////////////// use implememntation in Role table
    const handleClickEdit = () => {
        setOpen(false);
    };

    return (
        <Col md={12} lg={12}>
            <Card style={{ backgroundColor: 'rgba(19, 19, 19, .3)', marginLeft: '-10px', }}>
                <>
                    <Modal
                        className={drawerClasses.drawer}
                        variant="persistent"
                        anchor="right"
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={handleClose}
                        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
                    >
                        <RoleAddEdit
                            roles={roles}
                            onChange={handleChange}
                            handleClose={handleClose}
                            handleDone={handleDone}
                            title='New comment'
                            heading='Title'
                            description='Description'
                            variant='true'
                        />
                    </Modal>
                    {!open ? (
                        <div>
                            <div style={{ marginTop: '62px', padding: '27px' }}>
                                <ReactTableBaseConnectivityCasesPage
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    isSelected={isSelected}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    selectedRowValues={selectedRowValues}
                                    handleDrawar={handleDrawar}
                                    closeHandleDrawer={closeHandleDrawer}
                                    isExpanded={isExpanded}
                                />
                            </div>

                            <CardActions classes={{ root: drawerClasses.cardActionStyle }} >
                                <DoneButton handleDone={handleClickAdd} placeholder='ADD NEW' />
                            </CardActions>

                            {/* <div style={{ marginLeft: '435px', marginTop: '20.5vh', }}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SearchButton
                                        placeholder={'ADD NEW'}
                                        handleSearch={() => handleClickAdd()}
                                    />
                                </Grid>
                            </Grid>
                        </div> */}
                        </div>) : (<>
                            <CommentsDetails closeHandleDrawer1={closeHandleDrawer1} onChange={handleChange} roles={roles} handleClickEdit={handleClickEdit} />
                        </>)}
                </>
            </Card>
        </Col>
    );
};


const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(CommentsPage)