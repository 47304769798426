import React from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { GetFleets } from 'src/services/fleet-http.service'
import { makeStyles } from '@material-ui/core/styles'

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "100%",
    },
}));

const FleetSelect = ({ value, onChange, variant, orgId }) => {
    const [fleets, setFleets] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeFleets();
    }, [fleets.length]);

    const initializeFleets = () => {
        let fleetArray = [];
        GetFleets(orgId)
            .then(data => {
                Array.isArray(data) && data.length > 0 ? data.map(fleet => {
                    fleetArray.push({ key: fleet.id, value: fleet.name });
                })
                    : console.log("Error Occured");
                setFleets(fleetArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            label="Fleet"
            id="fleet"
            name="fleetId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={fleets}
            variant={variant ? variant : false}
            className={classes.SelectControl}
        ></FormSelect>
    )
}

export default FleetSelect