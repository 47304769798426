import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

/////////////////////////////////////////////////////////// To get vehicle connectivity details in VSP page

export async function QueryConnectivityInformation(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Connectivity/QueryConnectivityInformation`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

/////////////////////////////////////////////////////////// To get map details in VSP page

export async function GetVSPMapClosestToMCC(vin, vspType, address) {

    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "vspType": vspType,
            "address": address
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Connectivity/GetVSPMapClosestToMCC`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}