import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function GetFleets(orgId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    var url = new URL(`${environment.backendVehicleService}/api/Fleet/CreatedByOrganizationId`);
    var params = { organizationId: orgId }
    url.search = new URLSearchParams(params).toString();

    let apiData = await fetch(url, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function QueryFleetsByIds(fleetIdArray) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleetIdArray)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/QueryFleetsByIds`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function QueryFleetNamesByIds(fleetIdArray) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleetIdArray)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/QueryNamesByIds`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function CreateFleet(fleet) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleet)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/Create`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function UpdateFleet(fleet) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(fleet)
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/EditFleetName`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function DeleteFleet(id) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "DELETE",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/Delete?id=${id}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

//................. Search functionality .........................//

export async function SearchFleet(fleetName) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/SearchFleetsByName?name=${fleetName}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

//.............................Pagination functionality........................//

export async function GetFleetPaged({ pageNumber, pageSize, fleetIdArray }) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "ids": fleetIdArray
        })
    };

    let statusCode = ''
    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/QueryFleetsByIdsPaged`, options)
        .then((response) => { statusCode = response.status; return response.json() })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    apiData = { ...apiData, statusCode: statusCode }

    return apiData;
}

