import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from "src/redux/user/user.selectors";
import BlackCar from "src/assets/img/BackgroundImages/BlackCar.png";
import BlueCar from "src/assets/img/BackgroundImages/BlueCar.png";
import actionButton_dark_lock from "src/assets/img/vehicles/actionButton_dark_lock.png";
import actionButton_dark_unlock from "src/assets/img/vehicles/actionButton_dark_unlock.png";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BelgiumFlag from "src/assets/img/flags/BelgiumFlag.png";
import FranceFlag from "src/assets/img/flags/FranceFlag.png";
import SwedenFlag from "src/assets/img/flags/SwedenFlag.png";
import ItalyFlag from "src/assets/img/flags/ItalyFlag.png";
import GermanyFlag from "src/assets/img/flags/GermanyFlag.png";
import SpainFlag from "src/assets/img/flags/SpainFlag.png";
import NetherlandsFlag from "src/assets/img/flags/Netherlands.png";
import MarketSelect from 'src/components/form-elements/market-select.component';
import FormSelect from 'src/components/form-elements/custom-form-select.component';
import { GetPNOCodesByMarket } from "src/services/pnoCode-http.service";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { permission } from 'src/auth/permissions';
import Box from '@mui/material/Box';
import { FormatDropDownValue } from "src/utils/RvTableExcelFormatter";
import { GetUcPricingByMarketAndPnoCode, CreateUcPricing } from "src/services/UCPricing-http.service";
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        height: '2px',
        marginBottom: '16px',
    },
    dividerStyleFlag: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: '25.5px',
        height: '2px',
        marginBottom: '10px'
    },
    dividerStyleSetPricingCondition: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: '15px',
        height: '2px',
        marginBottom: '10px'
    },
    titleStyle: {
        marginLeft: '5px',
        fontSize: 'calc(10px + 0.390625vw)',
        marginTop: '20px',
        fontFamily: "LynkcoTypeMedium",
        '@media (min-width: 600px) and (max-width: 1000px)': {
            fontSize: 'calc(17px + 0.390625vw)'
        },
    },
    imageStyleFlag: {
        width: '90%',
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            width: '65%',
        },
    },
    imageStyleCar: {
        marginTop: '10px',
        width: '65%',
        '@media (min-width: 600px) and (max-width: 850px)': {
            width: '50%',
        },
    },
    imageStyleButton: {
        width: '15%',
        cursor: 'pointer',
        boxShadow: '3px 5px 6px #000000',
        borderRadius: '50%'
    },
    backgroundStyle: {
        backgroundColor: 'transparent',
        marginTop: '80px',
        borderRadius: '1px',
        position: 'relative',
        overflow: 'auto',
        overflowX: 'hidden',
        height: '80vh',
        "&::-webkit-scrollbar": {
            height: "0.1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
        marginRight: '0px',
        marginLeft: '0px',
        '@media (min-width: 600px) and (max-width: 1000px)': {
            marginRight: '8px',
            marginLeft: '0px',
            marginTop: '80px',
            overflowY: 'auto'
        },
        '@media (min-width: 1001px) and (max-width: 1075px)': {
            marginRight: '10px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1112px) and (max-width: 1275px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
            overflowY: 'hidden'
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
        },
        '@media (min-width: 1441px) and (max-width: 1600px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '70px',
        },
        '@media (min-width: 1401px) and (max-width: 1440px)': {
            marginRight: '5px',
            marginLeft: '0px',
            marginTop: '60px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginRight: '0px',
            marginLeft: '0px',
            marginTop: '80px',
        },
    },
    flagStyle: {
        position: 'absolute',
        width: '100%',
        height: '400px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all 0.50s ease',
        "&::-webkit-transition": 'all 0.50s ease',
        "&::-moz-transition:": 'all 0.50s ease'
    },
}));

let queryParams = {};

const ReservedPricingAssignment = (props) => {
    const { userOrg, userMarketIds, userPermissions } = props
    const classes = UseStyles(props);

    const [selectPNO, setSelectPNO] = useState(false);
    const [selectMarket, setSelectMarket] = useState(false);
    const [isMarketSelected, setIsMarkedSelected] = useState(false)
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [pnoCode, setPNOCode] = useState([]);
    const [pnoCodeDetails, setPNOCodeDetails] = useState([]);
    const [pnoCodeDetail, setPNOCodeDetail] = useState({});
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsPriceRange, setQueryParamsPriceRange] = useState('');
    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [queryParamsMarketName, setQueryParamsMarketName] = useState('');
    const [placeholder, setPlaceholder] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [displayPNO, setDisplayPNO] = useState(false);
    const [displayPriceSelect, setDisplayPriceSelect] = useState(false);

    const [displayMarkup, setDisplayMarkup] = useState('');

    const [displayMilege_0_to_9999, setDisplayMileage_0_to_9999] = useState('');
    const [displayMilege_10000_to_14999, setDisplayMileage_10000_to_14999] = useState('');
    const [displayMilege_15000_to_24999, setDisplayMileage_15000_to_24999] = useState('');
    const [displayMilege_25000_to_29999, setDisplayMileage_25000_to_29999] = useState('');
    const [displayMilege_30000_to_39999, setDisplayMileage_30000_to_39999] = useState('');
    const [displayMilege_40000_to_49999, setDisplayMileage_40000_to_49999] = useState('');
    const [displayMilege_50000_to_59999, setDisplayMileage_50000_to_59999] = useState('');

    const [displayAge_0_to_11, setDisplayAge_0_to_11] = useState('');
    const [displayAge_12_to_23, setDisplayAge_12_to_23] = useState('');
    const [displayAge_24_to_36, setDisplayAge_24_to_36] = useState('');

    const [displayTowbar, setDisplayTowbar] = useState('');

    const [displayStandardValue, setDisplayStandardValue] = useState('');
    const [hideLabel, setHideLabel] = useState('');

    useEffect(() => {
        selectPNOCode();
        getPNOCode();
    }, [queryParamsMarket]);

    let marketId = 'NONE';
    let marketName = 'Select Market';

    const handleChange = (event, markets) => {
        const { name, value } = event.target;
        let orgsMarkets = [];
        marketId = value.split('#')[0];
        marketName = value.split('#')[1];
        setHideLabel(event.target.value);

        if (marketId == 'NONE') {
            setIsMarkedSelected(false);
            setSelectMarket(false);
            setDisplayPNO(false);
            setDisplayPriceSelect(false);
        }
        else {
            setIsMarkedSelected(true);
            setSelectMarket(true);
            setDisplayPNO(true);
            setDisplayPriceSelect(false);
        }
        setSelectedMarket({ key: name, value: value });
        setQueryParamsMarket(marketId);
        setQueryParamsMarketName(marketName);
        handleReset();
    };

    const handleReset = () => {
        setSelectPNO(false);
        setDisplayPriceSelect(false);
        setQueryParamsPriceRange('');
        setQueryParamsPNOCode('');
    };

    const selectPNOCode = () => {
        let pnoCodeArray = [];
        let pnoCodeDetails = [];

        GetPNOCodesByMarket(queryParamsMarket).then(data => {
            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeArray.push({
                        key: pnoCode.pnoCode,
                        value: pnoCode.pnoCode
                    });
                })
                : console.log("Error Occured");
            setPNOCode(pnoCodeArray);

            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeDetails.push({
                        pnoCode: pnoCode.pnoCode,
                        lcColor: pnoCode.lcColor,
                        lcEngineType: pnoCode.lcEngineType,
                        lcModelYear: pnoCode.lcModelYear,
                        obcVersion: pnoCode.obcVersion
                    });
                    setPNOCodeDetails(pnoCodeDetails);
                })
                : console.log("Error Occured");
        });
    };

    queryParams = { ...queryParams, marketId: queryParamsMarket };
    queryParams = { ...queryParams, pnoCode: queryParamsPNOCode };
    queryParams = { ...queryParams, markdownAmount: parseFloat(queryParamsPriceRange) };

    const getPNOCode = (pnoCodeValue) => {
        let filteredValue = pnoCodeDetails.filter(item => item.pnoCode === pnoCodeValue)[0];
        setPNOCodeDetail(filteredValue);
    };

    const handleChangeExtended = (name) => (event) => {
        const value = event.target.value
        console.log(event.target)

        if (name == 'pnoCode') {
            setQueryParamsPNOCode(value);
            getPNOCode(value);
            setSelectPNO(true);
            setDisplayPriceSelect(true);
            handleFetchSettings(queryParamsMarket, value);
            if (value == '') {
                setDisplayPriceSelect(false);
            }
        }
    };

    const handleSubmit = () => {
        let market = queryParamsMarket;
        let marketName = queryParamsMarketName;
        let pnoCode = queryParamsPNOCode;
        let standardValue = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayStandardValue.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayStandardValue.replace(/\s/g, ""));
        let markUp = parseFloat(displayMarkup.replace(/.$/, ""));
        let month0To11 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayAge_0_to_11.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayAge_0_to_11);
        let month12To23 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayAge_12_to_23.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayAge_12_to_23);
        let month24To36 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayAge_24_to_36.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayAge_24_to_36);
        let km0To9999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_0_to_9999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_0_to_9999);
        let km10000To14999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_10000_to_14999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_10000_to_14999);
        let km15000To24999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_15000_to_24999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_15000_to_24999);
        let km25000To29999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_25000_to_29999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_25000_to_29999);
        let km30000To39999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_30000_to_39999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_30000_to_39999);
        let km40000To49999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_40000_to_49999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_40000_to_49999);
        let km50000To59999 = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayMilege_50000_to_59999.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayMilege_50000_to_59999);
        let towBar = queryParamsMarketName == 'Sweden' ? (FormatDropDownValue(displayTowbar.replace(/\s/g, "")) / 10) : FormatDropDownValue(displayTowbar);

        if (placeholder === true) {
            setPlaceholder(false);
        } else {
            CreateUcPricing({
                "market": market, "marketName": marketName, "pnoCode": pnoCode, "standardValue": standardValue, "month0To11": month0To11, "markUp": markUp, "month12To23": month12To23, "month24To36": month24To36, "km0To9999": km0To9999,
                "km10000To14999": km10000To14999, "km15000To24999": km15000To24999, "km15000To24999": km15000To24999, "km25000To29999": km25000To29999, "km30000To39999": km30000To39999, "km40000To49999": km40000To49999, "km50000To59999": km50000To59999, "towBar": towBar
            }).then(data => {
                if (data.ok === true) {
                    setPlaceholder(true);
                    setOpenDialog(true);
                    setDialogMessage(`Your 'Reserved price' configuration is now applied`);
                } else {
                    setPlaceholder(false);
                    setOpenDialog(true);
                    setDialogMessage('Please make sure that Market, PNO and other values is selected ');
                }
            })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const handleChangeUCA = (value) => (event) => {
        if (value == 'standardValue') {
            setDisplayStandardValue(event.target.value);
        };

        if (value == 'markUp') {
            setDisplayMarkup(event.target.value);
        };

        if (value == 'mileage_0_to_9999') {
            setDisplayMileage_0_to_9999(event.target.value);
        };

        if (value == 'mileage_10000_to_14999') {
            setDisplayMileage_10000_to_14999(event.target.value);
        };

        if (value == 'mileage_15000_to_24999') {
            setDisplayMileage_15000_to_24999(event.target.value);
        };

        if (value == 'mileage_25000_to_29999') {
            setDisplayMileage_25000_to_29999(event.target.value);
        };

        if (value == 'mileage_30000_to_39999') {
            setDisplayMileage_30000_to_39999(event.target.value);
        };

        if (value == 'mileage_40000_to_49999') {
            setDisplayMileage_40000_to_49999(event.target.value);
        };

        if (value == 'mileage_50000_to_59999') {
            setDisplayMileage_50000_to_59999(event.target.value);
        };

        if (value == 'age_0_to_11') {
            setDisplayAge_0_to_11(event.target.value);
        };

        if (value == 'age_12_to_23') {
            setDisplayAge_12_to_23(event.target.value);
        };

        if (value == 'age_24_to_36') {
            setDisplayAge_24_to_36(event.target.value);
        };


        if (value == 'towbar') {
            setDisplayTowbar(event.target.value);
        };
    };

    const handleFetchSettings = (marketCode, pnoCode) => {
        GetUcPricingByMarketAndPnoCode(marketCode, pnoCode).then(data => {
            if (data.ok == true) {
                let filteredPNO = data.ucPricing.filter(item => item.pnoCode === pnoCode)[0];

                if (data.ucPricing.length > 0) {
                    setPlaceholder(true);
                    queryParamsMarketName == 'Sweden' ? setDisplayStandardValue(`${formatAndVerifyNumericValue(filteredPNO.standardValue * 10)} SEK`) : setDisplayStandardValue(`${formatAndVerifyNumericValue(filteredPNO.standardValue)} EUR`);
                    setDisplayMarkup(`${filteredPNO.markUp} %`);
                    queryParamsMarketName == 'Sweden' ? setDisplayAge_0_to_11(`${formatAndVerifyNumericValue(filteredPNO.month0To11 * 10)} SEK`) : setDisplayAge_0_to_11(`${filteredPNO.month0To11} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayAge_12_to_23(`${formatAndVerifyNumericValue(filteredPNO.month12To23 * 10)} SEK`) : setDisplayAge_12_to_23(`${filteredPNO.month12To23} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayAge_24_to_36(`${formatAndVerifyNumericValue(filteredPNO.month24To36 * 10)} SEK`) : setDisplayAge_24_to_36(`${filteredPNO.month24To36} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_0_to_9999(`${formatAndVerifyNumericValue(filteredPNO.km0To9999 * 10)} SEK`) : setDisplayMileage_0_to_9999(`${filteredPNO.km0To9999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_10000_to_14999(`${formatAndVerifyNumericValue(filteredPNO.km10000To14999 * 10)} SEK`) : setDisplayMileage_10000_to_14999(`${filteredPNO.km10000To14999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_15000_to_24999(`${formatAndVerifyNumericValue(filteredPNO.km15000To24999 * 10)} SEK`) : setDisplayMileage_15000_to_24999(`${filteredPNO.km15000To24999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_25000_to_29999(`${formatAndVerifyNumericValue(filteredPNO.km25000To29999 * 10)} SEK`) : setDisplayMileage_25000_to_29999(`${filteredPNO.km25000To29999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_30000_to_39999(`${formatAndVerifyNumericValue(filteredPNO.km30000To39999 * 10)} SEK`) : setDisplayMileage_30000_to_39999(`${filteredPNO.km30000To39999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_40000_to_49999(`${formatAndVerifyNumericValue(filteredPNO.km40000To49999 * 10)} SEK`) : setDisplayMileage_40000_to_49999(`${filteredPNO.km40000To49999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayMileage_50000_to_59999(`${formatAndVerifyNumericValue(filteredPNO.km50000To59999 * 10)} SEK`) : setDisplayMileage_50000_to_59999(`${filteredPNO.km50000To59999} EUR`);
                    queryParamsMarketName == 'Sweden' ? setDisplayTowbar(`${formatAndVerifyNumericValue(filteredPNO.towBar * 10)} SEK`) : setDisplayTowbar(`${filteredPNO.towBar} EUR`);
                }
            } else {
                setPlaceholder(false);
                setDisplayStandardValue('');
                setDisplayMarkup('');
                setDisplayAge_0_to_11('');
                setDisplayAge_12_to_23('');
                setDisplayAge_24_to_36('');
                setDisplayMileage_0_to_9999('');
                setDisplayMileage_10000_to_14999('');
                setDisplayMileage_15000_to_24999('');
                setDisplayMileage_25000_to_29999('');
                setDisplayMileage_30000_to_39999('');
                setDisplayMileage_40000_to_49999('');
                setDisplayMileage_50000_to_59999('');
                setDisplayTowbar('');
            }
        })
    };

    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

    return (
        <>
            <div className={classes.backgroundStyle}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                />

                <Row>
                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        <Card id="CardBodyCardAllowance8">
                            <CardBody>
                                <Row style={{ marginTop: '-20px' }}>
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select Market
                                        </Typography>

                                        <div className="formwidthstyleBMC" style={{ marginLeft: '5px', marginTop: '17px' }}>
                                            <MarketSelect
                                                orgId={userOrg.id}
                                                value={selectedMarket.value}
                                                userMarketIds={userMarketIds}
                                                handleChange={handleChange}
                                                isVariant='true'
                                                hideLabel={hideLabel}
                                                isStandardMarket="true"
                                            />
                                        </div>

                                        {selectMarket &&
                                            <div className="flagstylebmc">
                                                <span
                                                    className={classes.flagStyle}
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                    onClick={() => props.onClick && props.onClick()}
                                                    style={{
                                                        ...{ opacity: hovered ? '0.5' : '1' }
                                                    }}
                                                >
                                                    <img
                                                        alt="Card image cap"
                                                        src={
                                                            queryParamsMarket == 'BE' ? BelgiumFlag :
                                                                queryParamsMarket == 'SE' ? SwedenFlag :
                                                                    queryParamsMarket == 'DE' ? GermanyFlag :
                                                                        queryParamsMarket == 'ES' ? SpainFlag :
                                                                            queryParamsMarket == 'NL' ? NetherlandsFlag :
                                                                                queryParamsMarket == 'FR' ? FranceFlag :
                                                                                    queryParamsMarket == 'IT' ? ItalyFlag : <></>
                                                        }
                                                        className={classes.imageStyleFlag}
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch" >
                        {
                            displayPNO &&

                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12" >
                                            <Col lg="12" md="12" style={{ marginLeft: '-11px' }} >
                                                <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                    Select PNO
                                                </Typography>
                                            </Col>

                                            <div className="formwidthstyleBMC" style={{ marginLeft: '10px', marginTop: '17px' }}>

                                                <FormSelect
                                                    label='PNO'
                                                    name='pnoCode'
                                                    dropdownfont='true'
                                                    value={queryParamsPNOCode}
                                                    menuObjectArray={pnoCode}
                                                    onChange={handleChangeExtended("pnoCode")}
                                                />
                                            </div>

                                            <Row style={{ marginTop: '30px', }}>
                                                <Col md={12} sm={12} xs={12} lg={12}>
                                                    {selectPNO &&
                                                        <>
                                                            {
                                                                pnoCodeDetail !== undefined &&
                                                                <div className="tableFixHead">
                                                                    <Row>
                                                                        <table className="tablestylereservedpricing">
                                                                            <tr>
                                                                                <td id="TabletitlesPoolCars">
                                                                                    {
                                                                                        <img
                                                                                            src={pnoCodeDetail.lcColor == 'Blue' ? BlueCar : BlackCar}
                                                                                            className={classes.imageStyleCar}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <br />
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="fontstylePNOLeft"><span>Model Year</span> </td>
                                                                                <td className="fontstylePNORight"><span>{pnoCodeDetail.lcModelYear}</span></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span>Color</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcColor === 'Blue' ? 'Blue' : 'Black'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span>Engine Type</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.lcEngineType}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <Divider className={classes.dividerStyle} style={{ marginTop: '3px' }} orientation='horizontal' />
                                                                                <td className="fontstylePNOLeft"><span>OBC Version</span> </td>
                                                                                <td className="fontstylePNORight">{pnoCodeDetail.obcVersion}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </Row>
                                                                </div>
                                                            }
                                                        </>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>

                    <Col lg="4" md="12" className="d-flex align-items-stretch">
                        {
                            displayPriceSelect &&
                            <Card id="CardBodyCardAllowance8">
                                <CardBody>
                                    <Row style={{ marginTop: '-20px' }}>
                                        <Col lg="12" md="12" sm="12" xs="12" >
                                            <Typography style={{ marginLeft: '14px' }} className={classes.titleStyle} variant="h4" id="tableTitle" >
                                                Set pricing conditions
                                            </Typography>
                                            <Row className="rowstyleReservedPice">
                                                <Col lg="12" md="12" >
                                                    <Box
                                                        component="form"
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6" >
                                                                <FormSelect
                                                                    label='Standard value'
                                                                    name='standardValue'
                                                                    dropdownfont='true'
                                                                    standardvalueform='true'
                                                                    value={displayStandardValue}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray=
                                                                    {
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '310 000 SEK', 'value': '310 000 SEK' },
                                                                                { 'key': '307 500 SEK', 'value': '307 500 SEK' },
                                                                                { 'key': '305 000 SEK', 'value': '305 000 SEK' },
                                                                                { 'key': '302 500 SEK', 'value': '302 500 SEK' },
                                                                                { 'key': '300 000 SEK', 'value': '300 000 SEK' },
                                                                                { 'key': '297 500 SEK', 'value': '297 500 SEK' },
                                                                                { 'key': '292 500 SEK', 'value': '292 500 SEK' },
                                                                                { 'key': '290 000 SEK', 'value': '290 000 SEK' },
                                                                                { 'key': '287 500 SEK', 'value': '287 500 SEK' },
                                                                                { 'key': '285 000 SEK', 'value': '285 000 SEK' },
                                                                                { 'key': '282 500 SEK', 'value': '282 500 SEK' },
                                                                                { 'key': '280 000 SEK', 'value': '280 000 SEK' },
                                                                                { 'key': '277 500 SEK', 'value': '277 500 SEK' },
                                                                                { 'key': '275 000 SEK', 'value': '275 000 SEK' },
                                                                                { 'key': '272 500 SEK', 'value': '272 500 SEK' },
                                                                                { 'key': '275 000 SEK', 'value': '275 000 SEK' },
                                                                                { 'key': '272 500 SEK', 'value': '272 500 SEK' },
                                                                                { 'key': '270 000 SEK', 'value': '270 000 SEK' },
                                                                                { 'key': '267 500 SEK', 'value': '267 500 SEK' },
                                                                                { 'key': '265 000 SEK', 'value': '265 000 SEK' },
                                                                                { 'key': '262 500 SEK', 'value': '262 500 SEK' },
                                                                                { 'key': '260 000 SEK', 'value': '260 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '31 000 EUR', 'value': '31 000 EUR' },
                                                                                { 'key': '30 750 EUR', 'value': '30 750 EUR' },
                                                                                { 'key': '30 500 EUR', 'value': '30 500 EUR' },
                                                                                { 'key': '30 250 EUR', 'value': '30 250 EUR' },
                                                                                { 'key': '30 000 EUR', 'value': '30 000 EUR' },
                                                                                { 'key': '29 750 EUR', 'value': '29 750 EUR' },
                                                                                { 'key': '29 250 EUR', 'value': '29 250 EUR' },
                                                                                { 'key': '29 000 EUR', 'value': '29 000 EUR' },
                                                                                { 'key': '28 750 EUR', 'value': '28 750 EUR' },
                                                                                { 'key': '28 500 EUR', 'value': '28 500 EUR' },
                                                                                { 'key': '28 250 EUR', 'value': '28 250 EUR' },
                                                                                { 'key': '28 000 EUR', 'value': '28 000 EUR' },
                                                                                { 'key': '27 750 EUR', 'value': '27 750 EUR' },
                                                                                { 'key': '27 500 EUR', 'value': '27 500 EUR' },
                                                                                { 'key': '27 250 EUR', 'value': '27 250 EUR' },
                                                                                { 'key': '27 500 EUR', 'value': '27 500 EUR' },
                                                                                { 'key': '27 250 EUR', 'value': '27 250 EUR' },
                                                                                { 'key': '27 000 EUR', 'value': '27 000 EUR' },
                                                                                { 'key': '26 750 EUR', 'value': '26 750 EUR' },
                                                                                { 'key': '26 500 EUR', 'value': '26 500 EUR' },
                                                                                { 'key': '26 250 EUR', 'value': '26 250 EUR' },
                                                                                { 'key': '26 000 EUR', 'value': '26 000 EUR' }
                                                                            ]
                                                                    }

                                                                    onChange={handleChangeUCA("standardValue")}
                                                                />
                                                            </Col>
                                                            <Col lg="6" md="6" sm="6" xs="6" ></Col>
                                                        </Row>

                                                        <Row className="rowstylereservedpricing">
                                                            <Col lg="6" md="6" sm="6" xs="6"><Typography className={classes.titleStyle} >Mileage</Typography></Col>
                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-7px' }}><Typography className={classes.titleStyle} >Age</Typography></Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <FormSelect
                                                                    label='0 to 9 999 km'
                                                                    name='mileage_0_to_9999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_0_to_9999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_0_to_9999")}
                                                                />
                                                            </Col>
                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-7px' }}>
                                                                <FormSelect
                                                                    label='0 to 11 months'
                                                                    name='age_0_to_11'
                                                                    dropdownfont='true'
                                                                    value={displayAge_0_to_11}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("age_0_to_11")}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <FormSelect
                                                                    label='10 000 to 14 999 km'
                                                                    name='mileage_10000_to_14999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_10000_to_14999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_10000_to_14999")}
                                                                />
                                                            </Col>
                                                            <Col llg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-7px' }}>
                                                                <FormSelect
                                                                    label='12 to 23 months'
                                                                    name='age_12_to_23'
                                                                    dropdownfont='true'
                                                                    value={displayAge_12_to_23}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 00 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("age_12_to_23")}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <FormSelect
                                                                    label='15 000 to 24 999 km'
                                                                    name='mileage_15000_to_24999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_15000_to_24999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_15000_to_24999")}
                                                                />
                                                            </Col>
                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-6px' }}>
                                                                <FormSelect
                                                                    label='24 to 36 months'
                                                                    name='age_24_to_36'
                                                                    dropdownfont='true'
                                                                    value={displayAge_24_to_36}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 00 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("age_24_to_36")}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop: '-15px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginTop: '20px' }}>
                                                                <FormSelect
                                                                    label='25 000 to 29 999 km'
                                                                    name='mileage_25000_to_29999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_25000_to_29999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_25000_to_29999")}
                                                                />
                                                            </Col>

                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-7px', marginTop: '20px' }}></Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '-15px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginTop: '20px' }}>
                                                                <FormSelect
                                                                    label='30 000 to 39 999 km'
                                                                    name='mileage_30000_to_39999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_30000_to_39999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_30000_to_39999")}
                                                                />
                                                            </Col>
                                                            <Col lg="6" md="6" sm="6" xs="6" ></Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <FormSelect
                                                                    label='40 000 to 49 999 km'
                                                                    name='mileage_40000_to_49999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_40000_to_49999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-47 500 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_40000_to_49999")}
                                                                />
                                                            </Col>

                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-7px', }}>
                                                                <FormSelect
                                                                    label='Towbar'
                                                                    name='towbar'
                                                                    dropdownfont='true'
                                                                    value={displayTowbar}
                                                                    towbardropdownheight="true"
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("towbar")}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={{ marginTop: '5px', marginLeft: '3px' }}>
                                                            <Col lg="6" md="6" sm="6" xs="6">
                                                                <FormSelect
                                                                    label='50 000 to 59 999 km'
                                                                    name='mileage_50000_to_59999'
                                                                    dropdownfont='true'
                                                                    value={displayMilege_50000_to_59999}
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={
                                                                        queryParamsMarketName == 'Sweden' ?
                                                                            [
                                                                                { 'key': '15 000 SEK', 'value': '15 000 SEK' },
                                                                                { 'key': '12 500 SEK', 'value': '12 500 SEK' },
                                                                                { 'key': '10 000 SEK', 'value': '10 000 SEK' },
                                                                                { 'key': '7 500 SEK', 'value': '7 500 SEK' },
                                                                                { 'key': '5 000 SEK', 'value': '5 000 SEK' },
                                                                                { 'key': '2 500 SEK', 'value': '2 500 SEK' },
                                                                                { 'key': '0 SEK', 'value': '0 SEK' },
                                                                                { 'key': '-2 500 SEK', 'value': '-2 500 SEK' },
                                                                                { 'key': '-5 000 SEK', 'value': '-5 000 SEK' },
                                                                                { 'key': '-7 500 SEK', 'value': '-7 500 SEK' },
                                                                                { 'key': '-10 000 SEK', 'value': '-10 000 SEK' },
                                                                                { 'key': '-12 500 SEK', 'value': '-12 500 SEK' },
                                                                                { 'key': '-15 000 SEK', 'value': '-15 000 SEK' },
                                                                                { 'key': '-17 500 SEK', 'value': '-17 500 SEK' },
                                                                                { 'key': '-20 000 SEK', 'value': '-20 000 SEK' },
                                                                                { 'key': '-22 500 SEK', 'value': '-22 500 SEK' },
                                                                                { 'key': '-25 000 SEK', 'value': '-25 000 SEK' },
                                                                                { 'key': '-27 500 SEK', 'value': '-27 500 SEK' },
                                                                                { 'key': '-30 000 SEK', 'value': '-30 000 SEK' },
                                                                                { 'key': '-32 500 SEK', 'value': '-32 500 SEK' },
                                                                                { 'key': '-35 000 SEK', 'value': '-35 000 SEK' },
                                                                                { 'key': '-37 500 SEK', 'value': '-37 500 SEK' },
                                                                                { 'key': '-40 000 SEK', 'value': '-40 000 SEK' },
                                                                                { 'key': '-42 500 SEK', 'value': '-42 500 SEK' },
                                                                                { 'key': '-45 000 SEK', 'value': '-45 000 SEK' },
                                                                                { 'key': '-47 500 SEK', 'value': '-4750 SEK' },
                                                                                { 'key': '-50 000 SEK', 'value': '-50 000 SEK' }
                                                                            ]
                                                                            :
                                                                            [
                                                                                { 'key': '1500 EUR', 'value': '1500 EUR' },
                                                                                { 'key': '1250 EUR', 'value': '1250 EUR' },
                                                                                { 'key': '1000 EUR', 'value': '1000 EUR' },
                                                                                { 'key': '750 EUR', 'value': '750 EUR' },
                                                                                { 'key': '500 EUR', 'value': '500 EUR' },
                                                                                { 'key': '250 EUR', 'value': '250 EUR' },
                                                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                                                { 'key': '-1750 EUR', 'value': '-1750 EUR' },
                                                                                { 'key': '-2000 EUR', 'value': '-2000 EUR' },
                                                                                { 'key': '-2250 EUR', 'value': '-2250 EUR' },
                                                                                { 'key': '-2500 EUR', 'value': '-2500 EUR' },
                                                                                { 'key': '-2750 EUR', 'value': '-2750 EUR' },
                                                                                { 'key': '-3000 EUR', 'value': '-3000 EUR' },
                                                                                { 'key': '-3250 EUR', 'value': '-3250 EUR' },
                                                                                { 'key': '-3500 EUR', 'value': '-3500 EUR' },
                                                                                { 'key': '-3750 EUR', 'value': '-3750 EUR' },
                                                                                { 'key': '-4000 EUR', 'value': '-4000 EUR' },
                                                                                { 'key': '-4250 EUR', 'value': '-4250 EUR' },
                                                                                { 'key': '-4500 EUR', 'value': '-4500 EUR' },
                                                                                { 'key': '-4750 EUR', 'value': '-4750 EUR' },
                                                                                { 'key': '-5000 EUR', 'value': '-5000 EUR' }
                                                                            ]
                                                                    }
                                                                    onChange={handleChangeUCA("mileage_50000_to_59999")}
                                                                />
                                                            </Col>

                                                            <Col lg="6" md="6" sm="6" xs="6" style={{ marginLeft: '-6px' }}>
                                                                <FormSelect
                                                                    label='Markup'
                                                                    name='markUp'
                                                                    dropdownfont='true'
                                                                    value={displayMarkup}
                                                                    obcdropdownheight="true"
                                                                    disabled={placeholder ? true : false}
                                                                    menuObjectArray={[
                                                                        { 'key': '1 %', 'value': '1 %' },
                                                                        { 'key': '1.5 %', 'value': '1.5 %' },
                                                                        { 'key': '2 %', 'value': '2 %' },
                                                                        { 'key': '2.5 %', 'value': '2.5 %' },
                                                                        { 'key': '3 %', 'value': '3 %' },
                                                                        { 'key': '3.5 %', 'value': '3.5 %' },
                                                                        { 'key': '4 %', 'value': '4 %' },
                                                                        { 'key': '4.5 %', 'value': '4.5 %' },
                                                                        { 'key': '5 %', 'value': '5 %' }
                                                                    ]}
                                                                    onChange={handleChangeUCA("markUp")}
                                                                />
                                                            </Col>
                                                        </Row>


                                                    </Box>
                                                </Col>

                                            </Row>
                                            <Row className="buttonHeightStyle">
                                                <Col lg="12" md="12" >
                                                    {
                                                        matchesPermission &&
                                                        <div class="click-to-top" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                            <span>

                                                            </span>
                                                            <img
                                                                src={placeholder ? actionButton_dark_lock : actionButton_dark_unlock}
                                                                className={classes.imageStyleButton}
                                                                onClick={handleSubmit}
                                                            />
                                                            <span style={{ color: placeholder ? 'rgb(193, 39, 37)' : '#1EF1C6', fontFamily: "LynkcoTypeRegular" }}>{placeholder ? 'Edit' : 'Confirm'}</span>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(ReservedPricingAssignment);