import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormmatDate } from 'src/utils/DateFormatter';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import EnhancedTableToolbar from 'src/components/tables/table-toolbar.component';
import AlertDialog from 'src/components/form-elements/dialog-window.component';
import Drawer from '@material-ui/core/Drawer';
import OrganizationAddEdit from 'src/components/form-elements/organization-add-edit.component';
import { GetFleets } from 'src/services/fleet-http.service'
import { GetOrganizationPaged, CreateOrganization, UpdateOrganization, DeleteOrganization, SearchOrganization } from 'src/services/organization-http.service'
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { permission } from 'src/auth/permissions';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

let rowsPerPage = 200;

let newPageVariable = 1;

const drawerWidth = '240';

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        marginTop: -theme.spacing(5.9),
        marginBottom: -theme.spacing(5.9),
        marginLeft: -theme.spacing(30),
        marginRight: theme.spacing(3.9),
        paddingLeft: "250px",
        paddingRight: "50px",
        height: "105%",
        width: "120%",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        position: 'absolute',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '160px'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        height: 'max-content',
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '9%',
            right: '45px'
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 2,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 190,
    },
}));


const OrganizationTable = ({ userOrg, userPermissions, inputValue }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const classes = useTableStyles();
    const drawerClasses = useDrawerStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('')
    const [isErrorDialog, setErrorDialogFlag] = useState(false)
    const [rows, setOrganizations] = useState([]);
    const [organizationNameInput, setOrganizationNameInput] = useState('')
    const [totalPages, setTotalPages] = useState(0);
    const [organizationInstance, setOrganizationInstance] = useState({
        id: "",
        name: "",
        parentOrganization: "",
        viewFleetIds: [],
        createdDateUtc: "",
        editMode: false
    });

    const [searchInput, setSearchInput] = useState('');
    const [showSearchBar, setShowSearchBar] = useState(false);

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);
    const [toggleSearchBar, setToggleSearchBar] = useState(false);
    const [enableSelect, setEnableSelect] = useState(true);

    const columns = useMemo(
        () => [
            { Header: 'Organization name', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Parent organization', accessor: "createdByOrganizationName", disableGlobalFilter: true, },
            { Header: 'Created', accessor: "createdDateUtc", disableGlobalFilter: true, }
        ],
        [],
    );

    let fleetIdArray = [];

    useEffect(() => {
        initializeFleetIds();
        initializeOrganizations();
        setOpenDialog(false);
    }, []);

    useEffect(() => {
        if (searchInput.length === 0) {
            initializeFleetIds();
            initializeOrganizations();
        }

    }, [searchInput])

    const initializeOrganizations = () => {
        let organizationArray = []
        GetOrganizationPaged({ pageNumber: newPageVariable, pageSize: rowsPerPage, parentOrganizationId: userOrg.id }).then(data => {
            Array.isArray(data.organizationFamily) ? data.organizationFamily.map(org => {
                setTotalPages(data.totalPages)
                organizationArray.push(
                    {
                        name: org.name,
                        id: org.id,
                        createdByOrganizationName: org.createdByOrganizationName,
                        viewFleetIds: org.viewFleetIds,
                        createdDateUtc: FormmatDate(org.createdDateUtc)
                    }
                );
            })
                : console.log("Error Occured");
            setOrganizations(organizationArray);
        });
    }

    const initializeFleetIds = () => {
        GetFleets()
            .then(data => {
                (data && Array.isArray(data)) ?
                    data.map(role => {
                        fleetIdArray.push({ key: role.id, value: role.name });
                    }) :
                    fleetIdArray.push({ key: data.id, value: data.name });
            }).catch(function (error) { console.log(error); });
    }

    const handleClickAdd = () => {
        resetOrganizationInstance();
        setOpen(true);
        setEditMode(false);
    };

    const handleClickEdit = () => {
        setEditMode(true);
        setOrganizationInstance({
            ...organizationInstance, id: selectedRow.id, name: selectedRow.name,
            parentOrganization: selectedRow.parentOrganization, viewFleetIds: selectedRow.viewFleetIds,
            createdDateUtc: selectedRow.createdDateUtc, editMode: true
        })
        setOpen(true);
    };

    const handleClickDelete = () => {
        setOpenDialog(false);
        setDialogMessage(`You are about to delete the selected fleet!`)
        setErrorDialogFlag(false)
        if (selected.length !== 0) {
            setOpenDialog(true);
        }
    };

    const handleDeleteDialogContinue = () => {
        const result = deleteOrganization(selectedRow.id).then(data => {
            if (!data.Ok) {
                setDialogMessage(data.errorMessage);
                setErrorDialogFlag(true);
            }
            return data;
        });

        setSelected('');
        setOpenDialog(false);
        setEnableSelect(true);
    }
    const handleDeleteDialogCancel = () => {
        setOpenDialog(false)
        setSelected('');
    }
    const handleDialogOk = () => {
        setOpenDialog(false)
        setSelected('');
    }

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
        setEnableSelect(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveOrganization();
        setEnableSelect(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        name === "Organization name" ? setOrganizationInstance({ ...organizationInstance, name: value })
            : setOrganizationInstance({ ...organizationInstance, viewFleetIds: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }

        setSelected(newSelected);
        setSelectedRow({
            id: row.organizationId,
            name: row.name,
            parentOrganization: row.parentOrganization,
            viewFleetIds: row.viewFleetIds,
            createdDateUtc: row.createdDateUtc
        });
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        newPageVariable = newPage + 1
        setPage(newPage);
        initializeOrganizations();

    };

    const handleChangeRowsPerPage = (event) => {
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        initializeOrganizations();
    };

    const saveOrganization = () => {
        organizationInstance.editMode ?
            UpdateOrganization({
                "id": organizationInstance.id,
                "name": organizationInstance.name,
                "viewFleetIds": organizationInstance.viewFleetIds
            }).then(data => {
                initializeOrganizations();
                setSelected('');
                resetOrganizationInstance();
                if (data.statusCode === 409) {
                    setDialogMessage("An organization with the given name already exists.")
                    setOpenDialog(true)
                    setErrorDialogFlag(true)
                }
            })
            :
            CreateOrganization({
                "name": organizationInstance.name,
                "createdByOrganizationId": userOrg.id,
                "viewFleetIds": organizationInstance.viewFleetIds
            }).then(data => {
                initializeOrganizations();
                setSelected('');
                resetOrganizationInstance();
                if (data.statusCode === 409) {
                    setDialogMessage("An organization with the given name already exists.")
                    setOpenDialog(true)
                    setErrorDialogFlag(true)
                }
            });
    };

    const resetOrganizationInstance = () => {
        setOrganizationInstance({
            id: "",
            name: "",
            parentOrganization: "",
            viewFleetIds: [],
            createdDateUtc: "",
            editMode: false
        });
    };

    const deleteOrganization = (organizationId) => {
        const result = DeleteOrganization(organizationId).then(data => {
            initializeOrganizations();
            resetOrganizationInstance();
            return data;
        })

        return result;
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const selectedRowValues = (row) => {
        setSelectedRow({
            name: row.original.name,
            id: row.original.id,
            createdByOrganizationName: row.original.createdByOrganizationName,
            fleetName: row.original.fleetName,
            viewFleetIds: row.original.viewFleetIds,
            createdDateUtc: FormmatDate(row.original.createdDateUtc)

        })
        setSelected({
            name: row.original.name,
            id: row.original.id,
            createdByOrganizationName: row.original.createdByOrganizationName,
            fleetName: row.original.fleetName,
            viewFleetIds: row.original.viewFleetIds,
            createdDateUtc: FormmatDate(row.original.createdDateUtc)

        });

    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };


    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let organizationArray = [];

            SearchOrganization(searchInput.trim())

                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(org => {
                            organizationArray.push(
                                {
                                    name: org.name,
                                    id: org.id,
                                    createdByOrganizationName: org.createdByOrganizationName,
                                    fleetName: org.fleetName,
                                    viewFleetIds: org.viewFleetIds,
                                    createdDateUtc: FormmatDate(org.createdDateUtc)
                                }
                            );
                        })
                    }
                    setOrganizations(organizationArray);
                });
        }

    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        setSearchInput('');
    };

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator);

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Col md={12} lg={12}>
            <AlertDialog
                className={drawerClasses.root}
                open={openDialog}
                message={dialogMessage}
                handleDeleteDialogCancel={handleDeleteDialogCancel}
                handleDeleteDialogContinue={handleDeleteDialogContinue}
                handleDialogOk={handleDialogOk}
                isErrorDialog={isErrorDialog} />

            <Drawer
                variant="permanet"
                anchor="right"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                classes={{
                    paper: drawerClasses.drawerPaper,
                }}>
                <OrganizationAddEdit orgId={userOrg.id} organization={organizationInstance} editMode={editMode}
                    onChange={handleChange} handleClose={handleClose} handleDone={handleDone}></OrganizationAddEdit>
            </Drawer>
            <div id="tablecardRolePage">
                <Row className='headerStyleRolePage'>
                    <Col lg="6" md="6" sm="6" xs="6" ></Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                        {
                            showSearchBar ?
                                <div className='searchStyleOrg'>
                                    <SearchField
                                        handleSearchbar={handleSearchbar}
                                        handleSearch={handleSearch}
                                        handleSearchInput={handleSearchInput}
                                        onKeyPress={textFieldKeypress}
                                        value={searchInput}
                                        priceBucketActiveTab='true'
                                        autoFocus='true'
                                        placeholder={'Organization name'}
                                    />
                                </div>
                                :
                                <Tooltip
                                    title="open search"
                                    onOpen={handleOpenMagnifyIcon}
                                    onClose={handleCloseMagnifyIcon}
                                    style={{ zIndex: 200 }}
                                >
                                    <IconButton
                                        onClick={handleCloseSearchbar}
                                    >
                                        <img
                                            src={iconMagnify}
                                            className="magnifyIconOrg"
                                        />
                                    </IconButton>
                                </Tooltip>
                        }
                    </Col >

                    <Col lg="2" md="2" sm="2" xs="2" className='columnstyle'>
                        {
                            matchesPermission &&
                            <div className='toolbarstyleOrg'>
                                <EnhancedTableToolbar
                                    selectedRowId={selected}
                                    handleClickDelete={handleClickDelete}
                                    handleClickEdit={handleClickEdit}
                                    handleClickAdd={handleClickAdd}
                                    enableSelect={enableSelect}
                                />
                            </div>
                        }
                    </Col>

                    <Col lg="2" md="2" sm="2" xs="2"></Col>
                </Row>

                <>
                    <Fade bottom duration={1500}>
                        <div className="tableFixHeadAdmin">
                            {
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRowValues={selectedRowValues}
                                    setEnableSelect={setEnableSelect}
                                />
                            }
                        </div>
                    </Fade>
                </>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(OrganizationTable)