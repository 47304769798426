import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTableDnDBody from './ReactTableDnDBody';

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow, handleDrawar, closeHandleDrawer, selectedRowValues, drawerToggle, setdrawerToggle, packageSelectTable }) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      return (
        <tr className="tr-hover"
          {...row.getRowProps({
            onClick: (e) => {
              if (e.detail == 2 && packageSelectTable) {
                if (row) {
                  if (row.index == drawerToggle) {
                    closeHandleDrawer();
                    setdrawerToggle(null);
                  }
                  else {
                    selectedRowValues(row);
                    setdrawerToggle(row.index);
                    handleDrawar();
                  }
                }
              }
            },
          })}
        >
          {row.cells.map(cell => (
            <td className="td2" {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, setdrawerToggle, selectedRowValues, handleDrawar, closeHandleDrawer, packageSelectTable
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          setdrawerToggle={setdrawerToggle}
          selectedRowValues={selectedRowValues}
          handleDrawar={handleDrawar}
          closeHandleDrawer={closeHandleDrawer}
          packageSelectTable={packageSelectTable}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          setdrawerToggle={setdrawerToggle}
          selectedRowValues={selectedRowValues}
          handleDrawar={handleDrawar}
          closeHandleDrawer={closeHandleDrawer}
          packageSelectTable={packageSelectTable}
        />
      )}
  </Fragment>
);

// ReactTableBody.propTypes = {
//   page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
//   getTableBodyProps: PropTypes.func.isRequired,
//   prepareRow: PropTypes.func.isRequired,
//   updateDraggableData: PropTypes.func.isRequired,
//   withDragAndDrop: PropTypes.bool.isRequired,
//   // ReactTableDefaultBody: PropTypes.func.isRequired,
// };

export default ReactTableBody
