import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const MaterialUISwitch = styled(Switch)(({ theme, disabled }) => ({
    width: 88,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,

        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            marginLeft: 29,
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/icons/SliderIcon.png"})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                boxShadow: '2px 2px 3px 1px rgba(0,0,0, 0.6)',
                borderRadius: '50%'
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#1EF1C6',
                height: 10,
                marginTop: 4,
                boxShadow: 'inset 3px 3px 5px rgba(0,0,0,0.8)'
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 30,
        height: 30,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/icons/SliderIcon.png"})`,
            boxShadow: '1px 2px 3px 1px rgba(0,0,0, 0.6)',
            borderRadius: '50%'
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#808080',
        borderRadius: 20 / 2,
        height: 10,
        marginTop: 4,
        boxShadow: 'inset 0px 2px 4px rgba(0,0,0,0.9)',
    },
}));

const ToggleComponent = ({ checked, onChange, disabled }) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={<MaterialUISwitch sx={{ m: -3, marginTop: '8px' }} checked={checked} disabled={disabled} onChange={onChange} />}
                label=""
            />
        </FormGroup>
    )
};

export default ToggleComponent;