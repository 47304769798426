import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { paddingRight } from 'src/containers/LayoutNew/scrollBar/directions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '-180px',
        marginLeft: '-10px',
        marginRight: '-10px',
        backgroundColor: '#212121',
        borderRadius: '1px'
    },
    toolbar: {
        paddingBottom: theme.spacing(1),
        display: 'flex',
        position: 'relative'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        cursor: 'pointer',
        fontSize: '17px', color: '#fff',
        marginLeft: '10vw',
        display: 'flex',
        marginTop: '-30px',
        position: 'relative',
    },
}));

const extractdateValue = (data) => {
    if (data) {
        let splitted = data.split(' ');
        return splitted[0];
    }
};

const WorkOrderDetail = (props) => {
    const { statusName, workOrderDetails, handleClose } = props;
    const additionalClasses = useStyles();
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    return (
        <Col md={10} lg={10}>
            <div className={additionalClasses.root}>
                <Col lg="12" md="12" >
                    <Row>
                        <Col xl="10" lg="9" md="10">
                        </Col>

                        <Col xl="2" lg="3" md="2">
                            <Toolbar className={clsx(additionalClasses.toolbar)}>
                                <Tooltip
                                    onClick={handleClose}
                                    className={additionalClasses.tooltipImg}
                                    title="Close"
                                >
                                    <span>
                                        <i className="mr-2 mdi mdi-close" />
                                    </span>
                                </Tooltip>
                            </Toolbar>
                        </Col>
                    </Row>
                </Col>

                <TableContainer
                    sx={{
                        maxHeight: '400px', position: 'relative', overflow: 'scroll', marginTop: '-45px', padding: '5px', overflowX: 'hidden',
                        "&::-webkit-scrollbar": {
                            height: "15px",
                            width: "5px",
                            opacity: "0.9",
                            backgroundColor: '#212121'
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: "100px",
                            backgroundColor: '#2c2c2c',
                            border: 'none'
                        }
                    }}  >
                    <div>
                        <Table aria-label="collapsible table" >
                            <TableHead stickyHeader sx={{ backgroundColor: '#212121' }}>
                                <TableRow sx={{ backgroundColor: '#212121', border: '2px solid #212121', }}>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Workshop ID</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Work Order ID</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Case</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Operations</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>CSC</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Warranty</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Parts</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>Date Scheduled</TableCell>
                                    <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeRegular" }}>{statusName === 'Started' ? 'Date Started' : statusName === 'Assinged' ? 'Date Assigned' : statusName === 'Finalized' ? 'Date Finalized' : statusName === 'Upcoming' ? 'Date Upcoming' : statusName === 'Proposed' ? 'Date Proposed' : 'Date Assigned'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workOrderDetails.map((row, index) => (
                                    <TableRow
                                        sx={{
                                            '& > *': { borderBottom: 'unset' }, backgroundColor: `${index === 0 && (row === selectedRow || !selectedRow) ? '#131313' : '#212121'}`, border: '2px solid #212121', "&.Mui-selected": {
                                                backgroundColor: '#131313',
                                                color: "white",
                                                cursor: "pointer",
                                            },

                                            '&.MuiTableRow-hover': {
                                                '&:hover': {
                                                    backgroundColor: '#2c2c2c'
                                                },
                                            },
                                        }}
                                        hover
                                        onClick={() => { handleRowClick(row); }}
                                        selected={selectedRow === row}
                                    >
                                        <TableCell size='small' component="th" scope="row" sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.workShopId}</TableCell>
                                        <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.workOrderId}</TableCell>
                                        <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.caseId}</TableCell>
                                        <TableCell size='small' component="th" scope="row" sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.operation}</TableCell>
                                        <TableCell size='small' align='left' sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.csc}</TableCell>
                                        <TableCell size='small' sx={{ color: '#DC1860', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}> {'Coming soon'}</TableCell>
                                        <TableCell size='small' sx={{ color: `${row.parts === 'Yes' ? '#1EF1C6' : 'white'}`, fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{row.parts}</TableCell>
                                        <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{extractdateValue(row?.scheduledDate)}</TableCell>
                                        <TableCell size='small' sx={{ color: 'white', fontFamily: "LynkcoTypeLight", fontSize: '13px' }}>{extractdateValue(row?.dateAssigned)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </div>
        </Col>
    );
};

export default WorkOrderDetail;