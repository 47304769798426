import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import UploadButton from 'src/components/buttons/upload-button.component';
import BrowseButton from 'src/components/buttons/browse-button.component';
import XLSX from 'xlsx';
import { UploadEditRVcurveTable } from 'src/services/rvcurve-http.service';
import { FormmatRvTableExcelJson } from 'src/utils/RvTableExcelFormatter'
import { selectUserOrganization } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  presentation: {
    height: theme.spacing(57),
    width: theme.spacing(43),
  },
  cardStyle: {
    "&.MuiCard-root": {
      overflow: 'hidden',
      height: '100%',
      position: 'relative'
    }
  },
  cardActionStyle: {
    "&.MuiCardActions-root": {
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  }
}));


const RVcurveUpload = ({ orgId, organization, editMode, onChange, handleClose, handleDone, handleChange, onUploadComplete, userOrg}) => {

  const [rvSate, setRvState] = React.useState({
    fileName: "Choose file...",
    file: {},
    data: [],
    cols: []
  })


  const handleChangeBrowse = (e) => {
    const files = e.target.files;
    if (files && files[0]) setRvState({ file: files[0], fileName: files[0].name });
  };

  const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
  };

  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      console.log("data ", data)
      var newData = FormmatRvTableExcelJson(data)
      console.log("newData ", newData)
      /* Update state */
      setRvState({ data: newData, cols: make_cols(ws['!ref']) });
      console.log(rvSate.data)
      UploadEditRVcurveTable(newData, userOrg.id, false).then(
        onUploadComplete()
      );
      //   onUploadComplete();
      handleClose();
    };

    if (rABS) {
      reader.readAsBinaryString(rvSate.file);
    } else {
      reader.readAsArrayBuffer(rvSate.file);
    };

  }

  const classes = useStyles();

  return (
    <div role="presentation" className={classes.presentation}>
      <Card classes={{ root: classes.cardStyle }}>
        <CardHeader title={"Upload RV Curve File"} />
        <Divider />
        <CardContent>
          <FormTextField
            onChange={handleChange}
            disabled={false}
            value={rvSate.fileName}
          />
        </CardContent>
        <CardActions classes={{ root: classes.cardActionStyle }} >
          <UploadButton
            variant="contained"
            handleFile={handleFile}
            handleClose={handleClose}
            color="default"
          />
          <BrowseButton
            handleChangeBrowse={handleChangeBrowse}
          />
        </CardActions>
      </Card>
    </div>
  );
}


const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state)
});

export default connect(mapStateToProps, null)(RVcurveUpload)