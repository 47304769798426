import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";

export async function GetAllPNOCodes() {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/PNOCode/GetAllPNOCodes`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function GetPNOCodesByMarket(marketID) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/PNOCode/GetPNOCodesByMarket?market=${marketID}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}