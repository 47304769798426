import React from 'react';
import { Col } from 'reactstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Grid from '@material-ui/core/Grid';
import Map from 'src/components/maps/MapPage.component';
// import { ReverseGeocoding } from 'src/services/geocoder.service';
import { useLoadScript } from "@react-google-maps/api";
import { environment } from 'src/environments/environment';

const UseStyles = makeStyles((theme) => ({
    gridContainerStyle: {
        marginLeft: '-32px',
        marginTop: '-85px'
    }
}));

const MapPage = (props) => {
    const classes = UseStyles(props);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: environment.googleMapsApiKey,
        libraries: ["places"]
    });

    return (
        <Col md={12} lg={12}>
            <Grid container className={classes.gridContainerStyle} >
                <Grid item xs={12} md={12}>
                    {isLoaded && <Map />}
                </Grid>
            </Grid>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(MapPage);