import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    button: {
        backgroundColor: (props) => props.iconProp ? '#dc1860' : props.rsa ? '#DC1860' : props.widthProp ? '#000' : '#1ef1c6',
        borderRadius: '300px',
        height: (props) => props.height ? '40px' : props.width ? '30px' : props.iconProp ? '40px' : '33px',
        color: (props) => props.iconProp ? '#000' : props.rsa ? 'white' : props.widthProp ? 'darkgray' : '#000',
        border: (props) => props.iconProp ? '' : props.rsa ? '' : '',
        textTransform: 'initial',
        fontFamily: 'LynkcoTypeBold',
        '&:hover': {
            backgroundColor: (props) => props.iconProp ? 'transparent' : props.rsa ? '#893257' : '#088a70',
            color: (props) => props.iconProp ? '#339586' : '#000',
        },
        whiteSpace: 'nowrap',
        width: (props) => props.width ? '3vw' : props.iconProp ? '7.5vw' : props.widthProp ? '7.5vw' : '7vw',
        fontSize: '15px',
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            fontSize: '13px',
        },
        '@media (min-width: 600px) and (max-width: 1000px)': {
            width: (props) => props.width ? '3vw' : props.iconProp ? '25vw' : props.widthProp ? '25vw' : '7vw',
            height: (props) => props.height ? '55px' : props.width ? '30px' : props.iconProp ? '55px' : '33px',
            fontSize: '20px',
        }
    }
});

const SearchButton = (props) => {
    const classes = useStyles(props);
    const { handleSearch, placeholder, icon, iconProp = false, height, rsa, width, widthProp = false, type } = props;

    return (
        <Button onClick={handleSearch} className={classes.button} variant="contained" placeholder={placeholder} icon={icon} type={type}  >
            {placeholder}
            {icon}
        </Button>
    );
}

export default SearchButton;