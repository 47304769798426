import { combineReducers } from 'redux'
import toggleSidebar from 'src/redux/sidebar/sidebar.reducer'
import userReducer from 'src/redux/user/user.reducer';
import SettingsReducer from "./settings/Reducer";
import todoReducer from 'src/redux/todos/index'
import maintodoReducer from "./todos/Todos";
import sidebarReducer from 'src/redux/sidebarReducer';
import themeReducer from 'src/redux/themeReducer'
import mainContentReducer from 'src/redux/mainContentReducer';
// import todoReducer from "./todos/";

const rootReducer = combineReducers({
    sideBarToggle: toggleSidebar,
    user: userReducer,
    settings: SettingsReducer,   //SettingsReducer
    todoReducer,
    maintodoReducer,
    sidebar: sidebarReducer,
    theme: themeReducer,
    content: mainContentReducer,

})

export default rootReducer;