import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SVTCards from 'src/pages/svt-page/SVTCards';
import 'src/pages/svt-page/dashboard.css';
import 'src/pages/svt-page/standingorder.css';
import MapPage from 'src/pages/svt-page/MapPage';

const SVTMainComponent = ({ mapheight = true, selectedVIN, setSelectedVIN, valueVIN, selectedVINMap, setAddressInput, setSelectedVINMap }) => {

    const [vehiclePosition, setVeiclePosition] = React.useState({});

    return (
        <Col md={12} lg={12}>
            <Row className='svtRowStyle'>
                <Col md={12} sm={12} xs={12} lg={3} className='svtCardStyle'>
                    <SVTCards vehiclePosition={vehiclePosition} />
                </Col>
                <Col md={12} sm={12} xs={12} lg={9}className='svtMapStyle'>
                    <MapPage
                        mapheight={mapheight}
                        vehiclePosition={vehiclePosition}
                        setVeiclePosition={setVeiclePosition}
                        selectedVIN={selectedVIN}
                        setSelectedVIN={setSelectedVIN}
                        valueVIN={valueVIN}
                        selectedVINMap={selectedVINMap}
                        setAddressInput={setAddressInput}
                        setSelectedVINMap={setSelectedVINMap}
                    />
                </Col>
            </Row>
        </Col>
    );
};

export default SVTMainComponent;
