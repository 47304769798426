import React, { useState, useEffect, useMemo } from 'react';
import { Col } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { FormatHourMinute } from 'src/utils/RvTableExcelFormatter';
import moment from 'moment';
import { GetWhoIsConnectedData } from 'src/services/provisioning-http.service';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import Fade from 'react-reveal/Fade';

let rowsPerPage = 500;
let newPageVariable = 1;
let vin = '';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ConnectivityTable = ({ searchVIN, setDisplaySelectedVehicle, setOpenDialog, setMessage, setDialogMessage }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);

    const [rows, setVehicleFleet] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});

    let selectedRowVehiclePostion = {};

    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [tableHight, setTableHeight] = useState(false);
    const [spinnerLength, setSpinnerLength] = useState(false);
    const [disablePagination, setDisablePagination] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'First Name', accessor: "firstName", disableGlobalFilter: true },
            { Header: 'Last Name', accessor: "lastName", disableGlobalFilter: true },
            { Header: 'Customer No.', accessor: "customerNumber", disableGlobalFilter: true },
            { Header: 'Role', accessor: "role", disableGlobalFilter: true, },
            { Header: 'Provisioned', accessor: "provisionedDateUtc", disableGlobalFilter: true },
            { Header: 'Unprovisioned', accessor: "unprovisionedDateUtc", disableGlobalFilter: true },
            { Header: 'Status', accessor: "provisioningStatus", disableGlobalFilter: true, }
        ],
        [],
    );

    useEffect(() => {
        setVehicleFleet([]);
        setTableHeight(true);
        setSpinnerLength(true);
        initializeFleetVehicles();
    }, [searchVIN]);

    const initializeFleetVehicles = () => {
        let vehicleFleetArray = [];

        GetWhoIsConnectedData({ vin: searchVIN?.trim(), pageNumber: newPageVariable, pageSize: rowsPerPage }).then(data => {
            if (data.ok == true) {
                if (data && Array.isArray(data.items)) {
                    setTotalItems(data.totalItems ?? 0)
                    data.items.map(vehicle => {
                        vehicleFleetArray.push({
                            firstName: vehicle.firstName,
                            lastName: vehicle.lastName,
                            customerNumber: vehicle.customerNumber,
                            role: vehicle.role == 'MainUser' ? 'Primary Driver' : vehicle.role,
                            provisionedDateUtc: vehicle.provisionedDateUtc == null ? null : FormatHourMinute(moment(vehicle.provisionedDateUtc).format("YYYY-MM-DD HH:mm").toString()),
                            unprovisionedDateUtc: vehicle.unprovisionedDateUtc == null ? null : FormatHourMinute(moment(vehicle.unprovisionedDateUtc).format("YYYY-MM-DD HH:mm").toString()),
                            provisioningStatus: vehicle.unprovisionedDateUtc == null ? 'ACTIVE' : 'INACTIVE'
                        })
                    })
                    setDataLoaded(true)
                    setVehicleFleet(vehicleFleetArray);
                }
            } else {
                setDisplaySelectedVehicle(false);
                setOpenDialog(true);
                setMessage(data.title);
                setDialogMessage('Provisioning data cannot be found for this vehicle');
            }

        })
    };

    /////////////////////////////////////////////////////////////////////

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        vin = selectedRowVehiclePostion.vin;
    }

    const closeHandleDrawer = () => {
        setOpen(false);
    }

    return (
        <Col md={12} lg={12}>
            <div className="tableFixHeadWhoisConnectedTable">
                <Fade bottom duration={3000}>
                    <ReactTableBaseVehiclePage
                        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                        columns={columns}
                        data={rows}
                        updateEditableData={updateEditableData}
                        updateDraggableData={updateDraggableData}
                        tableConfig={tableConfig}
                        isColumnHidden={isColumnHidden}
                        isSelected={isSelected}
                        selectedRowValues={selectedRowValues}
                        handleDrawar={handleDrawar}
                        closeHandleDrawer={closeHandleDrawer}
                        selected={selected}
                        tableHight={tableHight}
                        disablePagination={!disablePagination}
                    />
                </Fade>
            </div>
            <div className='paginationWhoisConnected' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
        </Col>
    );
};

export default ConnectivityTable;