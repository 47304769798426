import React from 'react';
import LoadingViewPlaceHolder from './loadingviewplaceholder';

function LoadingImagePlaceHolderRVTable() {
  let loadViewCounterOne = [0]

  return (
    <div>
      {loadViewCounterOne.map(o => 
      <>
        <table style={{ width: "100%" }}>
          <tr>
            <td>
              <LoadingViewPlaceHolder extraStyles={{
                height: '15px', width: '125px', borderRadius: '10px',
                marginTop: '20px'
              }} />
            </td>           
          </tr>
        </table>
    </> 
    )}
    </div>
  );
}

export default LoadingImagePlaceHolderRVTable;