import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-bootstrap';
import { TopbarBack, TopbarButton } from 'src/containers/LayoutNew/components/topbar/BasicTopbarComponents';
import {
  TopbarCollapse,
  TopbarCollapseButton,
  TopbarCollapseContent,
  TopbarCollapseDate,
  TopbarCollapseItem,
  TopbarCollapseMessage,
  TopbarCollapseName,
  TopbarCollapseTitle,
  TopbarCollapseTitleWrap,
} from 'src/containers/LayoutNew/components/topbar/CollapseTopbarComponents';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { FormatHourMinute } from 'src/utils/RvTableExcelFormatter';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { permission } from 'src/auth/permissions';
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserGraphData } from "src/redux/user/user.selectors";

const alertIconNew = `${process.env.PUBLIC_URL}/assets/icons/Newnotifications.png`;
const alertIcon = `${process.env.PUBLIC_URL}/assets/icons/Nonewnotification.png`;

const TopbarNotification = (props) => {
  const { userPermissions, graphData } = props

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showNotificationsMessage, setShowNotificationsMessage] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [notificationMessageReceived, setNotificationMessageReceived] = useState({});

  const [showNotifications, setShowNotifications] = useState(false);
  const userName = graphData.displayName;

  const collapseNotification = () => {
    setIsCollapsed(!isCollapsed);
    setShowNotifications(false);
    setShowNotificationsMessage(!showNotificationsMessage);
  };

  const matchesPermission = userPermissions.includes(permission.Connectivity_Reader);

  // create connection
  useEffect(() => {
    if (userName !== '' && matchesPermission) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${environment.backendNotificationService}/hubs/simactivationhub`)
        // .configureLogging(signalR.LogLevel.Debug)
        .build()

      // connect to method that hub invokes
      connection.on("ReceiveMessage", (ReceiveMessage) => {
        if (graphData.mail === ReceiveMessage.userId) {
          setNotificationMessage([...notificationMessage, ReceiveMessage]);
          setNotificationMessageReceived(ReceiveMessage);
        }
      }
      )
      // start connection
      connection.start().then(() => {
        if (!showNotificationsMessage) {
          setShowNotifications(true);
        }
      });
    };
  }, [notificationMessage.length, showNotifications, notificationMessageReceived]);

  const getItemsInAscendingDateOrderAndClosestToNowFirst = (arr) => {
    const time = Date.now();

    const [closest, ...rest] = Array
      // create a shallow copy in order to not mutate the original reference.
      .from(arr)
      // sort items by date closest to now.
      .sort((a, b) => {
        const aTime = new Date(a.sentDate).getTime();
        const bTime = new Date(b.sentDate).getTime();

        const aDelta = Math.abs(time - aTime);
        const bDelta = Math.abs(time - bTime);

        return (aDelta - bDelta);
      });

    return [
      closest,
      ...rest
        // sort all other items in ascending date order.
        .sort((a, b) =>
          new Date(b.sentDate).getTime()
          - new Date(a.sentDate).getTime()
        ),
    ];
  };

  const sortedData = getItemsInAscendingDateOrderAndClosestToNowFirst(notificationMessage);

  return (
    <TopbarCollapse>
      <TopbarButton type="button" onClick={collapseNotification}>
        <img className='notificationStyle' src={notificationMessage.length > 0 && showNotifications && !showNotificationsMessage ? alertIconNew : alertIcon} />
      </TopbarButton>
      {isCollapsed && (
        <TopbarBack
          aria-label="topbar__back"
          type="button"
          onClick={collapseNotification}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarCollapseContent>
          <TopbarCollapseTitleWrap>
            <TopbarCollapseTitle>Notifications</TopbarCollapseTitle>
            <TopbarCollapseButton type="button"></TopbarCollapseButton>
          </TopbarCollapseTitleWrap>
          {sortedData.map(notification => (
            <TopbarCollapseItem key={notification?.messageId}>
              <TopbarCollapseMessage>
                <TopbarCollapseName>{notification?.messageTitle}</TopbarCollapseName>
                {notification?.messageText}
              </TopbarCollapseMessage>
              <TopbarCollapseDate>{FormatHourMinute(moment(notification?.sentDate).format("YYYY-MM-DD HH:mm").toString())}</TopbarCollapseDate>
            </TopbarCollapseItem>
          ))}
        </TopbarCollapseContent>
      </Collapse>
    </TopbarCollapse>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state),
  userPermissions: selectUserPermissions(state),
  graphData: selectUserGraphData(state)
});

export default connect(mapStateToProps, null)(TopbarNotification);