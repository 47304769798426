import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.driverPage';
import IconButton from '@material-ui/core/IconButton';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { FormmatDate } from 'src/utils/DateFormatter';

const UseStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-14px',
        overflow: 'auto',
        height: '758px',
        marginRight: '-32px',
        paddingRight: '20px',
        // marginLeft: '5px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            opacity: "0.3",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.01)"
        }
    },
    dividerStyle: {
        backgroundColor: (props) => props.endDate == null ? "rgba(255, 255, 255, 0.05)" : '#1EF1C6',
        marginLeft: theme.spacing(1.4),
        marginRight: -theme.spacing(1),
        marginTop: -theme.spacing(2.5),
        height: '120px',
        width: '0.08px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        marginLeft: '-50px',
    },
    heading: {
        "&.MuiTypography-root": {
            color: "#fff",
            fontFamily: 'LynkcoTypeLight,',
            fontSize: '15px'
        }
    },
    startDateIcon: {
        color: '#1EF1C6',
        marginTop: '40px',
        marginLeft: '-10px'
    },
    endDateIcon: {
        color: (props) => props.endDate == null ? "rgba(255, 255, 255, 0.2)" : '#1EF1C6',
        marginTop: '-20px',
        marginLeft: '-10px',
    },
    iconBottom: {
        fontSize: '14px',
        fontFamily: "LynkcoTypeMedium",
        marginTop: (props) => props.endDate == null ? "133px" : '112px',
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '-10px'
    },
}));


const MasterWorkOrder = (props) => {
    const { data } = props

    const classes = UseStyles();

    return (
        <Col md={12} lg={12}>

            <div className={classes.root}>
                <Card style={{ backgroundColor: 'rgb(19 19 19 / 35%)', height: '800px', }}>
                    <CardBody style={{ padding: '50px' }}>
                        {
                            data !== undefined ? data.map(value => {
                                return (
                                    <Row>
                                        <Col lg="12" md="12" >
                                            <div style={{ marginTop: '-45px', marginBottom: '50px' }}>
                                                <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <div style={{ marginTop: '12px', marginBottom: '100px' }}>
                                                            <FirstGrid endDate={FormmatDate(value.endDate)} startDate={FormmatDate(value.startDate)} />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <div style={{ marginTop: '20px', marginLeft: '-60px' }}>
                                                            <SecondGrid endDate={value.endDate} />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <div style={{ marginTop: '12px', marginLeft: '-130px' }}>
                                                            <ThirdGrid workshopId={value.workshopName} operationName={value.operationName} />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Col>

                                    </Row>
                                )
                            }) : ''
                        }

                    </CardBody>
                </Card>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(MasterWorkOrder);


///// SELECT *
const FirstGrid = (props) => {
    const { startDate, endDate } = props
    const classes = UseStyles(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div >
                    <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeMedium", marginTop: '65px' }}><span >{startDate}</span></Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div >
                    <Typography className={classes.iconBottom}><span >{endDate}</span></Typography>
                </div>
            </Grid>
        </Grid>
    )
};

const SecondGrid = (props) => {
    const classes = UseStyles(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div title="Upload" >
                    <span>
                        <IconButton aria-label="Start Date">
                            <Brightness1Icon className={classes.startDateIcon} style={{ width: '18px', height: '38px' }} />
                        </IconButton>
                    </span>
                </div>
                <div  >
                    <Divider className={classes.dividerStyle} orientation='vertical' />
                </div>
                <div title="Upload"  >
                    <span>
                        <IconButton aria-label="End Date">
                            <Brightness1Icon className={classes.endDateIcon} style={{ width: '20px', height: '40px' }} />
                        </IconButton>

                    </span>
                </div>
            </Grid>
        </Grid>
    )
};

const ThirdGrid = (props) => {
    const { workshopId, operationName } = props

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div >
                    <div >
                        <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeMedium", marginTop: '85px' }}><span >Workshop</span></Typography>
                        <div style={{ marginLeft: '-0.8vw', marginTop: '-20px' }}>
                            <FormTextFieldSalesPlain value={workshopId} disabled />
                        </div>
                    </div>
                </div>
                <div >
                    <div >
                        <Typography style={{ fontSize: '14px', fontFamily: "LynkcoTypeMedium", marginTop: '30px' }}><span >Operation</span></Typography>
                        <div style={{ marginLeft: '-0.8vw', marginTop: '-20px' }}>
                            <FormTextFieldSalesPlain value={operationName} disabled />
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
