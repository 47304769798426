import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Row, Col, CardImg, } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.mapPage';
import { FormmatDateMap } from 'src/utils/DateFormatter';
import blackVehicle from 'src/assets/img/vehicles/blackVehicle.png';
import blueVehicle from 'src/assets/img/vehicles/blueVehicle.png';

const UseStyles = makeStyles({
    gridContainerStyle: {
        marginTop: '-25px',
        marginLeft: '-20px',
    }
});

const InfoWindowComponent = (props) => {
    const { vehiclePosition } = props
    const classes = UseStyles();
    return (
        <Col md={12} lg={12}>
            <div style={{
                marginLeft: '-14px',
                backgroundColor: 'white',
                height: '115px',
                width: '300px',
                marginBottom: '-5px'
            }}>
                <Row>
                    <Col lg="6" md="12" >
                        <div>
                            <CardImg
                                alt="Card image cap"
                                src={vehiclePosition.color == 'Blue' ? blueVehicle : blackVehicle}
                                style={{ transform: 'scale(.85)', marginLeft: '5px', marginTop: '-3px' }}
                            />
                        </div>
                    </Col>
                    <Col lg="6" md="12" >
                        <div style={{ marginRight: '-50px' }}>
                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography style={{ marginLeft: '15px', fontSize: '12px', color: '#000', marginTop: '20px', fontFamily: 'LynkcoTypeMedium,' }} variant="h4" id="tableTitle" component="div">
                                        VIN
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '-28px', }}>
                                    <FormTextFieldSalesPlain removeBorder='true' value={vehiclePosition.vin} disabled />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography style={{ marginLeft: '15px', fontSize: '12px', color: '#000', fontFamily: 'LynkcoTypeMedium,' }} variant="h4" id="tableTitle" component="div">
                                        Odometer
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '-28px', }}>
                                    <FormTextFieldSalesPlain removeBorder='true' value={vehiclePosition.odometer} disabled />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography style={{ marginLeft: '15px', fontSize: '12px', color: '#000', fontFamily: 'LynkcoTypeMedium,' }} variant="h4" id="tableTitle" component="div">
                                        Last Updated
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '-28px', }} >
                                    <FormTextFieldSalesPlain removeBorder='true' value={FormmatDateMap(vehiclePosition.vehicleUpdatedAt)} disabled />
                                </Grid>
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(InfoWindowComponent);