import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import Chart from "react-apexcharts";

const TotalFleetSizeView = () => {
  const optionsvisitors = {
    labels: [ "M2M", "Employee", "Test Drive"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
            },
            total: {
              show: true,
//            label: "Our Visitors",
              color: "#99abb4",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    colors: ["#1e88e5","#26c6da", "#745af2"],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };
  const seriesvisitors = [50, 40, 30];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Dashboard-1,2                                                          */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <CardTitle>Total Fleet Size</CardTitle>
        <CardSubtitle className="mb-3">
          Current Month
        </CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors}
          type="donut"
          height="255px"
        />
      </CardBody>
      <div>
        <hr className="mt-0 mb-0" />
      </div>
      <CardBody>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            <h6 className="text-cyan">
              <i className="fa fa-circle font-10 mr-2" />
              M2M
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-primary">
              <i className="fa fa-circle font-10 mr-2" />
              Test Drive
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-success">
              <i className="fa fa-circle font-10 mr-2" />
              Employee
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TotalFleetSizeView;
